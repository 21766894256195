import { DFBasicInputs } from './input-components';

import * as v from './validators';
import * as c from './converters';

import { InputDRecordArrayItem } from '../components/InputDRecrod';

import { useDeepSchemaBuilder } from '../composables/useDeepSchemaBuilder';

type Library = {
  recordItem: InputDRecordArrayItem,
  number: number,
  string: string,
}

let dfBasicLibrary: ReturnType<typeof initLibrary> | undefined = undefined;

function getLibrary() {
  if (dfBasicLibrary) return dfBasicLibrary;
  dfBasicLibrary = initLibrary();
  return dfBasicLibrary;
}

function initLibrary() {

  const lib = useDeepSchemaBuilder<Library, typeof DFBasicInputs>(DFBasicInputs);

  lib.add('recordItem').input('dform').data([
    lib.spawn<string>('key').input('vtext').mandatory()
      .props({ dfCol: 6, hideDetails: true, class: 'mr-md-4' }).item(),
    lib.spawn<string>('value').input('vtext').mandatory()
      .props({ dfCol: 6, hideDetails: true }).item(),
  ])
    .init(() => ({ key: '', value: '' }))
    .title('');

  lib.add('number').input('text').validators([v.number]).convert(c.number);
  lib.add('string').input('text');

  return lib;
}

export { getLibrary };
