<template>
  <div>
    <slot
      v-for="field in fields"
      v-bind="{
        field,
        values,
        props: {
          ...field.inputProps,
          label: field.label,
          type: field.input,
          hidden: field.hidden,
          optional: !field.required,
          onValid: (value: boolean) => onValid(field.name, value),
          modelValue: values[field.name],
          'onUpdate:modelValue': (value: any) => {
            values[field.name] = value;
          },
          'onUpdate:safeValue': (value: any) => {
            safeValues[field.name] = value;
          },
        },
        uiInput:inputComponent,
      }"
      :name="field.name"
    >
      <div
        v-if="!field.hidden"
        :key="field.name"
        :class="field.displaySelf?.class"
        :style="getDisplaySelfStyle(field.displaySelf)"
      >
        <ByType
          :modelValue="values[field.name]"
          :optional="!field.required"
          :label="$t(field.label || '')"
          :type="field.input"
          v-bind="field.inputProps"
          @valid="onValid(field.name, $event); emit('valid', isValid)"
          @update:model-value="(val: any) => values[field.name] = val"
          @update:safe-value="(val: any) => safeValues[field.name] = val"
        />
      </div>
    </slot>

    <slot name="submit" v-bind="{ onSubmit, isValid, loading }">
      <!-- submit button -->
      <VBtn
        class="mt-4"
        :disabled="!isValid"
        :loading="loading"
        @click="onSubmit"
      >
        {{ submitText || $t('dialogs.submit') }}
      </VBtn>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { markRaw, onMounted, ref } from 'vue';
import { VBtn } from 'vuetify/lib/components/index.mjs';
import { useInputValidator } from '../composables/useInputValidator';
import { DisplayMod, FormField } from '../lib/form.interface';
import { INPUTS_MAP } from '../lib/inputs.interface';

import ByType from '../inputs/by-type.vue';

const props = defineProps<{
  fields: FormField<keyof typeof INPUTS_MAP>[];
  submitText?: string;
   
  onSubmitFn?: (values: Record<string, any>) => Promise<void>;
}>();

const emit = defineEmits<{
  (event: 'submit', values: Record<string, unknown>): void;
  (event: 'valid', value: boolean): void;
}>();

const inputComponent = markRaw(ByType);

const loading = ref(false);
const { onValid, isValid } = useInputValidator();

 
const values = ref<Record<string, any>>({});

 
const safeValues = ref<Record<string, any>>({});

function getDisplaySelfStyle(displaySelf?: DisplayMod): string | undefined {
  if (!displaySelf) return undefined;

  let result = displaySelf.style || '';

  if (displaySelf.widthPercent) {
    result += `width: ${displaySelf.widthPercent}%;`;
  }

  return result;
}

async function onSubmit() {
  //console.log('onSubmit', safeValues.value);
  emit('submit', safeValues.value);
  if (props.onSubmitFn) {
    loading.value = true;
    await props.onSubmitFn(safeValues.value);
    loading.value = false;
  }
}

onMounted(() => {
  for (const field of props.fields) {
    values.value[field.name] = field.initialValue;
    safeValues.value[field.name] = field.initialValue;
  }
});

</script>
