
import { TableGridPaginationState } from "@/models/table-grid-interface";
import { parseVMListToTableGridItems } from "./properties-mapping-service";

import { useAccountApi, FilterItem } from '@ui-api3-sdk/api/api3';
import { usePropertiesStore } from '../pinia/usePropertiesStore';


export const FRONTLINE_MAX_LIMIT = 500;

export type FrontlineServiceParams = {
  treeId: number,

  accountId: number,
  offset?: number,
  positionId?: number,

  properties: string[],

  filter?: FilterItem[],
  pagination?: TableGridPaginationState,
  showInactive?: 'none' | 'all_inactive' | 'only_disabled',
};

export async function fetchFrontline(fp: FrontlineServiceParams, maxNodesPerRequest?: number) {

  const requestPlanProperties = fp.properties.filter((p) => p.startsWith('m.')).map((p) => p.split('.')[1]);

  const maxNodes = maxNodesPerRequest || FRONTLINE_MAX_LIMIT;
  let hitRequestLimit = false;

  const rp = {
    id: fp.accountId,
    limit: fp.pagination?.limit || maxNodes,
    page: fp.pagination?.page ? fp.pagination?.page - 1 : 0,
    treeId: fp.treeId,
    properties: requestPlanProperties,
    offset: fp.offset,
    positionId: fp.positionId,
    showInactiveAccounts: false,
    showDisabledAccounts: false,
  };

  if (fp.showInactive === 'all_inactive') {
    rp.showInactiveAccounts = true;
    rp.showDisabledAccounts = true;
  } else if (fp.showInactive === 'only_disabled') {
    rp.showDisabledAccounts = true;
  }

  const res = await useAccountApi().v2FrontLineControllerGetFrontline(rp, {
    params: {
      filter: fp.filter,
      orderBy: fp.pagination?.orderBy,
    },
  });

  usePropertiesStore().updatePlanProperties(res.data.map.planProperties);
  usePropertiesStore().updateProfileFields(res.data.map.profileFields);

  const totalItems = res.data.payload.total;
  const downlinePositionList = res.data.payload.list;

  const tableItems = parseVMListToTableGridItems(
    [],
    downlinePositionList,
  );

  hitRequestLimit = totalItems > (rp.page * rp.limit) + tableItems.length;

  return {
    totalItems,
    tableItems,

    maxNodes,
    hitRequestLimit,
    lastNode: tableItems.length > 0 ? tableItems[tableItems.length - 1] : undefined,
    lastParams: {
      ... fp,
      pagination: {
        ... fp.pagination,
        page: fp.pagination?.page ? fp.pagination.page : 1,
        limit: rp.limit,
      },
    },
  };

}
