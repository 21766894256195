<!-- eslint-disable @typescript-eslint/no-explicit-any -->

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { INPUTS_MAP } from '../lib/inputs.interface';

const props = defineProps<{
  type?: keyof typeof INPUTS_MAP;
  modelValue: any;
  optional?: boolean;
  customRules?: ((value: any) => string | true)[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: any): void,
  (event: 'update:safeValue', value: any): void,
  (event: 'valid', value: boolean): void,
}>();

const { t } = useI18n();

if (!props.type) {
  throw new Error('Type is required');
}

const input = INPUTS_MAP[props.type as keyof typeof INPUTS_MAP];

if (!input) {
  throw new Error(`Type ${props.type} is not supported`);
}

const zod = props.optional ? input.zod.optional() : input.zod;

function convertOut(value: any) {
  const convFn = input.convertOut;
  if (! convFn) return value;
  return convFn(value);
}

 
function convertIn(value: any) {
  const convFn = input.convertIn;
  if (! convFn) return value;
  return convFn(value);
}

const value = computed({
  get: () => convertIn(props.modelValue),
  set: (value) => {
    emit('update:modelValue', value);

    const sv = convertOut(value);

    if (typeof value !== 'object' && convertIn(sv) !== value)
      emit('update:safeValue', undefined);
    else
      emit('update:safeValue', sv);

  },
});

function rules(value: any) {

  if (props.optional && !value) {
    emit('valid', true);
    return true;
  }

  if (! props.optional && ( (value === null) || (value === undefined) || (value === "") ) ) {
    emit('valid', false);
    return t('inputs.err.field_required');
  }

  if (props.customRules) {
    for (const rule of props.customRules) {
      const res = rule(value);
      if (res !== true) {
        emit('valid', false);
        return res;
      }
    }
  }

  const val = convertOut(value);
  const p = zod.safeParse(val);

  emit('valid', p.success);

  return p.success
    ? true :
      ( input.formatErrorMsg || p.error.errors.map((e) => e.message).join(', ') );

}

onMounted(() => {
  rules(props.modelValue);
});

watch(() => props.modelValue, (nv) => {
  rules(nv);
  // inputRef.value?.validate();
});

watch(() => props.optional, () => {
  rules(value.value);
  // inputRef.value?.validate();
});

</script>

<template>
  <Component
    :is="input.component"
    v-model="value"
    :rules="[rules]"
  />
</template>
