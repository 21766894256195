
export interface AutoshipProps {
  id?: string
  title?: string
  headerClass?: string
  alias?: string,
  value?: string,
}

export const defaults = {
  headerClass: 'bg-primary',
  alias: 'shopify_customer_tags',
  value: 'appstle_subscription_active_customer',
};
