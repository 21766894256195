import { gravComponentSchema, createInputColorRule } from '../deep-form-grav';
import { LeaderBoardProps, defaults } from '../../../../components/ui/LeaderBoard';
import { queryPropertiesList } from '../lib/helpers';
import { ColorRule } from '@/utils/conditional-colors';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<LeaderBoardProps>({
  title: 'LeaderBoard',
  link: 'https://www.notion.so/mlmsoft/LeaderBoard-3a0a4a3952944ee3b3479c746f2905c6',
  text: text(),
},
  defaults,
);


export default toExport;

const leaderBoardPropertyNoPrefix = s.spawn<string>('property').input('dictionary')
  .dataAsync(async () => {
    const vmlb = await queryPropertiesList([{ prefix: 'vmlb', addNamePrefix: 'Leaderboard', removePrefix: true }]);
    return [...vmlb];
  })
  .init(() => '')
  .mandatory();

const leaderBoardProperty = s.spawn<string>('property').input('dictionary')
  .dataAsync(async () => queryPropertiesList([{ prefix: 'vmlb', addNamePrefix: 'Leaderboard' }]))
  .init(() => '')
  .mandatory();

s.add('boardId').as(bl.clone('number'))
  .title('Board ID')
  .desc('The ID of the report table should be provided to you by support team.')
  .col(4);

s.add('avatarSize').as(bl.clone('number'))
  .title('Avatar size').col(4);

s.add('showPositionChange').input('boolean')
  .title('Show Position Change')
  .desc('If enabled, the position change will be shown.')
  .col(4);

s.add('items').input('dlist').data(
  leaderBoardPropertyNoPrefix
    .props({ hideDetails: true }).item(),
)
  .title('Table columns')
  .col(4);

s.add('itemTitles').input('drecord').data(
  s.spawn().input('dform').data([
    s.spawn<string>('key').asMany([l.clone('itemLeft'), leaderBoardPropertyNoPrefix]).title('Column').item(),
    l.spawn<string>('value').asMany([l.clone('itemRight'), l.clone('translatedText')]).item(),
  ])
    .item(),
)
  .title('Item titles')
  .col(8);

const colorRuleForm = createInputColorRule('colorRuleForm', leaderBoardProperty);

s.add('colors').input('dform').data([
  l.spawn<Array<ColorRule>>('fields').input('dlist').data(
    colorRuleForm.item(),
  ).item(),
]).init(() => ({}))
  .title('Conditional colors')
  .desc('You can use $myAccount template to refer to the current user account number.')
  .col(12);

function text() {
  return `
<b>Leader Board</b>
If you have metabase reporting service enabled at your MLMSoft account, you may use this component to show the leader board of your top performers. You may specify the number of top performers to be shown, and you may also specify the metrics to be shown. You may also specify the colors for the leader board.`;
}
