import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";
import { InputByTypeProperties } from "@/models/form-builder-interface";

export interface AccountToAccountProps {
  id?: string;
  accountId?: number;
  walletId: number;
  inputProperties?: InputByTypeProperties;
  confirmBy?: ConfirmableFieldAlias;
}

export const defaults = {};
