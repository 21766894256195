import { gravComponentSchema } from '../deep-form-grav';
import { AutoshipProps, defaults } from '../../../../components/ui/Autoship';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<AutoshipProps>({
  title: 'Autoship',
  link: 'https://www.notion.so/mlmsoft/Autoship-6ddad6176f874259be95b12b37601465',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('title').input('text').props({ clearable: true })
  .title('Header Title')
  .flag('isText')
  .col(12);

  s.add('alias').input('text').props({ clearable: true })
  .title('Condition field')
  .flag('isText')
  .col(6);

  s.add('value').input('text').props({ clearable: true })
  .title('Condition value')
  .flag('isText')
  .col(6);

function text() {
  return `<b>UI Component: Autoship Box</b>`;
}
