<template>
  <div>
    <v-dialog
      v-model="modifyOperationDialog"
      maxWidth="800"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between">
            <div>Bulk modify: {{ selectedModifyOperationTitle }} ( {{ currentFileIndex }} / {{ totalFiles }} )</div>
            <v-btn icon @click="modifyOperationDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          {{ srcCurrentFile }}
          <v-textarea
            v-model="srcContent"
            label="Source content"
            rows="10"
            class="mt-1"
          />
          {{ dstCurrentFile }}
          <v-textarea
            v-model="dstContent"
            label="Destination content"
            rows="10"
            class="mt-1"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="cancelPressed = true"
          >
            STOP
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="cancelPressed = true; modifyOperationDialog = false"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-select
      v-model="selectedOperation"
      prependInnerIcon="mdi-run"
      :items="operations"
      itemTitle="text"
      itemValue="value"
      label="Do with selected files"
      class="mt-1"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>
      <template #append>
        <v-btn
          :color="selectedOperation === 'delete' ? 'error': 'primary'"
          :disabled="!selectedOperation || !selectedFiles?.length"
          @click="performOperation"
        >
          {{ selectedOperation || 'do' }}  ({{ selectedFiles?.length || 0 }})
        </v-btn>
      </template>
    </v-select>
    <div v-if="selectedOperation === 'modify'" class="d-flex align-center">
      <v-select
        v-model="selectedModifyOperation"
        prependInnerIcon="mdi-cog"
        :items="modifyOperations"
        itemTitle="title"
        itemValue="id"
        label="Tool"
        class="mt-1"
      />
      <v-text-field
        v-model="srcLanguage"
        label="Source language"
        class="mt-1 ml-4"
      />
      <v-icon class="mx-4">mdi-arrow-right</v-icon>
      <v-text-field
        v-model="dstLanguage"
        label="Destination language"
        class="mt-1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref , computed } from 'vue';
import { useGravManager } from '../comosables/useGravManager';
import { useToast } from '@/composables';

import modifyOperations from '../fileOperations';

type FileOperation = {
  text: string,
  value: string,
  enabled: boolean,
  handler: (files: string[]) => Promise<boolean | undefined>,
};

const props = defineProps<{
  selectedFiles?: string[] | null,
}>();

const emit = defineEmits<{
  (event: 'onOperationDone'): void;
}>();

 
const operations: Array<FileOperation> = [
  { text: 'Save to backup', value: 'backup', enabled: true,
    handler: (files: string[]) => useGravManager().upcliPagesToBackup(files)},
  { text: 'Restore from backup', value: 'restore', enabled: true,
    handler: (files: string[]) => useGravManager().upcliBackupToPages(files)},
  { text: 'Delete', value: 'delete', enabled: true,
    handler: (files: string[]) => useGravManager().upcliDeleteFiles(files)},
  { text: 'Bulk modify', value: 'modify', enabled: true, handler: bulkModify},
];

const selectedOperation = ref<string | null>(null);

const selectedModifyOperationTitle = computed(() => {
  const operation = modifyOperations.find(op => op.id === selectedModifyOperation.value);
  return operation ? operation.title : '';
});
const modifyOperationDialog = ref<boolean>(false);
const selectedModifyOperation = ref<string>(modifyOperations[0].id);
const srcLanguage = ref<string>(useGravManager().selectedPageLanguage);
const dstLanguage = ref<string>('');
const currentFileIndex = ref<number>(0);
const totalFiles = ref<number>(0);

const srcCurrentFile = ref<string>('');
const dstCurrentFile = ref<string>('');
const srcContent = ref<string>('');
const dstContent = ref<string>('');

const cancelPressed = ref<boolean>(false);

async function bulkModify(files: string[]) {

  const operation = modifyOperations.find(op => op.id === selectedModifyOperation.value);
  if (! operation) {
    useToast().error('Select bulk operation');
    return false;
  }

  if (! srcLanguage.value || ! dstLanguage.value) {
    useToast().error('Both languages must be set');
    return false;
  }

  const srcFiles = files.filter(file => file.endsWith(`${srcLanguage.value}.md`));

  if (! srcFiles.length) {
    useToast().error('No files in source language');
    return false;
  }

  modifyOperationDialog.value = true;
  cancelPressed.value = false;
  totalFiles.value = srcFiles.length;

  for (let i = 0; i < srcFiles.length; i++) {
    const file = srcFiles[i];
    currentFileIndex.value = i + 1;

    srcCurrentFile.value = file;

    const content = await useGravManager().upcliReadFile(file);

    if (! content ) {
      useToast().error(`Cannot read file ${file}`);
      return false;
    }

    srcContent.value = content;

    const dstFile = file.replace(`${srcLanguage.value}.md`, `${dstLanguage.value}.md`);
    dstCurrentFile.value = dstFile;

    const result = await operation.handler({
      fileContents: content,
      srcFile: file,
      dstFile,
      srcLang: srcLanguage.value,
      dstLang: dstLanguage.value,
    });

    if (result) {
      dstContent.value = result;
      await useGravManager().upcliWriteFile(dstFile, result);
    } else {
      dstContent.value = useGravManager().fileContent || '';
    }

    if (cancelPressed.value) {
      useToast().error('Operation cancelled');
      return false;
    }
  }


  // return operation.handler(files, srcLanguage.value, dstLanguage.value);

  return true;
}


async function performOperation() {
  if (! props.selectedFiles) return;

  const operation = operations.find(op => op.value === selectedOperation.value);
  if (! operation) return;

  const result = await operation.handler(props.selectedFiles);
  if (result) {
    useToast().ok('Operation done');
    emit('onOperationDone');
  } else {
    //useToast().error('Operation failed');
  }

}

</script>
