import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";

export type PayoutRequestProps = {
  id?: string;
  walletId: number;
  accountId?: number;
  textStyleType?: number;
  showTextPopup?: string;
  showDescriptionPopup?: string;
  paymentList?: number[];
  paymentProperties?: string[];
  editableProperties?: string[];
  edit?: 'all' | 'none' | 'editable' | 'except_editable';
  confirmBy?: ConfirmableFieldAlias;
};

export const defaults = {
  edit: 'all',
};
