<template>
  <GravManagerWrapper :id="id" :componentName="componentName" :properties="props">
    <component :is="RefLink" v-bind="{ style: $attrs.style, ...changableProps }">
      <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import GravManagerWrapper from './GravManagerWrapper.vue';
import { RefLinkProps } from './RefLink.ts';
import RefLink from './RefLink.vue';

import { useChangableProps } from '@/composables/useChangableProps';

const componentName = 'ui-ref-link';
const $slots = defineSlots<Record<string, unknown>>();

const props = defineProps<RefLinkProps>();
const changableProps = useChangableProps(componentName, props);

</script>
