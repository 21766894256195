import { gravComponentSchema } from '../deep-form-grav';
import { ScheduleHistoryProps, defaults } from '../../../../components/ui/ScheduleHistory';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<ScheduleHistoryProps>({
  title: 'ScheduleHistory',
  link: 'https://www.notion.so/mlmsoft/ScheduleHistory-3a3ce781ba414cb789920156043dcfeb',
  text: text(),
},
  defaults,
);

export default toExport;


s.add('metric').as(l.clone('propertyMarketingNoPrefix'))
  .title('Marketing property')
  .col(4);
s.add('title').input('text').title('Title').col(5);
s.add('backgroundClass').input('mdiColor').title('Background class').col(3);
//
s.add('lineWidth').as(bl.clone('number')).title('Line width').col(2);
s.add('color').input('color').title('Line color').col(4);

s.add('gridWidth').as(bl.clone('number')).title('Grid width').col(2);
s.add('gridColor').input('color').title('Grid color').col(4);
//
s.add('axisWidth').as(bl.clone('number')).title('Axis width').col(2);
s.add('axisColor').input('color').title('Axis color').col(4);

s.add('pointRadius').as(bl.clone('number')).title('Point radius').col(2);
s.add('ticksColor').input('color').title('Ticks color').col(4);
//
s.add('limit').as(bl.clone('number')).title('Limit periods').col(2);
s.add('showCurrentPeriod').input('boolean').title('Show current period').col(3);
s.add('fill').input('boolean').title('Fill')
  .desc('Fill the area below the line')
  .col(2);

s.add('hintText').input('text').title('Hint').desc('Popup hint').col(5);

function text() {
  return `
<b>UI Component: Metric History Chart</b>
This component can show a chart of historic values of a specified compensation plan property (metric). There could be a number of similar components shown on the dashboard. You may specify what properties you want to be shown, and chart styles (color, line thickness, filled or not) if you don’t want to spice things up.
  `;
}
