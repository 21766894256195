import { type AccountVM } from '@ui-api3-sdk/api/api3';
import { defineStore } from 'pinia';

import { useAccountApi } from '@ui-api3-sdk/api/api3';

import { useFetchState } from './useFetchState';
import { ref } from 'vue';

export const DOWNLINE_REQUEST_MAX_LIMIT_DEFAULT = 250;

export const useAccountStore = defineStore('account', () => {

  // TODO: these should go to useRuntimeSettingsStore (also why they are reactive?)
  const idMask = ref('000 000 000');
  const replaceProperties = ref<Record<string,string>>({
    'r.id': 's.id',
    'r.fullName': 't.fullName',
  });
  const prefferedCountries = ref<string[]>([]);
  const onlyCountries = ref<string[]>([]);
  const accountSearchFields = ref<string[]>([]);
  const heavyDownlineMode = ref(false);

  const currentAccount = ref<AccountVM | null>(null);
  // TODO: instead of this - keep TableGridItem with properties in store

  const { isNeedFetch, waitFor, setLoading, setError, setFetched } = useFetchState();

  async function getProfilePropertyValue(alias: string) {
    const currentAccount = await getAccountAsync();
    const property = currentAccount?.profile.find(item => item.alias === alias);
    return property?.value;
  }

  async function updateRawProfilePropertyValue(alias: string, newRaw: object | string) {
    const property = await getProfilePropertyValue(alias);
    if (typeof newRaw === 'object' && property) {
      if (!property.raw || typeof property.raw === 'object') {
        property.raw = { ...property.raw, ...newRaw };
        return;
      }
    }

    if (typeof newRaw === 'string' && property) {
      if (!property.raw || typeof property.raw === 'string') {
        (property.raw as unknown as string) = newRaw;
        return;
      }
    }

    // console.log('alias', alias, 'newRaw', newRaw, 'property', property);
    throw new Error('Could not update profile property in store');
  }

  function setHeavyDownlineMode(mode: boolean) {
    heavyDownlineMode.value = mode;
  }

  async function updatePresentableProfilePropertyValue(alias: string, newPresentableValue: string) {
    const property = await getProfilePropertyValue(alias);
    if (property)
      property.presentable = newPresentableValue;
  }

  function getAccountRef() {
    if (isNeedFetch('currentAccount')) fetchAccount();
    return currentAccount;
  }

  function getAccountValue() {
    return currentAccount.value;
  }

  async function getAccountAsync() {
    if (isNeedFetch('currentAccount')) fetchAccount();
    await waitFor('currentAccount');
    return currentAccount.value;
  }


  async function fetchAccount() {
    setLoading('currentAccount');
    return useAccountApi().getAccountCurrent()
      .then(res => {
        currentAccount.value = res.data.payload;
        setFetched('currentAccount');
      })
      .catch( () => {
        setError('currentAccount');
      });
  }

  function setAccount(account: AccountVM) {
    currentAccount.value = account;
    setFetched('currentAccount');
  }

  function setCountriesSettings(preffered: string[], only: string[]) {
    prefferedCountries.value = preffered;
    onlyCountries.value = only;
  }

  function setIdMask(mask: string) {
    idMask.value = mask;
  }

  function setAccountSearchFields(fields: string[]) {
    accountSearchFields.value = fields;
  }

  return {
    currentAccount,

    idMask,
    replaceProperties,
    prefferedCountries,
    onlyCountries,
    accountSearchFields,
    heavyDownlineMode,

    setCountriesSettings,
    setIdMask,
    setAccountSearchFields,
    setHeavyDownlineMode,

    getAccountValue,
    getAccountRef,
    getAccountAsync,
    fetchAccount,

    setAccount,

    getProfilePropertyValue,
    updateRawProfilePropertyValue,
    updatePresentableProfilePropertyValue,
  };

});
