<template>
  <GravManagerWrapper :id="id" :componentName="componentName" :properties="props">
    <component :is="MetricChartLine" v-bind="{ style: $attrs.style, ...changableProps }">
      <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import GravManagerWrapper from './GravManagerWrapper.vue';
import { MetricChartLineProps } from './MetricChartLine.ts';
import MetricChartLine from './MetricChartLine.vue';

import { useChangableProps } from '@/composables/useChangableProps';

const componentName = 'ui-metric-chart-line';
const $slots = defineSlots<Record<string, unknown>>();

const props = defineProps<MetricChartLineProps>();
const changableProps = useChangableProps(componentName, props);

</script>
