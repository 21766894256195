<template>
  <div>
    <v-text-field
      v-model="search"
      label="Search"
      variant="outlined"
      density="compact"
      clearable
    />
    <v-data-table
      v-model="selectedFiles"
      :headers="headers"
      :items="effectiveNodes"
      :showSelect="false"
      :itemsPerPage="-1"
      itemValue="name"
      :search="search"
    >
      <template #bottom />

      <template v-if="hideHeaders" #headers />

      <template #item.name="{ item }">
        <div @click="toggle(item)">
          <span :style="{ marginLeft: `${item.level}em` }">
            <span v-if="item.isFolder">
              <v-icon color="primary">mdi-folder</v-icon>
              {{ shortName(item) }}
              <v-icon color="primary">
                {{ item.isOpen ? `mdi-chevron-down` : `mdi-chevron-right` }}
              </v-icon>
            </span>
            <span v-else>
              <v-icon color="secondary">mdi-file</v-icon>
              <span style="cursor: pointer;" @click="emit('editFile', item.name)">
                {{ shortName(item) }}
              </span>
            </span>
          </span>
        </div>
      </template>

      <template #item.updated="{ item }">
        {{ new Date(item.updated).toLocaleString() }}
      </template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import { GravFileInfoNode } from '@/grav/grav-api-models';
import debounce from 'lodash.debounce';
import { computed, ref, watch } from 'vue';


const props = defineProps<{
  modelValue?: string[] | null;
  nodes: GravFileInfoNode[];
  hideHeaders?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string[]): void;
  (event: 'editFile', value: string): void;
}>();

const selectedFiles = ref<string[]>(props.modelValue || []);
const search = ref('');

const visibleNodes = ref<GravFileInfoNode[]>([]);
const allNodes = ref<GravFileInfoNode[]>([]);
const effectiveNodes = computed(() => search.value ? allNodes.value : visibleNodes.value);

watch(search, debounce((newVal: string) => {
  if (!newVal) return;
  allNodes.value = flattenNodes(props.nodes);
}, 500, { leading: false, trailing: true }));

watch(() => props.nodes, () => {
  visibleNodes.value = flattenAndFilterVisibleNodes(props.nodes);
}, { immediate: true });

watch (() => props.modelValue, () => {
  if (props.modelValue  === null)
    selectedFiles.value = [];
}, { immediate: true });

watch(() => selectedFiles.value, () => {
  emit('update:modelValue', getSelectedFiles(props.nodes, selectedFiles.value));
});

function shortName(node: GravFileInfoNode): string {
  if (search.value) return node.name;
  const removeStart = node.parent?.name.length || 0;
  return node.name.substring(removeStart ? removeStart + 1 : 0);
}


function hideRecursively(nodes: GravFileInfoNode[]): void {
  nodes?.forEach((node) => {
    node.isShown = false;
    if (node.isFolder) {
      node.isOpen = false;
      if (node.children?.length)
        hideRecursively(node.children);
    }
  });
}

function toggle(node: GravFileInfoNode): void {
  node.isOpen = !node.isOpen;
  if (node.children?.length) {
    if (node.isOpen) {
      node.children.forEach((child) => {
        child.isShown = true;
      });
    } else {
      hideRecursively(node.children);
    }
  }
  visibleNodes.value = flattenAndFilterVisibleNodes(props.nodes);
}


function flattenAndFilterVisibleNodes(nodes: GravFileInfoNode[], level = 0, parent?: GravFileInfoNode): GravFileInfoNode[] {
  const visible = nodes.filter((node) => node.isShown);
  const result: GravFileInfoNode[] = [];

  visible.forEach((node) => {
    if (parent) node.parent = parent;
    result.push(node);
    node.level = level;
    if (node.isFolder && node.isOpen) {
      result.push(...flattenAndFilterVisibleNodes(node.children, level + 1, node));
    }
  });

  return result;
}

function flattenNodes(nodes: GravFileInfoNode[], level = 0, parent?: GravFileInfoNode): GravFileInfoNode[] {
  const result: GravFileInfoNode[] = [];

  nodes.forEach((node) => {
    if (parent) node.parent = parent;
    result.push(node);
    node.level = level;
    if (node.isFolder) {
      result.push(...flattenNodes(node.children, level + 1, node));
    }
  });

  return result;
}

function getAllChildren(nodes: GravFileInfoNode[]): string[] {
  const result = new Set<string>();

  nodes.forEach((node) => {

    result.add(node.name);

    if (! node.isFolder || ! node.children?.length)
      return;

    const files = getAllChildren(node.children);
    files.forEach((file) => {
      result.add(file);
    });
  });

  return Array.from(result);
}

function getSelectedFiles(nodes: GravFileInfoNode[], selectedFiles: string[]): string[] {
  const result = new Set<string>();

  nodes.forEach((node) => {

    const fullMatch = selectedFiles.some((file) => file === node.name);
    if (fullMatch)
      result.add(node.name);

    if (! node.isFolder || ! node.children?.length) return;

    if (fullMatch) {
      getAllChildren(node.children).forEach((file) => result.add(file));
      return;
    }

    const files = getSelectedFiles(node.children, selectedFiles);
    files.forEach((file) => result.add(file));

  });

  return Array.from(result);
}


const headers = [
  {
    title: '',
    key: 'data-table-select',
    value: 'data-table-select',
    sortable: false,
  },
  {
    title: 'Name',
    key: 'name',
    value: 'name',
    sortable: true,
  },
  {
    title: 'Size',
    key: 'size',
    value: 'size',
    sortable: true,
  },
  {
    title: 'Updated',
    key: 'updated',
    value: 'updated',
    sortable: true,
  },
];


</script>
