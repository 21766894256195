<template>
  <div class="d-flex align-center align-self-center">
    <slot
      name="avatar"
      :data="data"
      :size="size"
      :avatarThumb="avatarThumb"
      :title="title"
    >
      <MemberAvatar
        :size="size"
        :src="avatarThumb"
        :title="title"
      />
    </slot>
    <div class="ml-4">
      <div class="text-button">
        <slot name="name" :value="title">
          <b>{{ title }}</b>
        </slot>
      </div>
      <div class="pb-2">
        <slot name="account" :value="accountId">
          {{ accountId }}
        </slot>
      </div>
      <TableColumnActions
        v-if="columnActions"
        :actions="firstDefinedColumnActions() ?? []"
        :item="data"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { Item, TableGridItem } from '@/models/table-grid-interface';
import { TableColumnAction, TableColumnActionsSchema, useTableColumnActions } from '@/composables/useTableColumnActions';

import MemberAvatar from './MemberAvatar.vue';
import TableColumnActions from './TableColumnActions.vue';

const props = withDefaults(defineProps<{
  data: TableGridItem;
  size?: number;
  columnActions?: TableColumnActionsSchema;
  titleField?: string,
  subtitleField?: string,
}>(), {
  size: 80,
  columnActions: undefined,
  titleField: 't.fullName',
  subtitleField: 't.accountPlusOffset',
},
);

const usedColumnActions = useTableColumnActions(props.columnActions);


function firstDefinedColumnActions(): TableColumnAction[] | undefined {
  if (! props.columnActions) return undefined;

  const firstColumnName = Object.keys(props.columnActions)[0];
  if (! firstColumnName) return undefined;

  return usedColumnActions.componentProps(firstColumnName)?.actions;
}

const avatarThumb = computed(() => Item(props.data).avatarUrl());
const title = computed(() => String(props.data[props.titleField]?.presentable || 'n/a'));
//const accountId = computed(() =>  formatAccountId(Number(props.data['accountId']?.raw), useAccountStore().idMask));
const accountId = computed(() => String(props.data[props.subtitleField]?.presentable || 'n/a'));

</script>
