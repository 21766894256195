/* eslint-disable brace-style */
import { defineStore } from 'pinia';
import { watch } from 'vue';

export type FetchState = 'not' | 'loading' | 'fetched' | 'error';

export const useFetchState = defineStore({
  id: 'fetch-state',
  state: () => ({
   fetchStates: {} as Record<string, FetchState>,
  }),
  actions: {
    async waitFor(key: string, timeout = 30_000) {
      return new Promise((resolve, reject) => {

        const timer = setTimeout(() => {
          reject(new Error('waitFor timeout'));
        }, timeout);

        if (! (this.$state.fetchStates[key] === 'loading') ) {
          clearTimeout(timer);
          resolve(this.$state.fetchStates[key]);
        } else {
          watch(this.$state.fetchStates, (fetchStates) => {
            if (fetchStates[key] !== 'loading') {
              clearTimeout(timer);
              resolve(fetchStates[key]);
            }
          });
        }
      });
    },
    get(key: string) { return this.fetchStates[key] || 'not'; },
    set(key: string, value: FetchState) { this.fetchStates[key] = value; },
    setLoading(key: string) { this.set(key, 'loading'); },
    setFetched(key: string) { this.set(key, 'fetched'); },
    setError(key: string) { this.set(key, 'error'); },
    setNotFetched(key: string) { this.set(key, 'not'); },
    isLoading(key: string) { return this.get(key) === 'loading'; },
    isFetched(key: string) { return this.get(key) === 'fetched'; },
    isError(key: string) { return this.get(key) === 'error'; },
    isNeedFetch(key: string) {return this.get(key) === 'not'; },
  },
});
