<template>
  <div>
    <ProgressMetricGrid
      v-if="type === 'grid'"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"
      :title="resTitle"
      :label="resLabel"
      :noCheckbox="noCheckbox"
      :goalReached="goalReached"
      :titleClass="titleClass"
      :labelClass="labelClass"
    />
    <ProgressMetricPercent
      v-else-if="type === 'percent'"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"
      :percent="percent"

      :bgColor="bgColor"
      :color="color"
      :colorDone="colorDone"
      :size="size"
      :striped="striped"
      :rounded="rounded"

      :textClass="labelClass"
    >
      <div v-if="onlyCenteredLabel">
        {{ resLabel }}
      </div>
      <ProgressMetricGrid
        v-else
        :title="resTitle"
        :label="resLabel"
        :goalReached="goalReached"
        :noCheckbox="true"
        :titleClass="titleClass"
        :labelClass="labelClass"
      />
    </ProgressMetricPercent>
    <ProgressMetricStackable
      v-else-if="type === 'stackable'"
      :max="max"
      :stackedValues="stackedValues"
      :size="size"
      :bgColor="bgColor"
      :rounded="rounded"
    />
    <div v-else-if="type === 'percent-outside'">
      <ProgressMetricGrid
        :title="resTitle"
        :label="resLabel"
        :goalReached="goalReached"
        :noCheckbox="true"
        :titleClass="titleClass"
        :labelClass="labelClass"
      />
      <ProgressMetricPercent
        :loading="loading"
        :data-loading="loading ? 'true' : undefined"

        :percent="percent"

        :bgColor="bgColor"
        :color="color"
        :colorDone="colorDone"
        :size="size"
        :striped="striped"
        :rounded="rounded"

        :textClass="labelClass"
        :noLabel="true"
      />
    </div>
    <div v-else>
      Metric type {{ type }} for {{ metric }} is not supported!
    </div>
  </div>
</template>

<script setup lang="ts">

import { useLocalization } from '@/composables';
import { aliasToPretty } from '@/utils/utils';
import { computed } from 'vue';
import { StackableEntry } from './base/ProgressMetricStackable.ts';

import ProgressMetricGrid from './base/ProgressMetricGrid.vue';
import ProgressMetricPercent from './base/ProgressMetricPercent.vue';
import ProgressMetricStackable from './base/ProgressMetricStackable.vue';

const props = withDefaults(defineProps<{
  type: 'percent' | 'grid' | 'stackable' | 'percent-outside';
  metric?: string;

  title?: string;
  label?: string;

  value?: number;
  max?: number;

  stackedValues?: StackableEntry[];

  loading?: boolean;
  noCheckbox?: boolean;

  color?: string;
  bgColor?: string;
  colorDone?: string;

  titleClass?: string;
  labelClass?: string;

  size?: number;
  striped?: boolean;
  rounded?: boolean;

  onlyCenteredLabel?: boolean;
  labelIsPercent?: boolean;

}>(), {
  type: 'percent',
  metric: undefined,

  title: undefined,
  label: undefined,

  value: 0,
  max: 0,
  stackedValues: () => [],

  loading: false,
  noCheckbox: false,

  color: undefined,
  bgColor: undefined,
  colorDone: undefined,

  titleClass: undefined,
  labelClass: undefined,

  size: undefined,
  striped: undefined,
  rounded: undefined,

  onlyCenteredLabel: false,
  labelIsPercent: false,

});

const { lt } = useLocalization('uiRankProgress');

const paramsDefined = computed(() => props.value !== undefined && props.max !== undefined);

const percent = computed(() => {
  if (!  paramsDefined.value )
    return 0;

  if ( props.max! === -1 )
    return 100;

  return ( props.max! > 0 ) ? Math.ceil((props.value! / props.max!) * 100) : 0;

});

const goalReached = computed(() => paramsDefined.value && ( props.value! >= props.max!) );

const resLabel = computed(() => {
  if (props.label)
    return props.label;

  if (props.labelIsPercent)
    return `${percent.value}%`;

  return `${formatValue(props.value)} ${lt('out_of')} ${formatValue(props.max === -1 ? props.value : props.max)}`;
});

const resTitle = computed(() => props.title || ( props.metric ? aliasToPretty(props.metric) : '') );

function formatValue(value: number): string {
  if (Number.isInteger(value)) {
    return value.toString();
  }
  return value.toFixed(2);
}

</script>
