
import { InputByTypeProperties } from '@/models/form-builder-interface';

export interface TransactionLogProps {
   
  id?: string;
  accountId?: number;
  filterDocument?: string[];
  filterProperties?: string[];
  items?: string[];
  itemTitles?: Record<string, string>;
  inputProperties?: InputByTypeProperties;
  showPeriods?: 'always-all' | 'allow-all' | 'only-selected';
}

export const defaults = {
  items: ['vmtl.created', 'vmtl.docType', 'vmtl.property', 'volumeChange', 'volumeDiff', 'doc:t.namePlusId'],
  showPeriods: 'allow-all' as const,
};
