
export interface MetricsProps {
  id?: string
  accountProperties: string[]
  positionType: 'vertical' | 'horizontal'
  title?: string
  offset?: number
  numberPeriodsBack?: number
  treeId?: number
  headerClass?: string
  colPropertyClass?: string;
  colValueClass?: string;
  allowPeriodSelect?: boolean;
}

export const defaults = {
  headerClass: 'bg-primary',
  positionType: 'vertical' as MetricsProps['positionType'],
  accountProperties: ['m.Downline'],
  colPropertyClass: 'pa-1',
  colValueClass: 'pa-1 font-weight-bold',
};
