import { watch } from 'vue';
import { ValidAwaitableRefs, UnRef, UserRefValues } from './composables-interface';

export function useAwaitValue<
  T extends ValidAwaitableRefs,
  V extends UserRefValues<T>,
  K extends keyof V
>(
  res: T,
  property: K,
  timeout: number = 30_000,
): Promise<UnRef<V[K]>> {
  const refv = (res as unknown as V)[property];

  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('useAwaitValue timeout'));
    }, timeout);

    if (! res.isLoading.value) {
      clearTimeout(timer);
      resolve(refv.value);
    } else {
      watch(res.isLoading, (loading) => {
        if (! loading) {
          clearTimeout(timer);
          resolve(refv.value);
        }
      });
    }
  });
}
