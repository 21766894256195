//import { TableGridItem } from "@/models/table-grid-interface";

export type TableColumnActionsSetupProps = {
  properties?: string[];
  popupInfoOnField?: string;
  popupProperties?: string[];
  columnActions?: TableColumnActionsSchema;
  headerTitles?: Record<string, string>;
  treeId?: number;
  popupLinkUrl?: string;
}

type CommonActionParams = {
  icon?: string;
  iconTooltip?: string;
  iconColor?: string;
}

export type InfoActionType = {
  treeId?: number;
  properties?: Array<string>;
  propertyTitles?: Record<string, string>;
}

export type LinkActionType = {
  link?: string;
}

export type TableColumnAction = CommonActionParams & (
  ({ type: 'info-popup' } & InfoActionType)
  | ({ type: 'link' } & LinkActionType)
);

export type TableColumnActionsSchema = Record<string, Array<TableColumnAction>>;

const defaultCommon: CommonActionParams = {
  icon: 'mdi-information-outline',
  iconTooltip: 'Info',
  iconColor: 'grey',
};

const defaultInfo: TableColumnAction = {
  ...defaultCommon,
  type: 'info-popup',
  icon: 'mdi-information-outline',
  iconTooltip: 'tableColumnActions.info_popup',
  treeId: 0,
  properties: [
    's.id',
    's.firstName',
  ],
};

const defaultLink: TableColumnAction = {
  ...defaultCommon,
  type: 'link',
  iconTooltip: 'tableColumnActions.link',
  icon: 'mdi-family-tree',
  link: '/{{site_language}}/network/view-network/?nodeId={{s.id.raw}}',
};

const defaultColumnName: string = '0';

const defaultActions: TableColumnActionsSchema = {
  defaultColumnName: [defaultInfo, defaultLink],
};

 
export function setupTableColumnActions(props: TableColumnActionsSetupProps) {
  if (props.columnActions) return props.columnActions;

  const columnName = props.popupInfoOnField || defaultColumnName;
  const res = { [columnName]: defaultActions.defaultColumnName };

  const infoPopup = find('info-popup') as InfoActionType;
  const link = find('link') as LinkActionType;

  if (props.properties)
    infoPopup.properties = props.properties;

  if (props.popupProperties && props.popupProperties.length > 0)
    infoPopup.properties = props.popupProperties;

  if (props.headerTitles)
    infoPopup.propertyTitles = props.headerTitles;

  if (props.treeId)
    infoPopup.treeId = props.treeId;

  if (props.popupLinkUrl)
    link.link = props.popupLinkUrl;

  function find(type: string) {
    return res[columnName].find((a) => a.type === type);
  }

  return res;
}

export function useTableColumnActions(schema: TableColumnActionsSchema = defaultActions) {

  function getActions(columnNameOrIndex?: string | number) {
    if (columnNameOrIndex === undefined) return undefined;
    const actions = typeof columnNameOrIndex === 'number' ?
      schema[String(columnNameOrIndex)]
      : schema[columnNameOrIndex];
    return actions;
  }

  function componentProps(columnName?: string, columnIndex?: number) {
    const actions = getActions(columnName) || getActions(columnIndex);
    return { actions: actions || [] };
  }

  function isActionsColumn(columnName?: string, columnIndex?: number) {
    const res = getActions(columnName) || getActions(columnIndex);
    return !!res;
  }

  return {
    isActionsColumn,
    componentProps,
  };
}
