import { gravComponentSchema } from '../deep-form-grav';

const gravHeader = {
  title: '',
  'metadata-nav_icon': '',
};

 
const { s, l, c, v, src, h, toExport } = gravComponentSchema<typeof gravHeader>({
  title: 'Page settings',
  link: '',
  text: text(),
},
  gravHeader,
);

export default toExport;

s.add('metadata-nav_icon').input('icon').col(4).title('Menu icon');
s.add('title').input('text').col(8).title('Page title as it appears in the menu');

function text() {
  return `
Here you can set up the page title and description, as well as the icon for the menu.
`;
}
