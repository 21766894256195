<template>
  <v-card>
    <v-card-title>
      <h3 class="text-h5">API override</h3>
    </v-card-title>
    <v-card-text>
      <v-form>
        <span class="text-caption">current: <b>{{ currentApiPath }}</b></span>
        <v-combobox
          v-model="selectedApiPath"
          :items="apiPaths"
          clearable
          @click:clear="clear()"
        >
          <template #append>
            <v-btn
              v-if="apiPaths.includes(selectedApiPath)"
              icon
              size="x-small"
              color="error"
              @click="deleteCurrentFromList()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              size="x-small"
              color="primary"
              @click="addCurrentToList()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-combobox>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { auth } from '@ui-api3-sdk/api';
import { ref, onMounted , watch } from 'vue';
import { baseUrl } from '@ui-api3-sdk/api/api3';
import debounce from 'lodash.debounce';

// const apiPath = ref('');
const selectedApiPath = ref('');
const apiPaths = ref<string[]>([]);
const currentApiPath = ref(baseUrl());

watch(selectedApiPath, debounce((newVal: string) => {
  if (!newVal) return;
  onUpdate();
}, 500, { leading: false, trailing: true }));

onMounted(() => {
  apiPaths.value = JSON.parse(localStorage.getItem('apiPaths') || '[]');
  selectedApiPath.value = auth.getApiPathOverride(); // apiPathOverride;
  if (!selectedApiPath.value) {
    selectedApiPath.value = apiPaths.value[0];
    if (!selectedApiPath.value) {
      auth.setApiPathOverride(selectedApiPath.value);
    }
  }
});

 
function onUpdate() {
  const newPath = selectedApiPath.value?.replace(/\/$/, '');
  auth.setApiPathOverride(newPath);
  currentApiPath.value = baseUrl();
}

function addOrMoveUp(newPath: string) {
  apiPaths.value = apiPaths.value.filter((p) => p !== newPath);
  apiPaths.value.unshift(newPath);
  localStorage.setItem('apiPaths', JSON.stringify(apiPaths.value));
}

function addCurrentToList() {
  const newPath = selectedApiPath.value?.replace(/\/$/, '');
  if (!newPath) return;
  addOrMoveUp(newPath);
}

function deleteCurrentFromList() {
  const newPath = selectedApiPath.value;
  if (!newPath) return;
  apiPaths.value = apiPaths.value.filter((p) => p !== newPath);
  localStorage.setItem('apiPaths', JSON.stringify(apiPaths.value));
}

function clear() {
  auth.setApiPathOverride('');
  selectedApiPath.value = '';
  currentApiPath.value = baseUrl();
}

</script>
