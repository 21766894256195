<template>
  <v-theme-provider :theme="useThemeStore().theme" :withBackground="true">
    <v-dialog
      v-model="dialogOpen"
      class="bg-none half-transparent movable"
      contentClass="half-transparent"
      :maxWidth="width"
      maxHeight="80%"
      :scrollable="true"
    >
      <v-card
        v-if="dialogOpen && selectedComponent"
      >
        <v-card-title class="bg-primary">
          <div class="d-flex">
            <div class="moveBy headerText">
              <v-icon class="moveBy" start>mdi-cursor-move</v-icon>
              Edit component:
              {{ selectedComponent.id }} ({{ grav.selectedPageName }} / {{ grav.selectedPageLanguage }})
            </div>
            <div>
              <v-icon @click="toggleWidth">{{ width === 1280 ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
              <v-icon @click="dialogOpen = false">mdi-close</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <GravEditFormComponent
            :component="selectedComponent"
          />
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn color="primary" block @click="dialogOpen = false">{{ $t('dialogs.close') }}</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-theme-provider>
</template>

<script setup lang="ts">
import { ref, toRefs , watch } from 'vue';

import { useGravManager } from '../comosables/useGravManager';
import { useThemeStore } from '@/pinia/useThemeStore';
import { addMovableDialogs } from '../lib/movable-dialog';

import GravEditFormComponent from './GravEditFormComponent.vue';

const grav = useGravManager();
const { selectedComponent } = toRefs(grav);
const width = ref(1280);

const dialogOpen = ref(false);

addMovableDialogs();

function toggleWidth() {
  if (width.value === 1280)
    width.value = 800;
  else
    width.value = 1280;
}

watch(selectedComponent, (newVal) => {
  if (newVal) {
    dialogOpen.value = true;
    return;
  }
  dialogOpen.value = false;
});

watch(dialogOpen, (newVal, prevVal) => {
  if (newVal === false && prevVal === true) {
    grav.selectComponentById(undefined);
  }
});


</script>

<style lang="scss" scoped>

.headerText { overflow: hidden;
  flex-grow: 1; text-overflow: ellipsis; white-space: nowrap;
}

</style>
