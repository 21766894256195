import { gravComponentSchema } from '../deep-form-grav';
import { VisualTreeProps, defaults } from '@/components/ui/VisualTree';

 
const { s, l, bl, c, v, src, h, toExport, createSchema } = gravComponentSchema<VisualTreeProps>({
  title: 'Visual Tree',
  link: 'https://www.notion.so/mlmsoft/VisualTree-c49e145b2f624c98b22e39962f2840fb',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('properties').as(l.clone('mandatoryProperties')).title('Card properties').col(6);
s.add('popupProperties').as(l.clone('popupProperties')).col(6);
//
s.add('treeId').as(l.clone('selectTree')).col(4);
s.add('backgroundColor').input('color').title('Background color').col(4);

s.add('showInactive').input('dictionary').data(['none' , 'all_inactive' , 'only_disabled'])
  .title('Show inactive')
  .init(() => 'none')
  .col(4);
//
s.add('titleField').as(l.clone('property')).title('Card title line').col(4);
s.add('titleFieldMax').as(bl.clone('number')).title('Title max length').col(2);

s.add('subtitleField').as(l.clone('property')).title('Card subtitle line').col(4);
s.add('subtitleFieldMax').as(bl.clone('number')).title('Subtitle max length').col(2);
//

s.add('initialFetch').as(bl.clone('number')).title('Initial depth')
  .desc('Depth to open at start')
  .col(3);
s.add('shortFetch').as(bl.clone('number')).title('Short depth')
  .desc('Depth to open on > click')
  .col(3);
s.add('longFetch').as(bl.clone('number')).title('Long depth')
  .desc('Depth to open on >> click')
  .col(3);
s.add('uplineLimit').as(bl.clone('number')).title('Upline limit')
  .desc('Max number of upline nodes')
  .col(3);
//

s.add('headerTitles').as(l.clone('headerTitles')).col(12);

s.add('colors').as(l.clone('fullColorRules')).col(12);

const cl = createSchema<NonNullable<VisualTreeProps['cardLayout']>>();

cl.default().col(2).props({ dfTitleInside: true });
cl.add('cardWidth').as(bl.clone('number')).title('Card width').col(2);
cl.add('avatarRadius').as(bl.clone('number')).title('Avatar radius').col(2);
cl.add('titleFontSize').as(bl.clone('number')).title('Title font size').col(2);
cl.add('subtitleFontSize').as(bl.clone('number')).title('Subtitle font size').col(2);
cl.add('propsFontSize').as(bl.clone('number')).title('Props font size').col(2);
cl.add('buttonRadius').as(bl.clone('number')).title('Button radius').col(2);

cl.add('cardPadding').as(bl.clone('number')).title('Card padding').col(2);
cl.add('cardMargin').as(bl.clone('number')).title('Card H. margin').col(2);
cl.add('cardHorizontalGap').as(bl.clone('number')).title('Card horizontal gap').col(2);
cl.add('cardVerticalGap').as(bl.clone('number')).title('Card vertical gap').col(2);
cl.add('propHorizontalGap').as(bl.clone('number')).title('Prop horizontal gap').col(2);
cl.add('propVerticalGap').as(bl.clone('number')).title('Prop vertical gap').col(2);

s.add('maxNodesPerRequest').as(bl.clone('number')).col(4).title('Max nodes per request. Max 500, Min: 10');
s.add('cardLayout').input('dform').data(cl.items()).title('Card layout').col(12);

function text() {
  return `
<b>Trees / Pages</b>
If there’s more than one tree in your compensation plan structure, you may use all the tree-related components and pages for any of those trees. For example, you can use this View Network component to show your binary tree, and you can use the Downline component to show your genealogy tree.

<b>Page Title and Description</b>
Please provide your custom title for the page. You may also provide a custom description which appears a line below the title. A menu icon can also be modified, please specify if you want to use a different icon.

<b>Custom Subtitle</b>
There’s a subtitle below the account's name in a tree. It can show the value of any profile fields or compensation plan property (metric). Please specify what value should be used here.

<b>Extra Fields</b>
Any additional compensation plan properties (metrics) can be shown for each account. Please provide a list of properties you want to be shown there.

<b>Detailed Popup Data Fields</b>
A click on a certain account opens a popup window which allows it to show more data fields. Please provide a list of fields to be shown (both: profile fields and metrics).

<b>Color Codes</b>
Each node in a visual network tree can be highlighted with different colors depending on a certain parameter, like account type or status, or ‘business level’, or rank, or is it active or not. Please specify which property to rely on, and what colors to use for different values of it.
  `;
}
