<template>
  <MemberInfoHeader
    :data="data"
    :loading="loading"
    :data-loading="loading ? 'true' : undefined"
    :titleField="titleField"
    :subtitleField="subtitleField"
    :size="size"
  />
</template>

<script lang="ts" setup>

import { AvatarBoxProps, defaults } from './AvatarBox.ts';

import { useCache } from '@/composables/useCache';
import { reactive, toRefs } from 'vue';

import MemberInfoHeader from './base/MemberInfoHeader.vue';

const props = withDefaults(defineProps<AvatarBoxProps>(), {

  accountId: undefined,
  treeId: undefined,
  offset: undefined,
  positionId: undefined,
  includeTree: undefined,

  titleField: () => defaults.titleField,
  subtitleField: () => defaults.subtitleField,
  avatarSize: () => defaults.size,
});


const { data, loading } = useCache(reactive({
  ... toRefs(props),
  properties: [ props.titleField, props.subtitleField ],
}));

</script>
