// deprecated
import { ref, watch } from 'vue';

import { useAccountStore } from '@/pinia/useAccountStore';
import { AwaitableRefs } from './composables-interface';
import { AccountVM } from '@ui-api3-sdk/api/api3';

export interface AccountProperty {
  presentable: string | null,
  raw: object | null,
}
interface useAccountPropertyCacheMethods {
  setPresentable: (a: string) => void;
  setRaw: (o: object | string) => void;
  setAll: (a: string) => void;
}

interface useAccountPropertyCacheRefs {
  prop: AccountProperty | null;
  presentable: string | null;
}

export const useAccountPropertyCache = (alias: string)
  : AwaitableRefs<useAccountPropertyCacheRefs> & useAccountPropertyCacheMethods => {

  const prop = ref<AccountProperty | null>(null);
  const presentable = ref<string | null>(null);

  const isLoading = ref(true);
  const isError = ref(false);

  const accountStore = useAccountStore();
  const currentAccount = accountStore.getAccountValue();

  if ( ! currentAccount ) {
    isLoading.value = true;

    accountStore.getAccountAsync().then( (currentAccount) => {
      isLoading.value = false;
      if ( currentAccount ) {
        refreshPropertyValue(currentAccount);
      } else {
        isError.value = true;
      }
    });
  } else {
    isLoading.value = false;
    refreshPropertyValue(currentAccount);
  }

  function refreshPropertyValue(currentAccount: AccountVM ) {
    const property = currentAccount.profile.find(item => item.alias === alias);
    if (!property) {
      prop.value = { presentable: null, raw: null };
      presentable.value = null;
      return;
    }
    prop.value = property.value;
    presentable.value = property.value.presentable;

    watch( property, (newVal) => {
      prop.value = { ... newVal.value };
      presentable.value = prop.value.presentable;
    });

  }

  function setPresentable(value: string) {
    accountStore.updatePresentableProfilePropertyValue(alias, value);
  }

  function setRaw(o: object | string) {
    accountStore.updateRawProfilePropertyValue(alias, o);
  }

  function setAll(v: string) {
    setPresentable(v);
    setRaw(v);
  }

  return { prop, presentable, isLoading, isError, setPresentable, setRaw, setAll };
};
