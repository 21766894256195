 

import { useAwaitAccountId } from "@/composables";
import { fetchDownline } from "./fetch-downline-service";

export async function fetchFullProperties(properties?: string[], treeId?: number, forAccountId?: number, forOffset?: number, forPositionId?: number, includeTree?: number) {

  const accountId = await useAwaitAccountId(forAccountId);

  const result = await fetchDownline({
    treeId: treeId || 0,
    accountId,
    offset: forOffset || 1,
    positionId: forPositionId,
    properties: properties || [],
    includeTree,
    filter: [],
    depth: 1,
    showInactive: 'all_inactive',
    pagination: {
      page: 0,
      limit: 1,
      orderBy: { 'position.tree_level' : 'ASC', 's.id': 'ASC' },
    },
  });

  return result.tableItems[0];
}
