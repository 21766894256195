<template>
  <WidgetTemplate
    :backgroundClass="backgroundClass"
    :headerClass="headerClass"
    :iconClass="iconClass"
    :wMM="wMM"
    :displayValue="value"
    :loading="loading"
    :data-loading="loading ? 'true' : undefined"
    :title="title || titleFrom"
    :postfix="postfix || postfixFrom"
  />
</template>

<script>
import { useAccountPropertyCache, useAwaitAccountId, useAwaitValue, useWallet } from '@/composables';
import { wearMaskMoney } from '@/utils/money';
import { defaults } from './WalletBalance.ts';

import WidgetTemplate from '@/components/ui/base/WidgetTemplate';


export default {
  name: 'WalletBalance',
  components: {
    WidgetTemplate,
  },

  props: {


    id: {
      type: String,
      default: undefined,
    },

    walletId: {
      type: Number,
      required: true,
    },

    accountId: {
      type: Number,
      default: undefined,
    },

    backgroundClass: {
      type: String,
      default: defaults.backgroundClass,
    },

    headerClass: {
      type: String,
      default: undefined,
    },

    iconClass: {
      type: String,
      default: defaults.iconClass,
    },

    title: {
      type: String,
      default: '',
    },

    postfix: {
      type: String,
      default: '',
    },

    walletCountries: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      wMM: wearMaskMoney,
      value: '',
      titleFrom: '',
      loading: true,
      aId: null,
      postfixFrom: null,
    };
  },

  created() {
    this.fetch();
  },

  methods: {

    async fetch() {
      let walletId = this.walletId;

      this.aId = await useAwaitAccountId(this.accountId);

      if (this.walletCountries.length) {

        const countryProp = await useAwaitValue(
          useAccountPropertyCache('country_id'), 'prop',
        );

        const countryId = String(countryProp.raw).toLowerCase();

        if (countryId) {
          const walletCountry = this.walletCountries.find(
            wc => wc[0].toLowerCase() === countryId,
          );

          if (walletCountry) walletId = +walletCountry[1];
        }
      }

      const wallet = await useAwaitValue(
        useWallet(this.aId, walletId), 'currentWallet',
      );

      this.titleFrom = wallet.title;
      this.value = +wallet.balance;
      this.postfixFrom= wallet.currency_id;

      this.loading = false;
    },
  },
};
</script>
