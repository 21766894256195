/* eslint-disable camelcase */
export default {
  hint: 'Нажмите, или перетащите файл в это окно',
  loading: 'Загружаю...',
  browser_not_supported: 'Ваш браузер не поддерживается, пожалуйста, используйте IE10 + или другие браузеры',
  upload_success: 'Загрузка выполнена успешно',
  upload_fail: 'Ошибка загрузки',
  preview: 'Предпросмотр',
  error_only_img: 'Только изображения',
  error_out_of_size: 'Изображение превышает предельный размер {0} Килобайт',
  error_lowest_px: 'Минимальный размер изображения {0} x {1} пикселей',
};
