import { gravComponentSchema } from '../deep-form-grav';
import { UserPopProps, defaults } from '../../../../components/ui/UserPop';

type ExtendedProps = UserPopProps & {
  _customFields: string[];
};

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<ExtendedProps>({
  title: 'User Popup',
  link: 'https://www.notion.so/mlmsoft/UserPop-cd9e6f5ea61a44acbb8536f5b8d1fbfe',
  text: text(),
},
  defaults,
);

export default toExport;

/*
  items?: string[];
  properties?: string[];
  treeId?: number,
  itemTitles?: Record<string, string>;
*/

const displayedItemInput = s.spawn<string>().input('dictionary').data(
  (value, ctx, rootCtx?: ExtendedProps) => {
    const possibleValues = [...rootCtx?._customFields || [], ...rootCtx?.properties || []]
      .filter((item) => !!item);
    return possibleValues;
  })
  .watch(['_customFields', 'properties'], 'data')
  .props({ hideDetails: true }).init(() => '')
  .mandatory();


s.add('items').input('dlist').data(() => displayedItemInput.item())
  .pre((value) => value?.filter((item) => item !== undefined) || [])
  .watch(['_customFields', 'properties'], 'data')
  .title('Displayed items order')
  .desc('Both properties and custom fields can be used')
  .desc('Items to be used in the popup')
  .col(4);


const translationEntryInput = s.spawn().input('dform').data([
  l.spawn<string>('key').asMany([l.clone('itemLeft'), displayedItemInput]).item(),
  l.spawn<string>('value').asMany([l.clone('itemRight'), l.clone('translatedText')]).item(),
])
  .init(() => ({ key: '', value: '' }));

s.add('itemTitles').input('drecord').data(translationEntryInput.item())
  .flag('isDynamic', true)
  .title('Item titles (translations)')
  .col(8);

s.add('properties').as(l.clone('mandatoryProperties'))
  .title('Properties to use')
  .desc('Properties made sure to be fetched to render the popup. Either to use in slots or directly.')
  .col(6);

s.add('_customFields').input('dlist').data(
  s.spawn<string>().input('text').props({ hideDetails: true }).init(() => '').item(),
)
  .init((ctx, props?: ExtendedProps) => {
    if (!props) return [];
    return props.items?.filter((item) => !item.includes('.')) || [];
  })
  .control((form, ctx) => {
    if (!ctx) return;

    const customFields = ctx._customFields || [];
    const inputs = form.getInputs();

    inputs.forEach((input: { alias: string; }) => {
      if (input.alias?.startsWith('#') && !customFields.includes(`#${input.alias}`)) {
        form.removeInput(input.alias, false);
      }
    });

     
    customFields.forEach((customField: any) => {
      if (customField && !form.getInput(`#${customField}`)) {

        const newInput = s.spawn<string>(`#${customField}`)
          .input('text')
          .title(`#${customField}`)
          .props({ hideDetails: true })
          .init(() => '')
          .item();

        form.addInput(newInput);
      }

    });

  })
  .watch(['_customFields'], 'control')
  .title('Custom fields to use')
  .desc('Custom field can be used to generate any content using slots')
  .col(6);

s.add('nameProperty').as( l.clone('profileProperty') )
  .title('Name property')
  .props({ clearable: true })
  .desc('Property to use instead of full name')
  .col(6);

  // avatarSize?: number;
  // avatarPosition?: 'start' | 'end' | 'none';
  // pulldownIcon?: string;


s.add('avatarSize').as( bl.clone('number') ).init(() => 40)
  .title('Avatar size')
  .desc('Size of the avatar in pixels')
  .col(4);

s.add('avatarPosition').input('dictionary').init(() => 'start')
  .data(['start', 'end', 'none'])
  .title('Avatar position')
  .desc('Position of the avatar')
  .col(4);

s.add('pulldownIcon').input('icon').init(() => 'mdi-arrow-down-circle')
  .title('Pulldown icon')
  .desc('Icon to show as pulldown')
  .col(4);

function text() {
  return `
<b>User Pop-up Fields</b>
User pop-up also opens a popup window with a customizable set of fields. Please provide a list of profile fields and/or compensation plan metrics to be shown there.
`;
}
