/* eslint-disable camelcase */

export default {
  p_requestId: "ID запроса",
  p_requestDate: "Дата запроса",
  p_amount: "Сумма",
  p_paymentSystem: "Платежная система",
  p_status: "Статус",

  status_pending: "Ожидание",
  status_done: "Выполнено",
  status_canceled: "Отменено",
  status_error: "Ошибка",

  unknown_error: "Неизвестная ошибка",
};
