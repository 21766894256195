<script setup lang="ts">
import { computed, ref } from 'vue';
import { VisualTreeProps, defaults } from './VisualTree.ts';

import { DNode, presentableValue as pv } from '@/composables/useTreeRender';
import { Item, TableGridItem } from '@/models/table-grid-interface';
import { RenderFunctionProps } from '@/models/tree-render-component-interface';
import { shortenCutEnd } from '@/utils/utils';

import MemberInfoPopup from '@/components/ui/base/MemberInfoPopup.vue';
import TreeRender from '@/components/ui/base/TreeRender.vue';


const props = withDefaults(defineProps<VisualTreeProps>(), {
  id: undefined,
  popupProperties: undefined,
  headerTitles: undefined,
  accountId: undefined,
  colors: undefined,
  inputProperties: undefined,
  showInactive: undefined,
  maxNodesPerRequest: () => defaults.maxNodesPerRequest,
  treeId: () => defaults.treeId,
  backgroundColor: () => defaults.backgroundColor,
  initialFetch: () => defaults.initialFetch,
  shortFetch: () => defaults.shortFetch,
  longFetch: () => defaults.longFetch,
  uplineLimit: () => defaults.uplineLimit,
  titleField: () => defaults.titleField,
  subtitleField: () => defaults.subtitleField,
  titleFieldMax: () => defaults.titleFieldMax,
  subtitleFieldMax: () => defaults.subtitleFieldMax,
  cardLayout: () => defaults.cardLayout,
});

const cl = calcCardLayout();
const infoPopupOpen = ref(false);
const infoPopupItem = ref<TableGridItem | undefined>();

const propsWithCustom = computed(() => {
  const p = [ ... props.properties];

  function addProp(prop: string) {
    if (p.includes(prop)) return;
    p.push(prop);
  }

  addProp(props.titleField);
  addProp(props.subtitleField);

  return p;

});

function calcCardLayout() {

  const cl = props.cardLayout || {};

  const cardWidth = cl?.cardWidth || 300;
  const hMargin = cl?.cardMargin || 40;
  const vPadding = cl?.cardPadding || 10; // padding inside the card
  const hPadding = cl?.cardPadding || 10;

  const avatarRadius = props.cardLayout?.avatarRadius || 30;
  const avatarPadding = 0;

  const nameFontSize = cl.titleFontSize || 20;
  const idFontSize = cl.subtitleFontSize || 15;
  const titleVerticalGap = (cl.subtitleFontSize || 15) / 2;

  const cardHorizontalGap = cl.cardHorizontalGap || 14;
  const cardVerticalGap = cl.cardVerticalGap || 20;

  const propFontSize = cl.propsFontSize || 14;
  const propHorizontalGap = cl.propHorizontalGap || 32;
  const propVerticalGap = cl.propVerticalGap || 4;


  const cardHeight =
    vPadding +
    Math.max(
      (avatarPadding + avatarRadius) * 2,
      nameFontSize + titleVerticalGap + idFontSize ,
    ) +
    cardVerticalGap +
    (propFontSize + propVerticalGap) * (props.properties.length + 1)
    + vPadding;

  const vMargin = Math.floor(cardHeight * 0.5 * 0.8);

  return {
    cardWidth,
    cardHeight,
    vMargin,
    hMargin,
    vPadding,
    hPadding,

    avatarRadius,
    avatarPadding,

    propFontSize,
    cardHorizontalGap,
    cardVerticalGap,
    nameFontSize,
    idFontSize,
    titleVerticalGap,
    propHorizontalGap,
    propVerticalGap,

    buttonRadius: cl.buttonRadius || 20,
    buttonOffsetX: (cl.buttonRadius || 20) / 4,
    buttonOffsetY: (cl.buttonRadius || 20) / 4,

  };

}


function cardCreate(params: RenderFunctionProps) {

  const { createAvatar, createText, createButtons } = params.core;

  params.nodes
    .select("rect")
    .attr("stroke", "#555")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5)
    .attr("rx", 5)
    .on("click", (event, d) =>  params.methods.setCurrentAccountByNodeId(d.data.id, d.data.item) );

  const ava = createAvatar(params.cardContents, d => Item(d.data.item).avatarUrl(), 'avatar', 0, 0, cl.avatarRadius, cl.avatarPadding);

  params.cardContents.select('.avatar')
    .on("click", (event, d) => {
      event.stopPropagation();
      openInfoPopup(d.data.item);
    });

  const tg = createText(params.cardContents, ava.width() + cl.cardHorizontalGap, 0)
    .add({
      class: 'card-title',
      text: (d: DNode) => shortenCutEnd(pv(d, props.titleField), props.titleFieldMax),
      fontSize: cl.nameFontSize,
      style: () => `font-weight: bold;`,
    })
    .space(cl.titleVerticalGap)
    .add({
      class: 'card-title',
      text: (d: DNode) =>  shortenCutEnd(pv(d, props.subtitleField), props.subtitleFieldMax),
      fontSize: cl.idFontSize,
      style: () => `font-weight: bold;`,
    });

  const sy = Math.max(ava.height(), tg.height()) + cl.cardVerticalGap;

  // props
  const propNames = createText(params.cardContents, 0, sy);

  const pTypes = params.propertyTypes.value || {};

  for(const prop of props.properties) {
    propNames.add({
      class: 'card-props',
      text: pTypes[prop]?.title || prop,
      fontSize: cl.propFontSize,
    });
    propNames.space(cl.propVerticalGap);
  }

  const propValues = createText(params.cardContents, propNames.width() + cl.propHorizontalGap, sy);

  for(const prop of props.properties) {
    propValues.add({
      class: 'card-props',
      text: d => pv(d, prop) || '-',
      fontSize: cl.propFontSize,
    });
    propValues.space(cl.propVerticalGap);
  }

  // buttons

  const buttons = createButtons(
    params.nodes,
    cl.hMargin + cl.cardWidth - cl.buttonOffsetX,
    cl.vMargin + cl.cardHeight - cl.buttonOffsetY,
    {
      anchorEnd: true,
      horizontal: true,
    },
  );

  buttons
    .circle(cl.buttonRadius, {
      id: 'open-1',
      hidden: (d: DNode) => !d.data.childrenCount || Item(d.data.item).level() < 0,
      icon: (d: DNode) => {
        if (d.data.loading)
          return { path: params.core.icons.loading, style: "animation: rotate 0.5s linear infinite;"  };
        if (d.data.open)
          return { path: params.core.icons.arrowDown, rotate: 0 };
        return { path: params.core.icons.arrowDown, rotate: -90 };
      },
      onClick: (event: unknown, d: DNode) => params.methods.toggleKids(d.data.id, props.shortFetch),
    })
    .circle(cl.buttonRadius, {
      id: 'open-3',
      hidden: (d: DNode) => !d.data.childrenCount || d.data.wasOpen,
      icon: (d: DNode) => {
        if (d.data.loading)
          return { path: params.core.icons.loading, style: "animation: rotate 0.5s linear infinite;"  };
        if (d.data.open)
          return { path: params.core.icons.arrowDown3, rotate: 0 };
        return { path: params.core.icons.arrowDown3, rotate: -90 };
      },
      onClick: (event: unknown, d: DNode) => params.methods.toggleKids(d.data.id, props.longFetch),
    });
  // load more button
  // .circle(cl.buttonRadius, {
  //   id: 'load-more',
  //   hidden: (d: DNode) => {
  //     const hidden = !d.data.item['_continue'];
  //     if (! hidden) {
  //       console.log('load-more visible: ', d.data.item['s.id'].presentable);
  //     }
  //     return hidden;
  //   },
  //   icon: (d: DNode) => {
  //     if (d.data.loading)
  //       return { path: params.core.icons.loading, style: "animation: rotate 0.5s linear infinite;"  };
  //     return { path: params.core.icons.arrowDown3, rotate: 0 };
  //   },
  //   onClick: (event: unknown, d: DNode) => params.methods.loadMore({
  //     nodeId: d.data.id,
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     lastFetchParams: d.data.item['_continue'].raw as any,
  //   }),
  // });

}


function cardUpdate(params: RenderFunctionProps) {

  const { nodes, cardContents } = params;


  const opacityUpline = (d: any) => {
    const item = params.core.findItem(d.data.id);
    if (! item ) return 1;
    return item.level() < 0 ? 0.5 : 1;
  };

  nodes
    .selectAll('.card-content')
    .style('opacity', opacityUpline);

  nodes
    .select("rect")
    .attr("fill", (d: DNode) => !d.data.isEmpty ? d.data.item.rowColor?.background || '#fff' : 'none')
    .style('opacity', opacityUpline);

  cardContents
    .selectAll(".card-title")

    .attr("fill", (d: any) => d.data.item.rowColor?.title || '#000');

  cardContents
    .selectAll(".card-props")

    .attr("fill", (d: any) => d.data.item.rowColor?.text || '#000');

  cardContents
    .style("pointer-events", (d: DNode) => !d.data.isEmpty ? "all" : 'none')
    .on("click", (event, d) =>  params.methods.setCurrentAccountByNodeId(d.data.id, d.data.item) );


}

function openInfoPopup(item: TableGridItem) {
  infoPopupOpen.value = true;
  infoPopupItem.value = item;
}

</script>


<template>
  <TreeRender
    :properties="propsWithCustom"
    :cardLayout="cl"
    :cardCreate="cardCreate"
    :cardUpdate="cardUpdate"

    :treeId="treeId"
    :accountId="accountId"
    :headerTitles="headerTitles"
    :colors="colors"
    :inputProperties="inputProperties"
    :backgroundColor="backgroundColor"
    :initialFetch="initialFetch"
    :uplineLimit="uplineLimit"
    :useTreeOptions="{ showInactive, maxNodesPerRequest }"
  >
    <template #default="{ containerRef }">
      <MemberInfoPopup
        v-if="infoPopupItem"
        v-model="infoPopupOpen"
        :properties="popupProperties && popupProperties?.length > 0 ? popupProperties : properties"

        :treeId="props.treeId"
        :data="infoPopupItem"

        :propertyTitles="headerTitles || {}"
        :buttonMode="false"
        :attachTo="containerRef"

        :titleField="titleField"
        :subtitleField="subtitleField"
      />
    </template>
  </TreeRender>
</template>

<style lang="scss" scoped>

:deep(.node),
:deep(.button-open-1 *),
:deep(.button-open-3 *) {
  cursor: pointer;
}

</style>
