
import { TableGridItem } from '@/models/table-grid-interface';
import { getLocaleRef } from './utils';
import { auth } from '@ui-api3-sdk/api';
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore';


export function runTemplate(
  template: string,
  item?: TableGridItem,
  custom?: Record<string, string>, encode: boolean = false): string {

    const valuesFromItem = systemVariables();
    if (item) {
      Object.keys(item).forEach((key) => {
        valuesFromItem[key] = String(item[key]?.presentable || '');
        valuesFromItem[`${key}.raw`] = String(item[key]?.raw || '');
      });
    }

    const values = { ... valuesFromItem, ...custom };

    Object.keys(values).forEach( (key) => {
      const value = values[key];
      const reg = new RegExp(`({{${key}}}|~~${key}~~|__${key}__)`, 'g');
      template = template.replace(reg, encode ? encodeURIComponent(value) : value);
    });

  return template;
}


function systemVariables(): Record<string, string> {

  const hostname = window?.location?.hostname;
  const proto = window?.location?.protocol;
  const parts = hostname.split('.');
  const instance = parts[0];
  const domain = parts.slice(1).join('.');
  const currentLanguage = getLocaleRef().value;
  const registrationPath = useRuntimeSettingsStore().getRegistrationPath.replace(/(^\/|\/$)/, '');
  const ssoKey = auth.getStoredSSOKey() || '';
  //TODO: add myAESPassword SSO here

  return {
    'site_language': currentLanguage,
    'site_proto': proto,
    'site_domain': domain,
    'site_instance': instance,
    'site_hostname': hostname,
    'site_url': `${proto}//${hostname}`,
    'site_enroll_url': `${proto}//${hostname}/${currentLanguage}/${registrationPath}`,
    'site_sso_key': ssoKey,
  };

}
