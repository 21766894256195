import axios from 'axios';
import { auth } from '@ui-api3-sdk/api';
import { GravResponseDTO, GravActionPayloadDTO, GravRequest, GravBulkUpdatePropertiesRequest, GravAction } from '../../grav-api-models';

import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useToast } from '../../../composables/useToast';
import { useLocalization } from '@/composables';

const defaultPageName = 'dashboard'; // TODO: get from grav via props?

function getPageAndLanguageFromUrl() {

  const path = window.location.pathname.split('/');

  const lang = useLocalization().locale.value || 'en';
  let pageName = path.pop();

  if (!pageName)
    pageName = defaultPageName;

  if (path.length === 1 && /^[a-z]{2}(?:-[a-zA-Z]{2,4})?$/.test(pageName || ''))
    pageName = defaultPageName;

  return { pageName, lang };
}

function genBaseUrl() {
  let hostname = window?.location?.hostname;
  if (hostname === 'localhost')
    hostname = `localhost:8000`;
  const proto = window?.location?.protocol;
  return `${proto}//${hostname}/api/theme-update`;
}

// function isDifferentPage(oldFileName: string, newFileName: string) {
//   const oldPagePath = oldFileName.split('/').splice(-1)
//     .join('/');
//   const newPagePath = newFileName.split('/').splice(-1)
//     .join('/');

//   return oldPagePath !== newPagePath;
// }

export const useGravManager = defineStore('gravManager', () => {
  const baseUrl = genBaseUrl();

  const loading = ref(false);
  const state = ref<GravActionPayloadDTO | undefined>();

  const backupFiles = computed(() => state.value?.backupFiles || []);
  const pageFiles = computed(() => state.value?.pageFiles || []);
  const currentPageDTO = computed(() => state.value?.pages?.find(p => p.language === selectedPageLanguage.value));
  const allPagesDTO = computed(() => state.value?.pages);
  const components = computed(() => currentPageDTO.value?.page || []);
  const fileContent = computed(() => state.value?.fileContent);

  const currentFileOnEdit = ref<string | undefined>();

  const storedPageName = ref<string | undefined>();
  const selectedPageName = ref<string>('');
  const selectedPageLanguage = ref<string>('');
  const requestPageName = computed(() => `${selectedPageLanguage.value}/${selectedPageName.value}`);

  watch(currentFileOnEdit, (newVal) => {

    if (newVal && newVal.startsWith('@pages/')) {

      const a = newVal.split('/');
      const pageFileName = a.pop();
      const pageFolderName = a.pop()?.replace(/^\d+\./, '');
      if (pageFileName && pageFolderName) {
        const lang = pageFileName.match(/\.([a-z]{2}(?:-[a-zA-Z]{2,4})?)\.md$/)?.[1];
        if (lang) {
          selectedPageName.value = pageFolderName;
          selectedPageLanguage.value = lang;
          return;
        }
      }
    }

    const { pageName, lang } = getPageAndLanguageFromUrl();
    selectedPageName.value = pageName;
    selectedPageLanguage.value = lang;

  }, { immediate: true });

  const selectedComponentId = ref<string | undefined>();
  const selectedComponent = computed(() => {
    if (!selectedComponentId.value) return undefined;
    return components.value?.find(c => c.id === selectedComponentId.value);
  });


  function setOverrideCurrentPage(pageName: string) {
    if (selectedPageName.value === pageName) return;
    storedPageName.value = selectedPageName.value;
    selectedPageName.value = pageName;
  }

  function resetOverrideCurrentPage() {
    if (!storedPageName.value) return;
    selectedPageName.value = storedPageName.value;
    storedPageName.value = undefined;
  }

  function selectComponentById(id: string | undefined) {
    selectedComponentId.value = id;
  }

  function setEditFile(file: string | undefined) {
    currentFileOnEdit.value = file;
  }

   
  async function upcli(onPage: string | undefined, action: GravAction, data: Partial<GravRequest>) {
    const page = onPage || requestPageName.value;

    const request = {
      token: auth.getAccessToken(),
      page,
      action,
      ...data,
    };

    if (!request.fileList?.length && currentFileOnEdit.value) {
      request.fileList = [currentFileOnEdit.value];
    }

    try {
      const res = await axios.post<GravResponseDTO<GravActionPayloadDTO>>(
        `${baseUrl}/upcli`, request);

      const error = res.data.payload ? res.data.payload.error : 'No response payload';

      if (error) {
        console.error('gravManager error:', error);
        useToast().error(error);
      }

      if (! res.data.payload) return res;

      const log = [ ... state.value?.log || [], ... res.data.payload.log ];
      state.value = { ... state.value, ... res.data.payload, log };

      return res;

     
    } catch (e: any) {
      console.error('gravManager error:', e);
      const msg = e?.message || 'Error while managing page';
      if (state.value) {
        state.value.error = msg;
        state.value.result = false;
      }
      useToast().error(msg);
    }
  }

  async function upcliReadFile(fileName: string) {
    loading.value = true;

    const needFileParsed = !allPagesDTO.value || allPagesDTO.value.every(p => p.filePath !== fileName);

    const res = await upcli(undefined, 'read-file', {
      fileList: [ fileName ],
      needFileParsed,
    });
    loading.value = false;
    return res?.data.payload.fileContent;
  }

  async function upcliSwitchPlaces(srcComponentId: string, dstComponentId: string) {
    loading.value = true;
    const res = await upcli(undefined, 'component-switch-places', {
      componentSwitchPlacesRequest: { srcId: srcComponentId, dstId: dstComponentId },
    });
    loading.value = false;
    return res?.data.payload.log;
  }

  async function upcliWriteFile(fileName: string, fileContent: string) {
    loading.value = true;
    const res = await upcli(undefined, 'write-file', {
      fileList: [ fileName ],
      fileContentToSave: fileContent,
    });
    loading.value = false;
    return res?.data.payload.result;
  }

  async function upcliResave(page?: string) {
    loading.value = true;
    const res = await upcli(page, 'resave', {});
    loading.value = false;
    return res?.data.payload.log;
  }

  async function upcliComponentsUpdate(page?: string) {
    loading.value = true;
    const res = await upcli(page, 'components-update', {});
    loading.value = false;
    return res?.data.payload.log;
  }

  async function upcliLayoutUpdate(page?: string) {
    loading.value = true;
    const res = await upcli(page, 'layout-update', {});
    loading.value = false;
    return res?.data.payload.log;
  }

  async function upcliMigration() {
    loading.value = true;
    const res = await upcli(undefined, 'migration', {});
    loading.value = false;
    return res?.data.payload.log;
  }

  async function upcliShow(page?: string) {
    loading.value = true;
    await upcli(page, 'show', {});
    loading.value = false;
    return state.value;
  }

  async function upcliShowBackups() {
    loading.value = true;
    await upcli(undefined, 'show-backups', {});
    loading.value = false;
    return state.value?.backupFiles;
  }

  async function upcliShowPages() {
    loading.value = true;
    await upcli(undefined, 'show-pages', {});
    loading.value = false;
    return state.value?.pageFiles;
  }

  async function upcliBackupToPages(files: string[]) {
    loading.value = true;
    await upcli(undefined, 'backup-to-pages', { fileList: files });
    loading.value = false;
    return state.value?.result;
  }

  async function upcliPagesToBackup(files: string[]) {
    loading.value = true;
    await upcli(undefined, 'pages-to-backup', { fileList: files });
    loading.value = false;
    return state.value?.result;
  }

  async function upcliDeleteFiles(files: string[]) {
    loading.value = true;
    await upcli(undefined, 'delete-files', { fileList: files });
    loading.value = false;
    return state.value?.result;
  }


  async function uploadBackup(file: File){
    try {
      const formData = new FormData();
      formData.append('file', file);

      await axios.post(`${baseUrl}/upload-backup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': auth.getAccessToken(),
        },
      });

     
    } catch (error: any) {
      useToast().error(String(error));
    }
  }

  async function upcliUpdateProperty(
    componentId: string,
    property: string,
    values: Record<string, string | null>,
    slotParams?: string,
  ) {
    loading.value = true;
    const res = await upcli(undefined, 'update-component-property', {
      updateComponentPropertyRequest: { componentId, property, values, slotParams },
    });
    if (res?.data.payload.result)
      useToast().ok('Property updated!');

    loading.value = false;
    return state.value;
  }

  async function upcliUpdateBulkProperties(
    req: GravBulkUpdatePropertiesRequest,
    page?: string,
  ) {
    loading.value = true;
    const res = await upcli(page, 'update-bulk-component-properties', {
      updateComponentPropertiesRequest: req,
    });
    if (res?.data.payload.result)
      useToast().ok('Properties updated!');

    loading.value = false;
    return state.value;
  }


  return {
    upcliMigration,

    upcliShow,
    upcliResave,
    upcliComponentsUpdate,
    upcliLayoutUpdate,
    upcliUpdateProperty,

    uploadBackup,
    upcliShowBackups,
    upcliShowPages,
    upcliBackupToPages,
    upcliPagesToBackup,
    upcliDeleteFiles,

    upcliReadFile,
    upcliWriteFile,
    upcliUpdateBulkProperties,
    upcliSwitchPlaces,

    selectedPageName,
    selectedPageLanguage,

    setOverrideCurrentPage,
    resetOverrideCurrentPage,

    selectedComponent,
    currentFileOnEdit,

    selectComponentById,
    setEditFile,

    fileContent,
    backupFiles,
    pageFiles,
    currentPageDTO,
    allPagesDTO,
    components,
    logs: computed(() => state.value?.log || []),
    result: computed(() => state.value?.result),

    loading,
  };

});
