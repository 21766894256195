<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <slot
    name="default"
    :treeId="treeId"
    :loading="loading"
    :xs="xs"
    :data-loading="loading ? 'true' : undefined"
    v-bind="slotParams(item, undefined, additional)"
  />
</template>

<script setup lang="ts">
// TODO: allow to use aux without item (only templates and callback)

import { TableGridItem, TableGridItemProperty, slotParams } from '@/models/table-grid-interface';

// import { fetchMyPosition } from '@/utils/fetch-my-position-service';
import { onMounted, ref } from 'vue';

import { mapAccountVMToV2 } from '@/models/account-v2-convert';
import { useAccountStore } from '@/pinia/useAccountStore';
import { Callback, CallbackV2, runCallbackAndMerge } from '@/utils/callback-service';
import { fetchDownline } from '@/utils/fetch-downline-service';
import { fetchFullProperties } from '@/utils/fetch-full-properties-service';
import { createTableGridItem } from '@/utils/properties-mapping-service';
import { PlanPropertyHistoryVM, useAccountPropertiesApi } from '@ui-api3-sdk/api/api3';
import { useDisplay } from 'vuetify';


const props = withDefaults(defineProps<{

  id?: string;
  treeId?: number;

  callback?: string | Callback<Record<string, any>>;

  callbackV2?: string | CallbackV2<Record<string, any>>;
  onlyAccount?: boolean;
  onlyCallback?: boolean;
  marketingProps?: string[];
  historyPeriods?: number;
  fetchDownlineDepth?: number;
}>(), {
  id: undefined,
  treeId: 0,
  callback: undefined,
  callbackV2: undefined,
  onlyAccount: false,
  onlyCallback: false,
  marketingProps: undefined,
  historyPeriods: 0,
  fetchDownlineDepth: undefined,
});

const { xs } = useDisplay();

const item = ref<TableGridItem | undefined>();
const downline = ref<TableGridItem[]>([]);

const loading = ref(false);
const additional = ref<Record<string, TableGridItemProperty['raw']>>({});

function setError(text: string) {
  additional.value = { ... additional.value, error: text };
  if (! item.value) item.value = {};
}

onMounted(async () => {
  loading.value = true;

  let resItem: TableGridItem = {};
  let history: PlanPropertyHistoryVM[] = [];

  const callback = props.callbackV2 || props.callback;
  const callbackVersion = props.callbackV2 ? 'v2' : 'v1';

  if (props.onlyCallback) {
    item.value = await runCallbackAndMerge(callbackVersion, callback, resItem, history, downline.value);
    loading.value = false;
    return;
  }

  if (props.onlyAccount) {
    const account = await useAccountStore().getAccountAsync();
    if (! account ) {
      loading.value = false;
      console.error('Aux: No account');
      setError('no_account');
    } else
      resItem = createTableGridItem([], { s: mapAccountVMToV2(account) });

  } else {
    let result = undefined;

    try {
      //result = await fetchMyPosition(props.treeId, props.marketingProps || []);
      result = { item: await fetchFullProperties(
        props.marketingProps || [],
        props.treeId,
      ) };
    } catch (e) {
      console.error('Aux: Error fetching position', e);
      result = undefined;
    }

    if (!result || !result.item || !result.item['s.id']) {
      loading.value = false;
      console.error('Aux: No position in tree');
      setError('no_position');
    } else
      resItem = result.item;
  }

  const aId = resItem['s.id'] ? Number(resItem['s.id'].raw) : undefined;

  if (aId && props.historyPeriods) {

    const res = await useAccountPropertiesApi()
      .getPropertiesHistory({
        includeCurrent: true,
        limit: props.historyPeriods,
        page: 0,
        id: aId,
        properties: props.marketingProps,
      });

    const { list } = res.data.payload;
    history = list;
  }


  if (aId && props.fetchDownlineDepth) {

    const res = await fetchDownline({
      accountId: aId,
      treeId: props.treeId,
      properties: props.marketingProps || [],
      depth: props.fetchDownlineDepth,
    });

    downline.value = res.tableItems;

  }

  item.value = await runCallbackAndMerge(callbackVersion, callback, resItem, history, downline.value);
  loading.value = false;

});

</script>
