import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useNavigation } from '../composables/useNavigation';

export const useRuntimeSettingsStore = defineStore('runtime-settings', () => {
  // Define state (private by default)
  const loginPath = ref('/login');
  const registrationPath = ref('/register');
  const languages = ref(['en', 'ru']);
  const loginRedirectPath = ref('');
  const logoutRedirectUrl = ref('');

  // logoutRedirectUrl

  // Define getters (reactive and controlled access)
  const getLoginPath = computed(() => loginPath.value);
  const getRegistrationPath = computed(() => registrationPath.value);
  const getLanguages = computed(() => languages.value);
  const getLoginRedirectPath = computed(() => loginRedirectPath.value);
  const getLogoutRedirectUrl = computed(() => logoutRedirectUrl.value);

  // Define actions (controlled mutations)
  const storeLoginRedirectFromUrl = () => {

    if (useNavigation().param('loginRedirect')) {
      loginRedirectPath.value = useNavigation().param('loginRedirect') || '';
      return;
    }

    if (useNavigation().isLoginOrRegistrationPage()) {
      return;
    }

    loginRedirectPath.value = useNavigation().getCurrentPath();
  };


  const setLoginRedirectPath = (path: string) => {
    loginRedirectPath.value = path;
  };

  const setLanguages = (newLanguages: Array<string>) => {
    languages.value = newLanguages;
  };

  const setLoginPath = (path: string) => {
    loginPath.value = path;
  };

  const setRegistrationPath = (path: string) => {
    registrationPath.value = path;
  };

  const setLogoutRedirectUrl = (url: string) => {
    logoutRedirectUrl.value = url;
  };


  // Return only what you want to expose
  return {
    // Getters
    getLoginPath,
    getRegistrationPath,
    getLanguages,
    getLoginRedirectPath,
    getLogoutRedirectUrl,

    // Actions
    setLoginRedirectPath,
    setLanguages,
    setLoginPath,
    setRegistrationPath,
    setLogoutRedirectUrl,

    storeLoginRedirectFromUrl,

    loginRedirectPath,

  };
}, {
    persist: {
      key: 'runtime-settings',
      storage: window.sessionStorage,
      pick: ['loginRedirectPath'],
    },
  },
);
