<template>
  <div>
    <VSkeletonLoader
      v-if="loading"
      minHeight="48px"
      type="heading"
    />

    <template v-else>
      <SegmentedSelect
        v-model="selectedId"
        :label="nameWallet"
        :items="paymentSystemList"
        :disabled="noWalletOrPayment"
        itemValue="id"
        itemTitle="text"
        :returnObject="false"
        @selected="onSelected"
      />

      <v-dialog
        v-model="showResultModal"
        class="result-dialog"
        width="700"
        minWidth="390"
      >
        <v-card class="pa-md-4">
          <v-card-text class="text-center">
            <p class="result-dialog__main-text">
              {{ showTextPopup }}
            </p>
            <p class="result-dialog__sub-text">
              {{ showDescriptionPopup }}
            </p>
          </v-card-text>

          <v-card-actions class="result-dialog__actions">
            <v-btn
              :disabled="formSending"
              color="primary-darken-1"
              variant="text"
              @click="closeResultModal"
            >
              {{ $t('dialogs.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="selectedPO"
        v-model="showModal"
        width="600"
        minWidth="390"
      >
        <v-card class="pa-md-4">
          <v-card-title class="text-h4">
            {{ `${$t('uiPayoutRequest.payout_from')} ${currentWallet.title}` }}
          </v-card-title>
          <v-card-text>
            <h5 class="text-h5 mb-4">
              {{ paymentSystemName(selectedPO.alias, selectedPO.title) }}
            </h5>

            <span class="text-caption">
              {{ $t('uiPayoutRequest.amount') }}
              <span class="text-error font-weight-bold">*</span>
            </span>

            <v-text-field
              v-model.number="amount"
              v-mask:currency
              :disabled="formSending"
              :prefix="currentWallet.currency_id"
              :errorMessages="ammountErrorMessages"
              variant="solo"
              class="pt-0"
              type="text"
              required
            />
            <InputByType
              v-for="item in selectedPO.paymentPersonalData"
              :key="item.alias"
              v-model="item.value"
              :alias="item.alias"
              :title="pt(item.alias, item.title)"
              :fieldType="item.fieldType"
              :countryId="countryId"
              :isMandatory="item.isMandatory"
              :errorMessages="item.errorMessages || []"
              :disabled="formSending || isReadonly(item.alias)"
              variant="solo"
              @update:model-value="item.errorMessages = null"
            />

            <v-alert
              v-if="alertMessage"
              density="compact"
              border="top"
              type="error"
            >
              {{ alertMessage }}
            </v-alert>

            <FieldConfirmation
              v-if="confirmBy && !isOtpConfirmed && submitPressed"
              :alias="confirmBy"
              :value="confirmFieldValue"
              :noSendImmediately="false"
              @confirmed="onOtpConfirmed($event)"
            />
          </v-card-text>

          <v-card-actions
            v-if="!confirmBy || !submitPressed || isOtpConfirmed"
          >
            <v-spacer />
            <v-btn
              :disabled="formSending"
              color="primary-darken-1"
              variant="text"
              @click="cancel"
            >
              {{ $t('uiPayoutRequest.cancel') }}
            </v-btn>
            <v-btn
              class="mr-4"
              color="primary-darken-1"
              :disabled="disableSubmit"
              :loading="formSending"
              :data-loading="loading ? 'true' : undefined"
              variant="elevated"
              @click="submit"
            >
              {{ $t('uiPayoutRequest.submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { useAccountProfileApi, usePaymentSystemApi, usePayoutApi } from '@ui-api3-sdk/api/api3';
import { useAwaitAccountId, useAwaitValue, useLocalization, useWallet } from '@/composables';
import { useAccountPropertyCache } from '@/composables/useAccountPropertyCache';
import { useEventBus } from '@/pinia/useEventBus';

import { defaults } from './PayoutRequest.ts';

import InputByType from '@/components/ui/base/InputByType';
import SegmentedSelect from '@/components/ui/base/SegmentedSelect';
import FieldConfirmation from './base/FieldConfirmation.vue';

let profile;

export default {
  name: 'PayoutRequest',

  components: {
    InputByType,
    SegmentedSelect,
    FieldConfirmation,
  },

  props: {

    id: {
      type: String,
      default: undefined,
    },

    accountId: {
      type: Number,
      default: null,
    },

    walletId: {
      type: Number,
      required: true,
    },

    paymentList: {
      type: Array,
      default: () => [],
    },

    paymentProperties: {
      type: Array,
      default: null,
    },

    editableProperties: {
      type: Array,
      default: () => defaults.readonlyProperties,
    },

    edit: {
      type: String,
      default: 'all',
    },

    confirmBy: {
      type: String,
      default: undefined,
    },

    textStyleType: {
      type: Number,
      default: null,
    },

    showTextPopup: {
      type: String,
      default: null,
    },

    showDescriptionPopup: {
      type: String,
      default: null,
    },

  },

  data() {
    return {
      selectedId: 0,
      walletBalance: 0,
      isOtpConfirmed: false,
      submitPressed: false,
      loading: true,
      aId: null,
      showModal: false,
      showResultModal: false,
      formSending: false,
      amount: 0,
      nameWallet: this.$t('uiPayoutRequest.wallet_not_found'),
      currentWallet: null,
      noWalletOrPayment: true,
      paymentSystemList: [],
      alertMessage: '',
      alertTimeout: null,
      ammountErrorMessages: [],
    };
  },

  computed: {

    confirmFieldValue() {
      if (!this.confirmBy) return '';
      return useAccountPropertyCache(this.confirmBy).presentable.value;
    },

    selectedPO() {
      if (isNaN(this.selectedId)) return null;

      return this.paymentSystemList.find(
        paymentSystem => paymentSystem.id === this.selectedId,
      );
    },

    countryId() {
      if (!this.selectedPO?.paymentPersonalData) return null;

      const country = this.selectedPO?.paymentPersonalData.find(
        item => item.alias === 'country_id',
      );

      if (!country) return null;

      return country.value;
    },

    disableSubmit() {
      if (!this.selectedPO?.paymentPersonalData) return true;
      return (
        this.amount === 0 ||
        !!this.selectedPO?.paymentPersonalData.find(
          item => item.isMandatory && !item.value,
        )
      );
    },
  },

  watch: {
    showModal(value) {
      if (! value ) {
        this.isOtpConfirmed = false;
        this.alertMessage = '';
        this.submitPressed = false;
        if (this.alertTimeout) {
          clearTimeout(this.alertTimeout);
          this.alertTimeout = null;
        }
      }
    },
  },

  async created() {
    this.localization = useLocalization('uiPayoutRequest');

    const storedId = localStorage.getItem('DefaultPayout_id');

    await this.fetch();

    this.selectedId = Number.parseInt(storedId || this.paymentList[0]);

  },

  methods: {

    isReadonly(alias) {
      if (this.edit === 'all') return false;
      if (this.edit === 'none') return true;
      if (this.edit === 'editable')
        return ! this.editableProperties.includes(alias);
      if (this.edit === 'except_editable')
        return this.editableProperties.includes(alias);
      return true;
    },

    onOtpConfirmed(value) {
      if (value?.otpHeader) {
        this.isOtpConfirmed = true;
        this.submit();
      }
    },

    onSelected(id) {
      this.selectedId = id;
      this.showModal = true;
      localStorage.setItem('DefaultPayout_id', id);
    },

    pt (alias, title) {
      return this.localization.pt(alias, title);
    },

    paymentSystemName(alias, title) {
      return title || alias;
    },

    getPaymentPersonalData(paymentItem) {

      const props = this.paymentProperties?.length ? this.paymentProperties : paymentItem.personalDataFields;
      if (! props) return;

      profile.forEach(p => {
        const found = props.find(alias => alias === p.alias);

        if (found) {
          const value = p?.value?.presentable || '';

          paymentItem.paymentPersonalData.push({
            ...p,
            fieldType: p.fieldType.alias,
            value,
            errorMessages: null,
          });
        }
      });

      // console.log('paymentItem:', paymentItem);
    },

    async fetch() {
      this.aId = await useAwaitAccountId(this.accountId);

      const [
        { value: currentWallet },
        { value: paymentSystemListResponse },
        { value: accountProfileResponse },
      ] = await Promise.allSettled([
        useAwaitValue(useWallet(this.aId, this.walletId), 'currentWallet'),
        usePaymentSystemApi().getPaymentSystemList({ limit: 100, page: 0 }),
        useAccountProfileApi().getProfile({ id: this.aId }),
      ]);

      if (currentWallet) {
        this.currentWallet = currentWallet;
        this.nameWallet = `${this.$t('uiPayoutRequest.request_payout')} ${
          this.currentWallet.title
        }`;

        this.walletBalance = parseFloat(this.currentWallet.balance);
      }

      profile = accountProfileResponse.data.payload;

      const paymentSystemList = paymentSystemListResponse.data.payload;

      if (paymentSystemList) {
        paymentSystemList.forEach(ps => {
          ps.paymentPersonalData = [];
          if (this.paymentList.includes(ps.id)) {
            this.getPaymentPersonalData(ps);
          }
        });

        this.paymentSystemList = paymentSystemList
          .filter(ps => this.paymentList.includes(ps.id))
          .map(item => {

            const name = this.paymentSystemName(item.alias, item.title);
            const text = this.nameWallet + (this.textStyleType === 1 ? ` to ${name}` : ` (${name})`);

            return { ...item, text };
          });
      }

      this.noWalletOrPayment = isNaN(this.selectedId) || !this.currentWallet;
      this.loading = false;
    },

    clearSum() {
      // this.selectedPO.paymentPersonalData.forEach(item => (item.value = ''));
      this.amount = 0;
    },

    async submit() {

      if (! this.submitPressed ) {
        this.submitPressed = true;
        if (this.confirmBy) return;
      }

      this.formSending = true;
      const personalData = {};

      this.selectedPO.paymentPersonalData.forEach(
        item => (personalData[item.alias] = item.value),
      );

      try {
        await usePayoutApi().createInvoice({
          id: this.aId,
          createPayoutParamsReq: {
            walletTypeId: this.currentWallet.id,
            amount: -this.amount,
            profile: personalData,
            paymentSystemId: this.selectedPO.id,
          },
        });

        this.showModal = false;

        if (this.showTextPopup || this.showDescriptionPopup) {
          this.showResultModal = true;
        }

        this.clearSum();

        useEventBus().update('payout_history');

      } catch (e) {
        console.log('e:', e);
        const {
          response: {
            data: { error },
          },
        } = e;

        if (!error.description) {
          this.alertMessage = this.$t('uiPayoutRequest.error_payout');
        } else {
          this.alertMessage = error.description;
        }

        if (error.fields && Object.keys(error.fields).length > 0) {
          Object.keys(error.fields).forEach(key => {
            if (key === 'amount') {
              this.ammountErrorMessages = error.fields[key];

              return;
            }

            const foundItem = this.selectedPO.paymentPersonalData.find(
              item => item.alias === key,
            );

            if (!foundItem) return;

            foundItem.errorMessages = error.fields[key];
          });
        }

        this.alertTimeout = setTimeout(() => {
          this.alertMessage = '';
          clearTimeout(this.alertTimeout);
          this.alertTimeout = null;
        }, 10000);
      }

      this.formSending = false;
    },

    cancel() {
      this.showModal = false;
    },

    closeResultModal() {
      this.showResultModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>

  .result-dialog {

    &__main-text {
      font-size: 20px;
    }

    &__sub-text {
      font-size: 18px;
      line-height: 50px;
    }

    &__actions {
      flex-direction: column;
      justify-content: center;
    }

  }

</style>
