<template>
  <div>
    <MemberInfoPopup
      v-if="infoPopup"
      v-model="infoPopupOpened"
      :buttonMode="false"
      :treeId="infoPopup.treeId"
      :data="item"
      :properties="infoPopup.properties || []"
      :propertyTitles="infoPopup.propertyTitles"
    />
    <span class="prepended-actions text-no-wrap">
      <slot name="beforeIcons" />

      <v-tooltip
        v-for="(action, index) in actions"
        :key="index"
        location="top"
        :openDelay="500"
      >
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            :icon="action.icon"
            :color="action.iconColor"
            :start="$slots.default && (index === actions?.length - 1)"
            :class="$slots.beforeIcons && (index === 0) ? 'ml-2' : ''"
            @click.stop="onClick(action)"
          />
        </template>
        <span>{{ $t(action.iconTooltip || '') }}</span>
      </v-tooltip>
      <slot />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import { InfoActionType, TableColumnAction } from '@/composables/useTableColumnActions';
import { TableGridItem } from '@/models/table-grid-interface';
import { runTemplate } from '@/utils/property-template-service';
import { useLocalization } from '@/composables/useLocalization';

import MemberInfoPopup from './MemberInfoPopup.vue';

useLocalization('tableColumnActions');

const props = defineProps<{
  actions: TableColumnAction[],
  item: TableGridItem,
}>();

const infoPopup = computed(() => props.actions.find(a => a.type === 'info-popup') as InfoActionType);
const infoPopupOpened = ref(false);

function onClick(action: TableColumnAction) {
  if (action.type === 'info-popup') {
    infoPopupOpened.value = true;
    return;
  }
  if (action.type === 'link') {
    if (action.link)
      window.location.href = runTemplate(action.link, props.item);
  }
}

</script>
