<template>
  <v-select
    v-model="selectedWalletId"
    data-component="wallet-select"
    :items="walletsFiltered"
    :loading="loading"
    :data-loading="loading ? 'true' : undefined"
    :label="title"
    itemTitle="title"
    itemValue="id"
    prependInnerIcon="mdi-wallet"
    :clearable="walletsFiltered.length > 1"
    :singleLine="true"
    :density="inputProperties?.density"
    :variant="inputProperties?.variant"
    :rounded="inputProperties?.rounded"
    :flat="inputProperties?.flat"
    :errorMessages="resultInputError ? [ resultInputError ] : []"
    :rules="rules"
    :chips="true"
  >
    <template #item="{ item, props }">
      <v-list-item v-bind="props" title="">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          ({{ item.raw.currency_id }} {{ item.raw.balance }})
        </v-list-item-subtitle>
      </v-list-item>
    </template>

    <template #chip="{ props, item }">
      <v-chip variant="text" v-bind="props" label size="normal">
        {{ item.title }} (<b>{{ item.raw.balance }}</b>&nbsp;{{ item.raw.currency_id }})
      </v-chip>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { WalletVM } from '@ui-api3-sdk/api/api3';
import { useWalletOperations } from '@/composables/useWalletOperations';
import { InputByTypeProperties } from '@/models/form-builder-interface';

const props = defineProps<{
  title?: string,
  modelValue?: number,
  inputProperties?: InputByTypeProperties,
  errorMessage?: string,
  rules?: ((v: number | undefined) => boolean | string)[],
  excepdIds?: number[],
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', result: number | undefined): void,
  (event: 'selected', result: WalletVM | undefined): void,
}>();

const { wallets, loading, error } = useWalletOperations();

const walletsFiltered = computed(() => {
  if (props.excepdIds) {
    return wallets.value.filter( i => !props.excepdIds?.includes(i.id));
  }
  return wallets.value;
});

const resultInputError = computed(() => {
  if (error.value) {
    return error.value;
  }
  if (props.errorMessage) {
    return props.errorMessage;
  }
  return undefined;
});

const selectedWalletId = ref<number | undefined>(undefined);
const firstLoad = ref(true);

watch ( () => walletsFiltered.value, () => {
  if (firstLoad.value) {
    firstLoad.value = false;
    updateDefaultSelectedId();
  }
});

watch( () => props.modelValue, (newValue) => {
  selectedWalletId.value = newValue;
});

watch( [ () => walletsFiltered.value ], () => {
  if (selectedWalletId.value && !walletsFiltered.value.find( i => i.id === selectedWalletId.value)) {
    selectedWalletId.value = undefined;
  }
  updateDefaultSelectedId();
});

watch ( selectedWalletId, () => {
  emit('update:modelValue', selectedWalletId.value);
  emit('selected', wallets.value.find( i => i.id === selectedWalletId.value));
});

function updateDefaultSelectedId() {
  if (selectedWalletId.value !== undefined) return;
  if (walletsFiltered.value.length === 0) return;

  if (walletsFiltered.value.length === 1) {
    selectedWalletId.value = walletsFiltered.value[0].id;
  }

  const walletsSortedByBalance = walletsFiltered.value.sort( (a, b) => Number(b.balance || 0) - Number(a.balance || 0));
  const biggestWallet = walletsSortedByBalance?.[0];
  if (! biggestWallet) return;

  if (Number(biggestWallet.balance || 0) > 0) {
    selectedWalletId.value = biggestWallet.id;
    return;
  }

  selectedWalletId.value = walletsFiltered.value[0].id;

}


</script>
