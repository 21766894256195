<!-- eslint-disable vue/no-lone-template -->
<template>
  <DynamicStepper
    v-bind="stepperProps"
    :inputProperties="inputProperties"
    :enableFirstBackButton="!!backHandler"
    :enableFinalSubmit="true"
    :firstBackButtonTitle="lv.back_button"
    :finalSubmitTitle="lv.change_password_button"
  >
    <template #default>
      <slot v-bind="{ confirmBy, destination }">
        <v-alert
          type="success"
          class="mb-4"
        >
          {{ lv.password_updated }}
        </v-alert>
      </slot>
    </template>
  </DynamicStepper>
</template>


<script setup lang="ts">
import { ref, watch } from 'vue';

import { useFormBuilder, FormResult } from '@/composables/useFormBuilder';
import { useLocalization } from '@/composables';

import { ConfirmationResult, ConfirmableFieldAlias } from '@/models/field-confirmation-interface';

import { useAccountProfileApi } from '@ui-api3-sdk/api/api3';
import { useDynamicStepper } from '@/composables/useDynamicStepper';
import { InputByTypeProperties } from '@/models/form-builder-interface';

import DynamicStepper from './DynamicStepper.vue';

const { lv, la } = useLocalization('changePassword', [
  'back_button',
  'change_password_button',
  'password_updated',
  'field_new_password',
  'field_confirm_password',
  'step_change_password',
  'step_contact',
  'step_contact_confirm',
  'unknown_error',
]);

const props = withDefaults(defineProps<{
  confirmBy?: ConfirmableFieldAlias;
  defaultValue?: string;
  canChangeValue?: boolean;
  inputProperties?: InputByTypeProperties;
  backHandler?: () => void;
  finalHandler?: () => void;
}>(), {
  confirmBy: 'email',
  defaultValue: '',
  canChangeValue: false,
  inputProperties: undefined,
  backHandler: undefined,
  finalHandler: undefined,
});

const { stepperProps, stepperMethods: stepper } = useDynamicStepper(
  [],
  async () => {
    props.finalHandler?.();
    return undefined;
  },
  async () => {
    props.backHandler?.();
    return undefined;
  },
);

const usePasswordForm = useFormBuilder([
  {
    title: la('field_new_password'),
    alias: 'password',
    isMandatory: true,
  },
  {
    title: la('field_new_password'),
    alias: 'password_confirm',
    isMandatory: true,
  },
]);

const destination = ref(props.defaultValue);
const confirmResult = ref<ConfirmationResult | undefined>(undefined);
const password = ref('');
const updatedPassword = ref('');

watch (() => props.defaultValue, () => {
  const formField = stepper.findFormField(props.confirmBy);
  if (formField) formField.value = props.defaultValue;
  destination.value = props.defaultValue;
});

generateSteps();

function generateSteps() {

  if (props.canChangeValue) {
    addStepChangeDestination();
    addStepVerifyDestination();
    addStepPassword(updatePassword);
  } else {
    addStepPassword();
    addStepVerifyDestination(updatePassword);
  }

  function addStepPassword(handler?: () => Promise<boolean>) {
    stepper.addStep({
      name: la('step_change_password'),
      type: 'FormBuilder',
      form: usePasswordForm,
      goNextGuard: async (res: FormResult) => {
        password.value = res['password'];
        if (handler) await handler();
        return true;
      },
    });
  }

  function addStepChangeDestination() {
    const useChangeForm = useFormBuilder([]);
    useChangeForm.methods.setFields([props.confirmBy]);
    useChangeForm.methods.getField(props.confirmBy).value = props.defaultValue;
    stepper.addStep({
      name: la('step_contact'),
      type: 'FormBuilder',
      form: useChangeForm,
      goNextGuard: async (res: FormResult) => {
        destination.value = res[props.confirmBy];
        return true;
      },
    });
  }

  function addStepVerifyDestination(handler?: () => Promise<boolean>) {
    stepper.addStep({
      name: la('step_contact_confirm'),
      type: 'FieldConfirmation',
      alias: props.confirmBy,
      valueRef: destination,
      goNextGuard: async (res: ConfirmationResult) => {
        confirmResult.value = res;
        if (handler) await handler();
        return true;
      },
      componentProps: {
        updateProfile: false,
      },
    });
  }

}

async function updatePassword() {

  if (! confirmResult.value) {
    console.error('updatePassword: confirmResult is undefined');
    return false;
  }
  if (! destination.value) {
    console.error('updatePassword: destination is undefined');
    return false;
  }
  if (! password.value) {
    console.error('updatePassword: password is undefined');
    return false;
  }

  if (password.value && updatedPassword.value === password.value) {
    return false;
  }

  const email = props.confirmBy === 'email' ? destination.value : '';
  const phone = props.confirmBy === 'phone' ? destination.value : '';

  await useAccountProfileApi().setPassword(
    {
      setPasswordReq: {
        password: password.value,
        email,
        phone,
      },
    }, {
      headers: { ... confirmResult.value.otpHeader },
    });

  updatedPassword.value = password.value;
  return true;
}


</script>
