import { gravComponentSchema } from '../deep-form-grav';
import { AccountToAccountProps, defaults } from '../../../../components/ui/AccountToAccount';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<AccountToAccountProps>({
  title: 'Account To Account',
  link: 'https://www.notion.so/mlmsoft/AccountToAccount-c8e1dd6dd13b4ebd9b7480be5882ea0b',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('walletId').as(l.clone('walletId'))
  .title('Default wallet').mandatory()
  .col(6);

s.add('confirmBy').input('dictionary').data([
  { title: 'Email', value: 'email' },
  { title: 'Phone', value: 'phone' },
])
  .props({ clearable: true })
  .title('Requires confirmation by').mandatory(false).col(6);

function text() {
  return `
<b>Account To Account</b>
This allows to transfer currency from one account to another.
`;
}
