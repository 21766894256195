<template>
  <div>
    <div class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row">
      <UserProfileAvatar
        v-if="isAvatar"
        class="flex-1-1-100 mt-8 mb-8 mt-md-2 mb-md-2 order-md-last order-lg-last order-xl-last"
        :noEdit="!isEditAvatar"
      >
        <h2 class="mt-8">{{ prettyAccountId }}</h2>
      </UserProfileAvatar>

      <div>
        <v-expansion-panels v-model="panelStates" style="max-width: 860px">
          <v-expansion-panel
            v-for="field in items"
            :key="field.alias"
          >
            <v-expansion-panel-title
              :hideActions="field.disabled"
              :disabled="field.disabled"
              expandIcon="mdi-square-edit-outline"
            >
              <v-col cols="4">
                <strong>{{ field.title }}</strong>
                <span v-if="field.isMandatory" class="text-error font-weight-bold">&nbsp;*</span>
              </v-col>
              <v-col>
                {{ field.value || '-' }}
                <FieldConfirmedStatusIcon v-if="field.value" :alias="field.alias" start />
              </v-col>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <UserProfileConfirmableField
                v-if="field.alias === 'email' && otpConfirm['email'] !== false"

                alias="email"
                :confirmMode="otpConfirm['email'] || 'optional'"
                :disabled="!isEditEmail"
                @updated="fetchData"
              />
              <UserProfileConfirmableField
                v-else-if="field.alias === 'phone' && otpConfirm['phone'] !== false"

                alias="phone"
                :confirmMode="otpConfirm['phone'] || 'optional'"
                :inputProperties="resultInputProperties"
                :disabled="!isEditPhone"
                @updated="fetchData"
              />
              <ChangePassword
                v-else-if="field.alias === 'password'"

                :confirmBy="passwordConfirmBy"
                :defaultValue="confirmDestination"
                :inputProperties="resultInputProperties"
                :canChangeValue="false"
              />
              <UserProfileEditField
                v-else
                :alias="field.alias"
                :inputProperties="resultInputProperties"
                :validateFn="getConditionForField(field.alias)"
                @updated="fetchData"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { UserProfileProps, defaults } from './UserProfile.ts';

import { FormField, assertFieldType } from '@/models/form-builder-interface';

import { useAccountPropertyCache, useEnrollSettings, useLocalization } from '@/composables';

import UserProfileAvatar from './base/UserProfileAvatar.vue';
import UserProfileConfirmableField from './base/UserProfileConfirmableField.vue';
import UserProfileEditField from './base/UserProfileEditField.vue';

import ChangePassword from './base/ChangePassword.vue';
import FieldConfirmedStatusIcon from './base/FieldConfirmedStatusIcon.vue';

import { apiErrorToString } from '@ui-api3-sdk/api/api3';
import { useAccountStore } from '@/pinia/useAccountStore';
import { usePropertiesStore } from '@/pinia/usePropertiesStore';
import { formatDate } from '@/utils/utils';
import { createEvalValidator } from '@/utils/eval-validator.ts';

const { lt, t } = useLocalization('uiUserProfile', [
  'unknown_error',
]);

const panelStates = ref<number | null>(null);

const props = withDefaults(defineProps<UserProfileProps>(), {
  id: undefined,
  editableFields: undefined,
  inputProperties: undefined,
  profileFields: () => defaults.profileFields,
  passwordConfirmBy: () => defaults.passwordConfirmBy,
  otpConfirm: () => defaults.otpConfirm,
});

const { inputProperties: resultInputProperties } = useEnrollSettings(props.inputProperties);

// const isEmail = computed(() => props.profileFields.includes('password'));
// const isPhone = computed(() => props.profileFields.includes('phone'));
// const isPassword = computed(() => props.editableFields?.includes('password'));

const isAvatar = computed(() => props.profileFields.includes('avatar_id'));

const isEditEmail = computed(() => props.editableFields?.includes('email'));
const isEditPhone = computed(() => props.editableFields?.includes('phone'));
const isEditAvatar = computed(() => props.editableFields?.includes('avatar_id'));

const prettyAccountId = ref('');

const { presentable: currentEmail } = useAccountPropertyCache('email');
const { presentable: currentPhone } = useAccountPropertyCache('phone');

const confirmDestination = computed(() => {
  if (props.passwordConfirmBy === 'email') return currentEmail.value || '';
  if (props.passwordConfirmBy === 'phone') return currentPhone.value || '';
  return '';
});

const items = ref<FormField[]>([]);
const loading = ref(false);
const error = ref<string | null>(null);


function getConditionForField(field: string) {

  const entries = props.conditions?.filter( (entry) => entry.field === field) || [];
  if (entries.length === 0) return undefined;

  const validators: Array<{ validator: (v:any) => boolean, text: string }> = [];

  entries.forEach( (entry) => {
    const validator = createEvalValidator(entry.condition);
    validators.push({
      validator,
      text: entry.text || t('inputs.err.invalid_value'),
    });
  });

  // return (value: any) => validatorFn(value) || entry.text || t('inputs.err.invalid_value');
  return (value: any) => {
    for (const { validator, text } of validators) {
      if (! validator(value)) return text;
    }
    return true;
  };

}


async function fetchData() {
  loading.value = true;
  const res: FormField[] = [];

  await usePropertiesStore().getPfAsync();

  await useAccountStore().fetchAccount(); // force fetch account

  const currentAccount = await useAccountStore().getAccountAsync();
  const profile = currentAccount?.profile;

  if (! profile ) throw new Error('Could not get profile fields');

  prettyAccountId.value = currentAccount.idValue.presentable;

  try {

    props.profileFields.forEach( (alias) => {

      if (alias === 'avatar_id') return;

      const propType = usePropertiesStore().getPropertyType(alias, undefined, 'p');

      const cachedProp = useAccountPropertyCache(alias).prop;

      const value =
        alias.includes('date') ?
          formatDate(cachedProp.value?.raw) :
          cachedProp.value?.presentable;

      res.push({
        title: propType.title,
        alias: propType.alias,
        value,
        disabled: ! props.editableFields?.includes(alias),
        isMandatory: propType.isMandatory,
        fieldType: assertFieldType(propType.fieldType),
      });

    });

    items.value = res;

  } catch (err) {
    error.value = apiErrorToString(err, lt('unknown_error'));
    console.error('fetchData', err);
  } finally {
    loading.value = false;
  }
}

onMounted( () => {
  fetchData();
});


</script>
