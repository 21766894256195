<template>
  <GravManagerWrapper :id="id" :componentName="componentName" :properties="props">
    <component :is="MetricsHistory" v-bind="{ style: $attrs.style, ...changableProps }">
      <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import GravManagerWrapper from './GravManagerWrapper.vue';
import { MetricsHistoryProps } from './MetricsHistory.ts';
import MetricsHistory from './MetricsHistory.vue';

import { useChangableProps } from '@/composables/useChangableProps';

const componentName = 'ui-metrics-history';
const $slots = defineSlots<Record<string, unknown>>();

const props = defineProps<MetricsHistoryProps>();
const changableProps = useChangableProps(componentName, props);

</script>
