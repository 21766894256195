import { ref, computed, watch, Ref, unref } from "vue";
import { PropertyType, PropertyTypes, isFetchableProperty, replaceProperties } from "@/utils/properties-mapping-service";
import { usePropertiesStore } from "@/pinia/usePropertiesStore";
import i18n from "@/i18n";
import { aliasToPretty } from "@/utils/utils";

export type ReactiveOrStaticDictionary = Ref<Record<string, string>> | Record<string, string>;

export const useProperties = (
  srcProperties: string[],
  defaultMandatoryProperties?: string[],
  overrideTitles?: ReactiveOrStaticDictionary,
) => {

  // console.log('running: useProperties() overrides=', overrideTitles);

  const properties = replaceProperties(srcProperties);

  const propertyTypes = ref<PropertyTypes | undefined>(undefined);

  const ppRef = usePropertiesStore().getPpRaw();
  const pfRef = usePropertiesStore().getPfRaw();

  const mandatoryProperties = ref<string[]>(replaceProperties(defaultMandatoryProperties || []));
  const propsWithMandatory = ref<string[]>([]);

  updatePropsWithMandatory();

  function getMandatoryProperties() {
    return mandatoryProperties.value;
  }

  function setMandatoryProperties(mandatory: string[]) {
    mandatoryProperties.value = replaceProperties(mandatory);
    updatePropsWithMandatory();
  }

  function updatePropsWithMandatory() {
    if (!getMandatoryProperties()) {
      propsWithMandatory.value = properties;
      return;
    }
    const result = new Set<string>();
    getMandatoryProperties()?.forEach((p) => result.add(p));
    properties.forEach((p) => result.add(p));
    propsWithMandatory.value = Array.from(result);
  }

  const propertyTranslations = computed(() => {
    let res = {} as Record<string, string>;

    propsWithMandatory.value.forEach((property) => {
      res[property] = aliasToPretty(property);
    });

    if (propertyTypes.value) {
        Object.entries(propertyTypes.value)
          .forEach(([k, v]) => res[k] = v.title);
    }

    if (typeof overrideTitles === 'object') {
      res = { ... res, ... unref(overrideTitles)};
    }

    return res;
  });


  async function updatePropertyTypes() {

    const res: Record<string, PropertyType> = {};
    const overrides = unref(overrideTitles) || {};

    usePropertiesStore().getPfRaw().value.forEach((item) => {
      const property = `p.${item.alias}`;
      const pt = usePropertiesStore().getPropertyType(property, overrides);
      if (pt) res[property] = pt;
    });

    usePropertiesStore().getPpRaw().value.forEach((item) => {
      const property = `m.${item.alias}`;
      const pt = usePropertiesStore().getPropertyType(property, overrides);
      if (pt) res[property] = pt;
    });

    allOtherProperties().forEach((property) => {
      const pt = usePropertiesStore().getPropertyType(property, overrides);
      if (pt) res[property] = pt;
    });

    propertyTypes.value = res;
  }


  function srcOtherProperties() {
    return properties.filter(p => !p.startsWith('m.') && !p.startsWith('p.'));
  }

  function allOtherProperties() {
    return propsWithMandatory.value.filter(p => !p.startsWith('m.') && !p.startsWith('p.'));
  }

  function allFetchableProperties() {
    return propsWithMandatory.value.filter(p => isFetchableProperty(p));
  }

  function srcPlanProperties() {
    return properties.filter(p => p.startsWith('m.'));
  }

  function allPlanProperties() {
    return propsWithMandatory.value.filter(p => p.startsWith('m.'));
  }

  function allProfileProperties() {
    return propsWithMandatory.value.filter(p => p.startsWith('p.'));
  }

  function srcProfileProperties() {
    return properties.filter(p => p.startsWith('p.'));
  }

  function srcProfileAndParentProperties() {
    return properties.filter(p => p.startsWith('p.') || p.startsWith('parent.') || p.startsWith('parent-profile.'));
  }

  function srcPlanAndParentProperties() {
    return properties.filter(p => p.startsWith('m.') || p.startsWith('parent.') || p.startsWith('parent-profile.'));
  }

  watch( [ () => ppRef.value.length, () => pfRef.value.length, () => overrideTitles?.value, i18n.global.locale ], () => {
    updatePropertyTypes();
  }, { immediate: true });

  return {
    properties,
    mandatoryProperties,
    propsWithMandatory,

    propertyTranslations, // TODO: these two should be global store
    propertyTypes,

    getMandatoryProperties,
    setMandatoryProperties,

    srcPlanProperties,
    srcPlanAndParentProperties,
    srcProfileProperties,
    srcProfileAndParentProperties,

    allPlanProperties,
    allProfileProperties,

    srcOtherProperties,
    allOtherProperties,

    allFetchableProperties,

  } as const;
};
