// This is old solution to AccountSearch being not in typescript
// could be removed later and replaced with properties-service, or useProperties composable

import { V2AccountVM , useAccountSearchApi } from "@ui-api3-sdk/api/api3";

import { createTableGridItem } from '@/utils/properties-mapping-service';
import { Item, TableGridItem } from "@/models/table-grid-interface";

export interface AccountSearchItem {
  accountId: number;
  fullname: string;
  email: string;
  formatAccountId: string;
  offset?: number;
}

export async function fetchAccountSearchInfo(accountId: number) {
  const res = await useAccountSearchApi().searchAccount({
    searchText: accountId.toString(),
    networkSearch: true,
  });

  return res.data.payload.map((account: V2AccountVM) => accountSearchItemMap(account));
}

export function accountSearchItemMap(account: V2AccountVM): AccountSearchItem {

  const item = Item(createTableGridItem([], { s: account }));

  return {
    accountId: item.accountId(),
    formatAccountId: item.presentableId(),
    fullname: item.fullName(),
    offset: item.offset(),
    email: item.email(),
  };

}

export function accountSearchItemFromItem(rawItem: TableGridItem): AccountSearchItem {
  const item = Item(rawItem);
  return {
    accountId: item.accountId(),
    formatAccountId: item.presentableId(),
    fullname: item.fullName(),
    offset: item.offset(),
    email: item.email(),
  };
}
