<template>
  <div class="d-flex flex-grow-1">
    <div data-component="segmented-select-current" class="custom-btn elevation-2" @click="onButtonPress">
      {{ selectedLabel }}
    </div>
    <v-btn v-if="items.length > 1 || ! selectedObject" v-bind="props">
      <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>

      <v-menu
        origin="top right"
        transition="scale-transition"
        style="z-index: 250"
        activator="parent"
      >
        <v-card>
          <v-list data-component="segmented-select-list">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="onItemClick(item)"
            >
              <v-list-item-title>
                {{ itemTitle(item) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Object, String, Number],
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  itemValue: {
    type: String,
    default: 'value',
  },
  itemTitle: {
    type: String,
    default: 'title',
  },
  returnObject: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'selected']);

function dealingWithObjects() {
  if (props.returnObject) {
    if (typeof props.modelValue !== 'object')
      throw new Error('modelValue must be an object');
    return true;
  }
  if (typeof props.modelValue !== 'string' && typeof props.modelValue !== 'number')
    throw new Error('modelValue must be a string or a number');
  return false;
}


const currentItemValue = computed(() =>
  dealingWithObjects() ? Object(props.modelValue)[props.itemValue] : props.modelValue,
);

const selectedObject = computed(() => {
  if (dealingWithObjects()) {
    return props.modelValue;
  }

  return props.items.find(item => Object(item)[props.itemValue] === props.modelValue);
});

const selectedLabel = computed(() => {
  if (dealingWithObjects()) {
    return Object(props.modelValue)[props.itemTitle];
  }

  const item = props.items.find(item => Object(item)[props.itemValue] === props.modelValue);

  return item ? Object(item)[props.itemTitle] : '---';
});

const onButtonPress = () => {
  emit('selected', props.modelValue);
};

 
const itemValue = (item: any) => {
  if (typeof item === 'object') return Object(item)[props.itemValue];
  return item;
};

 
const itemTitle = (item: any) => {
  if (typeof item === 'object') return Object(item)[props.itemTitle];
  return item;
};

 
const onItemClick = (item: any) => {
  const value = itemValue(item);

  if (item[props.itemValue] !== currentItemValue.value) {
    emit('update:modelValue', value);
  }

  emit('selected', value);
};
</script>

<style lang="scss" scoped>

.custom-btn {
  cursor: pointer;

  overflow: hidden;
  display: inline-block;

  padding: 8px 16px;

  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
  vertical-align: middle;

  border: none;
  border-radius: 4px;
}

.custom-btn:hover {
  background-color: rgba(128, 128, 128, 0.1);
}

</style>
