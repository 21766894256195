import { RankProgressProps, RankDefinition, defaults, MetricBarDefinition } from '@/components/ui/RankProgress';
import { gravComponentSchema } from '../deep-form-grav';

type WithSlots = RankProgressProps & {
  '#default': string;
  '#after': string;
};

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<WithSlots>({
  title: 'RankProgress',
  link: 'https://www.notion.so/mlmsoft/RankProgress-c4b507a8feae49e48d117a7bc99d8b8f',
  text: text(),
},
  defaults,
);

export default toExport;

const selectDefinedProperty = s.spawn<string>()
  .as(l.clone('propertyMarketing')); // replace with dependent property

const levelItem = s.spawn().input('dform').data([
  l.spawn<string>('key').asMany([l.clone('itemLeft'), selectDefinedProperty])
    .title('Marketing property')
    .props({ density: 'compact', dfTitleInside: true })
    .col(9).item(),
  l.spawn<string>('value').asMany([l.clone('itemRight'), bl.clone('number')])
    .title('Required value')
    .props({ density: 'compact', dfTitleInside: true })
    .col(3).item(),
])
  .init(() => ({ key: '', value: '' }));

const rankDefinition = s.spawn<RankDefinition>().input('dform').data([
  s.spawn<string>('title').input('text')
    .title('Rank name').mandatory()
    .props({ class: 'mr-md-2', density: 'compact', dfTitleInside: true })
    .col(3).item(),
  s.spawn<RankDefinition['levels']>('levels').input('drecord').data(levelItem.item())
    .col(9).item(),
])
  .props({ style: 'border: 1px solid #ccc; padding: 10px; margin-bottom: 10px; background-color: rgba(194,194,194,0.3);' })
  .init(() => ({ title: '', levels: {} }));

//

s.add('title').input('text').title('Title').col(8);
s.add('headerClass').input('mdiColor').title('Header calss').col(4);

//

s.add('treeId').as(l.clone('selectTree')).col(3);

s.add('rankField').as(l.clone('propertyMarketing'))
  .title('Rank field')
  .desc('Field used as actual current rank value from database')
  .col(3);

s.add('noSkipLevel').input('boolean').title('Do not skip level').col(3);
s.add('noDropRank').input('boolean').title('Non-droppable rank').col(3);

//

s.add('styleDefaults').input('dform').data([
  l.default().props({
    dfShowTitle: false,
    class: 'mr-md-2',
    clearable: true,
    hideDetails: false,
    dfTitleInside: true,
  }).and()
   .spawn<MetricBarDefinition['type']>('type').input('dictionary').data(['grid', 'percent', 'percent-outside']).title('Type').col(3).item(),
  l.spawn<string>('color').input('color').title('Color').col(3).item(),
  l.spawn<string>('bgColor').input('color').title('Background color').col(3).item(),
  l.spawn<string>('colorDone').input('color').title('Color done').col(3).item(),

  l.spawn<string>('titleClass').input('text').title('Title class').col(6).item(),
  l.spawn<string>('labelClass').input('text').title('Label class').col(6).item(),

  l.spawn<boolean>('striped').input('boolean').title('Striped').col(3).item(),
  l.spawn<boolean>('labelIsPercent').input('boolean').title('Show percent').col(3).item(),
  l.spawn<boolean>('rounded').input('boolean').title('Rounded').col(3).item(),
  l.spawn<number>('size').as( bl.clone('number') ).title('Height in px').col(3).item(),
])
  .props({ style: 'border: 1px solid #ccc; padding: 20px; margin: 10px; border-radius: 5px;' })
  .title('Default style')
  .desc('Default style for all metrics')
  .col(12);

//

s.add('metrics').input('dlist').data(

  s.spawn<MetricBarDefinition>()
    .input('dform').data([
      l.default().props({
        dfShowTitle: false,
        class: 'mr-md-2',
        clearable: true,
        hideDetails: false,
        dfTitleInside: true,
      }).and()
      .spawn<string>('metric').as(l.clone('propertyMarketing')).title('Metric').mandatory().col(4).item(),
      l.spawn<string>('title').input('text').title('Title/translation').col(5).item(),
      l.spawn<MetricBarDefinition['type']>('type').input('dictionary').data(['grid', 'percent', 'percent-outside']).title('Type').col(3).item(),

      l.spawn<string>('color').input('color').title('Color').col(4).item(),
      l.spawn<string>('bgColor').input('color').title('Background color').col(4).item(),
      l.spawn<string>('colorDone').input('color').title('Color done').col(4).item(),

      l.spawn<string>('titleClass').input('text').title('Title class').col(3).item(),
      l.spawn<string>('labelClass').input('text').title('Label class').col(3).item(),
      l.spawn<boolean>('labelIsPercent').input('boolean').title('Show percent').col(3).item(),
      l.spawn<boolean>('striped').input('boolean').title('Striped').col(3).item(),

    ])
    .pre((v) => {
      if (typeof v === 'string') {
        return { metric: v, type: 'percent', title: undefined };
      }
      return v;
    })
    .init(() => ({ metric: '', type: 'percent', title: undefined }))
    .props({ style: 'border: 1px solid #ccc; padding: 20px; margin: 10px; border-radius: 5px;' })
    .item(),
)
  .title('Fields (property, display type, title)')
  .desc('Metrics displayed in the component')
  .col(12);

//

s.add('ranks').input('dlist').data(rankDefinition.item())
  .title('Ranks')
  .desc('Ranks calculation rules. Make sure ranks are sorted by required values in ascending order. Lowest ranks must be higher up in this table.')
  .col(12);

s.add('#default').input('vtextarea').props({ clearable: true })
  .flag('isSlot').flag('isText').flag('slotParams', "sp")
  .title('Text before').col(12);

s.add('#after').input('vtextarea').props({ clearable: true })
  .flag('isSlot').flag('isText').flag('slotParams', "sp")
  .title('Text after').col(12);


function text() {
  return `
<b>UI Component: Rank Progress</b>
This component can show the account's progress towards the next rank.
  `;
}
