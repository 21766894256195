<template>
  <div v-if="valueChange">
    <v-chip :color="valueChange.color">{{ valueChange.diff }}</v-chip>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  oldv: number | string,
  newv: number | string,
  toFixed?: number,
  lowIsGood?: boolean,
}>(), {
  toFixed: 0,
  lowIsGood: false,
});

function toNumber(value: number | string) {
  if (typeof value === 'string')
    return Number(value.replace(/[^\d]+/g,''));

  return value;
}

const valueChange = computed(() => {
  const oldV = toNumber(props.oldv);
  const newV = toNumber(props.newv);

  if (isNaN(oldV) || isNaN(newV))
    return null;

  const diff = newV - oldV;
  const absoluteDiff = Math.abs(diff).toFixed(props.toFixed);
  const isGood = props.lowIsGood ? diff <= 0 : diff >= 0;
  const sign = isGood ? '+' : '-';

  return diff === 0
    ? null
    : {
      color: isGood ? 'green' : 'red',
      diff: `${sign}${absoluteDiff}`,
    };
});

</script>
