<template>
  <div>
    <div v-show="!fetched">
      <VSkeletonLoader type="table" />
    </div>
    <BasicCard v-show="fetched">
      <v-data-table-server
        v-model:options="options"
        :headers="headers"
        :items="items"
        :itemsLength="total"
        :loading="loadingTable"
        :data-loading="loadingTable ? 'true' : undefined"


        :itemsPerPage="noPagination ? -1 : options.itemsPerPage"
        :itemsPerPageOptions="noPagination ? undefined : [
          { value: 5, title: '5' },
          { value: 10, title: '10' },
          { value: 15, title: '15' },
          { value: 25, title: '25' },
          { value: 50, title: '50' },
          { value: 100, title: '100' },
          { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
        ]"
      />
    </BasicCard>
  </div>
</template>

<script>
import { useLocalization, useAccountId, useAwaitValue } from '@/composables';
import { useAccountPropertiesApi, useAccountPositionApi } from '@ui-api3-sdk/api/api3';

import BasicCard from './base/BasicCard.vue';

const { pt } = useLocalization();

export default {
  name: 'MetricsHistory',

  components: {
    BasicCard,
  },

  props: {
     
    id: {
      type: String,
      default: undefined,
    },

    accountId: {
      type: Number,
      default: null,
    },

    accountProperties: {
      type: Array,
      default() {
        return [];
      },
    },

    treeId: {
      type: Number,
      default: 0,
    },

    noPagination: {
      type: Boolean,
      default: false,
    },

    showCurrentPeriod: {
      type: Boolean,
      default: false,
    },

  },

  data() {
    return {
      options: {},
      total: 0,
      items: [],
      headers: [],
      loadingTable: true,
      fetched: false,

      positionIds: undefined,
      resultAccountId: undefined,
    };
  },

  watch: {
    options: {
      handler() {
        this.getInfo();
      },

      deep: true,
    },
  },

  methods: {

    async fetchPositionId() {

      if (!this.resultAccountId) {
        this.resultAccountId = await useAwaitValue(useAccountId(this.accountId), 'aId');
      }

      if (this.positionIds?.length) return;
      if (this.treeId === undefined) return;

      const { data } = await useAccountPositionApi()
        .getPosition({
          id: this.resultAccountId,
          treeId: this.treeId === -1 ? undefined : this.treeId,
          properties: [this.metric],
        });

      if (!data?.payload?.length) {
        this.resetToZero();
        this.loading = false;
        return;
      }

      const ourPositions = this.treeId === -1 ? data.payload : data.payload.filter(p => p.treeId === this.treeId);

      if(!ourPositions.length) {
        console.log(`No position found${this.treeId > -1 ? ` for treeId ${this.treeId}` : ``}`);
        return;
      }

      this.positionIds = ourPositions.map((p) => p.id);
    },


    async getInfo() {

      const { page, itemsPerPage } = this.options;

      this.loadingTable = true;

      await this.fetchPositionId();

      useAccountPropertiesApi()
        .getPropertiesHistory({
          limit: itemsPerPage,
          page: page - 1,
          id: this.resultAccountId,
          properties: this.accountProperties,
          includeCurrent: this.showCurrentPeriod,
        })
        .then(res => {
          const { map } = res.data;
          const { list: items, total } = res.data.payload;

          this.total = total;

          const headers = this.accountProperties.map(item => {
            const textField = map.properties.find(({ alias }) => alias === item);
            return {
              title: pt(`m.${item}`, textField?.title),
              value: item,
              sortable: false,
              key: item,
            };
          });

          if (this.headers.length === 0) {
            this.headers = [
              {
                title: this.$t('common.period'),
                value: 'period',
                sortable: false,
                key: 'period',
              },
              ...headers,
            ];
          }

          this.items = items.map(v => {
            const row = { period: v.period.name };

            v.properties.forEach(prop => {

              this.positionIds.forEach((id) => {
                const posIndex = prop?.values.findIndex(v => v.positionId === id);
                const value = prop?.values[posIndex]?.value;

                if (value?.raw) {
                  row[prop.alias] = value.presentable;
                }
              });

            });

            return row;
          });

          this.fetched = true;
        })
        .catch(e => {
          console.log(e);
          this.fetched = false;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
