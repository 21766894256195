<template>
  <div class="main mt-2 rounded" :style="outsideBorder">
    <v-alert
      v-if="errorMessage"
      class="mb-4"
      type="error"
      closable
      @click:close="emit('updateError', '')"
    >
      <!-- @update:model-value="errorMessage = ''" -->
      {{ $t(errorMessage) }}
    </v-alert>
    <!-- v-model:options="options" -->
    <v-data-table-server

      v-resize="onResize"

      :page="options.page"
      :itemsPerPage="noPagination ? -1 : options.itemsPerPage"
      :itemsLength="totalItems"

      :headers="headers"
      :items="modifiedItems"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"

      :itemsPerPageOptions="noPagination ? undefined : itemsPerPageOptions"

      @update:sort-by="onUpdateSortOrder($event)"
    >
      <template #item="{ item }">
        <tr>
          <td
            v-for="(header, index) in headers"
            :key="header.key"
            :style="item.rowColor?.background ? `background-color: ${item.rowColor.background}` : undefined"
            :data-column="header.key"
          >
            <TableColumnActions
              v-if="usedColumnActions.isActionsColumn(header.key, index)"
              v-bind="usedColumnActions.componentProps(header.key, index)"
              :item="item.srcItem"
            >
              <slot :name="header.key.toLowerCase()" v-bind="slotParams(item.srcItem, item[header.key])">
                {{ item[header.key] }}
              </slot>
            </TableColumnActions>
            <slot v-else :name="header.key.toLowerCase()" v-bind="slotParams(item.srcItem, item[header.key])">
              {{ item[header.key] }}
            </slot>
          </td>
        </tr>
      </template>

      <template #top>
        <div
          v-if="$slots.top || allowSwitchMode"
          class="d-flex justify-content-center flex-column flex-md-row flex-lg-row flex-xl-row toolbox-padding"
          :class="mdAndUp ? 'mdAndUp' : undefined"
        >
          <slot name="top">
            <v-switch
              v-if="allowSwitchMode"
              v-model="isGrid"
              class="pl-3"
              :label="$t('tableGrid.show_as_grid')"
              hideDetails
            />
          </slot>
        </div>

        <v-expansion-panels v-if="!isGrid" class="mb-4">
          <template v-for="(item, i) in modifiedItems" :key="`item_${i}`">
            <slot name="expansion" :item="item">
              <v-expansion-panel>
                <v-expansion-panel-title
                  :style="item.rowColor?.background ? `background-color: ${item.rowColor.background}` : undefined"
                >
                  <MemberInfoHeader :data="item.srcItem" :columnActions="columnActions">
                    <template v-if="$slots.cardAvatar" #avatar="avatarProps">
                      <slot name="cardAvatar" v-bind="avatarProps" />
                    </template>
                  </MemberInfoHeader>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="pt-4">
                  <MemberInfo
                    :data="item.srcItem"
                    :properties="properties"
                    :propertyTitles="headerTitles || {}"
                    :noHeader="true"
                    :columnActions="columnActions"
                  />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </slot>
          </template>
        </v-expansion-panels>
      </template>
      <template
        v-if="!isGrid && !nothingFound"
        #body
      />
      <template
        v-if="!isGrid"
        #headers
      />

      <template #bottom>
        <v-row v-if="showPagination" justify="center" class="align-center mt-4">
          <!-- <v-col cols="auto">
            {{ options }}
          </v-col> -->
          <v-col cols="auto">
            <v-btn
              :disabled="loading || options.page <= 1"
              variant="outlined"
              @click="options.page--"
            >
              <!-- {{ $t('$vuetify.dataFooter.prevPage') }} -->
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <span>{{ $t('$vuetify.dataFooter.pageText', fromToTotal) }}</span>
          </v-col>
          <!-- :label="t('$vuetify.dataFooter.itemsPerPageText')" -->
          <v-col cols="auto">
            <v-select
              v-if="itemsPerPageOptions"
              v-model="options.itemsPerPage"
              :disabled="loading"
              :items="itemsPerPageOptions"
              density="compact"
              variant="outlined"
              hideDetails
              @update:model-value="(ev: any) => { options.itemsPerPage = ev; options.page = 1; }"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              :disabled="loading || !nextPageExists"
              variant="outlined"
              @click="options.page++"
            >
              <!-- {{ $t('$vuetify.dataFooter.nextPage') }} -->
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table-server>
    <slot name="bottom" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue';

import { TableGridItem, TableGridPaginationState, slotParams } from '@/models/table-grid-interface';

import { useLocalization, useProperties } from '@/composables';
import { ColorScheme } from '@/utils/conditional-colors';
import { isBackendProperty } from '@/utils/properties-mapping-service';
import { useDisplay } from 'vuetify';

import { TableColumnActionsSchema, useTableColumnActions } from '@/composables/useTableColumnActions';
import MemberInfo from './MemberInfo.vue';
import MemberInfoHeader from './MemberInfoHeader.vue';
import TableColumnActions from './TableColumnActions.vue';

type TableGridProps = {
  properties: string[],
  items: TableGridItem[],
  totalItems: number;
  headerTitles?: Record<string, string>;
  loading?: boolean;
  autoSwitchMode?: boolean;
  allowSwitchMode?: boolean;
  drawBorder?: boolean;
  maximumLimit?: number;
  errorMessage?: string;
  noPagination?: boolean;
  columnActions?: TableColumnActionsSchema;
  paginations?: number[];
};

// const itemsPerPageOptions = computed(() => [
//   { value: 5, title: '5' },
//   { value: 15, title: '15' },
//   { value: 25, title: '25' },
//   { value: 50, title: '50' },
//   { value: 100, title: '100' },
//   { value: -1, title: t('$vuetify.dataFooter.itemsPerPageAll') },
// ]);

const props = withDefaults(defineProps<TableGridProps>(), {
  headerTitles: undefined,
  maximumLimit: undefined,
  errorMessage: undefined,
  drawBorder: undefined,
  noPagination: undefined,
  columnActions: undefined,
  paginations: () => [5, 15, 25, 50, 100, -1],
});


type DataTableServerOptions = {
  page: number;
  itemsPerPage: number;
  sortBy: Array<{ key: string; order: 'asc' | 'desc' | '' }>;
  groupBy: Array<{ key: string; order: 'asc' | 'desc' | '' }>;
  search?: string;
  expanded?: string[];
  grouped?: string[];
}

const emit = defineEmits<{
  (event: 'dataRequest', result: TableGridPaginationState): void,
  (event: 'updateError', result: string): void,
}>();

const { mdAndUp } = useDisplay();
const usedColumnActions = useTableColumnActions(props.columnActions);
const refHeadersOverride = toRef( () => props.headerTitles || {} );
const { propertyTranslations } = useProperties(props.properties, [], refHeadersOverride);

const { t } = useLocalization();

const isGrid = ref(true);
 
const options = ref<DataTableServerOptions>({
  page: 1,
  itemsPerPage: 10,
  sortBy: [],
  groupBy: [],
});

const itemsPerPageOptions = computed(() =>
  props.paginations.map((value) => ({
    value,
    title: value === -1 ? t('$vuetify.dataFooter.itemsPerPageAll') : String(value),
  }),
  ));

const nothingFound = computed(() => props.items.length === 0 && !props.loading);

const headers = computed(() => props.properties.map((property) => ({
  title: propertyTranslations.value?.[property] || property,
  key: property,
  value: property,
  sortable: isBackendProperty(property),
})));

const nextPageExists = computed(() => {
  const res = props.items.length >= options.value.itemsPerPage;
  return res;
});

const noTotalMode = computed(() => !props.totalItems && props.items.length);

const totalItemsText = computed(() => {
  if (noTotalMode.value) return '?';
  return props.totalItems;
});

const fromToTotal = computed(() => {
  const { page, itemsPerPage } = options.value;

  const startIndex = (page-1) * itemsPerPage + 1;
  const endIndex = startIndex + props.items.length - 1;

  return [
    startIndex,
    endIndex,
    totalItemsText.value,
  ];

});

const showPagination = computed(() => {
  if (props.noPagination === true) return false;
  if (props.noPagination === false) return true;

  if (noTotalMode.value) return true;

  if (props.totalItems)
    return props.items.length <= props.totalItems;

  return false;
});

const outsideBorder = computed(() => {
  const doDraw = props.drawBorder === undefined ? isGrid.value : props.drawBorder;
  return doDraw ? 'border: 2px solid #e0e0e0;' : undefined;
});

const modifiedItems = computed(() => props.items.map((dataItem) => {
  type InternalItem = Record<string, string> &
    {
      rowColor?: ColorScheme,
      srcItem: TableGridItem,
    };

  const item: InternalItem  = {
    srcItem: dataItem,
  } as InternalItem;

  props.properties.forEach((property) => {
    item[property] = String(dataItem[property]?.presentable || '');
  });

  item['rowColor'] = dataItem['rowColor'];

  return item;
}));

function onResize() {
  if (props.autoSwitchMode) isGrid.value = !(window.innerWidth < 800);
}

watch( () => options.value, (newOptions) => {
  onUpdateOptions(newOptions);
}, { deep: true, immediate: true });

function onUpdateSortOrder(sortBy: Array<{ key: string; order: 'asc' | 'desc' | '' }>) {
  options.value.sortBy = sortBy;
}

function onUpdateOptions(options: DataTableServerOptions) {
  // todo: do not send data request if only sorting changes and itemsPerPage is -1
  // or totalItems is less or equal? than itemsPerPage

  emit('dataRequest', {
    page: options.page,
    limit: options.itemsPerPage > 0 ? options.itemsPerPage : (props.maximumLimit ?? Number.MAX_SAFE_INTEGER),
    orderBy: options.sortBy?.[0]
      ? {
        [options.sortBy[0].key]: options.sortBy[0].order === 'asc' ? 'ASC' : 'DESC',
      }
      : undefined,
  });

}

</script>

<style lang="scss" scoped>
.main {
  padding-top: 1em;
  padding-bottom: 1em;
}

.toolbox-padding {
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}

.mdAndUp {
  gap: 1em;
  padding-top: 0;
}

</style>
