<template>
  <v-expansion-panels v-model="panelStates">
    <v-expansion-panel>
      <v-expansion-panel-title>
        <v-col cols="3">
          <strong>{{ info.title }}</strong>
        </v-col>
        <v-col>
          {{ shortenCutEnd(removeHtmlTags(info.text), 128) }}
        </v-col>
        <v-col cols="1">
          <a
            v-if="info.link && Number(useAccountId().aId?.value) === 1"
            :href="info.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon
              class="ml-2"
            >
              mdi-help-circle-outline
            </v-icon>
          </a>
        </v-col>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <pre class="pre-wrap" v-html="info.text" />
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useAccountId } from '@/composables';

import { ComponentFormFile } from '../dform/deep-form-grav';
import { shortenCutEnd } from '@/utils/utils';
import { DFFormValue } from '@/deep-form/composables/useDeepForm';

defineProps<{
  info: ComponentFormFile<DFFormValue, DFFormValue>['info'];
}>();

const panelStates = ref<number | null>(null);

function removeHtmlTags(str: string) {
  return str.replace(/<[^>]*>?/gm, '');
}

</script>

<style scoped>
.pre-wrap {
  overflow: hidden;

  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  white-space: pre-wrap;       /* CSS 3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
}
</style>
