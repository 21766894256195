<template>
  <WidgetCard
    :backgroundClass="backgroundClass"
    :headerClass="headerClass"
    :rounded="rounded"
    :height="height"
    :disableWrapper="!wrap"
    :icon="icon"
    :title="title"
  >
    <div v-if="mode === 'list'">
      <EditProperty
        v-for="property in properties"
        :key="property"
        :alias="property"
        :treeId="treeId"
        :positionId="positionId"
        :accountId="resultAccountId"
        :offset="offset"
        :readonly="!(editableProperties || []).includes(property)"
        :inputProperties="listInputProperties"
        :forceLoading="forceLoading"
      />
    </div>
    <MemberInfoTable
      v-else-if="mode === 'grid' && properties"
      class="flex-grow-1"
      :items="properties"
      :itemTitles="headerTitles || {}"
      :data="data"
      :loading="resultLoading"
      :data-loading="resultLoading ? 'true' : undefined"
    >
      <template v-for="(editProp) in editableProperties" #[`${editProp}`]="" :key="editProp">
        <slot :name="editProp.toLocaleLowerCase()">
          <EditProperty
            :alias="editProp"
            :accountId="resultAccountId"
            :treeId="treeId"
            :positionId="positionId"
            :offset="offset"

            :inputProperties="gridInputProperties"
            :autoCommit="true"
            :noTitle="true"
            :forceLoading="forceLoading"
          />
        </slot>
      </template>

      <!-- all not editable

      v-for="slotName in Object.keys($slots)"

      -->
      <template v-for="(prop) in exceptEditable" #[`${prop}`]="" :key="prop">
        <slot :name="prop.toLocaleLowerCase()" />
      </template>
    </MemberInfoTable>
    <div v-else>
      Comming soon mode: {{ mode }}
    </div>
    <slot />
  </WidgetCard>
</template>

<script lang="ts" setup>

import { PropertiesBoxProps, defaults } from './PropertiesBox';
import { useCache } from '@/composables/useCache';

import EditProperty from './base/EditProperty.vue';
import WidgetCard from './WidgetCard.vue';
import MemberInfoTable from './base/MemberInfoTable.vue';
import { computed, useSlots } from 'vue';

const slots = useSlots();

const props = withDefaults(defineProps<PropertiesBoxProps>(), {

  editableProperties: undefined,

  headerClass: undefined,
  icon: undefined,
  title: undefined,
  height: undefined,
  listInputProperties: undefined,
  treeId: undefined,
  offset: undefined,
  accountId: undefined,
  positionId: undefined,
  includeTree: undefined,
  headerTitle: undefined,
  gridAvatar: undefined,
  headerTitles: undefined,

  backgroundClass: () => defaults.backgroundClass,
  rounded: () => defaults.rounded,
  wrap: () => defaults.wrap,
  mode: () => defaults.mode,
  gridInputProperties: () => defaults.gridInputProperties,
});


const { data, loading, resultAccountId } = useCache( props );

const resultLoading = computed(() => loading.value || props.forceLoading);
const exceptEditable = computed(() => props.properties.filter( p => !props.editableProperties?.includes(p) && slots[p.toLocaleLowerCase()] ));
</script>
