<!-- eslint-disable vue/no-unused-components -->
<template>
  <OverlayProgress :loading="loading" :error="error">
    <WidgetCard :backgroundClass="backgroundClass">
      <v-tooltip
        :text="hintText"
        :disabled="!hintText"
        location="top"
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="v-row mb-2">
              <div class="v-col-3 text-no-wrap text-start flex-grow-1">
                <ValuePositiveBadge
                  v-if="showCurrentPeriod"
                  :value="currentValue.toFixed(2)"
                  zeroColor="orange"
                  variant="tonal"
                />
              </div>

              <div class="v-col-6 text-center flex-grow-1">
                {{ $t(chartTitle) }}
              </div>

              <div class="v-col-3 text-no-wrap text-end flex-grow-1">
                <ValuePositiveBadge :value="`${percent.toFixed(2)}%`" />
              </div>
            </div>
            <div>
              <Line
                :data="chartData"
                :options="chartOptions"
              />
            </div>
          </div>
        </template>
      </v-tooltip>
    </WidgetCard>
  </OverlayProgress>
</template>

<script>
import { defaults } from './ScheduleHistory.ts';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { apiErrorToString, useAccountPositionApi, useAccountPropertiesApi } from '@ui-api3-sdk/api/api3';
import { useAwaitAccountId, useLocalization } from '@/composables';
import { getMissingServerTranslations } from '@/utils/properties-translation';
import { aliasToPretty, formatBigNumber } from '@/utils/utils';
import { Line } from 'vue-chartjs';

import OverlayProgress from './base/OverlayProgress.vue';
import ValuePositiveBadge from './base/ValuePositiveBadge.vue';
import WidgetCard from './WidgetCard.vue';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const { pt, d } = useLocalization();

export default {
  name: 'ScheduleHistory',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Line,
    OverlayProgress,
    ValuePositiveBadge,
    WidgetCard,
  },

  props: {

    id: {
      type: String,
      default: undefined,
    },

    accountId: {
      type: Number,
      default: undefined,
    },

    treeId: {
      type: Number,
      default: undefined,
    },

    title: {
      type: String,
      default: undefined,
    },

    color: {
      type: String,
      default: defaults.color,
    },

    gridColor: {
      type: String,
      default: undefined,
    },

    gridWidth: {
      type: Number,
      default: defaults.gridWidth,
    },

    axisColor: {
      type: String,
      default: defaults.axisColor,
    },

    ticksColor: {
      type: String,
      default: defaults.ticksColor,
    },

    axisWidth: {
      type: Number,
      default: defaults.axisWidth,
    },

    backgroundClass: {
      type: String,
      default: defaults.backgroundClass,
    },

    metric: {
      type: String,
      default: defaults.metric,
    },

    lineWidth: {
      type: Number,
      default: defaults.lineWidth,
    },

    pointRadius: {
      type: Number,
      default: defaults.pointRadius,
    },

    fill: {
      type: Boolean,
      default: defaults.fill,
    },

    limit: {
      type: Number,
      default: defaults.limit,
    },

    showCurrentPeriod: {
      type: Boolean,
      default: defaults.showCurrentPeriod,
    },


    hintText: {
      type: String,
      default: undefined,
    },
  },

  data: function () {
    return {
      loading: true,
      error: '',
      aId: null,
      value: [],
      percent: 0,
      currentValue: 0,
      xPoints: [],

      chartOptions: {

        plugins: {
          legend: {
            display: false,
          },
        },

        interaction: {
          // mode: 'nearest',
          intersect: false, // Show tooltips based on horizontal position
        },

        scales: {
          x: {
            grid: {
              color: this.gridColor,
              lineWidth: this.gridWidth,
            },

            border: {
              color: this.axisColor,
              width: this.axisWidth,
            },

            ticks: {
              color: this.ticksColor,
            },
          },

          y: {
            grid: {
              color: this.gridColor,
              lineWidth: this.gridWidth,
            },

            border: {
              color: this.axisColor,
              width: this.axisWidth,
            },

            ticks: {

              callback: formatBigNumber,
              color: this.ticksColor,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  computed: {

    chartTitle() {
      return this.title || pt(`m.${this.metric}`);
    },

    chartData() {
      return {
        labels: this.xPoints,
        datasets: [
          {
            data: this.value,
            backgroundColor: this.color,
            borderColor: this.color,
            borderWidth: this.lineWidth,
            fill: this.fill,
            label: ` ${aliasToPretty(this.metric)}`,
            pointRadius: this.pointRadius,
          },
        ],
      };
    },
  },

  async mounted() {
    try {
      await this.fetch();
    } catch (e) {
      this.error = apiErrorToString(e);
      this.loading = false;
    }
  },

  methods: {

    async fetch() {
      this.aId = await useAwaitAccountId(this.accountId);

      let propertyId;
      let positionId;

      const positionsRes = await useAccountPositionApi()
        .getPosition({
          id: this.aId,
          treeId: this.treeId,
          properties: [this.metric],
        });

      if (! positionsRes.data?.payload?.length) {
        this.resetToZero();
        this.loading = false;
        return;
      }

      positionsRes.data.payload.forEach(position => {
        positionId = position.id;
        propertyId = position.properties.find(f => f.alias === this.metric).id;
      });

      const metricPromise = this.getMetric(propertyId);

      await metricPromise
        .then(({ data }) => {
          if (data.success && data.payload?.list?.length > 0) {
            this.value = [];
            this.xPoints = [];

            data.payload.list.sort((a, b) => {
              if (new Date(a.period.start_date) > new Date(b.period.start_date)) {
                return 1;
              }

              if (new Date(a.period.start_date) < new Date(b.period.start_date)) {
                return -1;
              }

              return 0;
            });

            data.payload.list.forEach(el => {
              this.value.push(
                +el.properties[0].values.find(v => v.positionId === positionId)?.value
                  ?.raw || 0,
              );

              this.xPoints.push(d(el.period.start_date, 'medium'));
            });

            if (this.value.length === 1) {
              this.value.push(this.value[0]);
              this.xPoints.unshift('');
            }

            // calculate percent
            const firstElem = this.value[0];
            const lastElem = this.value[this.value.length - 1];

            if (!firstElem && lastElem > 0) {
              this.percent = 100;
            } else if (firstElem && lastElem) {
              this.percent = (((lastElem - firstElem) / firstElem) * 100);
            }
            // -----------------

            this.currentValue = lastElem;
          } else {
            this.resetToZero();
          }

          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.resetToZero();
        });

      getMissingServerTranslations();

      this.loading = false;
    },

    resetToZero() {
      this.value = [0, 0];
      this.xPoints = ['',''];
      this.value = this.value.map(v => Number(v));
      this.percent = 0;
      this.currentValue = 0;
    },

    async getMetric(propId) {
      return useAccountPropertiesApi().getPropertyHistory({
        propertyId: propId,
        limit: this.limit,
        page: 0,
        id: this.aId,
        includeCurrent: this.showCurrentPeriod,
      });
    },
  },
};
</script>

<style lang="scss" module>
.ScheduleHistory {
  position: relative;
}

.listGrade {
  margin-top: 15px;
  padding: 0 5px 15px 5px;

  font-size: 12px;
  font-weight: 700;

  opacity: .72;
}

.title {
  padding: 10px 0 0;
  padding-top: 10px;

  font-size: 12px;
  font-weight: 700;
  line-height: 1.8em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0;

  opacity: .8;
}

.percent,
.current {
  position: absolute;
  top: 12px;

  display: inline;

  padding: .3em .6em;

  font-size: 80%;
  line-height: 1.1;
}

.percent {
  right: 0;
}

.current {
  left: 0;
}
</style>
