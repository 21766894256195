<template>
  <v-main>
    <v-container
      fluid
      class="px-1 px-sm-2 px-md-3 px-lg-3 px-xl-3"
    >
      <slot />
    </v-container>
  </v-main>
</template>

<script>

export default {
  name: 'DashboardCoreView',
};
</script>
