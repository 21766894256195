import { gravComponentSchema } from '../deep-form-grav';
import { FrontlineTableProps, defaults } from '@/components/ui/FrontlineTable';

 
const { s, l, v, src, h, toExport } = gravComponentSchema<FrontlineTableProps>({
  title: 'FrontLine',
  link: 'https://www.notion.so/mlmsoft/FrontlineTable-85fd25b004534e669a4d2bc4c093d3b9',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('properties').as(l.clone('mandatoryProperties')).col(6);
s.add('popupProperties').as(l.clone('popupProperties')).col(6);

s.add('popupInfoOnField').as(l.clone('popupInfoOnField')).col(4);
s.add('popupLinkUrl').as(l.clone('popupLinkUrl')).col(4);
s.add('autoSwitchMode').as(l.clone('autoSwitchMode')).col(4);

s.add('treeId').as(l.clone('selectTree')).col(4);

s.add('filter').input('filters').dataAsync(src.propertiesArray).col(8)
  .title('Filter by properties')
  .desc('Static filter for the table');

s.add('disableSearch').input('boolean')
.title('Disable search')
.desc('If you want to disable search functionality')
.col(3);

s.add('searchBy').as(l.clone('property'))
  .title('Default search by').col(3);

s.add('allowSearchBy').as(l.clone('popupProperties')).col(6)
.title('Allow search by').desc('Use only text properties here');

s.add('headerTitles').as(l.clone('headerTitles')).col(12);


function text() {
  return `
<b>Page Title and Description</b>

Please provide your custom title for the page. You may also provide a custom description which appears a line below the title. A menu icon can also be modified, please specify if you want to use a different icon.

<b>Layout</b>
Frontline accounts can be shown in different layouts: as a grid (if you expect there’ll be a lot of them for each account) or as cards. Card layout allows to show more data for each frontline account.

<b>Data Fields</b>
Please specify what profile fields and/or compensation plan properties (metrics) you want to be shown.

<b>Detailed Popup Data Fields</b>
A click on a certain account opens a popup window which allows it to show more data fields. Please provide a list of fields to be shown (both: profile fields and metrics).

<b>Pre-defined Filter</b>
This component can show not all the frontline members, but only those who meet a certain criteria. For example, you may want to show only those members having account status = ‘Customer’ (and you may rename this whole page to ‘My Customers’). And vice versa, you may want to show just those having ‘Affiliate’ status. Please provide pre-defined filter criteria if you want to use it.
`;
}
