import { TableGridItem, TableGridItemProperty } from "@/models/table-grid-interface";
import { auth } from '@ui-api3-sdk/api';
import { useCacheStore } from "@/pinia/useCacheStore";
import { usePropertiesStore } from "@/pinia/usePropertiesStore";
import { computed, watch , ref } from "vue";
import { FormField } from "./useFormBuilder";
import { apiErrorToString } from '@ui-api3-sdk/api/api3';

type useCacheProps = {
  properties: string[];

  accountId?: number;
  treeId?: number;
  offset?: number;
  positionId?: number;
  includeTree?: number;

}

export function useCache(props: useCacheProps, onFetched?: () => void){
  const data = ref<TableGridItem>({});
  const loading = ref(true);
  const resultAccountId = computed(() => props.accountId || auth.accountId());
  const formFields = ref<FormField[]>();
  const error = ref<string>();

  const refreshKey = computed(() => {
    const dependencies = [props.properties?.join('|'), resultAccountId.value, props.offset, props.positionId];
    return dependencies.join('-');
  });

  watch(refreshKey, async () => {

    if (props.properties?.length && resultAccountId.value) {
      loading.value = true;
      try {
        formFields.value = await usePropertiesStore().getFormFields(props.properties);
        const item = await useCacheStore().getProperties(props.properties, resultAccountId.value, props.offset, props.positionId, props.treeId, props.includeTree);
        data.value = item;
        if (onFetched) {
          onFetched();
        }
      } catch (err) {
        error.value = apiErrorToString(err);
      } finally {
        loading.value = false;
      }
    }
  }, { immediate: true });

  function updateProperty(property: string, value: TableGridItemProperty['raw']) {
    if (!resultAccountId.value)
      throw new Error('No account id');
    useCacheStore().updateProperty(resultAccountId.value, property, value);
  }

  return {
    resultAccountId,
    data,
    loading,
    error,
    formFields,
    updateProperty,
  };

}
