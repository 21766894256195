/* eslint-disable camelcase */
import { AccountVM, V2AccountVM } from "@ui-api3-sdk/api/api3";

export function mapAccountVMToV2(accountVM: AccountVM): V2AccountVM {
  const v2AccountVM: V2AccountVM = {
    id: accountVM.id,
    idValue: accountVM.idValue,
    status: accountVM.status,
    created_at: accountVM.created_at,
    activated_at: accountVM.activated_at,
    profile: accountVM.profile,
    externalId: accountVM.externalId,
  };
  return v2AccountVM;
}
