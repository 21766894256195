import { useAccountProfileApi, useOTPApi, useAccountApi } from '@ui-api3-sdk/api/api3';
import { useAccountStore } from '@/pinia/useAccountStore';

import { ConfirmableFieldAlias } from '@/models/field-confirmation-interface';
import { useFormBuilder } from './useFormBuilder';
import { useLocalization } from './useLocalization';

export function useConfirmableField(field: ConfirmableFieldAlias) {

  const { lt, language } = useLocalization('fieldConfirmation', [
    'not_unique',
    'error_checking_uniqueness',
  ]);

  const accountStore = useAccountStore();

  async function isConfirmed() {
    const currentAccount = await accountStore.getAccountAsync();
    if (!currentAccount) throw new Error('Account not found');

    const res = await useAccountProfileApi()
      .isConfirmed({
        id: currentAccount.id,
        alias: field,
      });

      return res.data.payload.confirmed;
  }

  async function sendCodeGetRequestId(destination: string, codeLength = 4) {

    const backendTemplatesCompat = {
      'ru': 'ru-RU',
      'en': 'en-US',
    } as Record<string, string>;

    const common = {
      language: backendTemplatesCompat[language] || language,
      codeLength,
    };

    const api = useOTPApi();

    const res = await ( field === 'email'
      ? api.otpEmail({ otpEmailReq: { email: destination,  ...common } })
      : api.otpSms({ otpSmsReq: { phone: destination,  ...common } })
    );

    return res?.data.payload.requestId || '';

  }

  async function checkUniqueness(
    value: string,
    form?: ReturnType<typeof useFormBuilder>,
  ) {

    if (form) form.methods.setLoading(true);

    try {

      const uniqueCheckResult = await useAccountApi()
        .otpUniquenessCheck({ uniquenessCheckReq: { [field]: value } });

      if (form) form.methods.setLoading(false);

      const isUnique = uniqueCheckResult.data.payload[ `${field}Unique` ];

      if (!isUnique ) {
        if (form) form.methods.addFieldError(field, lt('not_unique'));
      }

      return isUnique;

    } catch(err) {
      if (form) {
        form.methods.setLoading(false);
        if (form.methods.parseApiValidation(err)) return undefined;
        form.methods.addError(lt('error_checking_uniqueness'));
      }

      return undefined;
    }

  }

  return {isConfirmed, sendCodeGetRequestId, checkUniqueness};
}
