<template>
  <div>
    <MemberInfoHeader
      v-if="!noHeader"
      :data="resData || {}"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"

      :columnActions="columnActions"
      :titleField="titleField"
      :subtitleField="subtitleField"
    />
    <slot />

    <MemberInfoTable
      v-if="profileProps.length > 0"
      :title="$t('common.profile_props')"
      :items="profileProps"
      :itemTitles="headerTitles"
      :data="resData || {}"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"

      :columnActions="columnActions"
    />

    <MemberInfoTable
      v-if="planProps.length > 0"
      :title="$t('common.plan_props')"
      :items="planProps"
      :itemTitles="headerTitles"
      :data="resData || {}"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"

      :columnActions="columnActions"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { apiErrorToString } from '@ui-api3-sdk/api/api3';
import { useProperties, useToast } from '@/composables';
import { TableColumnActionsSchema } from '@/composables/useTableColumnActions';
import { Item, TableGridItem } from '@/models/table-grid-interface';
import { fetchFullProperties } from '@/utils/fetch-full-properties-service';

import MemberInfoHeader from './MemberInfoHeader.vue';
import MemberInfoTable from './MemberInfoTable.vue';

const props = defineProps<{
  properties: string[];

  data?: TableGridItem;
  treeId?: number;
  accountId?: number;
  offset?: number;

  propertyTitles?: Record<string, string>;
  noHeader?: boolean;

  columnActions?: TableColumnActionsSchema;
  titleField?: string,
  subtitleField?: string,
}>();
/*
import { TableColumnActionsSchema, useTableColumnActions } from '@/composables/useTableColumnActions';

<TableColumnActions
              v-if="usedColumnActions.isActionsColumn(header.key, index)"
              v-bind="usedColumnActions.componentProps(header.key, index)"
              :item="item.srcItem"
            >
const usedColumnActions = useTableColumnActions(props.columnActions);
*/
const usedProperties = useProperties(
  props.properties,
  ['s.id', 'r.id', 'p.firstname', 'p.lastname', 'p.avatar_id'],
  props.propertyTitles,
);

const loading = ref(false);
const resData = ref<TableGridItem | undefined>();

const profileProps = computed(() => usedProperties.srcProfileProperties());
const planProps = computed(() => usedProperties.srcPlanAndParentProperties());
const headerTitles = computed(() => usedProperties.propertyTranslations.value || {} );

function isEnoughProperties() {
  if (!resData.value) return false;
  return usedProperties.allFetchableProperties().every((prop) => !!resData.value![prop]);
}

watch ( [() => props.data, () => props.accountId, () => props.offset, () => props.properties], async () => {
  resData.value = props.data;

  if (isEnoughProperties()) {
    return;
  }

  loading.value = true;

  try {
    const item = props.data ? Item(props.data!) : undefined;

    resData.value = await fetchFullProperties(
      usedProperties.allFetchableProperties(),
      props.treeId,
      props.accountId || item?.accountId() || undefined,
      props.offset || item?.offset() || undefined,
    );
  } catch (e) {
    useToast().error(apiErrorToString(e));
  } finally {
    loading.value = false;
  }

}, { immediate: true });


</script>
