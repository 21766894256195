import { toast } from 'vuetify-sonner';

const ok = (message: string) => {
  toast(message, {
    cardProps: {
      color: 'success',
    },
  });
};

const error = (message: string) => {
  toast(message, {
    cardProps: {
      color: 'error',
    },
  });
};

export function useToast() {
  return { ok, error };
}
