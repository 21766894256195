
import { FilterItem } from '@ui-api3-sdk/api/api3';
import { TableColumnActionsSetupProps } from '@/composables/useTableColumnActions';

export type FrontlineTableProps = {
  id?: string;
  properties: string[],
  accountId?: number;
  headerTitles?: Record<string, string>;
  autoSwitchMode?: boolean;
  treeId?: number;
  offset?: number;
  filter?: FilterItem[];
  disableSearch?: boolean;
  searchBy?: string;
  allowSearchBy?: string[];
} & TableColumnActionsSetupProps;

export const defaults = {
  properties: ['s.id'],
  autoSwitchMode: true,
  treeId: 0,
  offset: 1,
  filter: [],
  searchBy: undefined,
  disableSearch: false,
  allowSearchBy: [],
  popupInfoOnField: '0',
  popupLinkUrl: '/{{site_language}}/network/view-network/?nodeId={{s.id.raw}}',
};
