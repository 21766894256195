<template>
  <v-expansion-panel :key="properties[currentLanguage].name">
    <v-expansion-panel-title>
      <v-col cols="4">
        <v-icon
          :icon="propertyTypeIcon"
          class="mr-2"
        />
        <span class="text-h6">{{ srcProperty.name }}</span>
      </v-col>
      <v-col>
        {{ srcProperty.value }}
      </v-col>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="pt-4">
      <div v-if="srcProperty.isSlot">
        <InputByType
          v-model="slotParamsValue"
          :alias="`${srcProperty.name}-slot-params`"
          title="slot params"
          fieldType="string"
        />
      </div>
      <div
        v-for="lang in shownLanguages"
        :key="lang"
        class="d-flex align-center"
      >
        <div v-if="shownLanguages.length > 1">
          <v-checkbox
            v-model="selected[lang]"
          />
        </div>
        <div class="flex-grow-1">
          <InputByType
            v-model="values[lang]"
            :title="lang"
            :alias="srcProperty.name"
            :fieldType="inputType(properties[lang], values[lang] || '')"
          >
            <template #title>
              <div class="d-flex justify-space-between">
                <div>{{ lang }}</div>
                <div>
                  <span
                    v-if="lang !== currentLanguage"
                    class="my-href"
                    @click="translate(lang)"
                  >
                    translate to {{ lang }} from {{ currentLanguage }}
                  </span>
                </div>
              </div>
            </template>
          </InputByType>
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <div class="flex-shrink-1">
          <v-checkbox
            v-model="doDelete"
            label="delete property"
          />
        </div>
        <div class="flex-grow-1" />
        <div class="d-flex align-center">
          <v-select
            v-model="updateMode"
            class="mr-4"
            :items="updateModeItems"
            itemTitle="text"
            itemValue="value"
            variant="plain"
          />
          <v-btn
            size="large"
            color="primary"
            :loading="grav.loading"
            :data-loading="grav.loading ? 'true' : undefined"

            @click="submitUpdate"
          >
            {{ doDelete ? 'Delete' : 'Update' }}
          </v-btn>
        </div>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { ref, watch, computed , onMounted } from 'vue';
import { GravComponentPropertyDTO } from '../../grav-api-models';
import { useGravManager } from '../comosables/useGravManager';
import { useTranslator } from '../comosables/useTranslator';

import InputByType from '@/components/ui/base/InputByType.vue';

type UpdateMode = 'this' | 'all' | 'selected';

const updateModeItems = [
  { text: 'Only current language', value: 'this' },
  { text: 'All languages using same value', value: 'all' },
  { text: 'Selected languages only', value: 'selected' },
];

const props = defineProps<{
  componentId: string;
  currentLanguage: string;
  properties: Record<string, GravComponentPropertyDTO>;
}>();

const grav = useGravManager();

const emit = defineEmits(['deleted']);

const updateMode = ref<UpdateMode>('this');
const doDelete = ref(false);

const languages = computed(() => {
  const withoutCurrent = Object.keys(props.properties).filter((lang) => lang !== props.currentLanguage);
  return [props.currentLanguage, ...withoutCurrent];
});

const shownLanguages = computed(() => {
  if (updateMode.value === 'this') return [props.currentLanguage];
  if (updateMode.value === 'all') return [props.currentLanguage];
  return languages.value;
});

const selected = ref<Record<string, boolean>>({});
const values = ref<Record<string, string | null>>({});
const slotParamsValue = ref<string>('');

const srcProperty = computed(() => props.properties[props.currentLanguage]);

// const propertyValue = computed(() => values.value[props.currentLanguage]);
const propertyTypeIcon = computed(() => {
  if (srcProperty?.value.isSlot)
    return 'mdi-xml';
  if (srcProperty?.value.isText)
    return 'mdi-comment-text-outline';
  if (srcProperty?.value.isDynamic)
    return 'mdi-code-json';
  return 'mdi-tag-outline';
});


function inputType(prop: GravComponentPropertyDTO, value: string) {
  if (prop.isSlot) return 'html';
  if (value.length > 120) return 'txt';
  return 'string';
}

function updateValue(language: string, value: string | null) {
  values.value[language] = value;
}

async function submitUpdate() {

  const updateData: Record<string, string | null> = updateMode.value === 'all' ?
    languages.value.reduce((acc, lang) => ({ ...acc, [lang]: values.value[props.currentLanguage] }), {})
    :
    Object.fromEntries(
      Object.entries(selected.value)
        .filter(([, isSelected]) => isSelected)
        .map(([lang]) => [lang, values.value[lang] ]),
    );

  // console.log('submitUpdate', props.componentId, srcProperty.value.name, updateData);

  if (Object.keys(updateData).length === 0) return;

  if (doDelete.value) {
    Object.keys(updateData).forEach((lang) => {
      updateData[lang] = null;
    });
  }

  await grav.upcliUpdateProperty(
    props.componentId,
    srcProperty.value.name,
    updateData,
    slotParamsValue.value,
  );

  if (doDelete.value) {
    emit('deleted');
  }

}

watch(
  () => props.properties,
  () => {
    Object.keys(props.properties).forEach((lang) => {
      updateValue(lang, props.properties[lang].value);
    });
    slotParamsValue.value = srcProperty?.value?.slotParams || '';
  }, { immediate: true, deep: true },
);

onMounted(() => {
  selected.value[props.currentLanguage] = true;
  updateMode.value = srcProperty.value.isText ? 'selected' : 'all';
});

async function translate(lang: string) {
  // console.log('translate', lang);
  const value = values.value[props.currentLanguage];
  if (! value) return;

  const translation = await useTranslator().translateText(lang, value);
  if (translation) {
    updateValue(lang, translation);
    selected.value[lang] = true;
  }
}

</script>

<style scoped lang="scss">
//need to make this class with cursor pointer and underline on hover

.my-href {
  cursor: pointer;
  text-decoration: underline;
}

</style>
