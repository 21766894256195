import { Item, TableGridItem } from "@/models/table-grid-interface";

export const CONDITION_OPERATORS = ['<', '>', '=', 'contains', 'one_of'] as const;
type Condition = typeof CONDITION_OPERATORS[number];
type Value = number | string | boolean | undefined | null;

export type ColorSchemeIn = string | {
  background?: string,
  title?: string,
  text?: string,
  badge?: string | Array<Badge | string>,
};

export type ColorRule = [string, Condition, Value, ColorSchemeIn];

export type ColorScheme = {
  background?: string,
  title?: string,
  text?: string,
  badges?: Array<Badge>,
}

export interface ColorRules {
  self?: ColorSchemeIn,
  front?: ColorSchemeIn,
  deadend?: ColorSchemeIn,
  fields?: Array<ColorRule>,
}

export interface Badge {

  id: string;

  imgUrl?: string;
  imgSize?: number;

  text?: string;
  fontSize?: number;

  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'top-middle' | 'bottom-middle';
  offsetX?: number;
  offsetY?: number;

  backgroundColor?: string;
  color?: string;
  borderRadius?: string;
  padding?: number;

}

export type ValueConstants = Record<string, Value>;

function parseSchemeIn(scheme: ColorSchemeIn): ColorScheme {
  if (typeof scheme === 'string') return {
    background: scheme,
  };

  const result: ColorScheme = {};

  if (scheme.background) result.background = scheme.background;
  if (scheme.title) result.title = scheme.title;
  if (scheme.text) result.text = scheme.text;

  if (scheme.badge) {
    if (scheme.badge instanceof Array) {
      result.badges = scheme.badge.map(badge => getFullBadgeDefinition(badge));
    } else {
      result.badges = [getFullBadgeDefinition(scheme.badge)];
    }
  }

  function getFullBadgeDefinition(badgeIn: string | Badge): Badge {

    if (Array.isArray(badgeIn))
      throw new Error('Singl badge definition cannot be an array!');

    let result : Badge | undefined = undefined;

    const textDefaults : Badge = {
      id: '',
      text: undefined,
      position: 'bottom-middle',
      color: 'rgba(var(--v-theme-on-warning))',
      backgroundColor: 'rgba(var(--v-theme-warning))',
      borderRadius: '5px',
      padding: 5,
      fontSize: 12,
      offsetY: -10,
    };

    const imgDefaults : Badge = {
      id: '',
      imgUrl: undefined,
      position: 'top-right',
      imgSize: 40,
      offsetX: -20,
      offsetY: -20,
      borderRadius: '50%',
      backgroundColor: 'rgba(var(--v-theme-accent))',
    };

    if (typeof badgeIn === 'string') {
      if (badgeIn.startsWith('http') || badgeIn.startsWith('/')) {
        const imgUrl = badgeIn;
        result = { ... imgDefaults, id: imgUrl, imgUrl };
      } else {
        const text = badgeIn;
        result = { ... textDefaults, id: text, text };
      }
    }

    if (typeof badgeIn === 'object') {
      const badge = badgeIn;
      if (badge.imgUrl) result = { ... imgDefaults, ... badge };
      if (badge.text) result = { ... textDefaults, ... badge };
    }

    if (! result)
      throw new Error('Badge definition error!');

    if (! result.id )
      result.id = result.imgUrl || result.text || '';

    return result;
  }

  return result;
}


export function getMatchedRules(rules: Array<ColorRule>, item: TableGridItem, valueConstants?: ValueConstants) {

  const matchedRules: ColorSchemeIn[] = [];

  for (const rule of rules) {
    const [prop, condition, conditionValueSrc, scheme] = rule;

    if (!scheme)
      throw new Error('color rules format error!');

    let value = item[prop]?.raw;
    if (typeof value === 'object') continue;

    if (typeof value === 'string' && /^[.,\d]+$/.test(value))
      value = Number(value);

    const conditionValue = replaceTemplates(conditionValueSrc, valueConstants);

    if (condition === '=') {
      // eslint-disable-next-line eqeqeq
      if (value == conditionValue)
        matchedRules.push(scheme);
      continue;
    }

    if (condition === 'contains') {
      if (typeof value === 'string' && value.includes(String(conditionValue)))
        matchedRules.push(scheme);
      continue;
    }

    if (condition === 'one_of') {
      if (typeof conditionValue !== 'string')
        throw new Error('one_of condition value must be a comma separated values string!');
      const values = conditionValue.split(',').map(v => v.trim());
      if (values.includes(String(value)))
        matchedRules.push(scheme);
      continue;
    }

    if (typeof value === 'string' || typeof value === 'boolean' || value === undefined || value === null) continue;

    if (condition === '>') {
      if (value > Number(conditionValue))
        matchedRules.push(scheme);
      continue;
    }

    if (condition === '<') {
      if (value < Number(conditionValue))
        matchedRules.push(scheme);
      continue;
    }

  }

  return matchedRules;

}

export function getColorScheme(item: TableGridItem, rules?: ColorRules, valueConstants?: ValueConstants) {

  if (!rules) return undefined;

  let resultScheme: ColorScheme | undefined = undefined;

  const level = Item(item).level();

  if (level === 0) merge(rules?.self);
  if (level === 1) merge(rules?.front);
  if (Item(item).kids() === 0) merge(rules?.deadend);

  if (!rules?.fields || rules?.fields.length === 0)
    return resultScheme;

  const matchedFieldRules = getMatchedRules(rules.fields, item, valueConstants);
  matchedFieldRules.forEach(scheme => merge(scheme));

  function merge(scheme?: ColorSchemeIn) {

    if (!scheme) return;

    const newScheme = parseSchemeIn(scheme);

    if (!resultScheme) {
      resultScheme = newScheme;
      return;
    }

    (['background', 'title', 'text'] as Array<keyof Omit<ColorScheme, 'badges'>>).forEach(prop => {
      if (newScheme[prop])
        resultScheme![prop] = newScheme[prop];
    });

    if (newScheme.badges) {
      if (!resultScheme.badges)
        resultScheme.badges = newScheme.badges;
      else {
        // merging badges by id, so we can disable badges by setting imgUrl and text to undefined
        newScheme.badges.forEach(newBadge => {
          const index = resultScheme!.badges?.findIndex(badge => badge.id === newBadge.id);
          if (index === -1 || !index)
            resultScheme!.badges?.push(newBadge);
          else
            resultScheme!.badges![index] = newBadge;
        });
      }
    }

  }

  return resultScheme;
}

function replaceTemplates(value: Value, valueConstants?: ValueConstants) {
  if (!valueConstants) return value;
  if (typeof value !== 'string') return value;
  if (!value.startsWith('$')) return value;
  return valueConstants[value] || value;
}
