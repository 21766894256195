import { isNumber } from "lodash";
import { computed, reactive } from "vue";

 
const store: Record<string, any> = reactive({});

export function useChangableProps<T>(componentName: string, initialProps: T) {
   
  const id = (initialProps as any)['id'] as string | undefined;

  if (!id) {
    return computed(() => initialProps);
  }

  const fullName = `${componentName}:${id}`;

  writeProps(fullName, initialProps);
  return computed<T>(() => store[fullName] as T);
}

 
export function writeProps(componentNameWithId: string, props: any) {
  // console.log('writing props', componentNameWithId, 'value', props);
  const oldProps = (store[componentNameWithId] || {});
  const key = oldProps['key'] && isNumber(oldProps['key']) ? ++oldProps['key'] : 1;
  store[componentNameWithId] = { ...oldProps, ...props, key };
}
