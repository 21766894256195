
// here is typescript schema of your input components

import { DFModelValue } from '../models/dform-input-core';

import { VTextField, VTextarea } from 'vuetify/lib/components/index.mjs';
// straight from vuetify^

import InputString from '../components/InputString.vue';
import InputBoolean from '../components/InputBoolean.vue';
// custom simple inputs^

import InputDictionary from '../components/InputDictionary.vue';
import { InputDictionaryData } from '../components/InputDictionary.ts';

import InputDList from '../components/InputDList.vue';
import { InputDListData } from '../components/InputDList.ts';

import InputDForm from '../components/InputDForm.vue';
import { InputDFormData } from '../components/InputDForm.ts';

import InputDRecord from '../components/InputDRecord.vue';
import { InputDRecordData } from '../components/InputDRecrod';
import InputDColor from '../components/InputDColor.vue';
import InputDisplay from '../components/InputDisplay.vue';

export const DFBasicInputs = {
  dictionary: {
    component: InputDictionary,
    data: [] as InputDictionaryData,
    value: undefined as string | number | undefined,
  },
  text: {
    component: InputString,
    data: null,
    value: undefined as string | number | undefined,
  },
  vtext: {
    component: VTextField,
    data: null,
    value: undefined as string | number | undefined,
  },
  vtextarea: {
    component: VTextarea,
    data: null,
    value: undefined as string | number | undefined,
  },
  display: {
    component: InputDisplay,
    data: null,

    value: '' as any,
  },
  dform: {
    component: InputDForm,
    data: {} as InputDFormData,
    value: {} as object,
  },
  dlist: {
    component: InputDList,
    data: {} as InputDListData,
    value: [] as Array<DFModelValue>,
  },
  drecord: {
    component: InputDRecord,
    data: {} as InputDRecordData,
    value: {} as object,
  },
  boolean: {
    component: InputBoolean,
    data: null,
    value: undefined as boolean | undefined,
  },
  color: {
    component: InputDColor,
    data: null,
    value: undefined as string | undefined,
  },
};
