import { useAccountPositionApi } from '@ui-api3-sdk/api/api3';
import { parseVMListToTableGridItems } from "./properties-mapping-service";
import { useAccountStore } from "@/pinia/useAccountStore";
import { useAwaitAccountId } from "@/composables";

export async function fetchMyPosition(treeId: number, properties: string[]) {
  const positionProps = properties
    .filter((prop) => prop.startsWith('m.'))
    .map((prop) => prop.replace('m.', ''));

  const accountId = await useAwaitAccountId();

  const asyncFunctions = [
    useAccountPositionApi().getPosition({
      treeId,
      id: accountId,
      properties: positionProps,
    }),
    useAccountStore().getAccountAsync(),
  ] as const;

  const [positionResult, account] = await Promise.all(asyncFunctions);

  if (!positionResult || !account)
    throw new Error('Could not get my position');

  useAccountStore().setAccount(account);

  const positions = positionResult.data.payload;
  const position = positions?.[0];

  if (!position)
    return null;

  position.account = account;
  const tableItems = parseVMListToTableGridItems([], [position]);

  const translations: Record<string, string> = {};

  position.properties.forEach((prop) => {
    translations[`m.${prop.alias}`] = prop.title;
  });

  return { item: tableItems[0], translations };
}
