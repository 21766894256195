 

export const number = (value: any) => {
  // console.log('c.number:', value);
  if (value === undefined || value === null || value === '') return undefined;
  const conv = Number(value);
  return Number.isNaN(conv) ? value : conv;
};

export const undefineEmpty = (value: any) => {
  if (value === undefined || value === null || value === '') return undefined;
  if (Array.isArray(value) && value.length === 0) return undefined;
  if (typeof value === 'object' && Object.keys(value).length === 0) return undefined;
  return value;
};
