/**
 * Creates a compiled function to evaluate a value against a JavaScript condition.
 * @param jsCondition String containing JavaScript code returning boolean, checking 'value'.
 * @returns A function that accepts a value and returns a boolean result of the evaluation.
 */
export function createEvalValidator(jsCondition: string): (value: any) => boolean {
  const code = `return (value) => { return ${jsCondition}; };`;

  try {
    const fn = new Function(code)();
    return (value: any) => {
      try {
        return !!fn(value);
      } catch (e) {
        console.error('EC: Error evaluating value:', value, 'with condition:', jsCondition, e);
        return false;
      }
    };
  } catch (e) {
    console.error('EC: Failed to create validator for condition:', jsCondition, e);
    // Return a no-op validator that always returns false
    return () => false;
  }
}


// const isNumberGreaterThanTen = createEvalValidator('(value > 10) && (typeof value === "number")');

// console.log(isNumberGreaterThanTen(15)); // true
// console.log(isNumberGreaterThanTen('15')); // false
// console.log(isNumberGreaterThanTen(5)); // false