<template>
  <v-toolbar color="grey-darken-4">
    <v-container class="d-flex flex-row align-center">
      <v-toolbar-title>
        <v-img
          :src="logoSmall"
        />
      </v-toolbar-title>

      <v-spacer />

      <div
        v-for="(item, i) in items || []"
        :key="item.title + i"
      >
        <v-btn
          v-if="! item.children || item.children.length === 0"
          variant="text"
          :active="item.active"
          :href="item.href && item.href !== '#' ? item.href : undefined"
        >
          <template #prepend>
            <v-icon v-if="!!item.icon">{{ item.icon }}</v-icon>
          </template>
          <span v-if="!xs || !item.icon">{{ item.title }}</span>
        </v-btn>

        <v-menu v-else>
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              :active="item.children?.some(a => a.active)"
            >
              <template #prepend>
                <v-icon v-if="!!item.icon">{{ item.icon }}</v-icon>
              </template>
              <span v-if="!xs || !item.icon">{{ item.title }}</span>
              <v-icon v-if="!xs">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(kid) in item.children"
              :key="kid.title"
              :active="kid.active"
              :href="kid.href && kid.href !== '#' ? kid.href : undefined"

              @click="actionItem(item)"
            >
              <v-list-item-title>
                <v-icon v-if="!!kid.icon" start>{{ kid.icon }}</v-icon>
                {{ kid.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-container>
  </v-toolbar>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { MenuItem } from '@/models/menu';
import { auth } from '@ui-api3-sdk/api';
import { useThemeStore } from '@/pinia/useThemeStore';
import { useDisplay } from 'vuetify';
import { useConditionalMenu } from '@/pinia/useConditionalMenu';

const conditionalMenu = useConditionalMenu();

 
const props = defineProps<{
  menuItems: Array<MenuItem>
}>();

const items = ref<MenuItem[] | undefined>();

const { xs } = useDisplay();

const theme = useThemeStore();
const logoSmall = theme.headerLogoSmall;

function actionItem(item: MenuItem) {
  if (item.action === 'logout') {
    auth.logout();
  }
}

onMounted(async() => {
  items.value = await conditionalMenu.filter(props.menuItems);
});

</script>
