<template>
  <v-alert
    v-if="isLoggedIn"
    type="success"
    class="mb-4"
  >
    <div>{{ lv.you_are_logged_in }}</div>
  </v-alert>

  <ChangePassword
    v-else-if="resetMode"
    :confirmBy="passwordConfirmBy"
    :defaultValue="form.hasField(loginFieldAlias)?.value || ''"
    :inputProperties="resInputProperties"
    :canChangeValue="true"
    :backHandler="() => resetMode = false"
    :finalHandler="onPasswordReset"
  />
  <FormBuilder
    v-else
    v-bind="formProps"
    ref="formBuilderRef"
    :hideSubmitButton="true"
    :buttons="[]"
    :inputProperties="resInputProperties"
    @submit="onSubmit($event)"
    @keydown.enter="enterPressed($event)"
  >
    <v-alert
      v-if="passwordWasChanged"
      type="success"
      class="mb-4"
    >
      <div>{{ lv.password_changed }}</div>
    </v-alert>

    <template #belowError="{ submitDisabled, submit, loading }">
      <div class="d-flex flex-row-reverse mb-6">
        <a href="#" @click="resetMode = true">
          {{ lv.forgot_password }}
        </a>
      </div>
      <div class="d-flex">
        <v-btn
          class="mx-auto"
          variant="elevated"
          color="primary"
          type="submit"
          :loading="loading"
          :data-loading="loading ? 'true' : undefined"
          :disabled="submitDisabled"
          @click="submit()"
        >
          {{ lv.login_button }}
        </v-btn>
      </div>
    </template>
  </FormBuilder>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { UserLoginProps, defaults } from './UserLogin';

import { useEnrollSettings, useFormBuilder, useLocalization, useNavigation } from '@/composables';
import { useAccountStore } from '@/pinia/useAccountStore';
import { auth } from '@ui-api3-sdk/api';
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore';

import { FormResult } from '@/composables/useFormBuilder';
import { getLoginFieldAlias } from '@/utils/utils';

import { getGeneralCallback } from '@/utils/callback-service';

import ChangePassword from './base/ChangePassword.vue';
import FormBuilder from './base/FormBuilder.vue';

const props = withDefaults(defineProps<UserLoginProps>(), {
  inputProperties: undefined,
  passwordConfirmBy: () => defaults.passwordConfirmBy,
});

const { lv, lt } = useLocalization('uiUserLogin', [
  'login_button',
  'forgot_password',
  'you_are_logged_in',
  'password_changed',
  'unknown_error',
]);

const formBuilderRef = ref<InstanceType<typeof FormBuilder> | null>(null);
const { props: formProps, methods: form } = useFormBuilder([]);

const loginFieldAlias = ref('');
const resetMode = ref(false);
const passwordWasChanged = ref(false);
const isLoggedIn = ref(false);
const isLoading = ref(true);

const { settings: enrollSettings, inputProperties: resInputProperties } = useEnrollSettings(props.inputProperties);
const runtimeSettings = useRuntimeSettingsStore();

watch( enrollSettings, () => {
  loginFieldAlias.value = getLoginFieldAlias(enrollSettings.value.loginType);
  form.setFields([loginFieldAlias.value, 'password']);

}, { immediate: true, deep: true } );

function enterPressed(event: KeyboardEvent) {
  const fromInput = event.target instanceof HTMLInputElement;
  if (fromInput) {
    event.preventDefault();
    event.stopPropagation();
    form.submit();
  }
}

function focusOnLastInput() {
  const el = formBuilderRef.value?.$el;
  if (!el) return;
  const inputs = el.getElementsByTagName('input');
  if (!inputs.length) return;
  const lastInput = inputs[inputs.length - 1];
  if (lastInput) lastInput.focus();
}

function onPasswordReset() {
  const loginField = form.getField(loginFieldAlias.value);
  const login = loginField.value;
  form.reset();
  loginField.value = login;
  resetMode.value = false;
  passwordWasChanged.value = true;
}

async function onSubmit(formRes: FormResult) {

  const password = formRes['password'];
  const login = formRes[loginFieldAlias.value];
  passwordWasChanged.value = false;

  form.setLoading(true);

  try {
    const res = await auth.login(login, password);

    if (res) {
      console.log('Login ok.');
      isLoggedIn.value = true;

      if (props.onLogin) {
        const cb = getGeneralCallback(props.onLogin);
        if (cb) cb(auth.accountId);
        return;
      }

      const gotTo = runtimeSettings.getLoginRedirectPath || '/';
      runtimeSettings.setLoginRedirectPath('');
      
      useNavigation().go(gotTo);

      return;
    }

    form.setLoading(false);
    form.reset();


  } catch (err: any) {
    setTimeout(focusOnLastInput, 300);
    form.setLoading(false);
    if (form.parseApiValidation(err)) return;
    form.addError(lt('unknown_error'));
  }

}

onMounted(() => {
  if (!auth.accessTokenExists) {
    isLoggedIn.value = false;
    isLoading.value = false;
    return;
  }

  useAccountStore().getAccountAsync()
    .then( (account) => {
      if (account) {
        isLoggedIn.value = true;
        useNavigation().go('/');
      }
    })
    .finally( () => {
      isLoading.value = false;
    });

});


</script>
