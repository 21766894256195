<template>
  <div>
    <v-progress-circular
      v-if="resLoading"
      :data-loading="true"
      color="primary"
      indeterminate
    />
    <div v-else>
      <div v-for="(line, i) in resLogs" :key="i">
        {{ line }}
      </div>
    </div>
    <div class="mt-4">
      <v-icon v-if="result === true" color="green">mdi-check</v-icon>
      <v-icon v-if="result === false" color="red">mdi-close</v-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useGravManager } from '../comosables/useGravManager';

const props = withDefaults(defineProps<{
  logs?: string[];
  result?: boolean;
}>(), {
  logs: undefined,
  result: undefined,
});

const resLogs = computed(() => props.logs ?? useGravManager().logs);
const resLoading = computed(() => useGravManager().loading);

</script>
