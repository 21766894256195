<template>
  <div>
    <v-autocomplete
      v-model="selectedAccount"
      v-model:search="search"
      data-component="account-search"
      :items="accounts"
      :loading="forceLoading || isLoading"
      :data-loading="forceLoading || isLoading ? 'true' : undefined"
      clearable
      hideNoData
      :hideDetails="hideDetails"
      returnObject
      :customFilter="() => true"
      color="primary"
      :label="$t(label || 'common.account')"
      itemTitle="fullname"
      itemValue="accountId"
      :menuIcon="accounts.length > 0 ? undefined : ''"
      :menuProps="{
        attach: attachTo
      }"

      prependInnerIcon="mdi-magnify"
      singleLine
      :density="inputProperties?.density"
      :variant="inputProperties?.variant"
      :rounded="inputProperties?.rounded"
      :flat="inputProperties?.flat"

      :messages="messages"
      :errorMessages="errorMessages"
      @update:model-value="onSelected"
      @update:focused="onFocused"
    >
      <template #chip="{ props, item }">
        <v-chip
          v-bind="props"
          theme="dark"
          :color="errorMessages?.length ? 'error' : 'primary'"
          :text="selectedAccountChipLabel(item)"
          :modelValue="item.selected"
          @click="item.select"
        />
      </template>
      <template #item="{ props, item }">
        <v-list-item
          v-bind="props"
        >
          <v-list-item-title>
            <p class="ma-0 pl-2 pt-1">
              {{ item.raw.formatAccountId }} {{ item.raw.offset && item.raw.offset > 1 ? ` / ${item.raw.offset}` : '' }}
            </p>
          </v-list-item-title>
          <v-list-item-subtitle class="pl-2">
            {{ item.raw.email ? '' + item.raw.email : '' }}
          </v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

  <script>
import { useAccountStore } from '@/pinia/useAccountStore';
import { accountSearchItemMap } from '@/utils/account-search-view.ts';
import { formatAccountId } from '@/utils/utils';
import { apiErrorToString, useAccountSearchApi } from '@ui-api3-sdk/api/api3';

import { useToast } from '@/composables';


export default {
  name: 'AccountSearch',
  props: {
    modelValue: {
      type: Object,
      default: null,
    },

    networkSearch: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: undefined,
    },

    messages: {
      type: [String, Array],
      default: '',
    },

    errorMessages: {
      type: [String, Array],
      default: '',
    },

    inputProperties: {
      type: Object,
      default: null,
    },

    prependItems: {
      type: Array,
      default: () => [],
    },

    attachTo: {
      type: HTMLElement,
      default: undefined,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    forceLoading: {
      type: Boolean,
      default: false,
    },

  },

  emits: ['update:modelValue', 'validate'],

  data() {
    return {
      selectedAccount: this.modelValue,
      isLoading: false,
      search: null,
      accounts: [ ... this.prependItems],
    };
  },

  watch: {

    prependItems(newVal) {
      this.accounts = [ ... newVal ];
    },

    search(val) {
      if (typeof val === 'object') return;

      let valTrim = val?.trim();

      if (! valTrim )
        return;

      const s1 = String(valTrim).normalize();
      const s2 = String(this.selectedAccount?.fullname?.trim()).normalize();

      if (s1 === s2) return;

      this.isLoading = true;

      if (valTrim.length < 20 && /^(\d|\s)*$/.test(valTrim))
        valTrim = valTrim.replace(/\s*/g, '');

      return this.debouncedSearch(valTrim)
        .then(r => {
          this.accounts = [ ... this.prependItems, ... this.mapAccounts(r.data.payload)];
        })
        .catch((e) => {
          this.accounts = [ ... this.prependItems];
          const errorMsg = apiErrorToString(e);
          console.log('searchError:', errorMsg);
          useToast().error(errorMsg);
        })
        .finally(() => (this.isLoading = false));
    },

    modelValue(newVal) {

      if ((this.selectedAccount?.accountId === newVal?.accountId)
        && (this.selectedAccount?.offset === newVal?.offset)
      ) return;

      if (newVal) {
        const found = this.accounts.find(
          a => (a.accountId === newVal.accountId) && (a.offset === newVal.offset));
        if (! found )
          this.accounts.push(newVal);
        this.selectedAccount = newVal;
      } else
        this.selectedAccount = null;

    },
  },

  created() {
    // TODO: convert to composition and use useDebounceFn from useVue
    this.debouncedSearch = debounce(this.searchInner, 500);
  },

  methods: {

    selectedAccountChipLabel() {
      const item = this.selectedAccount;
      if (!item) return '';
      const fullname = item.fullname.trim();
      const accountId = item.formatAccountId ||
        formatAccountId(item.accountId, useAccountStore().idMask);
      const offset = item.offset && item.offset > 1 ? ` / ${item.offset}` : '';
      return `${ fullname ? `${fullname} - ` : ''}${accountId}${offset}`;
    },

    mapAccounts(accountsFromApi) {
      return accountsFromApi.map( (a) => accountSearchItemMap(a));
    },

    onSelected(item) {
      this.$emit('update:modelValue', item);
      this.$emit('validate');
    },

    onFocused($event) {
      if ($event === false) this.$emit('validate');
    },

    clearList() {
      this.accounts = [ ... this.prependItems];
    },


    clearAll() {
      this.selectedAccount = null;
      this.clearList();
    },

    async searchInner(searchText) {
      if (searchText === '') return [];

      const api = useAccountSearchApi();

      return api.searchAccount({
        searchText,
        networkSearch: useAccountStore().heavyDownlineMode === true ? true : this.networkSearch,
        // networkSearch: this.networkSearch,
        profileFields: useAccountStore().accountSearchFields,
      }).catch(() => []);
    },
  },
};


function debounce(f, ms) {
  let timer = null;
  let promise = new Promise(resolve => {
    resolve([]);
  });

  return function (...args) {
    const onComplete = () => {
      timer = null;

      return f.apply(this, args);
    };

    if (timer) {
      clearTimeout(timer);
    }

    promise = new Promise(resolve => {
      timer = setTimeout(function () {
        resolve(onComplete());
      }, ms);
    });

    return promise;
  };
}


  </script>
