import { MetricsHistoryProps, defaults } from '@/components/ui/MetricsHistory';
import { gravComponentSchema } from '../deep-form-grav';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<MetricsHistoryProps>({
  title: 'Metrics History',
  link: '',
  text: text(),
},
  defaults,
);

export default toExport;

const propInput = s.spawn<string>('alias').input('dictionary').dataAsync(src.propertiesMarketingNoPrefix)
  .props({ hideDetails: true }).init(() => '').mandatory();

s.add('accountProperties').input('dlist').data(propInput.item())
  .title('Marketing properties to show in table').mandatory(false)
  .col(12).props({ class: 'mr-4' }).item();

s.add('treeId').as( l.clone('selectTree') ).mandatory(false).col(9);
s.add('showCurrentPeriod').input('boolean').title('Show current period').col(3);

function text() {
  return `
<b>Metrics History</b>
This shows a table of metric values over passed periods.
`;
}
