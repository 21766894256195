<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <DynamicStepper
    v-bind="stepperProps"
    :inputProperties="inputProperties"
    :enableFinalSubmit="true"
    :finalSubmitTitle="la('change_button')"
  >
    <template #default>
      <v-alert
        type="success"
        class="mb-4"
      >
        {{ confirmedText }}
      </v-alert>
    </template>
  </DynamicStepper>
</template>

<script setup lang="ts">
import { onMounted, computed , ref } from 'vue';

import { useAccountPropertyCache, useLocalization } from '@/composables';
import { useFormBuilder } from '@/composables/useFormBuilder';
import { useAccountStore } from '@/pinia/useAccountStore';

import { ConfirmableFieldAlias } from '@/models/field-confirmation-interface';

import DynamicStepper from './DynamicStepper.vue';
import { useDynamicStepper } from '@/composables/useDynamicStepper';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { propertyTranslation } from '@/utils/properties-translation';
import { ConfirmationMode } from '../SignupRegistration';
import { useEventBus } from '@/pinia/useEventBus';


const { lt, la } = useLocalization('userProfileConfirmableField', [
  'step_change',
  'step_confirm',
  'change_button',
  'your_new_email_confirmed',
  'your_new_phone_confirmed',
  'your_email_confirmation_skipped',
  'your_phone_confirmation_skipped',
  'unknown_error',
]);

const props = defineProps<{
  alias: ConfirmableFieldAlias,
  inputProperties?: InputByTypeProperties,
  disabled?: boolean,
  confirmMode?: ConfirmationMode,
}>();

const emit = defineEmits(['updated']);

const accountStore = useAccountStore();
const { setAll: updateValueInStore } = useAccountPropertyCache(props.alias);

const useChangeForm = useFormBuilder([
  {
    title: propertyTranslation(props.alias),
    alias: props.alias,
    isMandatory: true,
    disabled: props.disabled,
  },
]);

const confirmationDone = ref(false);

const confirmedText = computed(() => {
  if (confirmationDone.value)
    return lt(`your_new_${props.alias}_confirmed`, [newValue.value]);
  return lt(`your_${props.alias}_confirmation_skipped`);
});

const changeForm = useChangeForm.methods;
const newValue = computed(() => changeForm.getField(props.alias)?.value);

const { stepperProps } = useDynamicStepper([
  {
    name: la('step_change'),
    type: 'FormBuilder',
    form: useChangeForm,
  },
  {
    name: la('step_confirm'),
    type: 'FieldConfirmation',
    alias: props.alias,
    valueRef: newValue,
    goNextGuard: async (result) => {
      // console.log('goNextGuard, value=', newValue.value, 'alias=', props.alias, 'result=', result);
      if ( result?.otpHeader ) {
        confirmationDone.value = true;
      }
      useEventBus().update('confirm_field_status', { field: props.alias });
      updateValueInStore(newValue.value);
      emit('updated');
      return true;
    },
    componentProps: {
      updateProfile: true,
      optionalConfirm: props.confirmMode === 'optional',
    },
  },
], async () => undefined,
);

async function fetchData() {
  changeForm.setLoading(true);

  const currentAccount = await accountStore.getAccountAsync();
  const profile = currentAccount?.profile;
  if (! profile ) throw new Error('Could not get profile fields');

  changeForm.setValuesFromProfile(profile);
  changeForm.setLoading(false);
}

onMounted( () => {
  fetchData();
});

</script>
