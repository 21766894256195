
export interface UserPopProps {
  id?: string;
  items?: string[];
  properties?: string[];
  treeId?: number,
  itemTitles?: Record<string, string>;
  nameProperty?: string;
  avatarSize?: number;
  avatarPosition?: 'start' | 'end' | 'none';
  pulldownIcon?: string;
}

export const defaults = {
  items: [],
  properties: [],
  treeId: 0,
  itemTitles: {},
  avatarSize: 40,
  pulldownIcon: 'mdi-arrow-down-circle',
  avatarPosition: 'start' as const,
};
