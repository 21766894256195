import { TableGridItem } from "@/models/table-grid-interface";

export interface RankDefinition {
  title: string;
  levels: Record<string, number>;
  vars?: Record<string, string>;
}

export interface MetricBarStyle {
  type?: 'grid' | 'percent' | 'percent-outside';

  color?: string;
  bgColor?: string;
  colorDone?: string;
  striped?: boolean;

  labelIsPercent?: boolean;

  rounded?: boolean;
  size?: number;
  titleClass?: string;
  labelClass?: string;

}
export type MetricBarDefinition = {
  metric: string;
  title?: string;
} & MetricBarStyle;

export interface RankProgressProps {
  id?: string;
  treeId?: number;
  metrics: string[] | MetricBarDefinition[];
  ranks: RankDefinition[];
  title?: string;
  dataItem?: TableGridItem;
  metricTitles?: Record<string, string>;
  rankField?: string;
  noSkipLevel?: boolean;
  noDropRank?: boolean;

  headerClass?: string;

  styleDefaults?: MetricBarStyle;
}

export const defaults = {
  items: [],
  ranks: [],
  title: '{{yourNextRank}}: {{earnedRankTitle}}',
  treeId: 0,
  metricTitles: {},
  rankField: 'm.Downline',
  noSkipLevel: false,

  styleDefaults: {
    type: 'percent' as const,
    color: 'amber',
    bgColor: 'gray',
    colorDone: 'green',
    striped: false,
    labelIsPercent: false,
    rounded: true,
    size: 30,
    titleClass: 'ml-6',
    labelClass: 'mr-6',
  },

};
