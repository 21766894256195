<template>
  <div>
    <v-menu
      :closeOnContentClick="false"
      location="bottom"
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ props }">
        <v-text-field
          v-bind="{ ... $attrs, ... cProps }"
          :label="cProps.dfTitle"
          @update:model-value="emit('update:modelValue', $event);"
        >
          <template #prepend-inner>
            <v-icon v-bind="props" :color="cProps.modelValue" start>
              mdi-format-color-fill
            </v-icon>
          </template>
        </v-text-field>
      </template>

      <div>
        <v-color-picker
          @update:model-value="emit('update:modelValue', $event);"
        />
      </div>
    </v-menu>
  </div>
</template>

<script setup lang="ts">

import { InputProps, InputEmits } from '../models/dform-input-core';

const cProps = defineProps<InputProps<string, unknown>>();
const emit = defineEmits<InputEmits<string>>();

</script>
