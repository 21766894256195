 
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';

import 'vuetify/styles';
import '@/scss/overrides.scss';

import i18n from '@/i18n';

// import {
//   VAlert, VBtn, VToolbar, VContainer, VDivider, VSpacer, VMenu, VIcon, VList, VListItem, VListItemTitle,
//   VSkeletonLoader, VDataTable, VDataTableServer, VDatePicker, VStepper, VStepperItem, VStepperHeader, VStepperWindow, VStepperWindowItem, VStepperActions,
//   VProgressCircular, VCardActions, VCard, VThemeProvider, VSelect, VImg, VNavigationDrawer, VMain, VSwitch, VCardTitle, VCombobox, VForm, VCardText, VExpandTransition, VTextField, VTextarea,
//   VApp, VAppBar, VCol, VRow, VColorPicker, VItem, VSheet, VItemGroup, VFooter,
//   VTooltip, VChip, VTabs, VTab, VWindowItem, VWindow, VDialog, VListItemSubtitle,
//   VProgressLinear,
// } from 'vuetify/lib/components/index.mjs';

import * as components from 'vuetify/lib/components/index.mjs';
import * as directives from 'vuetify/lib/directives/index.mjs';

const colors = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
};

const dark = {
  dark: true,
  colors: { ...colors },
};

const light = {
  dark: false,
  colors: { ...colors,
    ['on-surface']: '#454545',
    ['on-background']: '#454545'},
};

export default createVuetify({
  components,
  directives,
  // components: {
  //   VSkeletonLoader,
  //   VDataTable,
  //   VDataTableServer,
  //   VDatePicker,
  //   VStepper,
  //   VStepperItem,
  //   VStepperHeader,
  //   VStepperWindow,
  //   VStepperWindowItem,
  //   VStepperActions,
  //   VAlert,
  //   VBtn,
  //   VToolbar,
  //   VContainer,
  //   VDivider,
  //   VSpacer,
  //   VMenu,
  //   VIcon,
  //   VList,
  //   VListItem,
  //   VListItemTitle,
  //   VProgressCircular,
  //   VCardActions,
  //   VCard,
  //   VThemeProvider,
  //   VSelect,
  //   VImg,
  //   VNavigationDrawer,
  //   VMain,
  //   VSwitch,
  //   VCardTitle,
  //   VCombobox,
  //   VForm,
  //   VCardText,
  //   VExpandTransition,
  //   VTextField,
  //   VTextarea,
  //   VApp,
  //   VAppBar,
  //   VCol,
  //   VRow,
  //   VColorPicker,
  //   VItem,
  //   VSheet,
  //   VItemGroup,
  //   VFooter,
  //   VTooltip,
  //   VChip,
  //   VTabs,
  //   VWindow,
  //   VDialog,
  //   VListItemSubtitle,
  //   VTab,
  //   VWindowItem,
  //   VProgressLinear,

  // },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: { dark, light },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 4,
      darken: 4,
    },
  },
});
