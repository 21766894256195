/* eslint-disable camelcase */

export default {
  p_requestId: "Request ID",
  p_requestDate: "Request date",
  p_amount: "Amount",
  p_paymentSystem: "Payment system",
  p_status: "Status",

  status_pending: "Pending",
  status_done: "Done",
  status_canceled: "Canceled",
  status_error: "Error",

  unknown_error: "Unknown error",
};
