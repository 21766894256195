 
import z from 'zod';

import { VTextarea, VTextField } from 'vuetify/lib/components/index.mjs';

import { CUSTOM_INPUTS_MAP } from '../custom.inputs';

import InputBoolean from "../inputs/boolean.vue";
import InputImage64 from  "../inputs/image64.vue";
import InputSelectEnum from '../inputs/select-enum.vue';
import InputMdiColorData from '../inputs/mdi-color.vue';
import InputDate from '../inputs/date.vue';

// import type { FilterItem } from '~/src/api/gen';

export const INPUTS_MAP = {
  'ID': {
    component: VTextField,
    props: undefined,
    zod: z.string(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'String': {
    component: VTextField,
    props: undefined,
    zod: z.string(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'Enum': {
    component: InputSelectEnum,
    props: { values: [] as string[], titles: {} as Record<string, string> },
    zod: z.string(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'Boolean': {
    component: InputBoolean,
    props: undefined,
    zod: z.boolean(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'Int': {
    component: VTextField,
    props: undefined,
    zod: z.number(),
    convertOut: (value?: string) => parseInt(value || '0', 10),
    convertIn: (value?: number) => value?.toString(),
    formatErrorMsg: 'Expected a number',
  },
  'Float': {
    component: VTextField,
    props: undefined,
    zod: z.number(),
    convertOut: (value?: string) => parseFloat(value || '0'),
    convertIn: (value?: number) => value?.toString(),
    formatErrorMsg: 'Expected a float number',
  },
  'DateTime': {
    component: InputDate,
    props: undefined,
    zod: z.date(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'JSON': {
    component: VTextField,
    props: undefined,
    zod: z.any(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'Object': {
    component: 'Form',
    props: undefined,
    zod: z.object({}).passthrough(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'Text': {
    component: VTextarea,
    props: undefined,
    zod: z.string(),
    convertOut: undefined,
    convertIn: undefined,
    formatErrorMsg: undefined,
  },
  'Image64': {
    component: InputImage64,
    props: undefined,
    zod: z.object({
      fileName: z.string().optional(),
      fileData: z.string().optional(),
    }),
    convertOut: undefined,
    convertIn: (v: any) => {
      if (typeof v === 'string') {
        return { fileData: v, fileName: '' };
      }
      return v;
    },
    formatErrorMsg: undefined,
  },
  'Image64Simple': {
    component: InputImage64,
    props: undefined,
    zod: z.string(),
    convertOut: (v: any) => {
      if (typeof v === 'object' && 'fileData' in v) {
        return v.fileData;
      }
      return v;
    },
    convertIn: (v: any) => {
      if (typeof v === 'string') {
        return { fileData: v, fileName: '' };
      }
      return v;
    },
    formatErrorMsg: undefined,
  },
  'MdiColor': {
    component: InputMdiColorData,
    props: undefined,
    zod: z.string().optional(),
    convertOut: undefined,
    convertIn: (v: any) => {
      if (typeof v !== 'string') return v;
      const rmPrefix = v.replace(/^status-color-/, '').replace('gray', 'grey');
      return rmPrefix;
    },
    formatErrorMsg: undefined,
  },
  ... CUSTOM_INPUTS_MAP,
};
