import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";
import { InputByTypeProperties } from "@/models/form-builder-interface";
import { ConfirmationMode } from "./SignupRegistration";

export type FieldCondition = {
  field: string;
  condition: string;
  text?: string;
};

export interface UserProfileProps {
  id?: string;
  profileFields?: string[];
  editableFields?: string[];
  passwordConfirmBy?: ConfirmableFieldAlias
  inputProperties?: InputByTypeProperties;
  otpConfirm?: Record<ConfirmableFieldAlias, ConfirmationMode>;
  conditions?: FieldCondition[];
}

export const defaults = {
  profileFields: ['avatar_id', 'firstname', 'lastname', 'birth_date', 'country_id', 'region_id', 'email', 'phone', 'password'],
  passwordConfirmBy: 'email' as const,
  otpConfirm: {
    'email': true,
    'phone': 'optional' as const,
  },
};
