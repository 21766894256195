/* eslint-disable camelcase */
export default {
  back_button: "Назад",
  change_password_button: "Сменить пароль",
  password_updated: "Ваш пароль успешно обновлен!",
  field_new_password: "Новый пароль",
  field_confirm_password: "Подтвердите пароль",
  step_change_password: "Смена пароля",
  step_contact: "Адрес контакта",
  step_contact_confirm: "Подтверждение",
  unknown_error: "Произошла неизвестная ошибка при смене пароля",
};
