 
import { AccountFullProfileVM, FrontLinePositionVM, LeaderboardItemDTO, PLanPropertyValueVM, PositionPropertyValueVM, PositionVM, TransactionLogVM, V2AccountVM, V2DownlinePositionVM } from "@ui-api3-sdk/api/api3";
import { TableGridItem, TableGridItemProperty } from "@/models/table-grid-interface";
import * as Calculated from './properties-calculated';
import { useAccountStore } from '@/pinia/useAccountStore';
import { formatAccountId } from "./utils";

const DYNAMIC_BACKEND_PROPERTY_PREFIXES = ['m', 'p'] as const;

const BACKEND_PROPERTY_PREFIXES =
  [...DYNAMIC_BACKEND_PROPERTY_PREFIXES, 's', 'position', 'parent-profile', 'parent'] as const;

const LOCAL_PROPERTY_PREFIXES = ['t', 'r', 'custom'] as const;

const VIEW_MODDEL_PREFIXES = ['vmdl', 'vmtl', 'vmlb'] as const;

const PROPERTY_PREFIXES =
  [...BACKEND_PROPERTY_PREFIXES, ...VIEW_MODDEL_PREFIXES, ...LOCAL_PROPERTY_PREFIXES] as const;

export type PropertyPrefix = typeof PROPERTY_PREFIXES[number];

const LOCAL_PROPERTY_PREFIXES_REGEXP = new RegExp(`^(${LOCAL_PROPERTY_PREFIXES.join('|')})\\.`);
const PROPERTY_PREFIX_REGEXP = new RegExp(`^(${PROPERTY_PREFIXES.join('|')})\\.`);
const BACKEND_PROPERTY_PREFIX_REGEXP = new RegExp(`^(${BACKEND_PROPERTY_PREFIXES.join('|')})\\.`);

type DownlineLikeVM = V2DownlinePositionVM | FrontLinePositionVM | PositionVM;

interface PropertiesValuesSource {
  s?: V2AccountVM;
  m?: PositionPropertyValueVM[] | PLanPropertyValueVM[];
  p?: AccountFullProfileVM[];
  vmdl?: DownlineLikeVM;
  vmtl?: TransactionLogVM;
  self?: TableGridItem;
  vmlb?: LeaderboardItemDTO;
}

type PropertyValuesList = AccountFullProfileVM[] | PositionPropertyValueVM[] | PLanPropertyValueVM[];

export interface PropertyType {
  id?: number;
  prefix?: PropertyPrefix;
  alias: string;
  title: string;
  filterable: boolean;
  sortable: boolean;
  fieldType: string;
  isMandatory?: boolean;
}

export type PropertyTypes = Record<string, PropertyType>;

// todo: add doc- ? for transaction log
export const PROPERTIES: Array<string> = [
  's.id',
  's.status',
  's.created_at',
  's.external_id',

  'parent.id',
  'parent.status',
  'parent.created_at',
  'parent.activated_at',

  'position.tree_level',

  'vmdl.id',
  'vmdl.offset',
  'vmdl.number',
  'vmdl.childrenCount',
  'vmdl.level',
  'vmdl.treeId',
  'vmdl.accountId',
  'vmdl.account_status_id',
  'vmdl.parentPositionId',
  'vmdl.genealogyParentAccountId',
  'vmdl.spillLeg',
  'vmdl.createdAt',

  'vmtl.property',
  'vmtl.docType',
  'vmtl.created',
  'vmtl.newValue',
  'vmtl.oldValue',
  'vmtl.context',

  'vmlb.accountAvatar',
  'vmlb.accountId',
  'vmlb.accountTitle',
  'vmlb.position',
  'vmlb.positionPrevious',
  'vmlb.value',

  't.level',
  't.fullName',
  't.namePlusId',
  't.accountPlusOffset',

  'custom.login',
  'custom.password',
  'custom.password_confirm',
  'custom.sponsor_id',

  'p.*',
  'parent-profile.*',

  'm.*',
  'r.id',
  'r.fullName',
];

type ValueResolveFN = (prefix: PropertyPrefix, property: string, src: PropertiesValuesSource) => TableGridItemProperty | undefined;
type ListResolveFN = (prefix: PropertyPrefix, src: PropertiesValuesSource) => string[];

const RESOLVERS: Record<PropertyPrefix, [ValueResolveFN, ListResolveFN]> = {
  's': [resolveValueAccount, resolveListStatic],
  'parent': [resolveValueAccount, resolveListStatic],
  'position': [resolveValuePosition, resolveListStatic],
  'vmdl': [resolveValueVMDownline, resolveListStatic],
  'vmtl': [resolveValueVMTransactionLog, resolveListStatic],
  'vmlb': [resolveValueVMLeaderBoard, resolveListStatic],
  'm': [resolveValueMarketingPlan, resolveListDynamic],
  'p': [resolveValueAccountProfile, resolveListDynamic],
  'parent-profile': [resolveValueAccountProfile, resolveListDynamic],

  't': [resolveValueCalculated, resolveListStatic],
  'r': [resolveEmpty, resolveListStatic],
  'custom': [resolveEmpty, resolveListStatic],
};


export function createTableGridItem(properties: string[], src: PropertiesValuesSource) {
  const item: TableGridItem = {};
  src.self = item;

  if (!properties.length) properties = getAllPropertyNames(src);

  properties.forEach((property) => {
    const value = getPropertyValue(property, src);
    if (value) item[property] = value;
  });

  return item;
}

export function absolutelyAllPropertyNames(list: Array<DownlineLikeVM>) {
  const uniqueNames = new Set<string>();
  list.forEach((vmdl) => {
    const names = getAllPropertyNames({ vmdl });
    names.forEach((name) => uniqueNames.add(name));
  });
  return [...uniqueNames];
}

export function parseVMListToTableGridItems(
  properties: string[],
  list: Array<DownlineLikeVM>,
) {
  const fromProperties = properties.length ? properties : getAllPropertyNames({ vmdl: list[0] });
  return list.map((vmdl) => createTableGridItem(fromProperties, { vmdl }));
}

export function parsePropertyName(propName: string) {

  const [metaPrefix, metaPropertyName] = propName.split(':');

  if (metaPrefix && metaPropertyName)
    propName = metaPropertyName;

  if (!PROPERTY_PREFIX_REGEXP.test(propName)) return [undefined, propName, metaPrefix] as const;
  const [prefix, name] = propName.split('.');
  return [prefix as PropertyPrefix, name, metaPrefix] as const;
}

export function isBackendProperty(property: string) {
  return BACKEND_PROPERTY_PREFIX_REGEXP.test(property);
}

export function isFetchableProperty(property: string) {
  return PROPERTY_PREFIX_REGEXP.test(property)
    && !LOCAL_PROPERTY_PREFIXES_REGEXP.test(property);
}

// ************* VM RESOLVERS

export function resolveListStatic(prefix: PropertyPrefix) {
  return PROPERTIES
    .filter((prop) => prop.startsWith(`${prefix}.`));
}

function collapseTreePositionPropertiesList(src: PropertiesValuesSource): PropertyValuesList {

  if (src.m) return src.m;
  if (!src.vmdl) return [];

  let list: PositionPropertyValueVM[] = src.vmdl?.properties || [];

  if (!('treePosition' in src.vmdl) || !src.vmdl.treePosition) return list;

  if (Array.isArray(src.vmdl.treePosition)) {

    // only properties from first additional position are used
    if (src.vmdl.treePosition?.[0]?.properties)
      list = [...list, ...src.vmdl.treePosition[0].properties];

    /*
    list = src.vmdl.treePosition.reduce((acc, item) => {
      if (item.properties) acc.push(...item.properties);
      return acc;
    }, list);
    */

  } else if (src.vmdl.treePosition.properties) {
    list = [...list, ...src.vmdl.treePosition.properties];
  }

  return list;
}

function resolveListDynamic(prefix: PropertyPrefix, src: PropertiesValuesSource) {

  let list: PropertyValuesList | undefined;

  if (prefix === 'm') {
    list = collapseTreePositionPropertiesList(src);
  } else if (prefix === 'p')
    list = src.p || src.s?.profile || src.vmdl?.account?.profile;
  else if (prefix === 'parent-profile') {
    if (src.vmdl && ('parentPosition' in src.vmdl))
      list = src.vmdl.parentPosition?.account.profile;
  } else
    throw new Error('resolveListDynamic/wrong prefix');

  if (!list) return [];
  return list.map((p) => `${prefix}.${p.alias}`);
}

 
function resolveEmpty(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  return stringValue('');
}

 
function resolveValueCalculated(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  if (!src.self) return undefined;

  const table: Record<string, (item: TableGridItem) => string> = {
    'fullName': Calculated.fullName,
    'namePlusId': Calculated.namePlusId,
    'accountPlusOffset': Calculated.idWithOffset,
  };

  const fn = table[name];
  if (!fn) return undefined;
  return stringValue(fn(src.self));

}

export function recalculateProperties(src: TableGridItem) {
  const props = resolveListStatic('t');
  const res = { ...src };
  props.forEach((prop) => {
    const value = getPropertyValue(prop, { self: src });
    if (value) res[prop] = value;
  });
  return res;
}

function resolveValueAccount(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  let account: V2AccountVM | undefined;

  if (prefix === 's')
    account = src.s || src.vmdl?.account;
  else if (prefix === 'parent') {
    if (src.vmdl && ('parentPosition' in src.vmdl))
      account = src.vmdl.parentPosition?.account;
  } else
    throw new Error('resolveValueAccount/wrong prefix');

  if (!account) {
    if (name === 'id') {
    if (src.vmdl && ('accountId' in src.vmdl))
      return {
        raw: Number(src.vmdl.accountId),
        presentable: formatAccountId(Number(src.vmdl.accountId), useAccountStore().idMask),
      };
    }
    return undefined;
  }

  if (name === 'id') return {
    raw: account.idValue.raw,
    presentable: account.idValue.presentable,
  };

  if (name === 'external_id') {
     
    const a = (account as unknown as any); // todo: this is here until api3/externalId is released
    return {
      raw: a.externalId,
      presentable: a.externalId,
    };
  }

  const value = account[name as keyof Omit<V2AccountVM, 'profile' | 'idValue' | 'id'>];
  return {
    raw: value,
    presentable: String(value),
  };
}

 
function resolveValueMarketingPlan(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {

  const findByAlias = (list: PositionPropertyValueVM[] | PLanPropertyValueVM[]) => list.find((p) => p.alias === name);

  let found = src.m ? findByAlias(src.m) : undefined;
  if (found) return found.value;

  found = findByAlias(collapseTreePositionPropertiesList(src));
  if (found) return found.value;
}

 
function resolveValueAccountProfile(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  let list: PropertyValuesList | undefined;

  if (prefix === 'p')
    list = src.p || src.s?.profile || src.vmdl?.account.profile;
  else if (prefix === 'parent-profile') {
    if (src.vmdl && ('parentPosition' in src.vmdl))
      list = src.vmdl.parentPosition?.account.profile;
  } else
    throw new Error('resolveValueAccountProfile/wrong prefix');

  if (!list) return undefined;
  const found = list.find((p) => p.alias === name);
  if (found) return found.value;
  return undefined;
}

function resolveValuePosition(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {

  if (!(name === 'tree_level') || prefix !== 'position')
    throw new Error('resolveValuePosition/wrong prefix');

  if (!src.vmdl || !('treeLevel' in src.vmdl)) return undefined;

  return {
    raw: src.vmdl.treeLevel,
    presentable: String(src.vmdl.treeLevel),
  };
}

 
function resolveValueVMDownline(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  if (!src.vmdl || !(name in src.vmdl))
    return undefined;

  const value = src.vmdl[name as keyof DownlineLikeVM] as unknown as string | number | boolean | undefined | null;

  if (value === undefined || value === null) return undefined;

  return {
    raw: value,
    presentable: String(value),
  };
}

 
function resolveValueVMTransactionLog(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  if (!src.vmtl) return undefined;

  if (name === 'property')
    return stringValue(src.vmtl.property);

  if (name === 'docType')
    return stringValue(src.vmtl.document?.documentType);

  if (name === 'created')
    return src.vmtl.created;

  if (name === 'newValue')
    return src.vmtl.newValue;

  if (name === 'oldValue')
    return src.vmtl.oldValue;

  if (name === 'context') {
    const ctxObject = src.vmtl.context as Record<string, unknown> | undefined;
    if (ctxObject) {
      let presentable =ctxObject.note ? String(ctxObject.note) : JSON.stringify(ctxObject);
      if (presentable === '{}') presentable = '';
      return {
        raw: ctxObject,
        presentable,
      };
    }
    return undefined;
  }

  throw new Error('resolveValueVMTransactionLog/wrong name');
}

function resolveValueVMLeaderBoard(prefix: PropertyPrefix, name: string, src: PropertiesValuesSource) {
  if (!src.vmlb || !(name in src.vmlb))
    return undefined;

  const value = src.vmlb[name as keyof LeaderboardItemDTO] as unknown as string | number | boolean | undefined | null;

  if (value === undefined || value === null) return undefined;

  return {
    raw: value,
    presentable: String(value),
  };
}

function stringValue(value: string) {
  return {
    raw: value,
    presentable: value,
  };
}


function getPropertyValue(property: string, src: PropertiesValuesSource): TableGridItemProperty | undefined {
  const [type, name] = parsePropertyName(property);
  if (!type) throw new Error(`getPropertyValue/wrong property name '${property}'`);
  const [resolveValue] = RESOLVERS[type];
  const value = resolveValue(type, name, src);
  if (value) return value;
}


function getAllPropertyNames(src: PropertiesValuesSource) {
  const result: string[] = [];
  PROPERTY_PREFIXES.forEach((prefix) => {
    const [, resolveList] = RESOLVERS[prefix];
    const list = resolveList(prefix, src);
    result.push(...list);
  });
  return result;
}

export function replaceProperty(originalProp: string) {
  if (!originalProp.startsWith('r.')) return originalProp;
  const replacement = useAccountStore().replaceProperties[originalProp];
  return replacement || originalProp;
}

export function replaceProperties(originalProps: string[]) {
  return originalProps.map((p) => replaceProperty(p));
}
