<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">

import { ref, nextTick, watch  } from 'vue';
import { InputProps, InputEmits } from '../models/dform-input-core';
import { InputDListValue, InputDListData } from './InputDList';
import { DeepFormSchemaItem } from '../models/dform-schema';

import { useDeepForm } from '@/deep-form/composables/useDeepForm';
import DeepForm from '@/deep-form/components/DeepForm.vue';

const props = defineProps<InputProps<InputDListValue, InputDListData>>();
const emit = defineEmits<InputEmits<InputDListValue>>();

const usedForm = ref<ReturnType<typeof useDeepForm<InputDListValue>>>();
const inputSchema = props.dfData! as DeepFormSchemaItem<InputDListValue[keyof InputDListValue], any>;
const count = ref(0);

const dragPlacement = ref<'above' | 'center' | 'below'>('center');

watch( () => props.dfData, () => {
  reCreateForm();
});

watch( () => props.modelValue, () => {

  if (usedForm.value) {
    if (props.modelValue && Array.isArray(props.modelValue)) {
      if (props.modelValue.length === usedForm.value.props.inputsRenderList.length) return;
    }
    //return; // KOSTYL!
  }

  reCreateForm(props.modelValue);

}, { immediate: true });

function reCreateForm(value?: InputDListValue) {
  if (!props.dfData) return undefined;

  const useValue = value || props.modelValue;

  const items = Array.isArray(useValue) ? useValue : [];

  // console.log('re-create dlist form:', items);

  usedForm.value = useDeepForm<InputDListValue>(items, [], props.dfRootFormProps);
  items.forEach((item, index) => {
    usedForm.value?.props.methods.cloneInput(String(index), props.dfData as any, item);
  });

}

function addInput() {
  if (! inputSchema.initialValue) throw new Error('InputDList: initialValue is required');
  const index = props.modelValue.length;
  usedForm.value?.props.methods.cloneInput(String(index), inputSchema, inputSchema.initialValue());
  nextTick(() => {
    usedForm.value?.props.methods.validate();
  });
}

function removeInput(alias?: string) {
  if (! alias) throw new Error('InputDList: alias is required');
  usedForm.value?.props.methods.removeInput(alias);
  nextTick(() => {
    reCreateForm(usedForm.value?.props.modelValue);
    count.value++;
  });
}

function moveItem(srcIndex: string, dstIndex: string) {

  const si = Number(srcIndex);
  const di = Number(dstIndex);

  const len = usedForm.value?.props.inputsRenderList.length;

  const sw = (a1: string, a2: string) =>
    usedForm.value?.props.methods.switchValues(a1,a2);


  if (!len || ! sw || (di === si) ) return;

  // console.log('switch:', 'si=', si, 'di=', di, dragPlacement.value, 'len=', len);

  const above = dragPlacement.value === 'above' ? 1: 0;
  const below = dragPlacement.value === 'below' ? 1: 0;

  if (dragPlacement.value === 'center') {
    sw(srcIndex, dstIndex);
  } else {
    if (di < si) {
      for (let i=si; i > Math.max(di + below, 0) ; i--) sw(String(i), String(i-1));
    } else {
      for (let i=si; i < Math.min(di - above, len);  i++) sw(String(i), String(i+1));
    }
  }


  reCreateForm(usedForm.value?.props.modelValue);
  count.value++;
}

function emitValue(value: InputDListValue) {
  // console.log('input-dlist emitValue', value);
  emit('update:modelValue', value);
}

 
function onDragOver(event: any) {

  const y = event.offsetY;
  const t = event.target;

  if (y < 10) {
    dragPlacement.value = 'above';
    t.style.borderTop = '4px solid black';
    t.style.borderBottom = 'none';
  } else if (y < 20) {
    dragPlacement.value = 'center';
    t.style.borderBottom = '4px solid black';
    t.style.borderTop = '4px solid black';
  } else {
    dragPlacement.value = 'below';
    t.style.borderBottom = '4px solid black';
    t.style.borderTop = 'none';
  }

}

 
function onDragLeave(event: any) {
  const t = event.target;
  t.style.borderBottom = 'none';
  t.style.borderTop = 'none';
}


</script>

<template>
  <DeepForm
    v-if="usedForm?.props"
    :key="count"
    v-bind="{ ... props, ... usedForm.props }"
    formDebugName="dlist"
    @update:model-value="emitValue($event)"
    @form-valid-state="emit('formValidState', $event);"
  >
    <template
      v-for="(input, index) in usedForm.props.inputsRenderList"
      :key="index"
      #[`prepend-${input.schema.alias}`]
    >
      <v-icon
        :id="`dlist-drag-${input.schema.alias}`"
        size="32"
        class="mr-2"
        :draggable="true"
        @dragstart="$event.dataTransfer.setData('text/plain', index);"
        @dragover.prevent="onDragOver($event)"
        @dragleave="onDragLeave($event)"
        @drop="moveItem($event.dataTransfer.getData('text/plain'), input.schema.alias);"
      >
        mdi-menu
      </v-icon>
    </template>
    <template
      v-for="(input, index) in usedForm.props.inputsRenderList"
      :key="index"
      #[`append-${input.schema.alias}`]
    >
      <v-icon
        icon="mdi-delete-circle"
        color="error"
        size="32"
        class="ml-2"
        @click="removeInput(input.schema.alias)"
      />
    </template>
    <template #after>
      <v-btn
        v-if="inputSchema.initialValue"
        class="mt-2"
        :style="props.modelValue?.length > 0 ? 'margin-left: 40px' : ''"
        size="small"
        @click="addInput"
      >
        Add
      </v-btn>
    </template>
  </DeepForm>
</template>
