
export interface CarouselWidgetProps {
  id?: string,
  srcs: Array<string[]>,
  noCover?: boolean,
  autoplay?: number,
  mute?: number,
  hideDelimiters?: boolean,
  delimitersStyle?: number,
  interval?: number,
  showArrows?: boolean,
  videoWidth?: string,
  videoHeight?: string,
  class?: string,
  height?: string | number;
}

export const defaults = {
  autoplay: 1,
  mute: 1,
  hideDelimiters: false,
  delimitersStyle: 1,
  interval: 8000,
  showArrows: false,
  videoWidth: '100%',
  videoHeight: '100%',
  height: 500,
};
