 
import { ConfirmableFieldAlias } from '@/models/field-confirmation-interface';
import { defineStore } from 'pinia';

type Event<T = null> = {
  triggerValue: string;
  payload: T;
};

const EVENTS = {
  'payout_history': {} as Event,
  'wallet_history': {} as Event,
  'confirm_field_status': {} as Event<{ field: ConfirmableFieldAlias | undefined }>,
};

type Events = typeof EVENTS;

// type TriggerName = keyof typeof EVENTS;
// type Payload<K extends TriggerName> = typeof EVENTS[K]['payload'];

export const useEventBus = defineStore({
  id: 'event-bus',
  state: () => ({
    EVENTS,
  } as { EVENTS: Events }),
  actions: {
    update<K extends keyof Events>(key: K, payload?: Events[K]['payload']) {
      this.EVENTS[key].triggerValue = [ Date.now(), JSON.stringify(payload) ].join(':');
      this.EVENTS[key].payload = payload as Events[K]['payload'];
    },
  },
  getters: {
    payoutHistoryUpdated: (state) => state.EVENTS.payout_history.triggerValue,
    walletHistoryUpdated: (state) => state.EVENTS.wallet_history.triggerValue,
    confirmFieldStatusUpdated: (state) => state.EVENTS.confirm_field_status.triggerValue,
    confirmFieldPayload: (state) => state.EVENTS.confirm_field_status.payload,
  },
});
