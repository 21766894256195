<template>
  <div v-show="!hidden" class="inputByType" :data-input-alias="alias">
    <span v-if="title" class="text-caption">
      <slot name="title">
        {{ $t(title) }}
        <span v-if="isMandatory" class="text-error font-weight-bold">*</span>
      </slot>
    </span>

    <!-- <span>(a = {{ alias }}, FT = {{ fieldType }}, MV = {{ modelValue }})</span> -->

    <div v-if="['password', 'password_confirm'].includes(alias)">
      <v-text-field
        :id="alias"
        ref="inputRef"
        :modelValue="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :disabled="disabled"
        :rules="rules"
        :autocomplete="noAutocomplete ? 'off' : undefined"
        :required="true"
        :appendInnerIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :errorMessages="computedErrorMessages"
        @click:append-inner="showPassword = !showPassword"
        @update:model-value="onUpdatedValue($event)"
      />
    </div>
    <div v-else-if="alias === 'phone'">
      <VueTelInputVuetify
        :id="alias"
        ref="inputRef"
        :modelValue="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :disabled="disabled"
        :rules="rules"
        :autocomplete="noAutocomplete ? 'off' : undefined"
        :errorMessages="computedErrorMessages"
        :preferredCountries="preferredCountries"
        :onlyCountries="onlyCountries"
        @update:model-value="onInputPhone"
      />
    </div>
    <div v-else-if="alias === 'language_id'">
      <v-select
        :id="alias"
        ref="inputRef"
        :modelValue="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :items="languages"
        :disabled="disabled"
        clearable
        :errorMessages="computedErrorMessages"
        :rules="rules"
        :autocomplete="noAutocomplete ? 'off' : undefined"
        @update:model-value="onUpdatedValue($event)"
      />
    </div>
    <div v-else-if="alias?.endsWith('country_id') && countries.length">
      <v-select
        :id="alias"
        ref="inputRef"
        :modelValue="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :items="sortedCountries"
        :disabled="disabled"
        itemTitle="title"
        itemValue="id"
        clearable
        :errorMessages="computedErrorMessages"
        :rules="rules"
        :autocomplete="noAutocomplete ? 'off' : undefined"
        @update:model-value="onUpdatedValue($event)"
      >
        <template #item="{ props, item }">
          <v-list-item
            v-bind="props"
            :class="dividerUnderCountryId === item.value ? 'divider' : ''"
          />
        </template>
      </v-select>
    </div>
    <div v-else-if="alias?.endsWith('region_id')">
      <v-select
        :id="alias"
        ref="inputRef"
        :modelValue="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :items="regions"
        :disabled="disabled"
        itemTitle="title"
        itemValue="id"
        clearable
        :errorMessages="computedErrorMessages"
        :rules="rules"
        :autocomplete="noAutocomplete ? 'off' : undefined"
        @update:model-value="onUpdatedValue($event)"
      />
    </div>
    <div v-else-if="fieldType === 'dictionary' && dictionaryItems()">
      <v-select
        :id="alias"
        ref="inputRef"
        :menuProps="{
          attach: attachTo
        }"
        :modelValue="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :items="dictionaryItems()"
        :disabled="disabled"
        itemTitle="value"
        itemValue="key"
        :clearable="clearable"
        :errorMessages="computedErrorMessages"
        :rules="rules"
        :autocomplete="noAutocomplete ? 'off' : undefined"
        @update:model-value="onUpdatedValue($event)"
      />
    </div>
    <v-text-field
      v-else-if="fieldType === 'string'"
      :id="alias"
      ref="inputRef"
      :modelValue="modelValue"
      :loading="resultLoading"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :hideDetails="hideDetails"
      :flat="flat"
      :disabled="disabled"
      clearable
      :errorMessages="computedErrorMessages"
      :rules="rules"
      :autocomplete="noAutocomplete ? 'off' : undefined"
      @update:model-value="onUpdatedValue($event)"
    />
    <v-text-field
      v-else-if="fieldType === 'currency'"
      :id="alias"
      ref="inputRef"
      :modelValue="modelValue"
      :loading="resultLoading"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :hideDetails="hideDetails"
      :flat="flat"
      :disabled="disabled"
      clearable
      type="number"
      :errorMessages="computedErrorMessages"
      :rules="rules"
      :autocomplete="noAutocomplete ? 'off' : undefined"
      @update:model-value="onUpdatedValue($event)"
    />
    <v-text-field
      v-else-if="fieldType === 'points'"
      :id="alias"
      ref="inputRef"
      :modelValue="modelValue"
      :loading="resultLoading"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :hideDetails="hideDetails"
      :flat="flat"
      :disabled="disabled"
      clearable
      type="number"
      :errorMessages="computedErrorMessages"
      :rules="rules"
      :autocomplete="noAutocomplete ? 'off' : undefined"
      @update:model-value="onUpdatedValue($event)"
    />
    <v-text-field
      v-else-if="['numeric', 'int', 'float'].includes(fieldType)"
      :id="alias"
      ref="inputRef"
      :modelValue="modelValue"
      :loading="resultLoading"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :hideDetails="hideDetails"
      :flat="flat"
      :disabled="disabled"
      clearable
      :errorMessages="computedErrorMessages"
      :rules="rules"
      :autocomplete="noAutocomplete ? 'off' : undefined"
      @keypress="validateNumber"
      @update:model-value="onUpdatedValue($event)"
    />
    <v-switch
      v-else-if="fieldType === 'bool'"
      :id="alias"
      ref="inputRef"
      :modelValue="modelValue"
      :loading="resultLoading"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :hideDetails="hideDetails"
      :flat="flat"
      :disabled="disabled"
      clearable
      :errorMessages="computedErrorMessages"
      :rules="rules"
      :autocomplete="noAutocomplete ? 'off' : undefined"
      @update:model-value="onUpdatedValue($event)"
    />
    <v-textarea
      v-else-if="['txt', 'html'].includes(fieldType)"
      :id="alias"
      ref="inputRef"
      :modelValue="modelValue"
      :loading="resultLoading"
      :density="density"
      :variant="variant"
      :rounded="rounded"
      :hideDetails="hideDetails"
      :flat="flat"
      :errorMessages="computedErrorMessages"
      :disabled="disabled"
      :rules="rules"
      :autocomplete="noAutocomplete ? 'off' : undefined"
      @update:model-value="onUpdatedValue($event)"
    />
    <v-menu
      v-else-if="fieldType === 'date'"
      v-model="menuDate"
      :closeOnContentClick="false"
      :disabled="disabled"
      transition="scale-transition"
      minWidth="auto"
    >
      <template #activator="{ props }">
        <!-- mv = {{ modelValue }}, fmf = {{ formattedDateValue }} -->
        <v-text-field
          v-bind="props"
          :modelValue="formattedDateValue"
          :loading="resultLoading"
          :density="density"
          :variant="variant"
          :rounded="rounded"
          :hideDetails="hideDetails"
          :flat="flat"
          :disabled="disabled"
          prependInnerIcon="mdi-calendar"
          readonly
          clearable
          :errorMessages="computedErrorMessages"
          :rules="rules"
          :autocomplete="noAutocomplete ? 'off' : undefined"
          @click:clear="$emit('update:modelValue', null);"
        />
      </template>
      <!--
        -->
      <v-date-picker
        :id="alias"
        ref="inputRef"
        :vModel="modelValue"
        :loading="resultLoading"
        :density="density"
        :variant="variant"
        :rounded="rounded"
        :hideDetails="hideDetails"
        :flat="flat"
        :disabled="disabled"
        :title="$t('inputs.date_select')"
        :header="$t('inputs.date_select')"
        :inputText="$t('inputs.date_enter')"
        :okText="$t('dialogs.ok')"
        :cancelText="$t('dialogs.cancel')"
        :min="1900"
        @update:model-value="
          onUpdatedValue($event);
          menuDate = false;
        "
        @click:cancel="menuDate = false"
      />
    </v-menu>
  </div>
</template>

<script>

import { useCountryApi } from '@ui-api3-sdk/api/api3';
import { useAccountStore } from '@/pinia/useAccountStore';
import { usePropertiesStore } from '@/pinia/usePropertiesStore';
import { parsePropertyName } from '@/utils/properties-mapping-service';
import { emailValidate } from '@/utils/utils';

import { FIELD_TYPES, LANGUAGE_DICTIONARY } from '@/models/form-builder-interface';

import VueTelInputVuetify from './VueTelInputVuetify';

export default {
  name: 'InputByType',
  components: {
    VueTelInputVuetify,
  },

  props: {

    modelValue: {
      type: [String, Number, Object, Boolean],
      default: '',
    },

    alias: {
      type: String,
      required: true,
    },

    fieldType: {
      type: String,
      default: 'string',
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return FIELD_TYPES.indexOf(value) !== -1;
      },
    },

    customValidateFn: {
      type: Function,
      default: undefined,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: [Array, String],
      default: () => [],
    },

    attachTo: {
      type: HTMLElement,
      default: undefined,
    },

    // *** styles:
    density: {
      type: String,
      default: 'compact',
    },

    variant: {
      type: String,
      default: 'filled',
    },

    rounded: {
      type: [Boolean, Number],
      default: false,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    noAutocomplete: {
      type: Boolean,
      default: false,
    },

    // *** other input dependencies:

    passwordMinLength: {
      type: Number,
      default: 6,
    },

    passwordHasSpecSymbols: {
      type: Boolean,
      default: false,
    },

    passwordHasCamelcase: {
      type: Boolean,
      default: false,
    },

    preferredCountries: {
      type: Array,
      default: undefined,
    },

    onlyCountries: {
      type: Array,
      default: undefined,
    },

    countryId: {
      type: [String, Number],
      default: null,
    },

    password: {
      type: [String],
      default: null,
    },

    hidden: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

  },

  emits: {
    'update:modelValue': [String, Number, Object],
  },

  data: function () {
    return {
      languages: LANGUAGE_DICTIONARY,
      countries: [],
      regions: [],
      menuDate: false,
      showPassword: false,
      internalLoading: false,
      phone: {
        number: undefined,
        valid: false,
        country: undefined,
      },
    };
  },

  computed: {

    resultLoading() {
      return this.internalLoading || this.loading;
    },

    resPreferredCountries() {
      return this.preferredCountries ?? useAccountStore().prefferedCountries;
    },

    dividerUnderCountryId() {
      if (! this.resPreferredCountries?.length)
        return null;

      const lastPreferredCountry = this.resPreferredCountries.at(-1).toUpperCase();
      const preferredIndex = this.sortedCountries.findIndex(country => country.id === lastPreferredCountry);
      if (preferredIndex === this.sortedCountries.length - 1)
        return null;

      return lastPreferredCountry;
    },

    resOnlyCountries() {
      return this.onlyCountries ?? useAccountStore().onlyCountries;
    },

    sortedCountries() { // preferedCountries go first

      if (! this.countries) return [];

      const ucPreffered = this.resPreferredCountries.map(country => country.toUpperCase());
      const ucOnly = this.resOnlyCountries.map(country => country.toUpperCase());
      const ucPrefferedAndOnly = Array.from(new Set([... ucPreffered, ... ucOnly]));

      const prefferedAndOnly = ucPrefferedAndOnly.map(id => this.countries.find(country => country.id === id))
        .filter(c => c);

      const filteredOther = this.resOnlyCountries.length
        ? [] :
          this.countries.filter(country => ! ucPrefferedAndOnly.includes(country.id));

      return [ ... prefferedAndOnly, ... filteredOther ];
    },

    formattedDateValue: {
      get() {
        const formatted = this.modelValue
          ? new Date(this.modelValue).toLocaleDateString(this.$i18n.locale)
          : '';
        return formatted;
      },

      set(value) {
        console.log('formattedDateValue set', value);
        this.$emit('update:modelValue', value);
      },
    },

    computedErrorMessages() {
      const errorMessages = this.errorMessages ? [...this.errorMessages] : [];

      if (this.alias === 'phone') {
        if (this.phone.number !== undefined && !this.phone.valid)
          errorMessages.push('Phone number is not valid');
      }

      return errorMessages;
    },

    rules() {
      const rules = [];

      if (this.isMandatory) {
        rules.push(v => (v !== undefined && v !== '' && v !== null) || this.$t('inputs.err.field_required'));
      }

      if (['numeric', 'int', 'float'].includes(this.fieldType))
        rules.push(v => /^\d+$/.test(v) || !v || this.$t('inputs.err.number_invalid'));

      if (this.alias === 'email') {
        rules.push(v => emailValidate(v) || !v || this.$t('inputs.err.email_invalid'));
      }

      if (this.alias === 'password_confirm')
        rules.push(v => v === this.password || this.$t('inputs.err.passwords_not_match'));

      if (this.alias === 'password')
        rules.push(this.passwordRules);

      // if alias is birth_date, then don't allow date in the future

      if (this.alias === 'birth_date')
        rules.push(v => !v || new Date(this.modelValue) < new Date() || this.$t('inputs.err.date_in_future'));

      if (this.customValidateFn)
        rules.push(this.customValidateFn);

      return rules;
    },
  },

  watch: {
    async countryId() {
      if (this.alias.endsWith('region_id')) {
        this.internalLoading = true;
        await this.fetchRegions();
        if (! this.regions.some(region => region.id === this.modelValue)) {
          this.$emit('update:modelValue', null);
        }
        this.internalLoading = false;
      }
    },

    password() {
      if (this.alias === 'password_confirm') {
        // console.log('password_confirm watch', this.$refs.inputRef.error);
        this.$refs.inputRef.validate();
      }
    },
  },

  created() {
    if (this.alias?.endsWith('country_id'))
      useCountryApi()
        .getCountries({ limit: 500, page: 0 })
        .then(res => (this.countries = res.data.payload.list));

    this.fetchRegions();

  },

  methods: {

    passwordRules(v) {

      if (v.length < this.passwordMinLength)
        return (this.$t('inputs.err.password_min_length', [this.passwordMinLength]));

      if (this.passwordHasSpecSymbols && !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v))
        return (this.$t('inputs.err.password_need_spec_chars'));

      if (this.passwordHasCamelcase && !/[A-Z]/.test(v))
        return (this.$t('inputs.err.password_need_capital'));

      return true;
    },

    onUpdatedValue($event) {
      this.$emit('update:modelValue', $event);
    },

    fetchRegions() {
      if (this.alias.endsWith('region_id')) {
        if (this.countryId) {
          return useCountryApi()
            .getRegions({ countryId: this.countryId, limit: 500, page: 0 })
            .then(res => (this.regions = res.data.payload.list));
        }
        this.regions = [];
        this.$emit('update:modelValue', null);
      }
    },

    validateNumber(evt) {
      // TODO: instead of this use v-mask
      const theEvent = evt || window.event;
      let key = theEvent.keyCode || theEvent.which;

      key = String.fromCharCode(key);
      if (!/[0-9]/.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },

    dictionaryItems() {
      const [type, name] = parsePropertyName(this.alias);
      const propDef = usePropertiesStore().findPropertyDefinition(type, name);
      return propDef?.dictionary?.dictionaryItems || [];
    },

    onInputPhone(formattedNumber, { number, valid, country }) {
      this.phone.number = number.international;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
      // console.log('phoneInput', this.phone, ' valid=', this.phone.valid);
      this.$emit('update:modelValue', number.international);
    },
  },
};
</script>

<style lang="scss">
.divider {
  border-bottom: 1px solid rgba(var(--v-theme-on-background), 0.12);
}

.inputByType {

  .v-text-field {
    padding-top: 0;
  }
}
</style>
