 
import { reactive, watch } from "vue";

import { StepTriggerRequestEvent, type Step, type StepDefinition } from "@/models/dynamic-stepper-interface";
import { apiErrorToString } from '@ui-api3-sdk/api/api3';

 
function parseStepDefinition(stepDef: StepDefinition, index: number, bindComponentProps: Record<string, any>) {

  const componentProps = { ...bindComponentProps, step: index + 1 };

   
  const item: Record<string, any> = reactive({
    name: stepDef.name,
    title: stepDef.title || stepDef.name,
    type: stepDef.type,
    nextDisabled: stepDef.nextDisabled || false,
    isMandatory: stepDef.isMandatory ?? true,
    goNextGuard: stepDef.goNextGuard,
  });

  switch (stepDef.type) {
    case 'FormBuilder':
      item.form = stepDef.form;
      item.componentProps = reactive({
        ...componentProps,
        ...stepDef.componentProps,
        ...stepDef.form.props,
      });
      watch(stepDef.form.props, (newValue) => {
        item.componentProps = { ...item.componentProps, ...newValue };
      });
      break;
    case 'FieldConfirmation':
      item.alias = stepDef.alias;
      item.value = stepDef.valueRef.value;
      item.componentProps = reactive({
        ...componentProps,
        ...stepDef.componentProps,
      });
      watch(stepDef.valueRef, (newValue) => item.value = newValue);
      break;
    case 'CustomComponent':
      item.componentProps = reactive({
        ...componentProps,
        ...stepDef.componentProps,
      });
      break;
    default:
      throw new Error(`Unknown step type ${(stepDef as any).type}`);
  }

  // console.log('parsedStepDefinition', stepDef, ' => ', item);
  return item as Step;
}


export const useDynamicStepper = (
  stepNames: StepDefinition[],
  onFinalSubmit?: () => Promise<string | undefined>,
  onFirstBack?: () => Promise<string | undefined>,
) => {

  const bindComponentProps = {
    step: undefined as number | undefined,
    loading: false,
    stepRequest: { event: undefined } as StepTriggerRequestEvent,
    onLoading,
    onStepResponse,
    onStepNextDisabled,
  };

  const stepperProps = reactive({
    step: 1,
    steps: stepNames.map((s, index) => parseStepDefinition(s, index, bindComponentProps)),
    loading: false,
    hideNavigation: false,
    errorMessage: undefined as string | undefined,
    finalSubmitDone: false,
    onNextRequest,
    onPrevRequest,
  });

  function onStepNextDisabled(step: number, disabled: boolean) {
    // console.log('onNextDisabled', step, disabled);
    const foundStep = stepperProps.steps[step - 1];
    foundStep.nextDisabled = foundStep.isMandatory ? disabled : false;
  }

  async function onStepResponse(event: any) {
    // console.log('onStepResponse', event);

    if (!event.allow) {
      stepperProps.loading = false;
      const currentStep = getCurrentStep();
      if (currentStep.isMandatory) return;
    }

    if (event.event === 'go-next') goNext(event.payload);
    if (event.event === 'go-prev') goPrev();
  }


  async function goNext(payload?: any) {
    let currentStep = getCurrentStep();

    const goNextGuard = currentStep.goNextGuard;
    if (goNextGuard) {

      const result = await goNextGuard(payload, currentStep)
        .catch((e) => {
          stepperProps.errorMessage = apiErrorToString(e);
        });

      stepperProps.loading = false;
      if (!result) return;
    }

    goToStep(stepperProps.step + 1);
    currentStep = getCurrentStep();
    currentStep.componentProps.stepRequest = { event: 'on-enter' };
    return;
  }

  function goPrev() {
    let currentStep = getCurrentStep();

    goToStep(stepperProps.step - 1);

    currentStep = getCurrentStep();
    currentStep.componentProps.stepRequest = { event: 'on-return' };

    return;

  }

  async function onNextRequest() {
    stepperProps.loading = true;

    const currentStep = getCurrentStep();
    if (!currentStep) throw new Error('onNext step: no more steps');

    if (!currentStep.isMandatory) {
      goNext();
      return;
    }

    currentStep.componentProps.stepRequest = { event: 'go-next' };

  }

  function onPrevRequest() {
    stepperProps.loading = true;
    goPrev();
  }

  function onLoading(loading: boolean) {
    stepperProps.loading = loading;
    stepperProps.steps.forEach(step => step.componentProps.loading = loading);
  }

  function validIndex(index: number) {
    return (index >= 0 && index <= stepperProps.steps.length - 1);
  }

  function getCurrentStep() {
    const index = stepperProps.step - 1;
    if (!validIndex(index)) throw new Error('getCurrentStep: invalid index');
    return stepperProps.steps[index];
  }

  function addStep(stepDef: StepDefinition) {
    stepperProps.steps.push(parseStepDefinition(stepDef, stepperProps.steps.length, bindComponentProps));
  }

  function findFormField(alias: string) {

    for (const step of stepperProps.steps) {
      if (step.type !== 'FormBuilder') continue;
      const field = step.form.methods.hasField(alias);
      if (field) return field;
    }

    return null;
  }

  function getAllFormsValues() {
    const resMap = new Map<string, any>();

    for (const step of stepperProps.steps) {
      if (step.type !== 'FormBuilder') continue;
      const fields = step.form.props.modelValue;
      fields.forEach( (field) => {
        resMap.set(field.alias, field.value);
      });
    }

    return Object.fromEntries(resMap);
  }

  function getStep(name: string) {
    const step = stepperProps.steps.find((s) => s.name === name);
    if (!step) throw new Error(`Step ${name} not found`);
    return step;
  }

  async function goToStep(step: number) {
    stepperProps.errorMessage = undefined;

    if (step < 1) {
      step = 1;
      if (onFirstBack) {
        stepperProps.loading = true;
        const errorString = await onFirstBack();
        if (errorString) {
          stepperProps.errorMessage = errorString;
        } else {
          stepperProps.hideNavigation = true;
        }
      }
    }

    if (step > stepperProps.steps.length) {
      step = stepperProps.steps.length;
      if (onFinalSubmit) {
          stepperProps.loading = true;
        try {
          const errorString = await onFinalSubmit();
          if (errorString) {
            stepperProps.errorMessage = errorString;
          } else {
            stepperProps.hideNavigation = true;
            stepperProps.finalSubmitDone = true;
          }
        } catch (e) {
          stepperProps.errorMessage = apiErrorToString(e);
        }
      }
    }

    stepperProps.step = step;
    stepperProps.loading = false;
  }

  function setError(msg: string) {
    stepperProps.errorMessage = msg;
  }

  const stepperMethods = {
    getStep,
    addStep,
    setError,
    findFormField,
    getAllFormsValues,
  };

  return { stepperProps, stepperMethods } as const;

};
