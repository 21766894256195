<template>
  <TableGrid
    v-bind="tableProps"
    :autoSwitchMode="false"
    noPagination
  >
    <template #expansion="slotParams">
      <v-expansion-panel>
        <v-expansion-panel-title>
          Title
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pt-4">
          <!-- <div><b>prop:</b>value</div> -->
          {{ slotParams.item.rowColor?.badges }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </template>

    <template #position="slotParams">
      <slot name="position" v-bind="slotParams">
        <div class="d-flex">
          <div class="text-h5">
            {{ slotParams.p('vmlb.position') }}.
          </div>
          <ValueChangeBadge
            v-if="showPositionChange && smAndUp"
            class="ml-2 move-up"
            :oldv="Number(slotParams.r('vmlb.positionPrevious'))"
            :newv="Number(slotParams.r('vmlb.position'))"
            lowIsGood
          />
        </div>
      </slot>
    </template>

    <template #accountavatar="slotParams">
      <slot name="accountAvatar" v-bind="slotParams">
        <MemberAvatar
          class="mt-4 mb-4"
          :size="Number(slotParams.r('vmlb.position')) <= 3 ? Math.floor(avatarSize * 1.5) : avatarSize"
          :src="slotParams.r('vmlb.accountAvatar') ? String(slotParams.r('vmlb.accountAvatar')) : undefined"
          :title="slotParams.p('vmlb.accountTitle')"

          :badges="slotParams.item?.rowColor?.badges"
          :item="slotParams.item"
        />
      </slot>
    </template>

    <template #accounttitle="slotParams">
      <slot name="accountTitle" v-bind="slotParams">
        <div><strong>{{ slotParams.p('vmlb.accountTitle') }}</strong></div>
        <div>
          {{ maskedId(String(slotParams.r('vmlb.accountId'))) }}
        </div>
      </slot>
    </template>

    <template #value="slotParams">
      <slot name="value" v-bind="slotParams">
        {{ slotParams.p('vmlb.value') || '-' }}
      </slot>
    </template>
  </TableGrid>
</template>

<script setup lang="ts">
import { LeaderBoardProps, defaults } from './LeaderBoard.ts';

import { useAwaitAccountId, useLocalization, useTableGrid } from '@/composables';

import { useLeaderboardApi } from '@ui-api3-sdk/api/api3';
import { createTableGridItem } from "@/utils/properties-mapping-service";

import { TableGridItem } from '@/models/table-grid-interface';
import { useAccountStore } from '@/pinia/useAccountStore';
import { ValueConstants, getColorScheme } from '@/utils/conditional-colors';
import { formatAccountId } from '@/utils/utils';
import { useDisplay } from 'vuetify';

import MemberAvatar from './base/MemberAvatar.vue';
import TableGrid from './base/TableGrid.vue';
import ValueChangeBadge from './base/ValueChangeBadge.vue';


const props = withDefaults(defineProps<LeaderBoardProps>(),{
  id: undefined,
  itemTitles: undefined,
  colors: undefined,
  items: () => defaults.items,
  avatarSize: () => defaults.avatarSize,
  showPositionChange: () => defaults.showPositionChange,
});

const allColumns = [
  'position',
  'accountAvatar',
  'accountTitle',
  'accountId',
  'value',
];

const { smAndUp } = useDisplay();

const { la, reactiveList } = useLocalization('uiLeaderBoard');

const headerTitles = reactiveList('p', allColumns, props.itemTitles);

const { tableProps, tableMethods: { setLoading } } = useTableGrid({
  properties: props.items,
  headerTitles,
  fetch,
  maximumLimit: 500,
  unknownErrorMessage: la('unknown_error'),
});


async function fetch() {
  setLoading(true);

  const result = await useLeaderboardApi().getLeaderboard({
    leaderboardId: props.boardId,
  });

  const items = result.data.payload.map( (e) => createTableGridItem([], { vmlb: e }) );

  const currentAccountId = await useAwaitAccountId();
  const templateVars: ValueConstants  = { '$myAccount': currentAccountId };

  const tableItems = items.map( (i: TableGridItem) =>
    ({
      ... i,
      rowColor: getColorScheme(i, props.colors, templateVars),
    } as TableGridItem),
  );

  setLoading(false);
  return { tableItems, totalItems: tableItems.length };
}

function maskedId(id: string | number) {
  return formatAccountId(Number(id), useAccountStore().idMask);
}

</script>

<style lang="scss" scoped>
.move-up {
  transform: translateY(-10px);
  opacity: 0.5;
}
</style>
