import { gravComponentSchema } from '../deep-form-grav';
import { GenealogyTableProps, defaults } from '@/components/ui/GenealogyTable';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<GenealogyTableProps>({
  title: 'Genealogy Table',
  link: 'https://www.notion.so/mlmsoft/GenealogyTable-2a43b216cecc4437a2cea7e2e5be5115',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('properties').as(l.clone('mandatoryProperties')).col(6);
s.add('popupProperties').as(l.clone('popupProperties')).col(6);

s.add('treeId').as(l.clone('selectTree')).col(4);
s.add('popupInfoOnField').as(l.clone('popupInfoOnField')).col(4);
s.add('popupLinkUrl').as(l.clone('popupLinkUrl')).col(4).props({ clearable: true });

s.add('autoSwitchMode').as(l.clone('autoSwitchMode')).col(4);
s.add('maxNodesPerRequest').as(bl.clone('number')).col(4).title('Max nodes per request. Max 500, Min: 10');

s.add('buttonOnField').as(l.clone('popupInfoOnField')).
  title('Open button on column').col(4);

s.add('headerTitles').as(l.clone('headerTitles')).col(12);

function text() {
  return `
<b>Trees / Pages</b>
If there’s more than one tree in your compensation plan structure, you may use all the tree-related components and pages for any of those trees. For example, you can use this Genealogy component to show your Binary tree, and you can use the Visual Tree component to show your genealogy tree.

<b>Page Title and Description</b>
Please provide your custom title for the page. You may also provide a custom description which appears a line below the title. A menu icon can also be modified, please specify if you want to use a different icon.

<b>Data Fields</b>
Please specify what profile fields and/or compensation plan properties (metrics) you want to be shown.

<b>Detailed Popup Data Fields</b>
A click on a certain account opens a popup window which allows it to show more data fields. Please provide a list of fields to be shown (both: profile fields and metrics).
`;
}
