import { DFFormValue } from "@/deep-form/composables/useDeepForm";
import { ContentModifier } from "../models/content-modifier";

function handler({ fileContents }: { fileContents: string }) {
  return Promise.resolve(fileContents);
}

export default {
  id: 'copy',
  title: 'Copy',
  handler,
  form: undefined,
} as ContentModifier<DFFormValue>;
