import { gravComponentSchema } from '../deep-form-grav';
import { MetricsProps, defaults } from '../../../../components/ui/Metrics';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<MetricsProps>({
  title: 'Metrics',
  link: 'https://www.notion.so/mlmsoft/Metrics-fe757284bf414d9a84700a31102e57c4',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('title').input('text').props({ clearable: true })
  .title('Header Title')
  .flag('isText')
  .col(9);

s.add('allowPeriodSelect').input('boolean')
  .title('Allow Period Select')
  .col(3);

s.add('positionType').input('dictionary').data(['vertical', 'horizontal']).mandatory()
  .title('Layout')
  .col(3);

s.add('treeId').as(l.clone('selectTree'))
.title('Only from tree')
.mandatory(false)
.props({ clearable: true })
.col(3);

s.add('headerClass').input('mdiColor').title('Background class').col(3);

s.add('numberPeriodsBack').as(bl.clone('number')).props({ clearable: true })
  .title('Number of Periods Back')
  .col(3);

s.add('accountProperties').as(l.clone('mandatoryProperties')).col(12);

function text() {
  return `
<b>UI Component: Metrics Box</b>
This component can show a number of your compensation plan metrics in a box. There could be a number of similar components shown on the dashboard. Please specify if you’d like to use this component, what would be a box title, box header color, and list of compensation plan properties (metrics) to be shown.
`;
}
