import { defineStore } from 'pinia';
import { useVuetify } from '@/composables/useVuetify';

import mlmsoftPrefix from '@/assets/images/mlmsoft_prefix.svg';
import mlmsoftBig from '@/assets/images/mlmsoft_big.svg';


type DisplayModeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const displayModes: Array<DisplayModeName> = ['xs', 'sm', 'md', 'lg', 'xl'];

export const useThemeStore = defineStore('theme', {
  state: () => ({

    dark: false,

    barDark: false,
    headerDark: true,
    footerDark: false,

    forcedBarColor: null as string | null,
    forcedHeaderColor: null as string | null,
    forcedFooterColor: null as string | null,

    barImage: null as string | null,

    headerLogoSmall: `data:image/svg+xml,${encodeURIComponent(mlmsoftPrefix)}`,
    headerLogoBig: `data:image/svg+xml,${encodeURIComponent(mlmsoftBig)}`,

    drawer: false,
    drawerModes: {} as Record<DisplayModeName, boolean>,
  }),
  persist: {
    storage: sessionStorage,
    pick: ['drawerModes'],
  },
  getters: {
    isHeaderColorDefined(state) {
      return !!state.forcedHeaderColor;
    },
    isBarColorDefined(state) {
      return !!state.forcedBarColor;
    },
    isFooterColorDefined(state) {
      return !!state.forcedFooterColor;
    },
    headerColor(state) {
      return state.forcedHeaderColor || 'linear-gradient(154deg, #008fe2 0, #00b29c 100%)';
    },
    barColor(state) {
      return state.forcedBarColor || '#FFFFFF';
    },
    footerColor(state) {
      return state.forcedFooterColor || 'linear-gradient(154deg, #008fe2 0, #00b29c 100%)';
    },
    theme(state) {
      return state.dark ? 'dark' : 'light';
    },
    headerTheme(state) {
      return state.headerDark ? 'dark' : 'light';
    },
    barTheme(state) {
      return state.barDark ? 'dark' : 'light';
    },
    footerTheme(state) {
      return state.footerDark ? 'dark' : 'light';
    },
  },
  actions: {
    storeDrawerMode(name: DisplayModeName, value: boolean) {

      if (value === false) {
        for (let i = 0; i <= displayModes.indexOf(name); i++) {
          this.drawerModes[displayModes[i]] = false;
        }
      } else if (value === true) {
        for (let i = displayModes.length - 1; i >= displayModes.indexOf(name); i--) {
          this.drawerModes[displayModes[i]] = true;
        }
      }

    },
    setBarImage(this: any, payload: string | null) {
      this.barImage = payload;
    },
    setDrawer(this: any, payload: boolean) {
      this.drawer = payload;
    },
    setBarColor(this: any, payload: string | null) {
      this.forcedBarColor = payload;
    },
    setBarDark(this: any, payload: boolean) {
      this.barDark = payload;
    },
    setHeaderColor(this: any, payload: string | null) {
      this.forcedHeaderColor = payload;
    },
    setHeaderDark(this: any, payload: boolean) {
      this.headerDark = payload;
    },
    setFooterColor(this: any, payload: string | null) {
      this.forcedFooterColor = payload;
    },
    setFooterDark(this: any, payload: boolean) {
      this.footerDark = payload;
    },
    setHeaderLogoBig(this: any, payload: string) {
      this.headerLogoBig = payload;
    },
    setHeaderLogoSmall(this: any, payload: string) {
      this.headerLogoSmall = payload;
    },
    setDark(this: any, payload: boolean) {
      this.dark = payload;
    },

    saveTheme(this: any, vuetifyInstance?: any) {

      const vuetify = vuetifyInstance || useVuetify();
      const vThemes = vuetify?.theme.themes;

      if (!vThemes) {
        console.log('Could not store theme, vuetify not initialized');
        return null;
      }

      const res = {
        theme: {
          dark: this.dark,
          themes: { dark: vThemes.dark.colors, light: vThemes.light.colors },
        },
        sidebarDark: this.barDark,
        headerDark: this.headerDark,
        footerDark: this.footerDark,
        sidebarColor: this.forcedBarColor,
        headerColor: this.forcedHeaderColor,
        footerColor: this.forcedFooterColor,
        image: this.barImage,
      };

      return res;

    },

    applyTheme(this: any, payload: any, vuetifyInstance?: any) {

      const t = payload;

      const defined = (v: unknown) => v !== undefined && v !== null;

      if (defined(t.theme?.dark)) this.setDark(t.theme?.dark);
      if (defined(t.sidebarDark)) this.setBarDark(t.sidebarDark);
      if (defined(t.headerDark)) this.setHeaderDark(t.headerDark);
      if (defined(t.footerDark)) this.setFooterDark(t.footerDark);

      if (t.sidebarColor) this.setBarColor(t.sidebarColor);
      if (t.headerColor) this.setHeaderColor(t.headerColor);
      if (t.footerColor) this.setFooterColor(t.footerColor);
      if (t.image) this.setBarImage(t.image);

      const vuetify = vuetifyInstance || useVuetify();

      const themes = payload?.theme?.themes;
      const vThemes = vuetify?.theme.themes;

      if (themes && vThemes) {
        vThemes.dark.colors = {  ... vThemes.dark.colors, ... themes.dark };
        vThemes.light.colors = {  ... vThemes.light.colors, ... themes.light };
      }

    },
  },
});
