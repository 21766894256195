import { gravComponentSchema } from '../deep-form-grav';
import { UserLoginProps, defaults } from '../../../../components/ui/UserLogin';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<UserLoginProps>({
  title: 'User Login',
  link: 'https://www.notion.so/mlmsoft/UserLogin-84fb8b351d5547679d56ce5ef188781e',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('passwordConfirmBy').input('dictionary').data([
  { title: 'Email', value: 'email' },
  { title: 'Phone', value: 'phone' },
])
  .title('Password confirm by').mandatory().item();

function text() {
  return `The User Login component is used to create a form for user login.`;
}
