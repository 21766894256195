<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="! allPages || allPages.length === 0">
    <GravEditComponent :component="component" />
  </div>
  <div v-else>
    <GravComponentInfo v-if="componentTypeFile" :info="componentTypeFile.info" />
    <v-tabs v-model="currentPage">
      <v-tab v-for="p in allPages" :key="p.name" :value="p.name">{{ p.name }}</v-tab>
      <v-tab value="rawEdit">RAW EDIT</v-tab>
    </v-tabs>
    <div>
      <v-window v-model="tabPage">
        <v-window-item v-for="p in allPages" :key="`w-${updCount}-${p.name}`" :value="p.name">
          <DeepForm v-if="p.form" v-bind="p.form.form.props" frontPage :clearable="true" />
          <div class="mt-2 mb-4 d-flex justify-space-around">
            <v-btn
              :disabled="!p.form.form.valid"
              :loading="loading || useGravManager().loading"
              :data-loading="loading || useGravManager().loading ? 'true' : undefined"
              color="primary"
              @click="submitPage(p.name)"
            >
              Save
            </v-btn>
          </div>
          <!-- <v-divider />
          <div :class="p.form.valid ? 'bg-success' : 'bg-error'">
            {{ p.form.valid }}
          </div> -->
          <!-- <div>
            formModelValue = {{ p.form.form.props.modelValue }}
          </div> -->
          <!-- <div class="mt-2">
            propTypes = {{ p.propTypes }}
          </div>
          <div class="mt-2">
            defaultState = {{ p.defaultState }}
          </div> -->
        </v-window-item>
        <v-window-item value="rawEdit">
          <GravEditComponent :component="component" />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script setup lang="ts">
import { writeProps } from '@/composables/useChangableProps';
import { usePropertiesStore } from '@/pinia/usePropertiesStore';
import { getLocaleRef, stringifyAny } from '@/utils/utils';
import { computed, markRaw, onMounted, ref, watch } from 'vue';
import { GravComponentDTO, GravComponentPropertyDTO } from '../../grav-api-models';
import { useGravManager } from '../comosables/useGravManager';
import { ComponentFormFile, GravInputFlags } from '../dform/deep-form-grav';

import DeepForm from '@/deep-form/components/DeepForm.vue';
import GravComponentInfo from './GravComponentInfo.vue';
import GravEditComponent from './GravEditComponent.vue';

const currentPage = ref(getLocaleRef().value);
const tabPage = computed({
  get: () => currentPage.value,
  set: () => undefined,
});

const loading = ref(true);

const allPages = ref<ReturnType<typeof reloadPages>>();
const updCount = ref(0);
const props = defineProps<{ component: GravComponentDTO; }>();
const formInputs = ref<Array<{ name: string, flags: GravInputFlags }>>();

const transformPropNamesNeeded = computed(() =>
  props.component?.name.startsWith('ui-'),
);


let componentTypeFile: ComponentFormFile<any, any> | undefined = undefined;


function submitPage(lang: string) {
  const page = allPages.value?.find((p) => p.name === lang);
  if (!page) {
    return;
  }
  const value = page.form.getValue() as Record<string, unknown>;

  // console.log('model_changed', modelHasChanged(lang, value));

  const values =
    Object.entries(value).map(([key, val]) => {
      const inputFlags = formInputs.value?.find((i) => i.name === key)?.flags;
      const propertyTypesFromBackend = page.propTypes[key];
      return {
        ... propertyTypesFromBackend,
        ... inputFlags,
        name: toKebapCase(key),
        value: val === undefined ? null : stringifyAny(val),
      };
    },
    );

  useGravManager().upcliUpdateBulkProperties({
    componentId: props.component.id,
    languages: {
      [lang]:  values,
    },
  },
  );

  const valuesWithoutSlots = Object.fromEntries(
    Object.entries(value).filter(([key]) =>
      !key.startsWith('#') && key !== 'class'),
  );

  writeProps(props.component.id, markRaw(valuesWithoutSlots));

}


function toKebapCase(str: string): string {
  if (!transformPropNamesNeeded.value) return str;
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function toCamelCase(str: string): string {
  if (!transformPropNamesNeeded.value) return str;
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

function evaluatedValue(p: GravComponentPropertyDTO, overrideValue?: string | null) {
  const value = overrideValue === undefined ? p.value : overrideValue;

  if (p.isDynamic) {
    // todo check for safe eval. Only numbers, strings, booleans, objects and arrays are allowed.
    if (value !== null) {
      const safeRegex =
        /^(true|false|undefined|null|[\d]+|[\d]+\.[\d]+|[\d]+\.[\d]+e[\d]+|[\d]+e[\d]+|'[^']*'|"[^"]*"|\{.*\}|\[.*\])$/s;
      if (!safeRegex.test(value.trim())) {
        console.log('skipping unsafe eval value=', value);
        return value;
      }
    }

    try {
      // eslint-disable-next-line no-eval
      return eval(`(${value})`);
    } catch (e) {
      console.log(`failed eval value='${value}'`);
      console.log(e);
      return value;
    }
  }
  return value;
}

watch ( [() => useGravManager().allPagesDTO], () => {
  // console.log('watch allPagesDTO -> reload');
  allPages.value = reloadPages();
  updCount.value++;
}, { immediate: false, deep: true });

function reloadPages() {
  if (! componentTypeFile) return;

  const { createForm, inputs, defaults } = componentTypeFile;


  formInputs.value = inputs().map((input) => ({
    name: input.alias,
    flags: input.customFlags as GravInputFlags,
  }));

  const pages = (useGravManager()?.allPagesDTO?.filter((page) => {
    const component = page.page.find((c) => c.id === props.component.id);
    return !!component;
  }) || []).map((page) => {
    const component = page.page.find((c) => c.id === props.component.id);
    if (! component) throw new Error('should not happen');


    const pageProps = {} as Record<string, any>;
    const propTypes = {} as Record<string, GravComponentPropertyDTO>;

    const defaultState = {} as Record<string, string>;

    Object.keys(defaults).forEach((key) => {
      const val = defaults[key];
      defaultState[key] = stringifyAny(val);
    });

    component.properties.forEach((pp) => {
      const name = toCamelCase(pp.name);
      const value = evaluatedValue(pp);
      pageProps[name] = value;
      defaultState[name] = stringifyAny(value);
      propTypes[name] = pp;
    });

    const pageForm = createForm({ ...defaults, ...pageProps });

    return {
      name: page.language,
      form: pageForm,
      defaultState,
      propTypes,
    };
  });

  return pages;

}

onMounted(async () => {
  loading.value = true;
  await usePropertiesStore().forceFetchPlanProperties();

  // const realComponentName = ucFirst(toCamelCase(props.component.name . replace(/^ui-/, '')));

  try {
    componentTypeFile =

      (await import(`@/grav/front/dform/components/${props.component.name}.ts`)).default as ComponentFormFile<any, any>;

    if (!componentTypeFile) {
      throw new Error(`Component ${props.component.name} file not found`);
    }

    const { createForm, inputs, defaults } = componentTypeFile;

    if (!createForm || !defaults || !inputs) {
      throw new Error(`File ${props.component.name} must return propsForm and defaults`);
    }

    allPages.value = reloadPages();

  } catch (e) {
    console.log('Properties schema not found', e);
  }
  loading.value = false;
});

</script>
