/* eslint-disable camelcase */

export default {
  p_accountAvatar: "Аватар",
  p_accountId: "ID",
  p_accountTitle: "Имя",
  p_position: "Позиция",
  p_value: "Значение",

  unknown_error: "Неизвестная ошибка при загрузке доски лидеров.",
};
