<template>
  <OverlayProgress :loading="aIdLoading || walletLoading">
    <BasicCard
      :title="la('title')"
    >
      <v-form
        ref="formRef"
        v-model="formValid"
      >
        <span class="text-caption"> {{ lv.label_balance }} </span>
        <v-text-field
          v-model="currentBalance"
          prependInnerIcon="mdi-wallet"
          :prefix="currentWallet?.currency_id"
          readonly

          :density="inputProperties?.density"
          :variant="inputProperties?.variant"
          :rounded="inputProperties?.rounded"
          :flat="inputProperties?.flat"

          :rules="[(v: any) => !!v || lt('error_balance_must_be_positive')]"
        />

        <span class="text-caption"> {{ lv.label_transfer_to }} </span>

        <AccountSearch
          v-model="toAccount"
          :label="la('start_typing_to_search')"
          :errorMessages="accountSearchErrors"
          :inputProperties="inputProperties"
          :networkSearch="true"
          required
          @validate="onAccountSearchValidate"
        />

        <!-- placeholder="1 000.00" (TODO - get this back) -->
        <span class="text-caption"> {{ lv.label_amount_to_transfer }} </span>
        <v-text-field
          v-model.number="amount"
          v-mask:currency
          :prefix="currentWallet?.currency_id"
          prependInnerIcon="mdi-arrow-right-thick"
          required

          :density="inputProperties?.density"
          :variant="inputProperties?.variant"
          :rounded="inputProperties?.rounded"
          :flat="inputProperties?.flat"

          :rules="[amountRules]"
        />

        <FieldConfirmation
          v-if="confirmBy && confirmFieldValue && !isOtpConfirmed && submitPressed"
          :key="confirmationResetCount"
          :alias="confirmBy"
          :value="confirmFieldValue"
          @confirmed="onOtpConfirmed($event)"
        />

        <v-expand-transition>
          <v-alert
            v-if="errorMessage"
            class="mb-4 mt-2"
            type="error"
            closable
          >
            {{ errorMessage }}
          </v-alert>
        </v-expand-transition>

        <div
          v-if="!confirmBy || !submitPressed || isOtpConfirmed"
          class="d-flex flex-row justify-center"
        >
          <v-btn
            class="ma-1"
            :data-loading="transferInProgress ? 'true' : undefined"
            :loading="transferInProgress"
            color="primary"
            type="submit"
            :disabled="submitDisabled"
            @click.prevent="submit()"
          >
            {{ lv.button_transfer }}
          </v-btn>
          <v-btn
            class="ma-1"
            color="secondary"
            @click="resetForm()"
          >
            {{ lv.button_reset }}
          </v-btn>
        </div>
      </v-form>
    </BasicCard>
  </OverlayProgress>
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import { AccountToAccountProps } from './AccountToAccount';

import { useEventBus } from '@/pinia/useEventBus';

import { useLocalization, useAccountId, useWallet, useToast, useAccountPropertyCache } from '@/composables';
import { apiErrorToString, useWalletApi } from '@ui-api3-sdk/api/api3';

import BasicCard from './base/BasicCard.vue';
import AccountSearch from './base/AccountSearch.vue';

import OverlayProgress from '@/components/ui/base/OverlayProgress.vue';
import { ConfirmationResult } from '@/models/field-confirmation-interface';
import FieldConfirmation from './base/FieldConfirmation.vue';


const { lt, lv, la } = useLocalization('uiAccountToAccount', [
  'title',
  'label_balance',
  'label_transfer_to',
  'label_amount_to_transfer',
  'start_typing_to_search',
  'button_transfer',
  'button_reset',
  'error_balance_must_be_positive',
  'error_amount_required',
  'error_not_number',
  'error_not_enough_balance',
  'error_account_required',
  'transfer_success',
]);

const props = defineProps<AccountToAccountProps>();

const formRef = ref<HTMLFormElement | null>(null);
const formValid = ref(true);
const errorMessage = ref('');

const accountSearchErrors = ref<string[]>([]);
const transferInProgress = ref(false);

const isOtpConfirmed = ref(false);

const { aId, isLoading: aIdLoading } = useAccountId(props.accountId);
const { currentWallet, currentBalance, isLoading: walletLoading } = useWallet(
  aId,
  props.walletId,
);

const toAccount = ref<{ accountId: number } | undefined>(undefined);
const amount = ref(0);
const submitPressed = ref(false);

const submitDisabled = computed(
  () => !toAccount.value || !formValid.value,
);

const confirmationResetCount = ref(0);
const confirmFieldValue = computed(() => {
  if (!props.confirmBy) return '';
  return useAccountPropertyCache(props.confirmBy).presentable.value || '';
});

const amountRules = (v: number) => {
  if (!v) return lt('error_amount_required');

  const f = parseFloat(v.toFixed(2));

  if (isNaN(f) || !isFinite(f)) return lt('error_not_number');
  if (f < 0.01) return lt('error_amount_required');
  if (f > currentBalance.value) return lt('error_not_enough_balance');

  return true;
};

function onOtpConfirmed(value: ConfirmationResult) {
  if (value?.otpHeader) {
    isOtpConfirmed.value = true;
    submit();
  }
}

const submit = async () => {

  if (! submitPressed.value ) {
    submitPressed.value = true;
    if (props.confirmBy) return;
  }

  if (!formValid.value) return;

  if (!currentWallet.value?.alias) throw new Error('invalid wallet alias');
  if (!toAccount.value?.accountId) throw new Error('to account undefined');
  if (!aId?.value) throw new Error('invalid account id');

  transferInProgress.value = true;

  useWalletApi()
    .walletTransferControllerCreateTransfer({
      walletId: props.walletId,
      id: aId.value,
      postTransferReq: {
        amount: amount.value,
        targetAccountId: toAccount.value?.accountId,
      },
    })
    .then(() => {
      currentBalance.value = parseFloat((currentBalance.value - amount.value).toFixed(2));
      useToast().ok(lt('transfer_success'));
      resetForm();
      useEventBus().update('wallet_history');
    })
    .catch(e => {
      errorMessage.value = apiErrorToString(e);
    })
    .finally(() => {
      transferInProgress.value = false;
    });
};

const onAccountSearchValidate = () => {
  if (!toAccount.value) {
    accountSearchErrors.value = [lt('error_account_required')];
  } else {
    accountSearchErrors.value = [];
  }
};

const resetForm = () => {
  accountSearchErrors.value = [];
  toAccount.value = undefined;
  amount.value = 0;
  isOtpConfirmed.value = false;
  submitPressed.value = false;
  confirmationResetCount.value++;
  errorMessage.value = '';

  nextTick(() => {
    formRef.value?.resetValidation();
  });
};
</script>
