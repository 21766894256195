/* eslint-disable camelcase */
export default {
  out_of: 'of',
  current_rank: 'Current rank',
  next_rank: 'Next rank',
  rank_progress: 'Rank progress',
  rank: 'Rank',
  no_rank: 'No rank',
  max_rank: 'Max rank',
};
