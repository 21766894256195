import { gravComponentSchema } from '../deep-form-grav';

const pageTitleProps = {
  h: '',
  p: '',
  attr: '',
};

 
const { s, l, c, v, src, h, toExport } = gravComponentSchema<typeof pageTitleProps>({
  title: 'Page settings',
  link: '',
  text: text(),
},
  pageTitleProps,
);

export default toExport;

s.add('h').input('text').col(12).title('Page title as it appears on the page');
s.add('p').input('text').col(12).title('Page description below the title');

function text() {
  return `
Here you can set up the page title and description for the page.
`;
}
