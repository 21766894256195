<template>
  <v-select
    v-bind="modelValue"
    :items="items"
    :loading="loading"
    :data-loading="loading ? 'true' : undefined"
    :label="$t(label || 'common.frontline')"
    itemTitle="name"
    prependInnerIcon="mdi-alien"
    :clearable="true"
    returnObject
    :singleLine="true"
    :density="inputProperties?.density"
    :variant="inputProperties?.variant"
    :rounded="inputProperties?.rounded"
    :flat="inputProperties?.flat"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

import { V2DownlinePositionVM , useAccountApi } from '@ui-api3-sdk/api/api3';
import { useAwaitAccountId, useLocalization } from '@/composables';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { TreePosition } from '@/models/tree-interface';

type Item = TreePosition & {
  name: string;
};

const props = withDefaults(
  defineProps<{
    modelValue?: TreePosition;
    accountId?: number;
    treeId?: number;
    inputProperties?: InputByTypeProperties;
    label?: string;
    limit?: number;

    positionTitles?: Record<number, string>;
  }>(),
  {
    accountId: undefined,
    modelValue: undefined,
    treeId: 2,
    inputProperties: undefined,
    label: undefined,
    limit: 500,
    positionTitles: () => ({}) as Record<number, string>,
  },
);

// const { d } = useLocalization();

const emit = defineEmits<{
  (event: 'update:modelValue', result: TreePosition | null): void;
}>();

const { t } = useLocalization();

const loading = ref(false);
const items = ref<Array<Item>>([]);
const totalItems = ref(0);

function parseFrontline(list: Array<V2DownlinePositionVM>): Item[] {
  function genName(position: V2DownlinePositionVM) {
    // TODO: api3 changed

    if (props.positionTitles[position.number])
      return `${t(props.positionTitles[position.number])}`;
    // return `${t(props.positionTitles[position.number])} (${position.hasChild})`;

    return `[${position.account.idValue.presentable} / ${position.offset}]`;
    // return `[${position.account.idValue.presentable} / ${position.offset}] ${position.account.title} (${position.hasChild})`;
  }

  return list.map(position => ({
    accountId: Number(position.account.id),
    positionId: Number(position.id),
    offset: Number(position.offset),
    name: genName(position),
  }));
}

async function fetch() {
  loading.value = true;

  // Array<FrontLinePositionVM>

  const aId = await useAwaitAccountId(props.accountId);

  useAccountApi()
    .v2FrontLineControllerGetFrontline({
      limit: props.limit,
      page: 0,
      id: aId,
      treeId: props.treeId,
      showInactiveAccounts: false,
    })
    .then(res => {
      items.value = parseFrontline(res.data.payload.list);
      totalItems.value = res.data.payload.total;
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
}

watch(
  () => props.accountId,
  () => {
    fetch();
  },
  { immediate: true },
);
</script>
