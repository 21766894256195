import { gravComponentSchema } from '../deep-form-grav';
import { BadgeBoxProps, BadgeEntry, defaults } from '../../../../components/ui/BadgeBox';
import { CONDITION_OPERATORS, ColorRule } from '@/utils/conditional-colors';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<BadgeBoxProps>({
  title: 'BadgeBox',
  link: 'https://www.notion.so/mlmsoft/BadgeBox-759a4f9199bf42ab886f05a515c2c42e',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('title').input('vtext')
  .title('Title').col(3);

s.add('backgroundClass').input('mdiColor')
  .title('Form background color').col(3);

s.add('headerClass').input('mdiColor')
  .title('Header color').col(3);

s.add('singleLine').input('boolean')
  .title('Inline layout (no window)').col(3);

//

s.add('callback').input('text')
  .title('Callback function')
  .desc('Callback gets (p, r, item) and should return array of badge ids: (p, r, item) => string[]')
  .col(3);

s.add('badgeUrlTemplate').input('text')
  .title('Badge URL template')
  .desc('Template for badge URL. Use {{badgeId}} to insert badge id')
  .col(5);

s.add('badgeWidth').as(bl.clone('number'))
  .title('Badge width').col(2);

s.add('badgeHeight').as(bl.clone('number'))
  .title('Badge height').col(2);

//

s.add('fromArrayField').as( l.clone('property') )
  .title('From array field')
  .desc('If set, will use this field as array of badge ids to run through Badge URL template')
  .col(3);

s.add('center').input('boolean')
  .title('Center content').col(3);

s.add('noBadgesText').input('vtext')
  .title('No badges text').col(6);

//

const myColorRuleInput = l.spawn('myColorRule')
  .pre((v) => Array.isArray(v)
    ? {
      field: v[0],
      condition: v[1],
      value: v[2],
      schemaItem: 'on',
    }
    : v)
  .input('dform').data([
    l.spawn<string>('field').as(l.clone('property')).mandatory()
      .props({ class: 'mr-md-4', hideDetails: true }).col(3).item(),
    l.spawn<string>('condition').input('dictionary')
       
      .data(CONDITION_OPERATORS as any).mandatory()
      .props({ class: 'mr-md-4', hideDetails: true }).col(2).item(),
    l.spawn<string>('value').input('text').mandatory()
      .props({ class: 'mr-md-4', hideDetails: true }).col(7).item(),
  ])
  .convert((v) => [v.field, v.condition, v.value, v.schemaItem])
  .init(() => (['', '=', '', 'on']));

const badgeEntryInput = l.spawn<BadgeEntry>('badgeEntry').input('dform').data([
  l.spawn<string>('title').input('text').title('Title')
    .props({ class: 'mr-md-2', variant: 'outlined', dfTitleInside: true })
    .col(4).item(),
  l.spawn<string>('badgeUrl').input('text').title('Badge URL')
    .props({ class: 'mr-md-2', variant: 'outlined', dfTitleInside: true })
    .col(8).item(),
  l.spawn<Array<ColorRule>>('rules').input('dlist').data(
    myColorRuleInput.item(),
  ).col(12).item(),
])
  .props({ style: 'border: 1px solid #ccc; padding: 20px; margin: 10px; border-radius: 5px;' })
  .init(() => ({ badgeUrl: '', title: '', rules: [] }));

s.add('badges').input('dlist').data(badgeEntryInput.item())
  .init(() => [])
  .title('Conditional badges')
  .col(12);


function text() {
  return `
<b>Badge Box</b>
You can decorate any page of your cabinet with badges and awards for your users.
`;
}
