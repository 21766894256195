import debounce from 'lodash.debounce';

import { FilterItem } from "@ui-api3-sdk/api/api3";
import { ref, watch, computed } from "vue";
import { isBackendProperty } from '@/utils/properties-mapping-service';
import { getLikeOperation } from '../utils/filter-service';
import { useLocalization } from './useLocalization';
import { usePropertiesStore } from '@/pinia/usePropertiesStore';

export function useSearchFilter(properties: string[], initialFilter: FilterItem[] = [], initialSearchBy?: string) {

  const { pt, isLoading } = useLocalization();

  const resultSearchFilter = ref(initialFilter);
  const searchText = ref('');
  const searchBy = ref(initialSearchBy ?? '');
  let previousSearchBy = initialSearchBy;

  const allowedTypes = ['string', 'txt', 'html'];

  const searchByItems = computed(() => {
    if (isLoading.value) return [];
    const res = [];

    for (const p of properties) {
      const propType = usePropertiesStore().getPropertyType(p, undefined);

      if (allowedTypes.indexOf(propType.fieldType) === -1) continue;
      if (!isBackendProperty(p)) continue;

      res.push({
        id: p,
        title: pt(p),
        fieldType: propType.fieldType,
      });
    }

    if (!searchBy.value) {
      if (res[1]?.id)
        searchBy.value = res[1]?.id;
      else
        searchBy.value = res[0]?.id || '';
    }

    return res;

  });

  watch([searchText, searchBy], debounce(() => {

    const newFilter = resultSearchFilter.value.filter((f) => f.field !== previousSearchBy);
    previousSearchBy = searchBy.value;

    if (searchText.value) {

      const propType = usePropertiesStore().getPropertyType(searchBy.value, undefined);
      const operation = getLikeOperation(propType.fieldType);

      newFilter.push({
        field: searchBy.value,
        operation: operation.value,
        value: searchText.value,
      });
    }

    resultSearchFilter.value = newFilter;

  }, 500, { leading: false, trailing: true }));


  return {
    resultSearchFilter,
    searchText,
    searchBy,
    searchByItems,
  };
}
