
import { ComputedRef , Ref } from 'vue';


export async function untilTrue(ref: Ref<boolean>): Promise<void> {
  return new Promise((resolve) => {
    const check = () => {
      if (ref.value === true) {
        resolve();
      } else {
        setTimeout(check, 100);
      }
    };

    check();
  });
}

export async function untilFalse(ref: Ref<boolean> | ComputedRef<boolean>): Promise<void> {
  return new Promise((resolve) => {
    const check = () => {
      if (ref.value === false) {
        resolve();
      } else {
        setTimeout(check, 100);
      }
    };

    check();
  });
}
