import { LoginType } from "@/composables/useEnrollSettings";
import i18n from "@/i18n";
import { Ref } from "vue";

export function emailValidate(email: string): boolean {
  const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  const startsCorrectly = /^[a-zA-Z0-9]/;

  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const [account, address] = emailParts;

  if (account.length > 64 || address.length > 255) return false;

  const domainParts = address.split('.');

  if (domainParts.some(part => part.length > 63)) return false;

  if (!startsCorrectly.test(email)) return false;

  return tester.test(email);
}


export function getLocaleRef() {
  return i18n.global.locale as unknown as Ref<string>;
}

 
export function formatDate(date: any) {
  if (!date) return undefined;
  return i18n.global.d(new Date(String(date)), 'medium');
}

export function formatBigNumber(value: number | string) {
  return Intl.NumberFormat(getLocaleRef().value, { notation: 'compact' }).format(Number(value));
}

export function formatAccountId(ss: number, idMask = '000 000 000') {
  const s_ = [...idMask];
  let sLength = s_.length - 1;
  const s = ss ? ss.toString() : '';

  [...s].reverse().forEach(item => {
     
    while (
      sLength > -1 &&
      (s_[sLength] !== '0' ||
         
        ((s_[sLength] = item) && (sLength = sLength - 1) && false))
    )
      sLength = sLength - 1;
  });

  return s_.join('');
}


export function generatePassword(props: {
  size: number | undefined,
  hasUpper: boolean,
  hasSpecial: boolean
}) {

  const lower = 'abcdefghijklmnopqrstuvwxyz';
  const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const special = '!@#$%^&*()_+~`|}{[]:;?><,./-=';

  const vocs = [lower, numbers];

  if (props.hasUpper) vocs.push(upper);
  if (props.hasSpecial) vocs.push(special);

  const vocsFlat = vocs.flat().join();

  if (props.size! < vocs.length) throw new Error('Size is too small');

  let password = '';

  vocs.forEach(voc => password += randomChar(voc));
  for (let i = 0; i < props.size! - vocs.length; i++) {
    password += randomChar(vocsFlat);
  }

  password = password.split('').sort(() => Math.random() - 0.5)
    .join('');

  function randomChar(src: string) {
    const randomIndex = Math.floor(Math.random() * src.length);
    return src.charAt(randomIndex);
  }

  // console.log('generated:', password);
  return password;
}

export function getLoginFieldAlias(loginType: LoginType) {
  switch (loginType) {
    case LoginType.PHONE_AS_LOGIN: return 'phone';
    case LoginType.EMAIL_AS_LOGIN: return 'email';
    case LoginType.ID_AS_LOGIN: return 'accountId';
    case LoginType.CUSTOM_LOGIN:
    default: return 'login';
  }
}

export function shortenCutMiddle(st: string, maxLength: number) {

  if (!st || st.length < maxLength) return st;

  if (maxLength < 5) return st.slice(0, maxLength);

  const remainsAtEachSide = Math.floor((maxLength - 3) / 2);
  const remainsAtStart = remainsAtEachSide;
  const remainsAtEnd = maxLength - remainsAtStart - 3;

  const first = st.slice(0, remainsAtStart);
  const last = st.slice(-1 * remainsAtEnd);

  return `${first}...${last}`;
}

export function shortenCutEnd(st: string, maxLength: number) {

  if (st.length < maxLength) return st;

  const remains = maxLength - 3;
  const first = st.slice(0, remains);

  return `${first}...`;
}


export function ucFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function aliasToPretty(alias: string) {
  const noPrefix = alias.split('.').pop() as string;
  // todo: split by capital letters
  return noPrefix.split('_').map(ucFirst)
     .join(' ');
}

 
export function stringifyJS(obj: Record<string, any>) {
  let str = '{';
  for (const key in obj) {
    if (typeof key === 'string') {

      if (/^[a-zA-Z\d_]+$/.test(key))
        str += `${key}: `;
      else
        str += `'${key}': `;

      if (Array.isArray(obj[key])) {
        str += stringifyArray(obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        str += stringifyJS(obj[key]);
      } else if (typeof obj[key] === 'string') {
        str += `'${obj[key]}'`;
      } else {
        str += obj[key];
      }
      str += ', ';
    }
  }
  // Remove trailing comma and space
  if (str.length > 2) {
    str = str.slice(0, -2);
  }
  str += '}';
  return str;
}

 
export function stringifyArray(arr: Array<string | number | boolean | Record<string, any>>) {
  let str = '[';
  for (const item of arr) {
    if (Array.isArray(item)) {
      str += stringifyArray(item);
    } else
    if (typeof item === 'object' && item !== null) {
      str += stringifyJS(item);
    } else if (typeof item === 'string') {
      str += `'${item}'`;
    } else {
      str += item;
    }
    str += ', ';
  }
  // Remove trailing comma and space
  if (str.length > 2) {
    str = str.slice(0, -2);
  }
  str += ']';
  return str;
}

export function stringifyAny(v: any) {
  if (typeof v === 'string') return v;
  if (typeof v === 'number') return String(v);
  if (typeof v === 'boolean') return String(v);
  if (Array.isArray(v)) return stringifyArray(v);
  if (v === null) return '';
  if (typeof v === 'object') return stringifyJS(v);
  return String(v);
}

 
export function deepClone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function removeDuplicatesById<T extends { id: string | number }>(array: Array<T>) {
  const ids = array.map(obj => obj.id);
  return array.filter((obj, index) => ids.indexOf(obj.id) === index);
}

export function filterUndefinedOrEmpty<T extends Object>(obj: T) {

  if (typeof obj !== 'object') return obj;

  const filtered = Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined && value !== '' ),
  );

  return filtered as T;
}
