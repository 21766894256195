/* eslint-disable camelcase */
export default {
  account_information: "Account information",
  contact_details: "Contact details",
  phone_confirmation: "Phone confirmation",
  email_confirmation: "Email confirmation",

  register: "Register",
  you_have_registered: "You have successfully registered!",
};
