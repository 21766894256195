<template>
  <div>
    <v-alert
      v-if="useGravManager().currentPageDTO?.isDomOK === false"
      type="error"
      class="mb-4"
    >
      Current page's DOM is broken: {{ useGravManager().currentPageDTO?.parseError }}
    </v-alert>
    <div class="d-flex align-center">
      <v-btn
        class="ma-2"
        color="primary"
        :loading="useGravManager().loading"
        :data-loading="useGravManager().loading ? 'true' : undefined"

        @click="fetchFile"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        class="ma-2"
        color="primary"
        :disabled="useGravManager().loading"
        @click="saveFile"
      >
        SAVE
      </v-btn>
      <div class="flex-grow-1">
        <span
          v-if="editFileName?.startsWith('@pages/')"
        >
          <span>
            ({{ grav.selectedPageName }} / {{ grav.selectedPageLanguage }})
            <!-- {{ grav.selectedPageName }}: -->
          </span>
          <span
            v-for="dto in useGravManager().allPagesDTO || []"
            :key="dto.filePath"
            class="text-caption"
          >
            <strong
              v-if="dto.filePath === editFileName"
              class="ml-4"
            >[{{ dto.language }}]</strong>
            <a
              v-else
              class="ml-4"
              style="cursor: pointer;"
              @click="changeFile(dto.filePath)"
            >{{ dto.language }}</a>
          </span>
        </span>
        <v-text-field
          v-model="editFileName"
          class="ma-2"
          :disabled="useGravManager().loading"
          :rules="[(v: any) => !!v || 'Required']"
        />
      </div>
    </div>
    <v-row>
      <v-col cols="10">
        <VTextarea
          v-model="fileContent"
          :disabled="useGravManager().loading"
          :autoGrow="true"
          :counter="100000"
          :rules="[(v: any) => !!v || 'Required']"
        />
      </v-col>
      <v-col cols="2">
        <GravPageComponentsList />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ref,  watch } from 'vue';
import { useGravManager } from '../comosables/useGravManager';
import { useToast } from '@/composables';

import GravPageComponentsList from './GravPageComponentsList.vue';

const grav = useGravManager();
const fileContent = ref<string>('');
const editFileName = ref('');

watch(() => grav.fileContent, (newVal) => {
  fileContent.value = newVal || '';
});

watch(() => grav.currentFileOnEdit, async (newVal) => {
  if (newVal) {
    editFileName.value = newVal;
    await fetchFile();
  }
}, { immediate: true });

function changeFile(fileName: string) {
  grav.setEditFile(fileName);
}

async function fetchFile() {
  if (!editFileName.value) return;
  const res = await grav.upcliReadFile(editFileName.value);
  if (res) {
    fileContent.value = res;
  }
}

async function saveFile() {
  if (!editFileName.value) return;
  const result = await grav.upcliWriteFile(editFileName.value, fileContent.value);
  if (result) {
    useToast().ok('File saved');
  }
}

</script>
