<!-- eslint-disable vue/no-unused-properties -->
<!-- eslint-disable @typescript-eslint/no-unused-vars -->

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import { Item, TableGridItem } from '@/models/table-grid-interface';
import { auth } from '@ui-api3-sdk/api';
import { useThemeStore } from '@/pinia/useThemeStore';

import { useProperties } from '@/composables';
import { useAccountPropertiesItem } from '@/composables/useAccountPropertiesItem';

import { apiErrorToString } from '@ui-api3-sdk/api/api3';
import { fetchFullProperties } from '@/utils/fetch-full-properties-service';
// import { useDisplay } from 'vuetify/lib/framework';

import MemberInfoTable from './base/MemberInfoTable.vue';
import UserAvatar from './base/UserAvatar.vue';

import { UserPopProps, defaults } from './UserPop.ts';
import { useDisplay } from 'vuetify';

const props = withDefaults(defineProps<UserPopProps>(), {
  id: undefined,
  nameProperty: undefined,
  treeId: () => defaults.treeId,
  items: () => defaults.items,
  properties: () => defaults.properties,
  itemTitles: () => defaults.itemTitles,
  avatarPosition: () => defaults.avatarPosition,
  avatarSize: () => defaults.avatarSize,
  pulldownIcon: () => defaults.pulldownIcon,

});

const mandatoryProps = [
  ... props.items,
  ... [ 's.id', 'r.id', 'p.firstname', 'p.lastname', 'p.avatar_id' ],
  ... props.nameProperty ? [ props.nameProperty ] : [],
];

const { propertyTranslations, allFetchableProperties, properties: resProperties } = useProperties(
  props.properties,
  mandatoryProps,
  props.itemTitles,
);

const { xs } = useDisplay();

const width = computed(() => xs.value ? 350 : 600 );

const { item: account, isLoading } = useAccountPropertiesItem();

// const mergedTitles = computed(() => usedProperties.propertyTranslations.value );

const open = ref(false);
const error = ref('');

const fullInfoLoading = ref(false);
const fullInfoRaw = ref<TableGridItem>();
const fullInfo = computed(() => fullInfoRaw.value ? Item(fullInfoRaw.value) : undefined );

const nameValue = computed(() =>
  props.nameProperty ? account.value?.p(props.nameProperty) : account.value?.fullName() );

watch (() => open.value, (newVal) => {
  if (newVal && !fullInfo.value) {
    fetchFullInfo();
  }
});

function onlyAccountPropsRequired() {
  return resProperties.every( p => p.startsWith('p.') || p.startsWith('s.') );
}

async function fetchFullInfo() {

  if (onlyAccountPropsRequired()) {
    fullInfoRaw.value = account.value?.getRaw();
    fullInfoLoading.value = false;
    return;
  }

  try {
    fullInfoLoading.value = true;
    fullInfoRaw.value = await fetchFullProperties(
      allFetchableProperties(),
      props.treeId,
    );
    fullInfoLoading.value = false;

  } catch (e) {
    error.value = apiErrorToString(e, 'Error fetching full info');
    fullInfoLoading.value = false;
  }

}

</script>

<template>
  <v-progress-circular
    v-if="isLoading"
    :data-loading="true"
    class="px-2"
    indeterminate
    color="primary"
    :size="40"
  />
  <div v-else-if="account">
    <!-- location="start" -->
    <v-menu
      v-model="open"
      :width="width"
      :maxWidth="width"
      :minWidth="width"
      :closeOnContentClick="false"
    >
      <template #activator="{ props }">
        <div v-bind="props" class="d-flex align-center">
          <UserAvatar
            v-if="avatarPosition === 'start'"
            class="mr-2"
            :round="true"
            :size="avatarSize"
            :allowChange="false"
          />
          <template v-if="!xs">
            <div>{{ nameValue }}</div>
            <v-btn
              v-if="!!pulldownIcon"
              :icon="pulldownIcon"
            />
          </template>
          <UserAvatar
            v-if="avatarPosition === 'end'"
            class="mr-2"
            :round="true"
            :size="avatarSize"
            :allowChange="false"
          />
        </div>
      </template>
      <v-theme-provider :theme="useThemeStore().theme" :withBackground="true">
        <v-card :width="width" class="pa-4">
          <v-list>
            <v-list-item
              :class="xs ? 'px-0' : undefined"
              :title="nameValue"
              :subtitle="account?.presentableId()"
            >
              <template #prepend>
                <UserAvatar class="mr-4" :round="true" :size="90" :allowChange="true" />
              </template>
              <template v-if="!xs" #append>
                <v-btn
                  color="error"
                  variant="elevated"
                  @click="auth.logout()"
                >
                  {{ $t('dialogs.logout') }}
                </v-btn>
              </template>
            </v-list-item>
          </v-list>

          <v-divider v-if="items.length" />

          <div v-if="fullInfoLoading" class="text-center pa-10">
            <v-progress-circular
              indeterminate
              :data-loading="true"
              color="primary"
              :size="40"
            />
          </div>

          <v-alert v-else-if="error" type="error" closable>
            {{ error }}
          </v-alert>

          <v-list v-else-if="items.length && fullInfoRaw">
            <v-list-item :class="xs ? 'px-0' : undefined" title="">
              <MemberInfoTable
                :items="items"
                :itemTitles="propertyTranslations || {}"
                :data="fullInfoRaw"
              >
                <template
                  v-for="itemName in items"
                  #[`${itemName}`]="{ value, p, r }"
                  :key="itemName"
                >
                  <slot
                    :name="itemName"
                    :value="value"
                    :p="p"
                    :r="r"
                    :xs="xs"
                  >
                    {{ value }}
                  </slot>
                </template>
              </MemberInfoTable>
            </v-list-item>
          </v-list>

          <v-card-actions v-if="xs">
            <v-spacer />

            <v-btn
              color="error"
              variant="elevated"
              @click="auth.logout()"
            >
              {{ $t('dialogs.logout') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-theme-provider>
    </v-menu>
  </div>
</template>
