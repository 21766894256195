import { gravComponentSchema } from '../deep-form-grav';
import { TransactionLogProps, defaults } from '@/components/ui/TransactionLog';
import { queryPropertiesList } from '../lib/helpers';

 
const { s, l, c, v, src, h, toExport } = gravComponentSchema<TransactionLogProps>({
  title: 'Transaction Log',
  link: 'https://www.notion.so/mlmsoft/TransactionLog-07d41c311a2b435cb8ef649cca0c6cc0',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('filterDocument').input('dlist').data(
  s.spawn<string>('documentName').input('vtext').mandatory().title('Document name')
    .props({ hideDetails: true })
    .init(() => '')
    .mandatory()
    .item(),
)
  .title('Filter by documents').col(6);

s.add('filterProperties').input('dlist').data(
  s.spawn<string>('property').input('dictionary')
    .dataAsync(src.propertiesMarketingNoPrefix)
    .props({ hideDetails: true })
    .init(() => '')
    .mandatory()
    .item(),
)
  .title('Filter by properties').col(6);

  //

const transactionLogProperty = s.spawn<string>('property').input('dictionary')
  .dataAsync(async () => {

    const vmtl = await queryPropertiesList([{ prefix: 'vmtl', addNamePrefix: 'Transaction' }]);
    const m = await queryPropertiesList([{ prefix: 'm', addNamePrefix: 'Marketing' }]);
    const vmdl = await queryPropertiesList([{ prefix: 'vmdl', addNamePrefix: 'Downline' }]);

    const p = await queryPropertiesList([{ prefix: 'p', addNamePrefix: 'Profile' }]);
    const t = await queryPropertiesList([{ prefix: 't', addNamePrefix: 'Template' }]);
    const s = await queryPropertiesList([{ prefix: 's', addNamePrefix: 'Account' }]);

    const dp = await queryPropertiesList([{ prefix: 'p', addNamePrefix: 'Document profile', addPropertyPrefix: 'doc:' }]);
    const dt = await queryPropertiesList([{ prefix: 't', addNamePrefix: 'Document template', addPropertyPrefix: 'doc:' }]);
    const ds = await queryPropertiesList([{ prefix: 's', addNamePrefix: 'Document account', addPropertyPrefix: 'doc:' }]);

    return [{
      title: 'Volume change',
      value: 'volumeChange',
    }, {
      title: 'Volume diff',
      value: 'volumeDiff',
    }, ...vmtl, ...m, ...vmdl, ...p, ...t, ...s, ...dp, ...dt, ...ds];
  })
  .init(() => '')
  .mandatory();

s.add('items').input('dlist').data(
  transactionLogProperty.props({ hideDetails: true }).item(),
)
  .title('Columns in the table').col(5);


s.add('itemTitles').input('drecord').data(

  s.spawn('').input('dform').data([
    l.spawn<string>('key').asMany([l.clone('itemLeft'), transactionLogProperty]).item(),
    l.spawn<string>('value').asMany([l.clone('itemRight'), l.clone('translatedText')]).item(),
  ])
    .init(() => ({ key: '', value: '' }))
    .item(),

)
  .title('Column headers translation').col(7);

//

s.add('showPeriods').input('dictionary').data([
  { title: 'Always show all (hide select)', value: 'always-all' },
  { title: 'Allow all periods', value: 'allow-all' },
  { title: 'Only selected period', value: 'only-selected' },
])
  .title('Show periods')
  .col(3)
// .desc('If checked all periods will be shown, otherwise period can be selected')
  .title('Allow all periods').col(6);


function text() {
  return `
<b>Transactions log</b>
Using this component you can show a list of transactions that have affected the account. Transaction log shows how marketing properties have changed over time and what documents caused those changes. You can filter the list by document type and/or by properties. You can also specify which properties to show in the list.
`;
}
