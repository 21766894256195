import { PropStep, SignupRegistrationProps, defaults } from '@/components/ui/SignupRegistration';
import { gravComponentSchema } from '../deep-form-grav';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<SignupRegistrationProps>({
  title: 'Signup Registration',
  link: 'https://www.notion.so/mlmsoft/SignupRegistration-01e00b2bc7fc4c92af25de86281d3e3e',
  text: text(),
},
  defaults,
);

export default toExport;


const propInput = l.clone('profilePropertyForRegistration');

const propStepInput = s.spawn<PropStep>('propStep').input('dform').data([
  s.spawn<string>('title').input('vtext')
    .title('Step title').mandatory()
    .col(6).props({ class: 'mr-4', hideDetails: true, dfShowTitle: true, density: 'compact' }).item(),
  s.spawn<string[]>('aliases').input('dlist').data(
    propInput
      .props({ hideDetails: true, density: 'compact' }).title('Alias').mandatory().item(),
  )
    .col(6).props({ hideDetails: true, dfShowTitle: true })
    .title('Step properties').mandatory().validators([v.minItems(1)]).item(),
])
  .props({ style: 'border: 1px solid #e2e8f0; padding: 1rem; border-radius: 0.5rem;' });

s.add('steps').input('dlist').data(propStepInput.item())
  .title('Steps').mandatory().validators([v.minItems(1)]).item();

s.add('optionalFields').input('dlist').data(propInput.item())
  .title('Optional Fields');

s.add('fieldTitles').as(l.clone('headerTitles')).col(12);

s.add('otpConfirm').as(l.clone('otpConfirm'));

// drecord with propInput = string

s.add('forceFields').input('drecord').data(
  s.spawn().input('dform').data([
    s.spawn<string>('key').asMany([l.clone('itemLeft'), propInput]).title('Property').item(),
    l.spawn<string>('value').asMany([l.clone('itemRight'), bl.clone('string')]).item(),
  ])
    .init(() => ({ key: '', value: '' }))
    .item(),
)
  .title('Force hidden values')
  .col(12);


s.add('autoLogin').input('boolean').title('Auto Login').col(3);
s.add('altLabels').input('boolean').title('Step title below the dot').col(3);

function text() {
  return `
<b>Multi-step Registration Process</b>
If you want new users to fill up a lot of fields, a sign-up form can be configured as a multi-step process. If you only want them to fill up a couple of fields you may prefer a single page sign-up form.
<b>Profile Fields</b>
Please provide a list of profile fields for a registration form, and mark required fields with ‘*’. If you want to use a multi-step form, please specify which fields belong to which step, together with the title for each step.
Please note: fields sponsor, name, and email are must have on a registration form.
  `;
}
