import { InputByTypeProperties } from "@/models/form-builder-interface";

export interface RegisterMemberProps {
  id?: string;
  profileFields?: string[];
  fieldTitles?: Record<string, string>;
  optionalFields?: string[];
  inputProperties?: InputByTypeProperties;
}

export const defaults = {
  profileFields: ['firstname', 'lastname', 'birth_date', 'country_id', 'region_id', 'email', 'password', 'password_confirm'],
  inputProperties: { noAutocomplete: true },
};
