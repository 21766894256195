<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    :minWidth="mdAndUp ? 600 : 350"
    :attach="attachTo"
  >
    <template v-if="buttonMode" #activator="{ props }">
      <v-icon
        color="text-on-primary-lighten-4"
        v-bind="props"
        start
      >
        mdi-information-outline
      </v-icon>
    </template>

    <v-card>
      <v-card-text>
        <MemberInfo
          :properties="properties"
          :data="data"
          :treeId="treeId"
          :accountId="accountId"
          :offset="offset"
          :propertyTitles="propertyTitles"
          :titleField="titleField"
          :subtitleField="subtitleField"
        >
          <slot />
        </MemberInfo>
        <slot name="bottom" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="dialog = false">{{ $t('dialogs.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

import { TableGridItem } from '@/models/table-grid-interface';

import MemberInfo from './MemberInfo.vue';
import { useDisplay } from 'vuetify';

const props = withDefaults(defineProps<{
  properties: string[];

  treeId?: number;
  accountId?: number;
  offset?: number;
  data?: TableGridItem;

  propertyTitles?: Record<string, string>
  buttonMode?: boolean;
  modelValue?: boolean;
  attachTo?: HTMLElement;

  titleField?: string,
  subtitleField?: string,
}>(), {

  treeId: undefined,
  accountId: undefined,
  offset: undefined,
  data: undefined,

  propertyTitles: undefined,
  buttonMode: true,
  attachTo: undefined,
  titleField: undefined,
  subtitleField: undefined,
});

const emit = defineEmits<{
  (event: 'update:modelValue', result: boolean): void,
}>();

const { mdAndUp } = useDisplay();
const dialog = ref(false);

watch(() => props.modelValue, (value) => {
  if (value)
    dialog.value = value;
}, { immediate: true });

watch([dialog], () => {
  if (dialog.value === false)
    emit('update:modelValue', dialog.value);
});

</script>
