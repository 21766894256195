<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-navigation-drawer
    v-bind="$attrs"
    id="core-navigation-drawer"
    v-model="drawer"
    :color="barColor"
    :theme="barTheme"
    :image="barImage || undefined"
    :disableResizeWatcher="true"
    width="260"
  >
    <template #image>
      <v-img :src="barImage || undefined" :cover="true" :gradient="barImage ? gradient : undefined" />
    </template>
    <div class="drawer-overflow-layer">
      <v-list v-if="items" nav>
        <template v-for="(item, i) in items" :key="`menu-group-${i}`">
          <DashboardMenuItemGroup
            v-if="item.children"
            :isActive="Boolean(item.children.find(a => a.active))"
            :item="item"
          />
          <DashboardMenuItem v-else :key="`menu-item-${i}`" :item="item" />
        </template>
        <v-divider class="my-2" />
        <DashboardMenuItem child :item="logoutItem" />
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, reactive, ref, onMounted } from 'vue';
import { useThemeStore } from '@/pinia/useThemeStore';
import { useLocalization } from '@/composables/useLocalization';
import { MenuItem } from '@/models/menu';
import { storeToRefs } from 'pinia';
import { useConditionalMenu } from '@/pinia/useConditionalMenu';

import DashboardMenuItemGroup from './DashboardMenuItemGroup.vue';
import DashboardMenuItem from './DashboardMenuItem.vue';


const conditionalMenu = useConditionalMenu();

const props = defineProps<{
  itemsMenu: MenuItem[];
}>();

const items = ref<MenuItem[] | undefined>();

// State & Store
const { barColor, barImage, barTheme, barDark, drawer } = storeToRefs(useThemeStore());

const logoutItem = reactive({
  title: useLocalization().t('dialogs.logout'),
  icon: 'mdi-power',
  color: 'error',
  action: 'logout',
  active: false,
});

const gradient = computed(() => !barDark.value
  ? 'to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'
  : 'to bottom, rgba(39,39,52, .7), rgba(39,39,52, .8)');


onMounted(async () => {
  items.value = await conditionalMenu.filter(props.itemsMenu);
});

</script>

<style lang="sass">
.v-navigation-drawer__img
  .v-img
    height: 100vh !important

.theme--light
  #core-navigation-drawer
    ::-webkit-scrollbar-track
      background: #e6e6e6
      border-left: 1px solid #dadada

    ::-webkit-scrollbar-thumb
      background: #b0b0b0
      border: solid 3px #e6e6e6
      border-radius: 7px

    ::-webkit-scrollbar-thumb:hover
      background: rgba(0, 0, 0, 0.6)

.theme--dark
  #core-navigation-drawer
    ::-webkit-scrollbar-track
      background: #202020
      border-left: 1px solid #2c2c2c

    ::-webkit-scrollbar-thumb
      background: #3e3e3e
      border: solid 3px #202020
      border-radius: 7px

    ::-webkit-scrollbar-thumb:hover
      background: white

#core-navigation-drawer.v-navigation-drawer--mini-variant
  .drawer-overflow-layer
    width: 80px !important

#core-navigation-drawer
  .drawer-logo-mini
    float: left
    width: 45px
    text-align: center
    margin-left: 2px
    margin-right: 15px

  .drawer-overflow-layer
    width: 260px

  ::-webkit-scrollbar
    width: 12px

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px
      .v-list--dense
        .v-list-item
          &__icon--text,
          &__icon:first-child
            margin-top: 10px
</style>
