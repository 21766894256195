/* eslint-disable camelcase */

export default {
  p_accountAvatar: "Avatar",
  p_accountId: "ID",
  p_accountTitle: "Name",
  p_position: "Position",
  p_value: "Value",

  unknown_error: "Unknown error while loading Leader Board data.",
};
