import { WidgetCardProps, defaults } from '@/components/ui/WidgetCard';
import { gravComponentSchema } from '../deep-form-grav';

type WithSlots = WidgetCardProps & {
  '#titleslot': string;
  '#icon': string;
  '#default': string;
  '#postfix': string;
  '#center': string;
};

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<WithSlots>({
  title: 'WidgetCard',
  link: 'https://www.notion.so/mlmsoft/WidgetCard-5f3af70a594c49138f4cbaf7b80e10b1',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('disableWrapper').input('boolean').col(4).title('Disable window').desc('Disable widget wrap window with header and background');

s.add('backgroundClass').input('mdiColor')
  .title('Form background color').col(4);

s.add('headerClass').input('mdiColor')
  .title('Header color').col(4);

s.add('rounded').as( bl.clone('number') ).col(3).title('Rounded corners');
s.add('height').as( bl.clone('number') ).col(3).title('Widget height');
s.add('icon').input('icon').col(3).title('Widget Icon');

s.add('hintIconText').input('text')
.title('Hint Icon')
.desc('Hint Icon to show on hover')
.flag('isText')
.col(3);

s.add('title').input('vtext').title('Title').col(6);
s.add('#titleslot').input('vtext').title('Title Slot').col(6).props({ clearable: true });

 
s.add('#default').input('vtextarea').props({ clearable: true, rows: 12 } as any)
  .flag('isSlot').flag('isText')
  .title('Default content').col(12);

s.add('#icon').input('vtextarea').title('Prepend default (icon)').col(6)
 
.props({ clearable: true, rows: 2 } as any)
  .desc('Left side of the card (overrides icon). Card content: [icon, default, postfix]');

s.add('#postfix').input('vtextarea').title('Append default (postfix)').col(6)
 
.props({ clearable: true, rows: 2 } as any)
.desc('Right side of the card. Card content: [icon, default, postfix]');

s.add('#center').input('vtextarea').title('Center content').col(12)
  .props({ clearable: true })
  .desc('Content aligned by center, default, icon and postfix are ignored if this is set.');

function text() {
  return `
<b>Widget Card</b>
Used as display wrap for different custom widgets computed using ui-aux.
`;
}
