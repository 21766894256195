<template>
  <div>
    <v-menu
      v-model="menuDate"
      v-bind="$attrs"
      :closeOnContentClick="false"
      transition="scale-transition"
      minWidth="auto"
    >
      <template #activator="{ props }">
        <v-text-field
          v-bind="{ ... componentProps, ...props }"
          :modelValue="formattedDateValue"
          prependInnerIcon="mdi-calendar"
          readonly
          clearable
          autocomplete="off"
          @click:clear="onUpdatedValue(undefined)"
        />
      </template>

      <!-- :locale="$i18n.locale" -->
      <v-date-picker
        v-bind="componentProps"
        v-model="myModelValue"
        :title="$t('inputs.date_select')"
        :header="$t('inputs.date_select')"

        :inputText="$t('inputs.date_enter')"
        :cancelText="$t('dialogs.cancel')"

        @click:cancel="menuDate = false"

        @update:model-value="
          onUpdatedValue($event);
          menuDate = false;
        "
      />
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const componentProps = defineProps<{
  modelValue: string | undefined;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | undefined): void;
}>();

const { locale } = useI18n();

const menuDate = ref(false);

const formattedDateValue = computed(() => {
  const formatted = componentProps.modelValue
    ? new Date(componentProps.modelValue).toLocaleDateString(locale.value)
    : null;
  return formatted;
});

 
const myModelValue = computed<any>({
  get: () => {
    const date = componentProps.modelValue ? new Date(componentProps.modelValue) : undefined;
    return date && !isNaN(date.getTime()) ? date : undefined;
  },

   
  set: (v: any) => v ? new Date(v).toISOString() : undefined,
});


function dateToISO(v: Date) {
  return v && v instanceof Date ? v.toISOString() : undefined;
}

 
function onUpdatedValue(value: any) {
  const outValue = value ? dateToISO(value) : undefined;
  emit('update:modelValue', outValue);
}

</script>
