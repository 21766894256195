 
import { reactive, ref, nextTick, UnwrapRef, computed, markRaw, watch } from "vue";
import { DFModelValue, DFRootFormProps, DFormAsInputProps, DFormMethods, DFormProps, InputProps, InputsRenderListItem } from "../models/dform-input-core";
import { DFWatchEntry, DeepFormSchemaItem } from "../models/dform-schema";
import { stringifyAny, deepClone } from "@/utils/utils";
import debounce from "lodash.debounce";

export type DFFormValue = Record<string, DFModelValue> | Array<DFModelValue>;
const dfDebug = 0;

export function useDeepForm<
  T extends DFFormValue,
   
  >(initialFormValue: T, inputs: Array<DeepFormSchemaItem<any, any>>, rootFormProps?: DFRootFormProps) {

  const watchEntries: (DFWatchEntry<T> & { key: keyof T })[] = [];
  const watchValuesState: Record<keyof T, DFModelValue> = {} as Record<keyof T, DFModelValue>;
  // todo later remove value to string and borrow stringifyAny function (or JSON.stringify)

  const formRef = ref<HTMLFormElement | null>(null);
  const formPath = ref<string | undefined>(undefined);

  const forcedInvalid = ref(false);

   
  const formMethods: DFormMethods<T> = {
    init,
    clearErrors,
    reset,
    validate,
    cloneInput,
    addInput,
    removeInput,
    switchValues,
    forceInvalid: (forced: boolean) => forcedInvalid.value = forced,
    getInput,
    getInputs,
  };

  const formValue = reactive(initialFormValue);

  const formProps = reactive({
    ...rootFormProps || {},
    dfDebug,
    modelValue: deepClone(initialFormValue), // break reactivity here by design
    loading: false,
    methods: formMethods,
    inputsRenderList: [] as InputsRenderListItem<T>[], // markRaw causes Dlist not to work?
    'onUpdate:modelValue': function ($event: T) {
      // console.log('form onUpdate:modelValue', $event);
      Object.assign(formValue, $event);
    },
  } as DFormAsInputProps<T>);

  function updateValue(newValue: T) {
    const nv = deepClone(newValue);
    Object.assign(formValue, nv);
    formProps.modelValue = deepClone(nv);
  }

  const isFormValid = computed(() => {
    // if (formProps.errorMessages && formProps.errorMessages?.length > 0) return false;
    if (forcedInvalid.value) return false;
    if (formRef.value) return formRef.value.isValid;
    return false;
  });

  const rootFormContext = computed(() =>
    rootFormProps?.dfContext || formProps.modelValue,
  );

  // TODO: [slow] rewrite this using watchEntries first approach;
  // do not stringyfy if no one is watching!

  watch([() => rootFormContext, () => watchEntries], debounce(() => {

    if (watchEntries.length === 0) return;

    if (dfDebug > 1) console.log('form watch debounce:', formPath.value);
    const firedUpdates: Array<{ key: keyof T, triggerTarget: DFWatchEntry<T>['triggerTarget'] }> = [];

    (Object.entries(rootFormContext.value) as [keyof T, DFModelValue]).forEach(([key, value]: [keyof T, DFModelValue]) => {
      const sValue = stringifyAny(value);
      if (watchValuesState[key] === sValue) return;
      // console.log('main watch changed key:', key, 'value:');
      watchValuesState[key] = sValue;

      watchEntries.forEach((entry) => {
        if (entry.watchKeys.includes(key)) {
          const target = entry.triggerTarget;
          const wasFired = firedUpdates.find((item) => item.key === entry.key && item.triggerTarget === target);
          if (wasFired) return;
          firedUpdates.push({ key: entry.key, triggerTarget: target });
          fireReaction(entry.key, target);
        }

      });

    });

  }, 500, { leading: false, trailing: true }),
    { deep: true, immediate: true },
  );

  for (const dfschema of inputs) {
    addInput(dfschema);
  }


  function fireReaction(key: keyof T, triggerTarget: DFWatchEntry<T>['triggerTarget']) {

    // console.log('fire update:', key, 'target=', triggerTarget);

    const input = formProps.inputsRenderList.find((item) => item.schema.alias === key);
    if (!input) throw new Error(`Input with alias ${String(key)} not found`);

    if (triggerTarget === 'convert') {
      const convertFn = input.schema.convert;
      if (!convertFn) throw new Error(`convert function is not defined in ${String(key)}`);
      const cValue = convertFn(
        input.componentProps.modelValue as T[keyof T],
        formProps.modelValue as T,
        rootFormContext.value,
      );
      input.componentProps.modelValue = cValue;
      formProps.modelValue[key as keyof UnwrapRef<T>] = cValue;
    }

    if (triggerTarget === 'data') {
      const dataAsyncFn = input.schema.dataAsync;
      if (dataAsyncFn) {
        input.componentProps.loading = true;
        dataAsyncFn(
          input.componentProps.modelValue as T[keyof T],
          formProps.modelValue as T,
          rootFormContext.value,
        ).then((data) => {
          input.componentProps.dfData = markRaw(data);
          input.componentProps.loading = false;
        });
      } else if (input.schema.data && typeof input.schema.data === 'function') {
        input.componentProps.dfData = markRaw(input.schema.data(
          input.componentProps.modelValue as T[keyof T],
          formProps.modelValue,
          rootFormContext.value,
        ));
      }

    }

    if (triggerTarget === 'control') {
      if (input.schema.formController) {
        input.schema.formController(
          formMethods,
          formProps.modelValue as T,
          rootFormContext.value,
        );
      }
    }

  }

  // exported functions

  function getInputs() {
    return (formProps.inputsRenderList as InputsRenderListItem<T>[])
      .map((item) => item.schema);
  }

  function getInput(alias: string) {
    const found = getInputs().find((item) => item.alias === alias);
    return found;
  }

   
  function switchValues(alias1: string, alias2: string) {
    const index1 = formProps.inputsRenderList.findIndex((item) => item.schema.alias === alias1);
    const index2 = formProps.inputsRenderList.findIndex((item) => item.schema.alias === alias2);
    if (index1 >= 0 && index2 >= 0) {
      const tmpMV = formProps.inputsRenderList[index1].componentProps.modelValue;
      formProps.inputsRenderList[index1].componentProps.modelValue = deepClone(formProps.inputsRenderList[index2].componentProps.modelValue);
      formProps.inputsRenderList[index2].componentProps.modelValue = deepClone(tmpMV);
      const tmpFormMV = formProps.modelValue[alias1 as keyof UnwrapRef<T>];
      formProps.modelValue[alias1 as keyof UnwrapRef<T>] = deepClone(formProps.modelValue[alias2 as keyof UnwrapRef<T>]);
      formProps.modelValue[alias2 as keyof UnwrapRef<T>] = deepClone(tmpFormMV);
    }

  }

  function init(newFormRef: HTMLFormElement, propsAsInput: InputProps<T, unknown>) {
    formRef.value = newFormRef;
    formPath.value = propsAsInput.dfPath || 'root';
    // console.log('deep_form_initialized:', propsAsInput);
  }

  function clearErrors() {
    // formProps.errorMessages = [];
  }

  function validate() {
    formRef.value?.validate();
    // TODO: add validation force for all kid inputs if they are forms
  }

   
  function cloneInput(alias: string, dfschema: DeepFormSchemaItem<T[keyof T], any>, value: T[keyof T]) {
    const item = { ...dfschema };
    item.alias = alias;
    addInput(item);
    const added = formProps.inputsRenderList.find((item) => item.schema.alias === alias);
    if (!added) throw new Error(`Input with alias ${alias} was not added`);
    (added.componentProps.modelValue as T[keyof T]) = dfschema.pre ? dfschema.pre(value) : value;
     
    formProps.modelValue[alias as unknown as keyof UnwrapRef<T>] = value as any;
  }

   
  function addInput(dfschema: DeepFormSchemaItem<any, any>) {
    if (!dfschema.inputAlias) throw new Error('inputAlias is required');

    const found = formProps.inputsRenderList.find((item) => item.schema.alias === dfschema.alias);
    if (found) throw new Error(`Input with alias ${dfschema.alias} already exists`);

    const component = markRaw(dfschema?.input?.component as object);
    if (!component) throw new Error('component is required');

    const key = dfschema.alias as unknown as keyof T;

    const initialValue = initialValueForInput();

    const inputProps = reactive({
      ...dfschema.inputProps || {},
      modelValue: initialValue,
      dfCustomFlags: dfschema.customFlags,
      dfData: initialDataForInput(),
      dfTitle: dfschema.title,
      dfDescription: dfschema.description,
      dfAlias: dfschema.alias,
      dfContext: formProps.modelValue as T | undefined,
      dfRootFormProps: rootFormProps || { dfContext: formProps.modelValue },
      dfOnUpdate,
    } as InputProps<T[keyof T], unknown, unknown, unknown>);

    const input = {
      component,
      componentProps: inputProps,
      schema: dfschema,
    } as InputsRenderListItem<T>;

    runAsyncDataUpdate();

    function initialDataForInput() {
      if (dfschema.data === undefined || dfschema.data === null) return dfschema.data;
      if (typeof dfschema.data === 'object')
        return markRaw(dfschema.data);

      if (typeof dfschema.data === 'function') {
        return markRaw(dfschema.data(initialValue, formProps.modelValue, rootFormContext.value));
      }
      return dfschema.data;
    }

    function initialValueForInput() {

      if (initialFormValue === null || initialFormValue === undefined) return undefined;

      if (typeof initialFormValue !== 'object' && !Array.isArray(initialFormValue)) {
        throw new Error('form value must be an object or array');
      }

      let inputValue = initialFormValue[key];

      if (inputValue === undefined) {
        const initFn = dfschema.initialValue;
        if (initFn) {
          inputValue = initFn(
            formProps.modelValue,
            rootFormContext.value,
          );
        }
      }

      return dfschema.pre ? dfschema.pre(inputValue) : inputValue;
    }

    function runAsyncDataUpdate() {
      if (!dfschema.dataAsync) return;
      inputProps.loading = true;
      dfschema.dataAsync(
        input.componentProps.modelValue as T[keyof T],
        formProps.modelValue,
        rootFormContext.value,
      ).then((data) => {
        inputProps.dfData = markRaw(data);
        inputProps.loading = false;
      });
    }

    // Input has updated its value:
    //
     
    function dfOnUpdate(value: any) {

      if (dfDebug > 0) console.log('input-emit:', key, '=', stringifyAny(value));

      let cValue = value;
      let pValue = value;

      // why check for undefined? test again and comment
      if (dfschema.convert && value !== undefined) {
        cValue = dfschema.convert(
          value,
          formProps.modelValue,
          rootFormContext.value,
        );
      }

      if (dfschema.pre) {
        pValue = dfschema.pre(cValue);
      }

      // if (key === 'foo') {
      //   console.log('foo disable inputProps.modelValue update');
      // } else
      inputProps.modelValue = pValue;
      // input gets value processed with pre()

      // if (key === 'foo') {
      //   console.log('foo disable formProps.modelValue update');
      // } else
      formProps.modelValue[key as keyof UnwrapRef<T>] = cValue;
      // form gets value processed with convert()
    }

     
    formProps.inputsRenderList.push(input as any);

    if (dfschema.watchers && dfschema.watchers.length > 0) {
      (dfschema.watchers as unknown as DFWatchEntry<T>[]).forEach((item) => {
        watchEntries.push({ ...item, key });
      });
    }

  }

  function removeInput(alias: string, withValue = true) {
    const index = formProps.inputsRenderList.findIndex((item) => item.schema.alias === alias);
    if (index < 0) throw new Error(`Input with alias ${alias} not found`);

    const watchEntriesToRemove = watchEntries.filter((item) => item.key === alias);
    watchEntriesToRemove.forEach((item) => {
      const index = watchEntries.indexOf(item);
      if (index >= 0) watchEntries.splice(index, 1);
    });

    if (Array.isArray(formProps.modelValue)) {

      for (let i = index; i < formProps.modelValue.length - 1; i++) {
        formProps.modelValue[i] = formProps.modelValue[i + 1];
        formProps.inputsRenderList[i].componentProps.modelValue = formProps.inputsRenderList[i + 1].componentProps.modelValue;
      }

      formProps.modelValue.pop();
      formProps.inputsRenderList.pop();

    } else {
      formProps.inputsRenderList.splice(index, 1);
      if (withValue)
        delete formProps.modelValue[alias as keyof UnwrapRef<T>];
    }
  }

  function reset() {
    nextTick(() => {
      formRef.value?.resetValidation();
    });
  }

  return {
    valid: isFormValid,
    props: formProps,
    formValue,
    updateValue, // TODO: test this
  };
}
