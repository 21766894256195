import { ref } from 'vue';

import { auth } from '@ui-api3-sdk/api';
import { useAccountStore } from '@/pinia/useAccountStore';
import { AwaitableRefs } from './composables-interface';
import { useToast } from './useToast';

export const useAccountId = (accountId?: number): AwaitableRefs<{ aId: number | undefined }> => {

  const aId = ref<number | undefined>(undefined);
  const isLoading = ref(true);
  const isError = ref(false);

  const accountStore = useAccountStore();

  aId.value = accountId || auth.accountId();

  if ( ! aId.value) {
    isLoading.value = true;

    accountStore.getAccountAsync().then( (currentAccount) => {
      aId.value = currentAccount?.id || undefined;

      if ( ! aId.value && auth.accessTokenExists()) {
        useToast().error('No network account id found in token. Please login as a network user.');
        auth.logout(true);
      }

      isLoading.value = false;
    });

  } else {
    isLoading.value = false;
  }

  return { aId, isLoading, isError };
};
