<template>
  <div v-if="!!items.length" data-component="period-scroll-list">
    <v-btn
      variant="text"
      size="x-small"
      class="mr-2"
      icon="mdi-arrow-left"
      :disabled="! selectedPeriod || selectedPeriod.index === items.length - 1"
      @click="stepBack"
    />
    <span class="text-caption">
      {{ selectedPeriod?.label || '...' }}
    </span>
    <v-btn
      variant="text"
      size="x-small"
      class="ml-2"
      icon="mdi-arrow-right"
      :disabled="! selectedPeriod || selectedPeriod.index === 0"
      @click="stepForward"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { useAwaitAccountId, useLocalization } from '@/composables';
import { removeDuplicatesById } from '@/utils/utils';
import { PeriodVM, useAccountApi, usePeriodApi } from '@ui-api3-sdk/api/api3';
import { PeriodSelectItem } from './PeriodSelect';


const props = defineProps<{
  modelValue?: PeriodSelectItem,
  forCurrentAccount?: boolean;
}>();

const { d } = useLocalization();

const emit = defineEmits<{
  (event: 'update:modelValue', result: PeriodSelectItem): void,
  (event: 'loaded', result: PeriodSelectItem[]): void,
}>();

const loading = ref(false);
const items = ref<Array<PeriodSelectItem>>([]);
const totalItems = ref(0);
const selectedPeriod = ref<PeriodSelectItem | undefined>(undefined);

watch( () => props.modelValue, (newValue) => {
  selectedPeriod.value = newValue;
});

function parsePeriods(list: Array<PeriodVM>) {
  return removeDuplicatesById(list).map( (p, index) => ({
    ...p,
    label: periodName(p),
    index,
  }));
  function periodName(p: PeriodVM){
    const start = d(new Date(p.start_date), 'short');
    return `${start}`;
  }
}

function stepBack() {
  if (! selectedPeriod.value || ! items.value.length ) return;
  if (selectedPeriod.value.index === items.value.length - 1) return;
  selectedPeriod.value = items.value[selectedPeriod.value.index + 1];
  emit('update:modelValue', selectedPeriod.value);
}

function stepForward() {
  if (! selectedPeriod.value || ! items.value.length ) return;
  if (selectedPeriod.value.index === 0) return;
  selectedPeriod.value = items.value[selectedPeriod.value.index - 1];
  emit('update:modelValue', selectedPeriod.value);
}

onMounted( async () => {

  loading.value = true;

  const accountId = props.forCurrentAccount ? await useAwaitAccountId() : undefined;

  const func = accountId !== undefined ?
    useAccountApi().getAccountPeriods({
      id: accountId,
      page: 0,
      limit: 100,
    })
    : usePeriodApi().getPeriods({
      page: 0,
      limit: 100,
    });

  func
    .then((res) => {
      items.value = parsePeriods(res.data.payload.list);
      totalItems.value = res.data.payload.total;

      if (selectedPeriod.value === undefined) {
        selectedPeriod.value = items.value[0];
      }

      emit('loaded', items.value);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });

});

</script>
