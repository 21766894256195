<!-- eslint-disable @typescript-eslint/no-explicit-any -->

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
const cProps = defineProps<{
  modelValue: number | string | undefined;
  values: string[];
  titles?: Record<string, string>;
}>();

const emit = defineEmits<{
  ( event: 'update:modelValue', value: number | string | undefined ): void;
  ( event: 'update:object', value: any): void;
}>();

if (! cProps.values) {
  throw new Error('SelectEnum: values prop is required');
}

const localMv = ref<number | string | undefined>(cProps.modelValue);

function onUpdate(value: number | string | undefined) {
  emit('update:modelValue', value);

}

watch(() => cProps.modelValue, (nv) => {
  localMv.value = nv;
});

const itemsPretty = computed(() => cProps.values.map((id) => ({
  id,
  title: cProps.titles ? cProps.titles[id] : id,
})));

</script>

<template>
  <v-autocomplete
    v-model="localMv"
    :items="itemsPretty"

    itemTitle="title"
    itemValue="id"

    v-bind="$attrs"
    @update:model-value="onUpdate"
  />
</template>
