import { InputDictionaryItem } from "@/deep-form/components/InputDictionary";
import { usePropertiesStore } from "@/pinia/usePropertiesStore";
import { PROPERTIES, PropertyPrefix } from "@/utils/properties-mapping-service";

type NoStringDictionaryItem = Exclude<InputDictionaryItem, string>;

type QueryItem = {
  prefix: PropertyPrefix;
  addNamePrefix?: string;
  addPropertyPrefix?: string;
  removePrefix?: boolean;
}

export async function queryPropertiesList(query: QueryItem[]) {
  const res: Array<NoStringDictionaryItem> = [];

  const store = usePropertiesStore();
  const pf = await store.getPfAsync();
  const pp = await store.getPpAsync();

  query.forEach((q) => {
    if (q.prefix === 'p') {
      pf.forEach(p => add(`p.${p.alias}`, p.title, q));
    }
    if (q.prefix === 'm') {
      pp.forEach(p => add(`m.${p.alias}`, p.title, q));
    } else {
      PROPERTIES.forEach(p => {
        if (p.includes('*')) return;
        const type = usePropertiesStore().getPropertyType(p);
        if (p.startsWith(q.prefix)) add(p, type.title, q);
      });
    }

  });

  function add(property: string, propTitle: string, q: QueryItem) {
    const title = q.addNamePrefix ? `${q.addNamePrefix}: ${propTitle}` : propTitle;
    const propRmp = q.removePrefix ? property.split('.').pop() : property;
    const value = q.addPropertyPrefix ? `${q.addPropertyPrefix}${propRmp}` : propRmp;
    res.push({
      title,
      value,
    });
  }

  return res;

}

export async function getPropertiesList(filter?: string[], filterPrefix?: string, cutPrefix?: boolean) {

  const res: Array<NoStringDictionaryItem> = [];
  const store = usePropertiesStore();

  const pf = await store.getPfAsync();
  pf.forEach(p => {
    add(p, 'Profile', 'p');
  });

  const pp = await store.getPpAsync();
  pp.forEach(p => add(p, 'Marketing', 'm'));

  // addStatic('parent-profile');
  addStatic('s', 'Account');
  addStatic('t', 'Template');
  addStatic('vmdl', 'Position');
  addStatic('vmlb', 'Leaderboard');
  addStatic('position', 'Position');
  addStatic('r', 'Replace');
  addStatic('parent', 'Parent');

  pf.forEach(p => {
    add(p, 'Parent profile', 'parent-profile');
  });

  function notFiltered(alias: string) {

    const listFilter = (!filter || filter.length === 0) ? undefined : filter;
    const prefixFilter = (!filterPrefix || filterPrefix.length === 0) ? undefined : filterPrefix;

    if (listFilter) {
      if (!listFilter.includes(alias)) return false;
    }

    if (prefixFilter) {
      if (!alias.startsWith(prefixFilter)) return false;
    }

    return true;
  }

  function add(item: { title: string, alias: string }, prefixName?: string, prefixAlias?: string) {
    const aliasWithPrefix = prefixAlias ? `${prefixAlias}.${item.alias}` : item.alias;

    if (notFiltered(aliasWithPrefix))
      res.push({
        title: prefixName ? `${prefixName}: ${item.title}` : item.title,
        value: cutPrefix ? item.alias : aliasWithPrefix,
      });
  }

  function addStatic(prefix: string, prefixName: string) {
    PROPERTIES.forEach(p => {
      const type = usePropertiesStore().getPropertyType(p);
      if (p.startsWith(prefix)) add({ title: type.title, alias: p }, prefixName);
    });
  }

  return res;
}
