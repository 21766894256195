

// it is input schema for grav wrapper with its specific common inputs

import { DFSchemaBuilderItem, useDeepSchemaBuilder } from "@/deep-form/composables/useDeepSchemaBuilder";
import { DFFormValue, useDeepForm } from '@/deep-form/composables/useDeepForm';

import * as src from './lib/sources';
import * as basicConverters from "@/deep-form/lib/converters";
import * as basicValidators from "@/deep-form/lib/validators";
import * as h from "./lib/helpers";

import InputFilter from "./inputs/InputFilter.vue";
import { FilterItem } from '@ui-api3-sdk/api/api3';

import { InputDRecordArrayItem } from "@/deep-form/components/InputDRecrod";
import { DFBasicInputs } from "@/deep-form/lib/input-components";
import { getLibrary } from "@/deep-form/lib/library";
import { GravComponentPropertyDTO } from "@/grav/grav-api-models";
import { DeepFormSchemaItem } from "@/deep-form/models/dform-schema";
import { InputDictionaryItem } from "@/deep-form/components/InputDictionary";
import { CONDITION_OPERATORS, ColorRule, ColorRules, ColorSchemeIn } from "@/utils/conditional-colors";

import InputMdiColor from './inputs/InputMdiColor.vue';
import { inputMdiColorDefaults } from './inputs/InputMdiColorData';

import InputDate from "./inputs/InputDate.vue";
import InputMdiIcon from "./inputs/InputMdiIcon.vue";
import { WalletCountries } from "@/components/ui/WalletBalance";
import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";
import { ConfirmationMode } from "@/components/ui/SignupRegistration";

export type GravInputFlags = Omit<GravComponentPropertyDTO, 'name' | 'value'>;


export type ComponentFormFile<TInt extends DFFormValue, TRes extends DFFormValue> = {
  createForm: (props: TRes) => {
    form: ReturnType<typeof useDeepForm<TInt>>;
    getValue: () => TRes,
  },
  inputs: () => DeepFormSchemaItem<unknown, typeof AllInputs>[];
  defaults: Record<string, unknown>;
  info: { title: string; text: string, link?: string };
};


const DFGravInputs = {
  filters: {
    component: InputFilter,
    data: [] as Array<string>,
    value: [] as FilterItem[],
  },
  icon: {
    component: InputMdiIcon,
    value: 'mdi-alien' as string,
    data: null,
  },
  mdiColor: {
    component: InputMdiColor,
    value: '',
    data: inputMdiColorDefaults,
  },
  date: {
    component: InputDate,
    value: null as string | null,
    data: null,
  },
};

const AllInputs = { ...DFBasicInputs, ...DFGravInputs };

const c = { ...basicConverters };
const v = { ...basicValidators };

const l = createSchema<Library>();
const bl = getLibrary();

export function gravComponentSchema<TRes extends DFFormValue, TInt extends DFFormValue = TRes>(
  info: ComponentFormFile<TInt, TRes>['info'],
  defaults: ComponentFormFile<TInt, TRes>['defaults'],
  preProcessFn?: (originalValue: TRes) => TInt,
  postProcessFn?: (val: TInt) => TRes,
) {

  const s = createSchema<TInt>();

  const inputs = () => s.items() as DeepFormSchemaItem<any, typeof AllInputs>[];

  const createForm = (props: TRes) => {

    const initialValue = preProcessFn ? preProcessFn(props) : props as unknown as TInt;
    const newForm = useDeepForm(initialValue, s.items());
    const getValue = () => postProcessFn ? postProcessFn(newForm.formValue as TInt) : newForm.formValue as TRes;

    return {
      form: newForm,
      getValue,
    };
  };

  const toExport: ComponentFormFile<TInt, TRes> = {
    createForm,
    inputs,
    defaults,
    info,
  };

  return {
    s,
    l,
    bl,
    c,
    v,
    src,
    h,
    toExport,
    createSchema,
  };
}

function createSchema<T>() {
  return useDeepSchemaBuilder<T, typeof AllInputs, GravInputFlags>(AllInputs, {});
}

type Library = {
  property: string,
  propertyMarketingNoPrefix: string,
  propertyMarketing: string,

  properties: string[],
  titles: Record<string, string>,
  selectTree: number,
  propTitleItem: InputDRecordArrayItem,
  propTitles: Record<string, string>,
  translatedText: string;
  itemLeft: unknown;
  itemRight: unknown;

  popupInfoOnField: string;
  popupLinkUrl: string;
  autoSwitchMode: boolean;
  popupProperties: string[];
  headerTitles: Record<string, string>;
  mandatoryProperties: string[];

  // color schema
  colorSchemaItem: ColorSchemeIn;
  simpleColorRules: ColorRules;
  fullColorRules: ColorRules;
  colorRuleForm: ColorRule;

  numberAsBoolean: number;

  countryId: number;
  walletId: number;
  walletCountries: WalletCountries;

  otpConfirm: Record<ConfirmableFieldAlias, ConfirmationMode>;

  profilePropertyForRegistration: string;
  profileProperty: string;
}

l.add('profilePropertyForRegistration')
  .input('dictionary').dataAsync(src.propertiesProfileNoPrefixForRegistration)
  .init(() => '');

l.add('profileProperty')
  .input('dictionary').dataAsync(src.propertiesProfile)
  .init(() => '');

l.add('numberAsBoolean')
  .pre((v) => !isNaN(Number(v)) ? !!Number(v) : false)
  .input('boolean')
  .convert((v) => v ? 1 : 0);

l.add('property').input('dictionary').dataAsync(src.properties)
  .init(() => '');

l.add('propertyMarketingNoPrefix').input('dictionary').dataAsync(src.propertiesMarketingNoPrefix)
  .init(() => '');

l.add('propertyMarketing').input('dictionary').dataAsync(src.propertiesMarketing)
  .init(() => '');

l.add('properties').input('dlist')
  .data(l.clone('property').props({ hideDetails: true }).mandatory().item());

l.add('titles').input('drecord').data(bl.clone('recordItem').item());

l.add('selectTree').input('dictionary').dataAsync(src.trees).title('Tree to show');

l.add('translatedText').input('vtext').flag('isText');

l.add('itemLeft').props({ dfCol: 6, hideDetails: true, class: 'mr-md-4' }).mandatory();
l.add('itemRight').props({ dfCol: 6, hideDetails: true }).mandatory();

l.add('propTitles').as(
  createTranslationsInput('propTitleItem', l.clone('property')),
);

// TODO: check reactivity of this
// popup buttons
l.add('popupInfoOnField').as(l.clone('property'))
  .input('dictionary').dataAsync(
    async (value, ctx?) => {
      const res: InputDictionaryItem[] = [];
      ctx?.properties?.forEach((p, i) => {
        res.push({ title: `Column ${i.toString()}`, value: `${i}` });
      });
      const props = await h.getPropertiesList(ctx?.properties);
      return [...res, ...props];
    },
  )
  .watch(['properties'], 'data')
  .flag('isDynamic', false)
  .title('Column to bind action icons to');

l.add('popupLinkUrl').input('text').title('View on tree link url')
  .desc('Link template for View-on-visual-tree action icon');

l.add('autoSwitchMode')
  .input('boolean')
  .title('Allow auto switch')
  .desc('Switch to panel mode on mobile devices');

l.add('popupProperties').as(l.clone('properties')).title('Properties for additional info');
l.add('headerTitles').as(l.clone('propTitles')).title('Custom property translations');

l.add('mandatoryProperties').as(l.clone('properties'))
  .mandatory()
  .validators([v.minItems(1)])
  .title('Properties in table');


l.add('colorSchemaItem').input('color');

const simpleColorInputs = [
  l.spawn<string>('self').as(l.clone('colorSchemaItem')).title('Self color')
    .props({ class: 'mr-md-4' }).col(4).item(),
  l.spawn<string>('front').as(l.clone('colorSchemaItem')).title('Frontline color')
    .props({ class: 'mr-md-4' }).col(4).item(),
  l.spawn<string>('deadend').as(l.clone('colorSchemaItem')).title('Deadend color')
    .col(4).item(),
];

l.add('simpleColorRules').input('dform').data(simpleColorInputs)
  .title('Conditional colors').init(() => ({}));

l.add('colorRuleForm').as(
  createInputColorRule('colorRuleForm', l.clone('property')),
);

l.add('fullColorRules').input('dform').data([
  ...simpleColorInputs,
  l.spawn<Array<ColorRule>>('fields').input('dlist').data(
    l.clone('colorRuleForm').item(),
  ).item(),
]).title('Conditional colors').init(() => ({}));

/* wallet */

l.add('countryId').input('dictionary').dataAsync(src.countries);

l.add('walletId').input('dictionary').dataAsync(src.wallets);

const walletCountryEntry = l.spawn<[string, string]>('walletCoutryItem')
  .pre((v) => Array.isArray(v) ? { countryId: v[0], walletId: v[1] } : v)
  .input('dform').data([
    l.spawn<string>('countryId').as(l.clone('countryId'))
      .title('Country')
      .mandatory()
      .init(() => '')
      .props({ class: 'mr-md-4', hideDetails: true })
      .col(3).item(),
    l.spawn<string>('walletId').as(l.clone('walletId'))
      .title('Wallet Id')
      .mandatory()
      .props({ hideDetails: true })
      .col(9).item(),
  ])
  .convert((v) => {
    if (v.walletId) {
      return [v.countryId, v.walletId];
    }
  })
  .init(() => (['', '']));

l.add('walletCountries').input('dlist').data(
  walletCountryEntry.item(),
);

const confirmInput = l.spawn<boolean | undefined>().input('dictionary').data([
  { title: 'Confirmation is mandatory', value: true },
  { title: 'Skip confirmation entirely', value: false },
  { title: 'Confirmation is optional', value: 'optional' },
])
  .props({ dfShowTitle: true });

l.add('otpConfirm').input('dform').data([
  l.spawn<ConfirmationMode>('email').asMany([l.clone('itemLeft'), confirmInput])
    .title('Email').mandatory().item(),
  l.spawn<ConfirmationMode>('phone').asMany([l.clone('itemLeft'), confirmInput])
    .title('Phone').mandatory().item(),
])
  .title('OTP Confirmation');


export function createInputColorRule(name: string, propertyInput: DFSchemaBuilderItem<any, any, any, any, any, any>) {
  return l.spawn(name)
    .pre((v) => Array.isArray(v)
      ? {
        field: v[0],
        condition: v[1],
        value: v[2],
        schemaItem: v[3],
      }
      : v)
    .input('dform').data([
      l.spawn<string>('field').as(propertyInput).mandatory()
        .props({ class: 'mr-md-4', hideDetails: true }).col(4).item(),
      l.spawn<string>('condition').input('dictionary')

        .data(CONDITION_OPERATORS as any).mandatory()
        .props({ class: 'mr-md-4', hideDetails: true }).col(2).item(),
      l.spawn<string>('value').input('text').mandatory()
        .props({ class: 'mr-md-4', hideDetails: true }).col(3).item(),
      l.spawn<string>('schemaItem').as(l.clone('colorSchemaItem')).mandatory()
        .props({ hideDetails: true })
        .col(3).item(),
    ])
    .convert((v) => [v.field, v.condition, v.value, v.schemaItem])
    .init(() => (['', '=', '', '']));

}


export function createTranslationsInput(name: string, keyInput: DFSchemaBuilderItem<any, any, any, any, any, any>) {
  const item = l.spawn('translate-item').input('dform').data([
    l.spawn<string>('key').asMany([l.clone('itemLeft'), keyInput.clone()]).item(),
    l.spawn<string>('value').asMany([l.clone('itemRight'), l.clone('translatedText')]).item(),
  ])
    .init(() => ({ key: '', value: '' }));

  return l.spawn(name).input('drecord').data(item.item());

}
