import { ref, computed } from 'vue';

export const useInputValidator = () => {
  const state = ref<{ [key: string]: boolean }>({});

  function onValid(name: string, value: boolean) {
    // console.log('onValid', name, '->', value);
    state.value[name] = value;
  }

  const isValid = computed(() => Object.values(state.value).every((value) => value));

  return {
    onValid,
    isValid,
  };

};
