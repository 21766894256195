import { Callback } from "@/utils/callback-service";
import { ColorRule } from "@/utils/conditional-colors";

export interface BadgeEntry {
  badgeUrl: string;
  title: string;
  rules: ColorRule[];
}

export interface BadgeBoxProps {
  id?: string,
  badges?: Array<BadgeEntry>,

  treeId?: number;
  onlyAccount?: boolean;

  badgeUrlTemplate?: string,
  callbackBadgeUrlTemplate?: string, // deprecated
  callback?: string | Callback<Array<string>>;

  fromArrayField?: string,

  title?: string,
  noBadgesText?: string | null,
  singleLine?: boolean,
  backgroundClass?: string,
  headerClass?: string,
  badgeWidth?: number;
  badgeHeight?: number;

  center?: boolean;
}

export const defaults = {
  badgeUrlTemplate: '{{badgeId}}',

  badgeWidth: 50,
  badgeHeight: 50,

  center: true,
};
