/* eslint-disable camelcase */
export default {
  operation: "Операция",
  date: "Дата",
  amount: "Сумма",
  operation_history_for: "История операций для {n1}",
  loading: 'загружается...',

  op_adjustment: "Коррекция",
  op_commission_payment: "Комиссионный платеж",
  op_payout: "Вывод средств",
  op_account_to_account_transfer: "Перевод между аккаунтами",
  op_administrative_transfer: "Административный перевод",
  op_purchase_order: "Оформление заказа",
  op_undefined_operation: "Неизвестная операция",
};
