// todo: deal with this the proper way :)
const GOOGLE_API = 'AIzaSyD9Nddaj9sWRXbbxGGJIiCAvbqCBwoDxds';

import axios from 'axios';

export function useTranslator() {

    async function translateText(targetLanguage: string, text: string) {
      const apiUrl = 'https://translation.googleapis.com/language/translate/v2';
      let translation = '';

      try {
        const response = await axios.post(`${apiUrl}?key=${GOOGLE_API}`, {
          q: text,
          target: targetLanguage,
        });

        translation = response.data.data.translations[0].translatedText;

        // console.log(`Text: ${text}`);
        // console.log(`Translation: ${translation}`);

       
      } catch (error: any) {
        console.error('Error during translation:', error.message);
        return '';
      }

      return translation.replace(/&#39;/g, '\'');
    }


    async function translateArray(targetLanguage: string, text: string[]) {
      const apiUrl = 'https://translation.googleapis.com/language/translate/v2';
      let translations: string[] = [];

      try {
        const response = await axios.post(`${apiUrl}?key=${GOOGLE_API}`, {
          q: text,
          target: targetLanguage,
        });

        translations = (response.data.data.translations as { translatedText: string}[])
          .map(t => t.translatedText.replace(/&#39;/g, '\''));

       
      } catch (error: any) {
        console.error('Error during translation:', error.message);
        return [];
      }

      return translations;
    }


  return {
    translateText,
    translateArray,
  };

}
