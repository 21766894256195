<template>
  <template v-if="mode === 'change'">
    {{ item['vmtl.oldValue']?.presentable }} -> {{ item['vmtl.newValue']?.presentable }}
  </template>
  <template v-else-if="volumeChange && isCountable">
    <v-chip :color="volumeChange.color">{{ volumeChange.diff }}</v-chip>
  </template>
  <div v-else>-</div>
</template>

<script setup lang="ts">
import { TableGridItem } from '@/models/table-grid-interface';
import { computed } from 'vue';

const props = defineProps<{
  item: TableGridItem,
  mode: 'change' | 'diff',
}>();

const isCountable = computed(() => {
  const oldP = String(props.item?.['vmtl.oldValue']?.presentable)?.replace(/[^\d]+/g, '');
  const newP = String(props.item?.['vmtl.newValue']?.presentable)?.replace(/[^\d]+/g, '');

  if (! oldP || ! newP) {
    return false;
  }

  return true;

});

const volumeChange = computed(() => {
  const oldV = Number(props.item?.['vmtl.oldValue']?.raw);
  const newV = Number(props.item?.['vmtl.newValue']?.raw);

  if (isNaN(oldV) || isNaN(newV)) {
    return null;
  }

  const diff = newV - oldV;
  const plus = diff > 0 ? '+' : '';

  return {
    color: diff > 0 ? 'green' : 'red',
    diff: `${plus}${diff.toFixed(2)}`,
  };
});

</script>
