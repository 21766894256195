import { DFFormValue } from "@/deep-form/composables/useDeepForm";
import { ContentModifier, ContentModifierHandlerParams } from "../models/content-modifier";
import { useGravManager } from "../comosables/useGravManager";
import { useTranslator } from "../comosables/useTranslator";
import { GravBulkUpdatePropertiesRequest, GravComponentPropertyDTO } from "@/grav/grav-api-models";

async function handler({fileContents, dstFile, srcFile, dstLang} : ContentModifierHandlerParams) {

  // const readFile = await useGravManager().upcliShow(srcFile);
  // const pages = readFile?.pages;

  await useGravManager().upcliWriteFile(dstFile, fileContents);
  const pages = useGravManager().allPagesDTO;

  if (!pages) {
    throw new Error('Pages not found');
  }

  const srcPage = pages.find(p => p.filePath === srcFile);
  if (!srcPage) {
    throw new Error('Source page not found');
  }

  const index: Array<{ componentId: string, propName: string }> = [];
  const json: Array<string> = [];

  srcPage.page.forEach((component) => {

    component.properties.forEach((prop) => {
      if (!prop.isText || !prop.value) return;
      index.push({ componentId: component.id, propName: prop.name });
      json.push(prop.value);

    });
  });

  const translated = await useTranslator().translateArray(dstLang, json);

  const reduced = index.reduce((acc, { componentId, propName }, i) => {
    acc[componentId] = acc[componentId] || {};
    acc[componentId][propName] = translated[i];
    return acc;
  }, {} as Record<string, Record<string, string>>);

  const requests = [] as GravBulkUpdatePropertiesRequest[];

  Object.keys(reduced).forEach((componentId) => {

    const component = srcPage.page.find(p => p.id === componentId);
    if (!component) throw new Error(`Component ${componentId} not found`);

    const languages = {
      [dstLang]: [] as GravComponentPropertyDTO[],
    };

    for (const [key, value] of Object.entries(reduced[component.id])) {
      const originalProp = component.properties.find(p => p.name === key);
      if (!originalProp) throw new Error('Property not found');

      languages[dstLang].push({ ... originalProp, name: key, value });
    }

    const request: GravBulkUpdatePropertiesRequest = {
      componentId: component.id,
      languages,
    };

    requests.push(request);

  });

  // useGravManager().setEditFile(dstFile);

  for (let i = 0; i < requests.length; i++) {
    await useGravManager().upcliUpdateBulkProperties(requests[i], dstFile);
  }

  return undefined;
}


// async function handler(fileContents: string, params: DFFormValue, srcLang: string, dstLang: string) {

//   const match = /^(\s*---*?\n([\s\S]*?)\n---*\S*)(.*)$/sgi.exec(fileContents);

//   if (!match) {
//     return useTranslator().translateText(dstLang, fileContents);
//   }

//   const header = match[2];
//   const html = match[3];

//   let res = '';

//   const titleMatch = /title:\s*(.*)/m.exec(header);
//   const title = titleMatch ? titleMatch[1].replace("'", '') : '';

//   if (title) {
//     const translatedTitle = await useTranslator().translateText(dstLang, title);
//     const newHeader = header.replace(/title:\s*(.*)/m, `title: ${translatedTitle}`);
//     res += `---\n${newHeader}\n---\n`;
//   } else {
//     res += `---\n${header}\n---\n`;
//   }
//   if (html) {
//     const translatedHtml = await useTranslator().translateText(dstLang, html);
//     res += translatedHtml;
//   }

//   return res;
// }

export default {
  id: 'googleTranslate',
  title: 'Google Translate',
  handler,
  form: undefined,
} as ContentModifier<DFFormValue>;
