import { gravComponentSchema } from '../deep-form-grav';
import { CarouselWidgetProps, defaults } from '../../../../components/ui/CarouselWidget';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<CarouselWidgetProps>({
  title: 'Carousel Widget',
  link: 'https://www.notion.so/mlmsoft/CarouselWidget-2a5fa7eb38e844f99eb00d2deb7be07f',
  text: text(),
},
  defaults,
);

export default toExport;


const srcEntry = s.spawn<[string, string]>('entry')
  .title('Slide')
  .pre((v) => Array.isArray(v) ? { url: v[0], type: v[1] || 'image' } : v)
  .input('dform').data([
    s.spawn<string>('type').input('dictionary').data(['image', 'video'])
      .title('Slide type')
      .mandatory()
      .init(() => 'image')
      .col(2).item(),
    s.spawn<string>('url').input('vtext')
      .title('URL of the image or video')
      .mandatory()
      .col(10).item(),
  ])
  .convert((v) => {
    if (v.type) {
      return [v.url, v.type];
    }
  })
  .init(() => (['', 'image']));

s.add('srcs').input('dlist').data(
  srcEntry.item(),
);

s.add('autoplay').as(l.clone('numberAsBoolean')).title('Autoplay').col(3);
s.add('mute').as(l.clone('numberAsBoolean')).title('Mute').col(3);
s.add('hideDelimiters').input('boolean').title('Hide dots').col(3);
s.add('delimitersStyle').as(l.clone('numberAsBoolean')).title('Dots style').col(3);

s.add('showArrows').input('boolean').title('Show arrows').col(3);

s.add('videoWidth').input('vtext').title('Video width').col(3);
s.add('videoHeight').input('vtext').title('Video height').col(3);

s.add('interval').as(bl.clone('number'))
  .title('Interval between slides (ms)')
  .col(3);

s.add('height').as(bl.clone('number')).title('Height').col(3);
s.add('noCover').input('boolean').title('No cover').col(3);

function text() {
  return `
<b>Image Carousel Block</b>
This block can show banners carousel, or it can be replaced with anything else (for example: embedded YouTube video).
`;
}
