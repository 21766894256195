<script setup lang="ts">
import { InputDictionaryItem } from '@/deep-form/components/InputDictionary';
import { InputEmits, InputProps } from '@/deep-form/models/dform-input-core';
import { computed } from 'vue';
import { InputMdiColorData, inputMdiColorDefaults } from './InputMdiColorData';

type InternalItem = Exclude<InputDictionaryItem, string>;

const props = defineProps<InputProps<string, InputMdiColorData>>();
const emit = defineEmits<InputEmits<string>>();

const noAccent = ['brown', 'grey', 'blue-grey'];

const items = computed<Array<InternalItem>>(() => {

  const data = props.dfData || inputMdiColorDefaults;
  const res: InternalItem[] = [];

  ['primary', 'secondary', 'error', 'warning', 'info', 'success'].forEach((color) => {
    add(`${data.prefix}-${color}`);
  });

  data.colors.forEach((color) => {

    add(`${data.prefix}-${color}`);

    for (let i = 1; i <= data.maxLighten; i++) {
      add(`${data.prefix}-${color}-lighten-${i}`);
    }

    for (let i = 1; i <= data.maxDarken; i++) {
      add(`${data.prefix}-${color}-darken-${i}`);
    }

    if (! noAccent.includes(color)) {
      for (let i = 1; i <= data.maxAccent; i++) {
        add(`${data.prefix}-${color}-accent-${i}`);
      }
    }

  });

  if (data.prefix === 'bg') {
    add('elevation-0');
    add('elevation-1');
    add('elevation-2');
    add('elevation-3');
    add('elevation-4');
    add('elevation-5');
    add('elevation-6');
  }

  function add(color: string) {
    res.push({ title: color, value: color });
  }

  return res;

});

/*
{ item: ListItem; index: number; props: Record<string, unknown> }
Define a custom item appearance. The root element of this slot must be a v-list-item with v-bind="props" applied. props includes everything required for the default select list behaviour - including title, value, click handlers, virtual scrolling, and anything else that has been added with item-props.
*/
</script>

<template>
  <v-autocomplete
    v-bind="props"

    :items="items"
    itemTitle="title"
    itemValue="value"
    :prependInnerIcon="props.modelValue"

    @update:model-value="emit('update:modelValue', $event);"
  >
    <template #item="{ item, props: { title, ...otherProps } }">
      <v-list-item v-bind="otherProps" :class="item.value">
        {{ title }}
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
