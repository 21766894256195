<template>
  <div>
    <div class="d-flex align-center">
      <div class="flex-grow-1">
        <GravFileOperations
          :selectedFiles="selectedFiles"
        >
          <template #prepend>
            <v-btn
              class="flex-grow-1"
              color="primary"
              :loading="useGravManager().loading"
              :data-loading="useGravManager().loading ? 'true' : undefined"
              @click="fetchNodes"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
        </GravFileOperations>
      </div>
      <div class="flex-grow-1">
        <VFileInput
          v-model="filesToUpload"
          prependInnerIcon="mdi-upload"
          prependIcon="null"
          class="mt-1"
          label="Select a zip archive of /user/pages folder"
          accept=".zip"
          variant="outlined"
        >
          <template #append>
            <v-btn color="primary" @click="uploadBackup">Upload</v-btn>
          </template>
        </VFileInput>
      </div>
    </div>

    <div>
      <GravBrowseFiles
        v-model="selectedFiles"
        :nodes="nodes"
        @edit-file="emit('editFile', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { GravFileInfoNode } from '@/grav/grav-api-models';
import { useGravManager } from '../comosables/useGravManager';

import GravBrowseFiles from './GravBrowseFiles.vue';
import GravFileOperations from './GravFileOperations.vue';

const emit = defineEmits<{
  (event: 'editFile', value: string): void;
}>();

const filesToUpload = ref<File[]>([]);

const grav = useGravManager();
const nodes = ref<GravFileInfoNode[]>([]);
const selectedFiles = ref<string[] | null>([]);

watch( () => grav.backupFiles, () => {
  nodes.value = grav.backupFiles;
  nodes.value.forEach((node) => {
    node.isShown = true;
  });
});

onMounted(async () => {
  await fetchNodes();
});

async function fetchNodes() {
  await grav.upcliShowBackups();
}

async function uploadBackup() {
  if (filesToUpload.value) {
    await grav.uploadBackup(filesToUpload.value[0]);
    await fetchNodes();
  }
}

</script>
