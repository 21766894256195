/* eslint-disable camelcase */
export default {
  title: "Перевод другому участнику",
  label_balance: "Баланс",
  label_transfer_to: "Перевести аккаунту",
  label_amount_to_transfer: "Сумма для перевода",
  start_typing_to_search: "Начните печатать имя или номер для поиска аккаунта",
  button_transfer: "Перевести",
  button_reset: "Сбросить",
  error_balance_must_be_positive: "Баланс должен быть положительным",
  error_amount_required: "Сумма обязательна для заполнения",
  error_not_number: "Сумма должна быть числом",
  error_not_enough_balance: "Недостаточно средств",
  error_account_required: "Аккаунт обязателен для заполнения",
  transfer_success: "Перевод успешно выполнен",
};
