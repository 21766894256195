import { Item, TableGridItem, TableGridItemProperty } from "@/models/table-grid-interface";
import { untilFalse } from "@/utils/reactive-helpers";
import { computed, ref , markRaw } from "vue";
import { defineStore } from "pinia";
import { fetchFullProperties } from "@/utils/fetch-full-properties-service";

const EXPIRE_TIME = 1000 * 30;
const CHECK_INTERVAL = 1000 * 10;

type DataItem = {
  timestamp: number;

  accountId: number;
  treeId?: number;
  offset?: number;
  positionId?: number;

  data: TableGridItem;
}


const cache: DataItem[] = [];


export const useCacheStore = defineStore('cache', () => {

  const loadingCount = ref(0);

  const loading = computed(() => loadingCount.value > 0);

  function waitLoading() {
    return untilFalse(loading);
  }

  return {
    cache,
    loadingCount,
    loading,

    waitLoading,

    getProperties,
    updateCache,
    updateProperty,
  };
});

 
function updateProperty(accountId: number, property: string, value: TableGridItemProperty['raw']) {
  const entries = cache.filter((item) => item.accountId === accountId);

  // TODO: better raw -> presentable, or just remove from all propeties.

  // entries.forEach((item) => {
  //   item.data[property] = { raw: value, presentable: String(value) };
  //   item.timestamp = Date.now();
  // });

  // remove property

  entries.forEach((item) => {
    delete item.data[property];
  });

}

async function getProperties(properties: string[], accountId: number, offset?: number, positionId?: number, treeId?: number, includeTree?: number) {

  if (! properties.length) return {};
  const now = Date.now();

  // console.log('cacheRequest', { properties, accountId, offset, positionId, treeId });

  let res: TableGridItem | undefined = undefined;

  res = findRecord();

  if (! allPropsFound() && useCacheStore().loading) {
    await useCacheStore().waitLoading();
    res = findRecord();
  }

  if (! allPropsFound()) {
    res = await fetchFields(accountId, offset, positionId, treeId, includeTree);
  }


  function allPropsFound() {
    return properties.every((prop) => res?.[prop]);
  }

  function findRecord() {
    let entries: DataItem[] = [];
    let res: TableGridItem = {};

    if (positionId) {
      entries = cache.filter((item) =>
        (item.accountId === accountId)
        && (treeId ? item.treeId === treeId : true)
        && (positionId ? item.positionId === positionId : true)
        && (now - item.timestamp < EXPIRE_TIME),
      );
      res = entries[0]?.data || {};
    } else if (offset) {
      entries = cache.filter((item) =>
        (item.accountId === accountId)
        && (treeId ? item.treeId === treeId : true)
        && (offset ? item.offset === offset : true)
        && (now - item.timestamp < EXPIRE_TIME),
      );
      res = entries[0]?.data || {};
    } else if (treeId) {
      entries = cache.filter((item) =>
        (item.accountId === accountId)
        && (item.treeId === treeId)
        && (now - item.timestamp < EXPIRE_TIME),
      );
      res = entries[0]?.data || {};
    } else {
      entries = cache.filter((item) =>
        (item.accountId === accountId)
        && (now - item.timestamp < EXPIRE_TIME),
      );
      res = entries.reduce((acc, item) => ({ ...acc, ...item.data }), {});
    }

    return entries.length > 0 ? res : undefined;
  }

  return res || {};
}

async function fetchFields(accountId: number, offset?: number, positionId?: number, treeId?: number, includeTree?: number): Promise<TableGridItem> {

  useCacheStore().loadingCount++;
  const result = await fetchFullProperties([], treeId, accountId, offset, positionId, includeTree);
  updateCache(result, accountId);
  useCacheStore().loadingCount--;

  return result;
}

function updateCache(data: TableGridItem, accountId?: number) {

  const item = Item(markRaw(data));

  const resAccountId = accountId || item.accountId();

  if (! resAccountId) {
    console.log('Cannot update cache without accountId');
    return;
  }

  const treeId = item.treeId();
  const positionId = item.positionId();
  const offset = item.offset();

  const entries = cache.filter((item) =>
    (item.accountId === resAccountId)
    && (treeId ? item.treeId === treeId : true)
    && (positionId ? item.positionId === positionId : item.positionId === undefined)
    && (offset ? item.offset === offset : true),
  );

  if (entries.length === 0) {
    // console.log('adding plan cache item', { accountId: resAccountId, treeId, positionId, offset });
    cache.push({ timestamp: Date.now(), accountId: resAccountId, data, treeId, positionId, offset });
    return;
  }

  entries.forEach((item) => {
    // console.log('updating plan cache item', { accountId: item.accountId, treeId: item.treeId, positionId: item.positionId, offset: item.offset });
    item.data = { ...item.data, ...data };
    item.timestamp = Date.now();
  });

}

function removeExpired() {
  const now = Date.now();
  cache.forEach((item, index) => {
    if (now - item.timestamp > EXPIRE_TIME) {
      cache.splice(index, 1);
    }
  });
}

setInterval(removeExpired, CHECK_INTERVAL);
