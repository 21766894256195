export interface ScheduleHistoryProps {
  id?: string;
  metric: string;
  showCurrentPeriod?: boolean;
  accountId?: number;
  treeId?: number;
  title?: string;
  color?: string;
  gridColor?: string;
  gridWidth?: number;
  axisColor?: string;
  ticksColor?: string;
  axisWidth?: number;
  backgroundClass?: string;
  lineWidth?: number;
  pointRadius?: number;
  limit?: number;
  fill?: boolean;
  hintText?: string;
}

export const defaults = {
  id: undefined,
  accountId: undefined,
  treeId: undefined,
  gridColor: undefined,
  title: undefined,
  metric: 'PV',
  color: '#0000FF',
  gridWidth: 0,
  axisColor: '#EEEEEE',
  ticksColor: '#BBBBBB',
  axisWidth: 4,
  backgroundClass: 'elevation-2',
  lineWidth: 2,
  pointRadius: 0,
  fill: false,
  showCurrentPeriod: false,
  limit: 100,
};
