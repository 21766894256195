export interface RefLinkProps {
  id?: string;
  headerClass?: string;
  title?: string;
  singleLine?: boolean;
  template?: string;
  showQr?: boolean;
  showUrl?: boolean;
  maxVisibleChars?: number;
  qrSize?: number;
}

export const defaults = {

  headerClass: 'bg-primary',
  template: '{{site_url}}/?ref={{p.invite_code}}',
  singleLine: false,
  title: 'uiRefLink.title',
  maxVisibleChars: 256,
  qrSize: 160,
  showQr: true,
  showUrl: true,

};
