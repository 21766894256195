
export type WidgetCardProps = {
  id?: string;
  backgroundClass?: string,
   
  headerClass?: string,
  icon?: string,
  title?: string,
  hintIconText?: string,
  rounded?: number;
  height?: number;
  disableWrapper?: boolean;
};

export const defaults = {
  backgroundClass: 'bg-primary',
  rounded: 4,
  disableWrapper: false,
};
