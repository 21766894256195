import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";
import { InputByTypeProperties } from "@/models/form-builder-interface";

export interface PropStep {
  title: string;
  aliases: string[];
}

export type ConfirmationMode = true | false | 'optional';

export interface SignupRegistrationProps {
  id?: string;
  steps?: PropStep[];
  inputProperties?: InputByTypeProperties;
  optionalFields?: string[];
  fieldTitles?: Record<string, string>;
  otpConfirm?: Record<ConfirmableFieldAlias, ConfirmationMode>;
  forceFields?: Record<string, string>;
  autoLogin?: boolean;
  altLabels?: boolean;
}


export const defaults = {
  steps: [
    { title: 'uiSignupRegistration.account_information', aliases: ['sponsor_id', 'firstname', 'lastname'] },
    { title: 'uiSignupRegistration.contact_details', aliases: ['email', 'password', 'password_confirm'] },
  ],
  otpConfirm: {
    'email': true,
    'phone': 'optional' as const,
  },
  autoLogin: true,
};
