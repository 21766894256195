<script setup lang="ts">
import { ref } from 'vue';

import { FrontlineTableProps, defaults } from './FrontlineTable.ts';

import { TableGridPaginationState } from "@/models/table-grid-interface";
import { FRONTLINE_MAX_LIMIT, fetchFrontline } from '@/utils/fetch-frontline-service';

import { useAwaitAccountId, useLocalization } from '@/composables';
import { useProperties } from "@/composables/useProperties";
import { setupTableColumnActions } from '@/composables/useTableColumnActions';
import { useTableGrid } from '@/composables/useTableGrid';

import { FilterItem } from '@ui-api3-sdk/api/api3';

import SearchFilter from './base/SearchFilter.vue';
import TableGrid from './base/TableGrid.vue';


const props = withDefaults(defineProps<FrontlineTableProps>(), {
  id: undefined,
  headerTitles: undefined,
  accountId: undefined,
  searchBy: undefined,
  disableSearch: () => defaults.disableSearch,
  allowSearchBy: () => defaults.allowSearchBy,
  autoSwitchMode: () => defaults.autoSwitchMode,
  treeId: () => defaults.treeId,
  offset: () => defaults.offset,
  filter: () => defaults.filter,
});

const { la } = useLocalization('uiFrontlineTable');

const usedProperties = useProperties(
  props.properties,
  [
    's.id',
    'r.id',
    'p.avatar_id',
    'p.firstname',
    'p.lastname',
  ],
  props.headerTitles,
);

const { tableProps, tableMethods } = useTableGrid({
  properties: usedProperties.properties,
  requestProperties: usedProperties.allFetchableProperties(),
  headerTitles: props.headerTitles,
  fetch,
  maximumLimit: FRONTLINE_MAX_LIMIT,
  unknownErrorMessage: la('unknown_error'),
});

const resultSearchFilter = ref<FilterItem[]>(props.filter);

async function fetch(properties: string[], pagination: TableGridPaginationState) {
  tableMethods.setLoading(true);

  const forAccountId = await useAwaitAccountId(props.accountId);

  const result =
    await fetchFrontline({
      treeId: props.treeId,
      accountId: forAccountId,
      properties,
      filter: resultSearchFilter.value,
      pagination,
      offset: props.offset,
    });

  tableMethods.setLoading(false);
  return result;
}

</script>

<template>
  <TableGrid
    v-bind="tableProps"
    :autoSwitchMode="autoSwitchMode"
    :columnActions="setupTableColumnActions(props)"
    :drawBorder="false"
  >
    <template #top>
      <div class="flex-grow-1" />
      <SearchFilter
        v-if="!disableSearch"
        class="pb-md-6"
        :properties="allowSearchBy.length ? allowSearchBy : Array.from(new Set([ ... properties, ... popupProperties || [] ]))"
        :defaultSearchBy="searchBy"
        :initialFilter="filter"
        @updated="resultSearchFilter = $event; tableMethods.doFetch()"
      />
    </template>

    <template
      v-for="prop in usedProperties.properties"
      #[`${prop.toLowerCase()}`]="slotParams"
    >
      <slot :name="prop.toLowerCase()" v-bind="slotParams" />
    </template>

    <template v-if="$slots.cardAvatar" #cardAvatar="avatarProps">
      <slot
        name="cardAvatar"
        v-bind="avatarProps"
      />
    </template>
  </TableGrid>
</template>
