export type MetricChartItem = {
  metric: string;
  title?: string;
  color?: string;
  lineWidth?: number;
  pointRadius?: number;
  fill?: boolean;
  values?: number[];

}

export interface MetricChartLineProps {
  id?: string;
  showCurrentPeriod?: boolean;
  accountId?: number;
  treeId?: number;
  title?: string;

  charts: MetricChartItem[];

  gridColor?: string;
  gridWidth?: number;

  axisColor?: string;
  axisWidth?: number;

  ticksColor?: string;

  backgroundClass?: string;
  limit?: number;

  legendPosition?: 'top' | 'bottom' | 'left' | 'right' | 'chartArea' | 'none';
  hideHeader?: boolean;
  forceHeight?: string;

  hintText?: string;
}

export const defaults = {
  gridWidth: 0,
  axisColor: '#EEEEEE',
  ticksColor: '#BBBBBB',
  axisWidth: 4,
  backgroundClass: 'elevation-2',
  showCurrentPeriod: false,
  limit: 100,
  legendPosition: 'none' as const,
  hideHeader: false,
};
