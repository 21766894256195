
export type AvatarBoxProps = {
  id?: string;

  accountId?: number;
  treeId?: number;
  offset?: number;
  positionId?: number;
  includeTree?: number;

  titleField?: string;
  subtitleField?: string;
  size?: number;
}

export const defaults = {
  titleField: 't.fullName',
  subtitleField: 't.accountPlusOffset',
  size: 140,
};
