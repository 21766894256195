import { PlanPropertyVM, ProfileFieldVM , usePlanPropertyApi, useProfileFieldsApi } from '@ui-api3-sdk/api/api3';
import { defineStore } from 'pinia';


import { useFetchState } from './useFetchState';
import { ref, computed } from 'vue';
import { PropertyPrefix, PropertyType, isBackendProperty, parsePropertyName } from '@/utils/properties-mapping-service';
import { useLocalization } from '@/composables';
import { propertyTranslation } from '@/utils/properties-translation';
import { FormField } from '@/composables/useFormBuilder';

export const usePropertiesStore = defineStore('properties', () => {

  const pp = ref<PlanPropertyVM[]>([]);
  const pf = ref<ProfileFieldVM[]>([]);

  const loading = computed(() => isLoading('pf') || isLoading('pp'));

  const { isNeedFetch, waitFor, isLoading, setLoading, setFetched } = useFetchState();

  function getPfValue() {
    return pf.value;
  }

  function getPfRef() {
    if (isNeedFetch('pf')) fetchProfileFields();
    return pf;
  }
  async function getPfAsync() {
    if (isNeedFetch('pf')) {
      await fetchProfileFields();
      return pf.value;
    }
    await waitFor('pf');
    return pf.value;
  }

  function getPpValue() {
    return pp.value;
  }

  function getPpRef() {
    if (isNeedFetch('pp')) fetchPlanProperties();
    return pp;
  }
  async function getPpAsync() {
    if (isNeedFetch('pp')) {
      await fetchPlanProperties();
      return pp.value;
    }
    await waitFor('pp');
    return pp.value;
  }


  async function fetchPlanProperties() {
    setLoading('pp');
    return usePlanPropertyApi()
      .getPlanProperties()
      .then(res => {
        pp.value = res.data.payload;
        setFetched('pp');
      });
  }

  function setPlanProperties(data: PlanPropertyVM[]) {
    pp.value = data;
    setFetched('pp');
  }

  function updatePlanProperties(data: PlanPropertyVM[]) {
    // console.log('updatePlanProperties', data.length);

    pp.value = pp.value.map((item) => data.find((i) => i.alias === item.alias) ?? item );

    data.forEach((item) => {
      if ( ! pp.value.find((i) => i.alias === item.alias) ) pp.value.push(item);
    });

    setFetched('pp');
  }

  function updateProfileFields(data: ProfileFieldVM[]) {
    // console.log('updateProfileFields', data.length);

    pf.value = pf.value.map((item) => data.find((i) => i.alias === item.alias) ?? item );

    data.forEach((item) => {
      if ( ! pf.value.find((i) => i.alias === item.alias) ) pf.value.push(item);
    });

    setFetched('pf');
  }

  async function fetchProfileFields() {
    setLoading('pf');
    return useProfileFieldsApi()
      .getProfileFields()
      .then(res => {
        if (res?.data?.payload) {
          pf.value = res.data.payload;
          setFetched('pf');
        }
      });
  }

  function setProfileFields(data: ProfileFieldVM[]) {
    pf.value = data;
    setFetched('pf');
  }

  function findPropertyDefinition(type: PropertyPrefix | undefined, alias: string) {

    const findProfileField = () => pf.value.find((p) => p.alias === alias);
    const findPlanProperty = () => pp.value.find((p) => p.alias === alias);

    switch (type) {
      case 'm': return findPlanProperty();
      case 'p': return findProfileField();
    }

    return findProfileField() ?? findPlanProperty();

  }

  async function getPropertyTypes(properties: string[], overrideTitles?: Record<string, string> ) {

    const needsPlanProperties = properties.some((p) => p.startsWith('m.'));
    const needsProfileFields = properties.some((p) => p.startsWith('p.'));
    const fetchPromises: Promise<unknown>[] = [];

    if (needsPlanProperties) fetchPromises.push(getPpAsync());
    if (needsProfileFields) fetchPromises.push(getPfAsync());

    await Promise.all(fetchPromises);

    const result = properties.map((property) => getPropertyType(property, overrideTitles));
    return result;
  }

  async function getFormFields(properties: string[], overrideTitles?: Record<string, string>, noPrefix?: boolean) {
    const types = await getPropertyTypes(properties, overrideTitles);
    return types.map((type) => ({
        alias: noPrefix? type.alias : `${type.prefix}.${type.alias}`,
        title: type.title,
        fieldType: type.fieldType,
        isMandatory: type.isMandatory,
      } as FormField));
  }

  function getPropertyType(
    property: string,
    translationOverride?: Record<string, string>,
    assumePrefix?: PropertyPrefix,
  ) {

    const [propType, name] = parsePropertyName(property);
    const type = assumePrefix || propType;

    // console.log('getPropertyType:', property, 'type:', type, 'name:', name, 'translationOverride:', translationOverride);

    const result: PropertyType = {
      id: undefined,
      prefix: type,
      alias: name,
      title: propertyTranslation(property, translationOverride),
      filterable: isBackendProperty(property),
      sortable: isBackendProperty(property),
      fieldType: getFieldDefaultType(type, name),
      isMandatory: false,
    };

    const serverFieldDefinition = usePropertiesStore().findPropertyDefinition(type, name);

    if (serverFieldDefinition) {
      result.id = serverFieldDefinition.id;

      if ('is_mandatory' in serverFieldDefinition)
        result.isMandatory = serverFieldDefinition['is_mandatory'];

      if (serverFieldDefinition.fieldType?.alias) {
        result.fieldType = serverFieldDefinition.fieldType.alias;
      } else {
        console.log('serverDefinition:', serverFieldDefinition);
        console.error(`fieldType.alias is not defined for property '${property}'`);
      }
    }

    const { te, t } = useLocalization();

    if (type === 'parent-profile') {
      const parentWrapTemplate = 'properties_parent-profile.parent_wrap';
      if (te(parentWrapTemplate)) {
        result.title = t(parentWrapTemplate, [result.title]);
      } else {
        result.title = `${result.title} [SP]`;
      }
    }

    return result;
  }


  return {

    getPropertyType,
    getPropertyTypes,
    getFormFields,

    loading,

    // get rid of all these:
    getPfRaw: () => pf,
    getPfValue,
    getPfRef,
    getPfAsync,

    getPpRaw: () => pp,
    getPpValue,
    getPpRef,
    getPpAsync,

    setPlanProperties,
    setProfileFields,

    updatePlanProperties,
    updateProfileFields,

    findPropertyDefinition,
    forceFetchPlanProperties: fetchPlanProperties,

  };

});


function getFieldDefaultType(prefix: PropertyPrefix | undefined, alias: string) {

  if (prefix && ['m', 'p'].includes(prefix))
    return 'string'; // type will be clarified later

  if (alias.toLocaleLowerCase() === 'country_id') return 'string';

  if (alias.toLocaleLowerCase().endsWith('level')) return 'int';
  if (alias.toLocaleLowerCase().endsWith('id')) return 'int';
  if (alias.endsWith('At') || alias.toLocaleLowerCase().endsWith('date')) return 'date';

  return 'string';
}
