<template>
  <div v-if="singleLine">
    <RefLinkParts
      v-if="showQr"
      showQr
      :url="url"
      :qrSize="qrSize"
    />
    <RefLinkParts
      v-if="showUrl"
      showUrl
      showBtnCopy
      :url="url"
      btnSize="small"
      :urlSize="maxVisibleChars"
    />
  </div>
  <div v-else>
    <BasicCard
      :headerClass="headerClass"
      :title="title"
      :style="{ minHeight }"
    >
      <template #buttons>
        <RefLinkParts
          showBtnLink
          showBtnCopy
          showBtnEmail
          :url="url"
          btnSize="small"
        />
      </template>

      <slot name="customLayout" :url="url">
        <div class="d-flex">
          <div v-if="showQr" class="mr-4">
            <RefLinkParts
              showQr
              :url="url"
              :qrSize="qrSize"
            />
          </div>
          <div>
            <slot :url="url" />

            <RefLinkParts
              v-if="showUrl"
              showUrl
              showBtnCopy
              :url="url"
              btnSize="small"
              :urlSize="maxVisibleChars"
            />
            <slot name="after" :url="url" />
          </div>
        </div>
      </slot>
    </BasicCard>
  </div>
</template>

<script lang="ts" setup>

import { useAccountPropertiesItem, useAwaitValue } from '@/composables';
import { TableGridItem } from '@/models/table-grid-interface';
import { runTemplate } from '@/utils/property-template-service';
import { computed, onMounted, ref } from 'vue';
import { RefLinkProps, defaults } from './RefLink.ts';

import BasicCard from './base/BasicCard.vue';
import RefLinkParts from './RefLinkParts.vue';


const props = withDefaults(
  defineProps<RefLinkProps>(), {
    id: undefined,
    headerClass: () => defaults.headerClass,
    template: () => defaults.template,
    singleLine: () => defaults.singleLine,
    title: () => defaults.title,
    maxVisibleChars: () => defaults.maxVisibleChars,
    qrSize: () => defaults.qrSize,
    showQr: () => defaults.showQr,
    showUrl: () => defaults.showUrl,
  });

const url = ref(runTemplate(props.template));

const accountProps = ref<TableGridItem | null>();
const minHeight = computed(() => props.showQr ? props.qrSize : 0);

onMounted(async () => {
  accountProps.value = await useAwaitValue(useAccountPropertiesItem(), 'srcItem');
  if (accountProps.value) {
    url.value = runTemplate(props.template, accountProps.value);
  }
});


</script>
