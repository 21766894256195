/* eslint-disable camelcase */
export default {
  step_change: "Change",
  step_confirm: "Confirm",
  change_button: "Change",
  your_new_email_confirmed: "Your new email address {0} has been successfully confirmed!",
  your_new_phone_confirmed: "Your new phone number {0} has been successfully confirmed!",

  your_email_confirmation_skipped: "Confirmation of your email {0} has been skipped.",
  your_phone_confirmation_skipped: "Confirmation of your phone number {0} has been skipped.",

  unknown_error: "An unknown error occurred while editing the contact",
};
