<template>
  <v-footer
    id="dashboard-core-footer"
    absolute
    inset
    app
    :theme="footerTheme"
    :color="footerColor"
  >
    <slot name="default" />
  </v-footer>
</template>

<script>
import { useThemeStore } from '@/pinia/useThemeStore';
import { mapState } from 'pinia';

export default {
  name: 'DashboardFooter',
  computed: {
    ...mapState(useThemeStore, ['footerTheme', 'footerColor']),
  },
};
</script>
