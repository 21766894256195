<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <v-stepper
    v-model="myStep"
    :mobile="xs"
    :altLabels="altLabels"
    class="elevation-0"
  >
    <template #default>
      <v-stepper-header>
        <template v-for="(item, index) in steps" :key="item.name">
          <v-stepper-item
            :complete="step > index+1"
            :title="$t(item.title)"
            :value="index+1"
            :editable="false"
          />
          <v-divider v-if="index < steps.length-1" />
        </template>
      </v-stepper-header>

      <v-stepper-window v-if="! finalSubmitDone">
        <v-stepper-window-item
          v-for="(item, index) in steps"
          :key="item.name"
          :value="index+1"
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
        >
          <v-alert
            v-if="errorMessage"
            type="error"
            :value="true"
            :closable="false"
          >
            {{ errorMessage }}
          </v-alert>
          <div v-show="! errorMessage">
            <slot :key="`item.${index+1}`" :name="`item.${index+1}`" v-bind="item">
              <FormBuilder
                v-if="item.type === 'FormBuilder'"
                :key="`${item.name}-fb`"
                :modelValue="[]"
                v-bind="item.componentProps"
                :hideSubmitButton="true"
                :buttons="[]"
                :inputProperties="inputProperties"
              />
              <FieldConfirmation
                v-else-if="item.type === 'FieldConfirmation'"
                :key="`${item.name}-fc`"
                v-bind="item.componentProps"
                :alias="item.alias"
                :value="item.value"
                :inputProperties="inputProperties"
              />
            </slot>
          </div>
        </v-stepper-window-item>
      </v-stepper-window>

      <v-stepper-window v-else>
        <slot>
          <v-alert
            type="success"
            :value="true"
            :closable="false"
          >
            All steps are done!
          </v-alert>
        </slot>
      </v-stepper-window>
    </template>
    <template v-if="!hideNavigation" #actions>
      <v-stepper-window :style=" xs ? { margin: '1em;' } : undefined">
        <VStepperNavigation
          :loading="loading"
          :data-loading="loading ? 'true' : undefined"
          :step="step"
          :maxStep="steps.length"
          :disabledNextAtSteps="disabledNextAtSteps"
          :finalSubmitTitle="finalSubmitTitle"
          :enableFinalSubmit="enableFinalSubmit"
          :enableFirstBackButton="enableFirstBackButton"
          :firstBackButtonTitle="firstBackButtonTitle"
          @next="emit('nextRequest')"
          @prev="emit('prevRequest')"
        />
      </v-stepper-window>
    </template>
  </v-stepper>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { type Step } from '@/models/dynamic-stepper-interface';

import { InputByTypeProperties } from '@/models/form-builder-interface';
import { useDisplay } from 'vuetify';

import VStepperNavigation from './VStepperNavigation.vue';
import FormBuilder from './FormBuilder.vue';
import FieldConfirmation from './FieldConfirmation.vue';

const props = defineProps<{
  step: number;
  steps: Step[];
  finalSubmitTitle?: string;
  enableFinalSubmit?: boolean;
  finalSubmitDone?: boolean;
  enableFirstBackButton?: boolean;
  firstBackButtonTitle?: string;
  loading?: boolean;
  hideNavigation?: boolean;
  errorMessage?: string;
  inputProperties?: InputByTypeProperties;
  altLabels?: boolean;
}>();

const { xs } = useDisplay();
const myStep = computed(() => props.step);

const disabledNextAtSteps = computed(() => {
  const disabledAt = props.steps.reduce( (acc, step, index) => {
    if (step.nextDisabled) acc.push(index+1);
    return acc;
  }, [] as number[]);
  return disabledAt;
});

const emit = defineEmits<{
  (event: 'nextRequest'): void,
  (event: 'prevRequest'): void,
}>();

</script>

<style global>
.v-stepper-window {
    margin-right: 1rem;
    margin-left: 1rem;
}
</style>
