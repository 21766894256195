/* eslint-disable camelcase */
export default {
  error_placing: 'Ошибка при размещении ребенка: {0}',
  placement_disabled: 'Ручное размещение отключено',

  place_confirm_header: 'Вы уверены?',
  place: 'Разместить',
  under: 'под',
  left_leg: '- левое плечо?',
  right_leg: '- правое плечо?',

  lowest_left: 'Нижний левый',
  lowest_right: 'Нижний правый',
  lowest_pos: 'Нижний {0}',
  back_to_me: 'Мой аккаунт',
};
