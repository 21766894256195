import { InputByTypeProperties } from "@/models/form-builder-interface";

export type PropertiesBoxProps = {
  id?: string;

  properties: string[],
  editableProperties?: string[],

  accountId?: number;
  treeId?: number;
  offset?: number;
  positionId?: number;
  includeTree?: number;

  headerTitles?: Record<string, string>;

  backgroundClass?: string,
  headerClass?: string,
  icon?: string,
  title?: string,
  rounded?: number;
  height?: number;
  wrap?: boolean;

  listInputProperties?: InputByTypeProperties;
  gridInputProperties?: InputByTypeProperties;
  mode?: 'list' | 'grid';

  forceLoading?: boolean;

}

export const defaults = {
  backgroundClass: 'elevation-2',
  rounded: 4,
  wrap: false,
  mode: 'list' as const,
  gridInputProperties: { variant: 'solo-filled', clearable: false, hideDetails: true, density: 'compact' } as const,
};
