<template>
  <OverlayProgress :loading="loading" :error="error">
    <WidgetCard :backgroundClass="backgroundClass">
      <v-tooltip
        :text="hintText"
        :disabled="!hintText"
        location="top"
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <div v-if="!hideHeader" class="v-row mb-2">
              <div class="v-col-3 text-no-wrap text-start flex-grow-1">
                <ValuePositiveBadge
                  v-if="showCurrentPeriod"
                  :value="currentValue.toFixed(2)"
                  zeroColor="orange"
                  variant="tonal"
                />
              </div>
              <div class="v-col-6 text-center flex-grow-1">
                {{ $t(chartTitle) }}
              </div>
              <div class="v-col-3 text-no-wrap text-end flex-grow-1">
                <ValuePositiveBadge :value="`${percent.toFixed(2)}%`" />
              </div>
            </div>
            <div
              id="chart-container"
              :style="`position: relative; width: 100%; height: ${forceHeight || '100%'}`"
            >
              <Line
                :data="chartData"
                :options="chartOptions"
              />
            </div>
          </div>
        </template>
      </v-tooltip>
    </WidgetCard>
  </OverlayProgress>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import { MetricChartLineProps, defaults } from './MetricChartLine.ts';

import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';


import { apiErrorToString, useAccountPropertiesApi } from '@ui-api3-sdk/api/api3';
import { useAwaitAccountId, useLocalization } from '@/composables';
import { Item } from '@/models/table-grid-interface';
import { usePropertiesStore } from '@/pinia/usePropertiesStore';
import { fetchPositions } from '@/utils/fetch-positions-service';
import { formatBigNumber } from '@/utils/utils';
import { Line } from 'vue-chartjs';

import OverlayProgress from './base/OverlayProgress.vue';
import ValuePositiveBadge from './base/ValuePositiveBadge.vue';
import WidgetCard from './WidgetCard.vue';


const props = withDefaults(
  defineProps<MetricChartLineProps>(), {
    id: undefined,
    accountId: undefined,
    treeId: undefined,
    gridColor: undefined,
    title: undefined,
    width: undefined,
    forceHeight: undefined,
    hintText: undefined,

    gridWidth: () => defaults.gridWidth,
    axisColor: () => defaults.axisColor,
    ticksColor: () => defaults.ticksColor,
    axisWidth: () => defaults.axisWidth,
    backgroundClass: () => defaults.backgroundClass,
    showCurrentPeriod: () => defaults.showCurrentPeriod,
    limit: () => defaults.limit,
    legendPosition: () => defaults.legendPosition,
    hideHeader: () => defaults.hideHeader,
  });

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const { pt, d } = useLocalization();

const loading = ref(false);
const error = ref('');

const chartData = ref<ChartData<"line">>({
  labels: [],
  datasets: [],
});

const chartOptions: ChartOptions<"line"> = {
  plugins: {
    legend: {
      display: props.legendPosition !== 'none',
      position: props.legendPosition as Exclude<MetricChartLineProps['legendPosition'], 'none'>,
    },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        color: props.gridColor,
        lineWidth: props.gridWidth,
      },
      border: {
        color: props.axisColor,
        width: props.axisWidth,
      },
      ticks: {
        color: props.ticksColor,
      },
    },
    y: {
      grid: {
        color: props.gridColor,
        lineWidth: props.gridWidth,
      },
      border: {
        color: props.axisColor,
        width: props.axisWidth,
      },
      ticks: {
        callback: formatBigNumber,
        color: props.ticksColor,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const chartTitle = computed(() => props.title || props.charts.map(chart => pt(chart.metric)).join(', '));

const firstChartData = computed(() => chartData.value?.datasets[0]?.data || []);

const currentValue = computed(() => {
  const data = firstChartData.value;
  if (data.length === 0) return 0;
  return data[data.length - 1] as number;
});

const percent = computed(() => {
  const data = firstChartData.value;
  if (data.length === 0) return 0;
  if (data.length === 1) return 100;

  const firstElem = data[0] as number;
  const lastElem = data[data.length - 1] as number;

  if (lastElem === null || lastElem === undefined) return 0;

  if (firstElem && lastElem > 0)
    return 100;

  if (firstElem && lastElem) {
    return (((lastElem - firstElem) / firstElem) * 100);
  }

  return 0;
});


async function fetchData() {

  try {
    loading.value = true;
    error.value = '';

    const xPoints: string[] = [];
    const values: Record<string, number[]> = {};

    const aId = await useAwaitAccountId(props.accountId);
    await usePropertiesStore().getPpAsync();

    const positionsRes = await fetchPositions(aId, props.treeId);
    const positionId = Item(positionsRes[0]).positionId();

    const res = await useAccountPropertiesApi()
      .getPropertiesHistory({
        limit: props.limit,
        page: 0,
        includeCurrent: props.showCurrentPeriod,
        id: aId,
        properties: props.charts.map(chart => chart.metric.replace('m.', '')),
      });

    const { list } = res.data.payload;

    const sortedList = list.sort((a, b) => {
      if (new Date(a.period.start_date) > new Date(b.period.start_date)) {
        return 1;
      }

      if (new Date(a.period.start_date) < new Date(b.period.start_date)) {
        return -1;
      }

      return 0;
    });

    sortedList.forEach((item) => {
      const startDate = new Date(item.period.start_date);
      xPoints.push(d( startDate, 'medium'));


      item.properties.forEach((prop) => {

        const myPositionRecord = prop.values.find((value) => value.positionId === positionId);
        if (!myPositionRecord)
          throw new Error('Position value not found');

        const propName = `m.${prop.alias}`;

        if (!values[propName])
          values[propName] = [];

        values[propName].push(Number(myPositionRecord.value.raw));
      });

    });

    chartData.value = {
      labels: xPoints,
      datasets: props.charts.map((chart) => ({
        data: chart.values || values[ chart.metric ] || [],
        backgroundColor: chart.color,
        borderColor: chart.color,
        borderWidth: chart.lineWidth,
        fill: chart.fill,
        label: chart.title || pt(chart.metric),
        pointRadius: chart.pointRadius,
      })),
    };

  } catch (e) {
    error.value = apiErrorToString(e);
  }

  loading.value = false;

}
onMounted(() => {
  fetchData();
});

</script>
