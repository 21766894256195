import { WalletHistoryProps, defaults } from '@/components/ui/WalletHistory';
import { gravComponentSchema } from '../deep-form-grav';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<WalletHistoryProps>({
  title: 'Wallet History',
  link: 'https://www.notion.so/mlmsoft/WalletHistory-caefe9f6a0194ac98f242877a59f7256',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('wallet').as(l.clone('walletId'))
  .title('Default wallet').mandatory()
  .col(4);

s.add('headerClass').input('mdiColor')
  .title('Widget header color').col(3);

s.add('title').input('text').title('Title').props({ clearable: true }).col(5);


s.add('start').input('date').title('Start date').col(3);
s.add('end').input('date').title('End date').col(3);


s.add('operationTypes').input('dlist').data(

  // s.spawn<number>().input('dictionary').dataAsync(src.walletOperations)
  //   .init(() => 0).mandatory().item(),

  s.spawn<number>().as(bl.clone('number'))
    .title('ID of operation')
    .init(() => 0).props({ hideDetails: true }).mandatory().item(),


)
  .col(6).title('Operation types');

s.add('walletCountries').as(l.clone('walletCountries'))
  .title('Wallet depending on country')
  .desc('Depending on country setting in users profile make this widget to use corresponding wallet');


function text() {
  return `
<b>Wallet History</b>
`;
}
