
import { TableGridItem, TableGridPaginationState } from "@/models/table-grid-interface";
import { parseVMListToTableGridItems } from "./properties-mapping-service";

import { useAccountApi, FilterItem } from '@ui-api3-sdk/api/api3';
import { usePropertiesStore } from '../pinia/usePropertiesStore';

import { nodeIdFromItem } from "@/models/tree-interface";
import { useAccountStore } from "@/pinia/useAccountStore";

export const DOWNLINE_MAX_LIMIT = 250;

export type DownlineServiceParams = {
  treeId: number,

  accountId: number,
  offset?: number,
  positionId?: number,

  properties: string[],

  periodId?: number,
  filter?: FilterItem[],
  pagination?: TableGridPaginationState,
  depth?: number,

  doNotUpdatePropertyTypes?: boolean,
  includeTree?: number,
  showInactive?: 'none' | 'all_inactive' | 'only_disabled',

  disableCountTotal?: boolean,
  disableSorting?: boolean,

};


export async function fetchDownline(dp: DownlineServiceParams, maxNodesPerRequest?: number) {

  const requestPlanProperties = dp.properties.filter((p) => p.startsWith('m.')).map((p) => p.split('.')[1]);

  const maxNodes = maxNodesPerRequest || DOWNLINE_MAX_LIMIT;
  let hitRequestLimit = false;

  const rp = {
    id: dp.accountId,
    limit: dp.pagination?.limit || maxNodes,
    page: dp.pagination?.page ? dp.pagination?.page - 1 : 0,
    periodId: dp.periodId,
    treeId: dp.treeId,
    properties: requestPlanProperties,
    depth: dp.depth,
    offset: dp.offset,
    positionId: dp.positionId,
    includeTree: dp.includeTree,
    showInactiveAccounts: false,
    showDisabledAccounts: false,
   };

  if (dp.showInactive === 'all_inactive') {
    rp.showInactiveAccounts = true;
    rp.showDisabledAccounts = true;
  } else if (dp.showInactive === 'only_disabled') {
    rp.showDisabledAccounts = true;
  }

  const countTotal = !dp.disableCountTotal ? undefined : false;
  const disableSorting = dp.disableSorting ? true : undefined;

  if (useAccountStore().heavyDownlineMode) {
    // Sasha said that now jeunese should work without those limitations
    // countTotal = false;
    // disableSorting = true;
  }

  const res = await useAccountApi().v2DownlineControllerGetDownline(rp, {
    params: {
      countTotal,
      disableSorting,
      filter: dp.filter,
      orderBy: dp?.pagination?.orderBy,
    },
  });

  if (!dp.doNotUpdatePropertyTypes) {
    usePropertiesStore().updatePlanProperties(res.data.map.planProperties);
    usePropertiesStore().updateProfileFields(res.data.map.profileFields);
  }

  const totalItems = res.data.payload.total;
  const downlinePositionList = res.data.payload.list;

  const tableItems = parseVMListToTableGridItems(
    [],
    downlinePositionList,
  );

  if (! totalItems && tableItems.length) {
    // means we in heavyDownline mode.
    hitRequestLimit = ! ( tableItems.length < maxNodes );
  } else {
    hitRequestLimit = totalItems > (rp.page * rp.limit) + tableItems.length;
  }

  return {
    totalItems,
    tableItems,
    requestParams: rp,

    maxNodes,
    hitRequestLimit,
    lastNode: tableItems.length > 0 ? tableItems[tableItems.length - 1] : undefined,
    lastParams: {
      ... dp,
      pagination: {
        ... dp.pagination,
        page: dp.pagination?.page ? dp.pagination.page : 1,
        limit: rp.limit,
      },
    },

  };

}

/*
export async function fetchDownlineAll(
  dp: DownlineServiceParams, batchSize?: number, maxNodesPerRequest?: number) {

  console.log('downlineAll batch=', batchSize, 'maxNodes=', maxNodesPerRequest, 'dp=', dp);

  const result: TableGridItem[] = [];

  const limit = batchSize || useAccountStore().downlineMaxLimit;

  const params = { ...dp };

  if (!params.pagination)
      params.pagination = { limit, page: 1, orderBy: { 's.id': 'ASC' } };

  let knownTotal = 0;
  let batchCount = 0;
  let hitRequestLimit = false;
  let needStop = false;

  const maxNodes = maxNodesPerRequest || DEFAULT_DOWNLINE_BATCH_TOTAL_MAX_NODES;

  while (!needStop) {
    const { tableItems, totalItems, requestParams } = await fetchDownline(params);
    result.push(...tableItems);

    knownTotal = totalItems;
    batchCount++;

    const fetchedItems = tableItems.length;
    const wasLimit = requestParams.limit;
    const noMoreItems = fetchedItems < wasLimit;

    if (result.length > maxNodes) {
      hitRequestLimit = true;
    }

    if (noMoreItems || hitRequestLimit || result.length === maxNodes) {
      needStop = true;
    } else {
      params.pagination.page++;
      params.doNotUpdatePropertyTypes = true;
    }
  }

  result.sort((a, b) => {
    const aLevel = Number(a['vmdl.level']?.raw || 0);
    const bLevel = Number(b['vmdl.level']?.raw || 0);
    return aLevel - bLevel;
  });

  if (result.length > maxNodes) {
    const splicedNodes = result.splice(maxNodes);
    console.log('splicedNodes', splicedNodes);
  }

  return {
    totalItems: knownTotal,
    tableItems: result,

    hitRequestLimit,
    batchCount,
    batchSize: limit,
    maxNodes,

    lastNode: result.length > 0 ? result[result.length - 1] : undefined,
    lastParams: params,
  };
}
*/

export function removeDuplicateNodes(arr: TableGridItem[]) {
  const result: TableGridItem[] = [];
  const map = new Map();
  for (const item of arr) {
    const id = nodeIdFromItem(item);
    if (!map.has(id)) {
      map.set(id, true);
      result.push(item);
    }
  }
  return result;
}
