<script setup lang="ts">
import { watch , computed } from 'vue';
import { InputProps, InputEmits } from '../models/dform-input-core';
import { InputDictionaryData, InputDictionaryItem, InputDictionaryItemValue } from './InputDictionary';

const props = defineProps<InputProps<InputDictionaryItemValue, InputDictionaryData>>();
const emit = defineEmits<InputEmits<InputDictionaryItemValue>>();
type InternalItem = Exclude<InputDictionaryItem, string>;

const items = computed<Array<InternalItem>>(() => {
  if (! props.dfData) return [];

  const dfData = typeof props.dfData === 'function' ? props.dfData() : props.dfData;

  if (!dfData || !Array.isArray(props.dfData) || dfData.length === 0)
    return [];

  if (typeof dfData[0] === 'string')
    return dfData.map((item) => (
      { title: item, value: item } as InternalItem
    ));

  return dfData as unknown as Array<InternalItem> || [];
});

watch ( [items, () => props.modelValue], () => {
  if (items.value.length === 0) return;

  const currentValueFound = items.value.find((item) => item.value === props.modelValue);
  if (!currentValueFound) {
    emit('update:modelValue', undefined);
  }
});

</script>

<template>
  <v-autocomplete
    v-bind="props"

    :items="items"
    itemTitle="title"
    itemValue="value"
    :label="props.dfTitleInside ? props.dfTitle : props.label"


    @update:model-value="emit('update:modelValue', $event);"
  />
</template>
