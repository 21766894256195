 

export function required(value: any, message = 'inputs.err.field_required') {
  return value === null || value === undefined || value === '' ? message : undefined;
}

export function number(value: any, message = 'Not a number') {
  if (!value) return undefined;
  return /^\d+(\.\d+)?$/.test(value) ? undefined : message;
}

export const noEmptyProps = (value: any, message = 'Some props are empty') => {
  if (!value) return undefined;
  const err = (Object.keys(value) as Array<keyof typeof value>)
    .some((key) => value[key] === undefined || value[key] === null || value[key] === '');
  return err ? message : undefined;
};

export function minItems(min: number, message = 'Not enough items') {
  return (value: Array<any> | undefined) => {
    if (!value) return undefined;
    if (!Array.isArray(value)) return 'Not an array';
    return value.length >= min ? undefined : message;
  };
}
