import { useLocalization } from "@/composables";


export function getLikeOperation(fieldType: string) {
  const ops = getFilterOperations(fieldType);

  const likeOp = ops.find((op) => op.value === 'iLike');
  if (likeOp) return likeOp;

  const eqOp = ops.find((op) => op.value === '=');
  if (eqOp) return eqOp;

  console.error(`No like operation for ${fieldType}`);
  return ops[0];

}

export function getFilterOperations(fieldType: string) {

  const { t } = useLocalization();

  const OP_EQUALS = {
    title: t('filter.equals'),
    value: '=',
    count: 'one',
  };

  const OP_NOT_EQUALS = {
    title: t('filter.not_equals'),
    value: '!=',
    count: 'one',
  };

  const BASE_OPERATIONS = {
    bool: [OP_EQUALS, OP_NOT_EQUALS],
    dict: [OP_EQUALS, OP_NOT_EQUALS],
    num: [
      OP_EQUALS,
      OP_NOT_EQUALS,
      {
        title: t('filter.greater'),
        value: '>',
        count: 'one',
      },
      {
        title: t('filter.greater_or_equals'),
        value: '>=',
        count: 'one',
      },
      {
        title: t('filter.less'),
        value: '<',
        count: 'one',
      },
      {
        title: t('filter.less_or_equals'),
        value: '<=',
        count: 'one',
      },
      {
        title: t('filter.between'),
        value: 'between',
        count: 'two',
      },
      {
        title: t('filter.not_between'),
        value: 'n-between',
        count: 'two',
      },
    ],

    str: [
      OP_EQUALS,
      OP_NOT_EQUALS,
      {
        title: t('filter.contains'),
        value: 'iLike',
        count: 'one',
      },
      {
        title: t('filter.not_contains'),
        value: 'n-iLike',
        count: 'one',
      },
    ],
  };

  const TYPE_OPERATIONS = {
    string: BASE_OPERATIONS.str,
    txt: BASE_OPERATIONS.str,
    html: BASE_OPERATIONS.str,

    dictionary: BASE_OPERATIONS.dict,

    date: BASE_OPERATIONS.num,
    numeric: BASE_OPERATIONS.num,
    int: BASE_OPERATIONS.num,
    currency: BASE_OPERATIONS.num,
    points: BASE_OPERATIONS.num,
    country: BASE_OPERATIONS.num,
    region: BASE_OPERATIONS.num,
    float: BASE_OPERATIONS.num,
  };

  const to = TYPE_OPERATIONS[fieldType as keyof typeof TYPE_OPERATIONS];
  if (to) return to;
  console.error(`No filter operations for ${fieldType}`);
  return [];
}
