/* eslint-disable camelcase */
export default {
  request_payout: 'Request payout from',
  payout_from: 'Payout from',
  amount: 'Amount',
  no_creds: 'No credential settings for this payment system',
  cancel: 'Cancel',
  submit: 'Submit request',
  update_successful: 'Update successful',
  payment_data: 'Payment Personal data',
  create_successful: 'Create successful',
  enter: 'Enter',
  error_payout: 'Payout error',
  wallet_not_found: 'Wallet not found',
};
