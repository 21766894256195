<!--
  This component is the entry point for the whole application.
  In production it recieves all outside parameters from components/index.js
  (GRAV initialization).

  Init workflow goes as follows:

  In production: base.twig (grav) -> components/index.js  -> this component
  In development: /App.vue        -> /main.ts             -> this component

-->
<template>
  <VThemeProvider :theme="activeTheme" :withBackground="true">
    <VApp v-bind="attrs" style="overflow: hidden;">
      <VSonner position="top-center" />
      <slot v-if="!loading" />
    </VApp>
  </VThemeProvider>
</template>

<script setup lang="ts">
import { onMounted, ref, useAttrs, watch } from 'vue';

import { useAccountStore } from '@/pinia/useAccountStore';
import { useThemeStore } from '@/pinia/useThemeStore';

import { VSonner } from 'vuetify-sonner';
import { VThemeProvider, VApp } from 'vuetify/lib/components/index.mjs';
import { auth } from '@ui-api3-sdk/api';
import { getLocaleRef } from '@/utils/utils';
import { useNavigation } from '@/composables';
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore';

const props = withDefaults(defineProps<{

  themeSettings?: Record<string, any>,
  loginPath?: string,
  registrationPath?: string,
  languages?: string[],
  idMask?: string,
  preferredCountries?: string[],
  onlyCountries?: string[],
  logoBig?: string,
  logoSmall?: string,
  useExternalId?: boolean,
  logoutRedirectUrl?: string,
  accountSearchFields?: string[],
  fullNameField?: string,
  doNotRedirectToLogin?: string,
}>(), {
  themeSettings: () => ({}),
  loginPath: '/login',
  registrationPath: '/registration',
  languages: () => ['ru', 'en'],
  idMask: '000 000 000',
  preferredCountries: () => [],
  onlyCountries: () => [],
  logoBig: undefined,
  logoSmall: undefined,
  useExternalId: false,
  logoutRedirectUrl: undefined,
  fullNameField: undefined,
  accountSearchFields: () => [],
  doNotRedirectToLogin: undefined,
});

const attrs = useAttrs();
const activeTheme = ref<string>('light');
const loading = ref<boolean>(true);

onMounted(async () => {

  // TODO:
  // move passing menu items through the same mechanism
  // make one entry point from GRAV

  const accountStore = useAccountStore();
  const runtimeSettings = useRuntimeSettingsStore();
  const nav = useNavigation();

  if (props.languages)
    runtimeSettings.setLanguages(props.languages);

  if (props.loginPath)
    runtimeSettings.setLoginPath(props.loginPath);

  if (props.registrationPath)
    runtimeSettings.setRegistrationPath(props.registrationPath);

  if (props.logoutRedirectUrl)
    runtimeSettings.setLogoutRedirectUrl(props.logoutRedirectUrl);


  if (props.useExternalId)
    accountStore.replaceProperties['r.id'] = 's.external_id';

  if (props.fullNameField)
    accountStore.replaceProperties['r.fullName'] = props.fullNameField;

  if (props.themeSettings) {
    useThemeStore().applyTheme(props.themeSettings);

    watch(() => useThemeStore().theme, () => {
      activeTheme.value = useThemeStore().theme;
    }, { immediate: true });
  }

  if (props.logoBig)
    useThemeStore().setHeaderLogoBig(props.logoBig);

  if (props.logoSmall)
    useThemeStore().setHeaderLogoSmall(props.logoSmall);

  if (props.idMask)
    accountStore.setIdMask(props.idMask);

  if (props.preferredCountries || props.onlyCountries)
    accountStore.setCountriesSettings(props.preferredCountries, props.onlyCountries);

  if (props.accountSearchFields)
    accountStore.setAccountSearchFields(props.accountSearchFields);
  

  function doRedirectToLoginOnUnauthorized() {
    if (!props.doNotRedirectToLogin) return true;

    let res = true;
    const cpath = window.location.pathname;

    props.doNotRedirectToLogin.split('|').forEach( path => {
      if ( cpath.startsWith(path) ) {
        res = false;
        return;
      }
    });
      
    return res;
  }

  // console.log('DashboardApp: before init auth');
  await auth.init({
    getLocale: () => getLocaleRef().value,

    // getApiDevPath: () => baseUrl(),

    onAfterLogout(becauseUnauthorized?: boolean) {
      
      if (! becauseUnauthorized) {
        if (props.logoutRedirectUrl)
          useNavigation().go(props.logoutRedirectUrl);
        else
          useNavigation().toLogin();
        return;
      }

      console.log('store login redirect from url');
      runtimeSettings.storeLoginRedirectFromUrl();
      
      if (nav.isLoginPage() || nav.isRegistrationPage())
        return;

      if (doRedirectToLoginOnUnauthorized())
        useNavigation().toLogin();

    },

  });

  loading.value = false;
  // console.log('DashboardApp: after init auth');

});


</script>
