<template>
  <v-list>
    <v-list-item
      v-for="comp in components"
      :key="comp.id"
      @click="grav.selectComponentById(comp.id)"
    >
      <v-list-item-title>
        {{ comp.id }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { useGravManager } from '../comosables/useGravManager';

const grav = useGravManager();
const { components } = toRefs(grav);


</script>
