<template>
  <div>
    <template v-if="showQr">
      <QrcodeVue
        v-if="url"
        :size="qrSize"
        :value="url"
        level="H"
        renderAs="svg"
        @click="copyText"
      />
      <div v-else class="qr-loading">
        <v-icon :size="qrSize">mdi-qrcode-scan</v-icon>
      </div>
    </template>
    <a v-if="showUrl && url" :href="url" style="word-break: break-all;">{{ visibleUrl }}</a>
    <v-btn
      v-if="showBtnLink"
      :disabled="!url"
      :href="url"
      icon="mdi-link-variant"
      variant="plain"
      :size="btnSize"
    />
    <v-btn
      v-if="showBtnCopy"
      :disabled="!url"
      icon="mdi-content-copy"
      variant="plain"
      :size="btnSize"
      @click="copyText"
    />
    <v-btn
      v-if="showBtnEmail"
      :disabled="!url"
      :href="emailLink"
      icon="mdi-email-arrow-right-outline"
      variant="plain"
      :size="btnSize"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { useLocalization } from '@/composables';
import { useToast } from '@/composables/useToast';
import { shortenCutMiddle } from '@/utils/utils';

import QrcodeVue from 'qrcode.vue';

const props = withDefaults(defineProps<{
  url: string | undefined;

  showQr?: boolean;
  showUrl?: boolean;
  showBtnLink?: boolean;
  showBtnCopy?: boolean;
  showBtnEmail?: boolean;

  qrSize?: number;
  urlSize?: number;
  btnSize?: string;
}>(), {
  showQr: false,
  showUrl: false,
  showBtnLink: false,
  showBtnCopy: false,
  showBtnEmail: false,
  btnSize: 'small',
  qrSize: 160,
  urlSize: 160,
});

const { lt } = useLocalization('uiRefLink');

const emailLink = computed(() => props.url ? `mailto:?body=${props.url}` : '');
const visibleUrl = computed(() => typeof props.urlSize === 'number' ? shortenCutMiddle(props.url || '', props.urlSize) : props.url);

async function copyText() {
  try {
    if (!props.url) return;
    await navigator.clipboard.writeText(props.url);
    useToast().ok(lt('copied_to_clipboard'));
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

</script>

<style lang="scss" scoped>
.qr-loading {
  opacity: 0.5;
}
</style>
