<template>
  <div data-component="ui-payout-request-history">
    <TableGrid
      v-bind="tableProps"
      autoSwitchMode
    >
      <template #expansion="{ item }">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <div class="text-caption">
              <span class="mr-4">{{ item.requestDate.split(/[,\s]+/)[0] }}</span>
              <v-chip class="mr-4" :color="statusColor(String(item?.srcItem?.statusCode?.raw))">
                {{ item.status }}
              </v-chip>
              <span><b>{{ item.amount }}</b> {{ item.srcItem?.currency?.presentable }}</span>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="pt-4">
            <div><b>{{ headerTitles['requestId'] }}:</b> {{ item.requestId }}</div>
            <div><b>{{ headerTitles['requestDate'] }}:</b> {{ item.requestDate }} </div>
            <div><b>{{ headerTitles['paymentSystem'] }}:</b> {{ item.paymentSystem }}</div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </template>

      <template #status="{ item }">
        <v-chip :color="statusColor(String(item?.statusCode?.raw))">
          {{ item?.status?.presentable }}
        </v-chip>
      </template>

      <template #amount="{ item }">
        <b>{{ item?.amount?.presentable }}</b> {{ item?.currency?.presentable }}
      </template>
    </TableGrid>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';

import i18n from '@/i18n';
import { TableGridItem, TableGridPaginationState } from "@/models/table-grid-interface";

import { useAwaitAccountId, useLocalization } from '@/composables';
import { useTableGrid } from '@/composables/useTableGrid';

import { useEventBus } from '@/pinia/useEventBus';
import { PayoutVM, usePayoutApi } from '@ui-api3-sdk/api/api3';

import TableGrid from './base/TableGrid.vue';

const { lt, la, reactiveList } = useLocalization('uiPayoutRequestHistory');

interface PayoutRequestHistoryProps {
  accountId?: number;
}

const props = withDefaults(defineProps<PayoutRequestHistoryProps>(),{
  accountId: undefined,
});

const properties = ['requestId', 'requestDate', 'amount', 'paymentSystem', 'status'];
const headerTitles = reactiveList('p', properties);

const { tableProps, tableMethods } = useTableGrid({
  properties,
  headerTitles,
  fetch,
  maximumLimit: 500,
  unknownErrorMessage: la('unknown_error'),
});

watch(() => useEventBus().payoutHistoryUpdated, () => {
  tableMethods.doFetch();
});

async function fetch(properties: string[], pagination: TableGridPaginationState) {
  tableMethods.setLoading(true);

  const forAccountId = await useAwaitAccountId(props.accountId);

  const result = await usePayoutApi().getAccountInvoiceList({
    id: forAccountId,
    limit: pagination.limit,
    page: pagination.page - 1,

  }, {
    params: {
      orderBy: {
        'payout.created_at': 'DESC',
      },
    },
  });

  const tableItems = result.data.payload.list.
    // sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).
    map( (e) => mapPayment(e) );

  const totalItems = result.data.payload.total;

  tableMethods.setLoading(false);
  return { tableItems, totalItems };
}


function statusColor(statusCode: string) {
  switch (statusCode) {
    case 'pending':
      return 'primary';
    case 'done':
      return 'success';
    case 'canceled':
      return 'error';
    case 'error':
      return 'error';
  }
  return '';
}

function mapPayment(payment: PayoutVM): TableGridItem {


  function val(v: any) {
    return { presentable: String(v), raw: v };
  }

  const statusField = new Map([['processing', 'pending'],['new', 'pending'], ['full_filled', 'done'], ['completed', 'done'], ['canceled', 'canceled'], ['error', 'error']]);

  const statusCode = statusField.get(payment.internalStatus);
  const dateTime = new Date(payment.createdAt).toLocaleString(i18n.global.locale);
  //dayjs().format('{YYYY} MM-DD HH:mm:ss SSS [Z] A')

  return {
    requestId: val(payment.id),
    requestDate: val(dateTime),
    amount: val(payment.buyerAmount),
    currency: val(payment?.paymentRule?.sourceWalletType?.currency_id),
    paymentSystem: val(payment.paymentRule.paymentSystem.title),
    status: val(lt( `status_${statusCode}` )),
    statusCode: val(statusCode),
  };
}

</script>
