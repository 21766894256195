/* eslint-disable camelcase */
export default {
  title: "Перевод между кошельками",

  label_src_balance: "Баланс",
  label_dst_balance: "Баланс",
  result_sum: "Итог",
  commission: "Комиссия",
  exchange_rate: "Обменный курс",

  label_transfer_to: "Кошелек назначения",
  label_transfer_from: "Исходный кошелек",
  error_wallet_required: "Требуется указать кошелек",

  label_debit_amount: "Сумма списания",
  label_transfer_amount: "Сумма перевода",
  label_amount_to_receive: "Сумма для получения",

  button_transfer: "Перевести",
  button_reset: "Сбросить",
  error_balance_must_be_positive: "Баланс должен быть положительным",
  error_amount_required: "Требуется указать сумму",
  error_not_number: "Сумма должна быть числом",
  error_not_enough_balance: "Недостаточно средств на исходном кошельке",
  transfer_success: "Перевод успешно выполнен",
};
