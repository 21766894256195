<template>
  <div>
    <div v-if="title && resultItems.length > 0" class="mt-6 mb-2">
      <h4>{{ title }}</h4>
    </div>
    <v-table density="compact">
      <tbody>
        <tr
          v-for="(item, i) in resultItems"
          :key="`prop-${i}`"
        >
          <td class="pl-0">{{ item[1] }}</td>
          <td :class="`pr-0 ${dataColumnWidthClass}`">
            <v-progress-circular
              v-if="loading"
              :data-loading="true"
              class="px-2"
              indeterminate
              color="primary"
              size="20"
            />
            <div v-else>
              <TableColumnActions
                v-if="data && usedColumnActions.isActionsColumn(item[0] as string)"
                v-bind="usedColumnActions.componentProps(item[0] as string)"
                :item="data"
              >
                <slot :name="item[0]" v-bind="slotParams(props.data, item[2])">
                  {{ item[2] }}
                </slot>
              </TableColumnActions>

              <slot v-else :name="item[0]" v-bind="slotParams(props.data, item[2])">
                {{ item[2] }}
              </slot>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { computed , toRef } from 'vue';

import { TableGridItem, slotParams } from '@/models/table-grid-interface';
import { useProperties } from '@/composables/useProperties';

import { TableColumnActionsSchema, useTableColumnActions } from '@/composables/useTableColumnActions';

import TableColumnActions from './TableColumnActions.vue';


const props = withDefaults(defineProps<{
  items: string[];
  itemTitles: Record<string, string>;
  title?: string;
  data: TableGridItem;
  loading?: boolean;
  columnActions?: TableColumnActionsSchema;
  dataColumnWidthClass?: string;
}>(), {
  title: undefined,
  columnActions: undefined,
  dataColumnWidthClass: 'w-50',
});

const usedColumnActions = useTableColumnActions(props.columnActions);
const { propertyTranslations } = useProperties(props.items, [], toRef(props, 'itemTitles'));

const resultItems = computed(() => {
  const res = props.items?.map( p => [
    p,
    propertyTranslations.value?.[p] || p,
    props.data[ p ]?.presentable || '-',
  ] ) || [];
  return res;
});

</script>
