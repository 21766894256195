<template>
  <div>
    <v-text-field
      v-show="false"
      v-bind="props"
      :label="props.dfTitleInside ? props.dfTitle : undefined"
      @update:model-value="emit('update:modelValue', $event);"
    />
    {{ modelValue }}
  </div>
</template>

<script setup lang="ts">
import { InputProps, InputEmits } from '../models/dform-input-core';

 
const props = defineProps<InputProps<any, unknown>>();
 
const emit = defineEmits<InputEmits<any>>();

</script>
