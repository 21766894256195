/* eslint-disable camelcase */
export default {
  title: "Transfer to Another Account",
  label_balance: "Balance",
  label_transfer_to: "Transfer to Account",
  label_amount_to_transfer: "Amount to Transfer",
  start_typing_to_search: "Start typing a name or number to search for an account",
  button_transfer: "Transfer",
  button_reset: "Reset",
  error_balance_must_be_positive: "Balance must be positive",
  error_amount_required: "Amount is required",
  error_not_number: "Amount must be a number",
  error_not_enough_balance: "Insufficient funds",
  error_account_required: "Account is required",
  transfer_success: "Transfer successful",
};
