<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">
import { VisualBinaryTreeProps, defaults } from './VisualBinaryTree.ts';

import { computed, ref, watch } from 'vue';

import { useAccountId, useLocalization, useNavigation, useToast } from '@/composables';
import { DNode, presentableValue as pv } from '@/composables/useTreeRender';
import { Item, ItemType, TableGridItem, itemsEqual } from '@/models/table-grid-interface';
import { TreeFetchResult, createNodeId, nodeIdFromItem, parseNodeId } from '@/models/tree-interface';
import { RenderFunctionProps, TreeRenderComponentMethods } from '@/models/tree-render-component-interface';
import { parseVMListToTableGridItems } from '@/utils/properties-mapping-service';

import { apiErrorToString, useAccountPositionApi, useCPE3Api } from '@ui-api3-sdk/api/api3';
import { shortenCutEnd } from '@/utils/utils';
import { useDisplay } from 'vuetify';

import greenPlus from '@/assets/images/plus.png';

import TreeRender from '@/components/ui/base/TreeRender.vue';
import { auth } from '@ui-api3-sdk/api';
import FrontlineTable from './FrontlineTable.vue';
import EditProperty from './base/EditProperty.vue';
import MemberAvatar from './base/MemberAvatar.vue';
import MemberInfoPopup from './base/MemberInfoPopup.vue';

const { lt } = useLocalization('uiVisualBinaryTree');
const { xs } = useDisplay();

const props = withDefaults(defineProps<VisualBinaryTreeProps>(), {
  id: undefined,
  headerTitles: undefined,
  colors: undefined,
  inputProperties: undefined,
  popupProperties: undefined,
  properties: undefined,
  disabledPlacementText: undefined,
  showInactive: undefined,

  treeId: () => defaults.treeId,
  backgroundColor: () => defaults.backgroundColor,
  initialFetch: () => defaults.initialFetch,
  shortFetch: () => defaults.shortFetch,
  longFetch: () => defaults.longFetch,
  uplineLimit: () => defaults.uplineLimit,
  frontlineTreeId: () => defaults.frontlineTreeId,
  frontlineProperties: () => defaults.frontlineProperties,
  frontlineFilter: () => defaults.frontlineFilter,

  titleField: () => defaults.titleField,
  subtitleField: () => defaults.subtitleField,
  titleFieldMax: () => defaults.titleFieldMax,
  subtitleFieldMax: () => defaults.subtitleFieldMax,
  editableProperties: () => defaults.editableProperties,
  editPermission: () => defaults.editPermission,

  disablePlacement: () => defaults.disablePlacement,
  disableNavigateToLowest: () => defaults.disableNavigateToLowest,

  placementDocument: () => defaults.placementDocument,
  placementPayloadType: () => defaults.placementPayloadType,

  maxNodesPerRequest: () => defaults.maxNodesPerRequest,
  forcePlacementMode: () => defaults.forcePlacementMode,
});

const propsWithCustom = computed(() => {
  const p: string[] = [ ... props.properties || [] ];

  function addProp(prop: string) {
    if (p.includes(prop)) return;
    p.push(prop);
  }

  addProp(props.titleField);
  addProp(props.subtitleField);
  props.editableProperties?.forEach(addProp);

  return p;

});

let treeMethods: TreeRenderComponentMethods | null = null;

const cl = calcCardLayout();

const { aId: myAccountId } = useAccountId();

type NavigationButton = {
  isReset: boolean;
  title: string;
  item?: TableGridItem;
}

const navigationButtons = ref<NavigationButton[]>([]);

const currentRootItem = ref<TableGridItem | undefined>();

const infoPopupOpen = ref(false);
const infoPopupOpenItem = ref<TableGridItem | undefined>();

const placementPopupOpen = ref(false);
const placementPopupState = ref<'closed' | 'open' | 'confirmation' | 'error'>('closed');

const currentPlacementDestNodeId = ref('');
const currentPlacementParentItem = ref<ItemType | undefined>();
const currentKidToPlace = ref<ItemType | undefined>();

const infoPopupOpenItemItem = computed(() => infoPopupOpenItem.value ? Item(infoPopupOpenItem.value) : undefined);

function canEditPropertiesOf(accountId: number | undefined) {
  if (! accountId) return false;
  if (! myAccountId?.value) return false;
  if (props.editPermission === 'all') return true;
  if (props.editPermission === 'self') return accountId === myAccountId.value;
  return false;
}


watch( () => nodeIdFromItem(currentRootItem.value || {}) , async () => {

  if (! currentRootItem.value ) return;

  const currentItem = Item(currentRootItem.value);

  const accountId = currentItem.accountId();
  if (! accountId) return;

  try {
    const lastBinary = props.disableNavigateToLowest
      ? undefined
      : await useAccountPositionApi().getLastBinaryPosition({
        treeId: props.treeId,
        id: accountId,
        positionId: currentItem.positionId(),
      });

    navigationButtons.value = [];

    if (lastBinary) {

      const tableItems = parseVMListToTableGridItems(
        [],
        lastBinary.data.payload,
      );

      for (const tableItem of tableItems) {
        const item = Item(tableItem);

        if (! item.sameAs(currentItem))

          navigationButtons.value.push({
            isReset: false,
            title: buttonTitleFromItem(item),
            item: tableItem,
          });
      }
    }

    if (myAccountId?.value) {
      if (currentItem.accountId() !== myAccountId.value) {
        navigationButtons.value.push({
          isReset: true,
          title: lt('back_to_me'),
        });
      }
    }


  } catch (e: any) {
    useToast().error(apiErrorToString(e));
  }

  function buttonTitleFromItem(item: ReturnType<typeof Item>) {
    let position = '';

    if (item.number() === 1) position = lt('lowest_left');
    else if (item.number() === 2) position = lt('lowest_right');
    else position = lt('lowest_pos', [item.number()]);

    return position;
  }

});

function openInfoPopup(item: TableGridItem) {
  infoPopupOpen.value = true;
  infoPopupOpenItem.value = item;
}

function openPlacementPopup(emptyNodeId: string, parentItem?: ItemType) {
  // console.log('openPlacementPopup', emptyNodeId, parentItem);
  currentPlacementDestNodeId.value = emptyNodeId;
  currentPlacementParentItem.value = parentItem;
  placementPopupState.value = 'open';
  placementPopupOpen.value = true;
}


function selectKidToPlace(item: TableGridItem) {
  // console.log('selectKidToPlace', item);
  currentKidToPlace.value = Item(item);
  placementPopupState.value = 'confirmation';
}

function parseEmptyNodeId(nodeId: string) {
  const parts = nodeId.split('/');
  if (parts.length !== 3) return undefined;
  if (parts[0] !== 'empty') return undefined;
  return {
    parentNodeId: parts[1],
    legNumber: Number(parts[2]),
  };
}

async function placementConfirmed() {
  // console.log('placementConfirmed: kid=', currentKidToPlace.value, 'dst=', currentPlacementDestNodeId.value);

  try {

    if (! currentKidToPlace.value ) throw new Error('currentKidToPlace is null');
    if (! currentPlacementDestNodeId.value  ) throw new Error('currentNodeItem is null');

    // Thats how empty nodeId looks: "empty/54-1-56300/2"

    const dst = parseEmptyNodeId(currentPlacementDestNodeId.value);
    if (! dst ) throw new Error('Problems with empty node id!');

    const parentNode = parseNodeId(dst.parentNodeId);

    const kidAccountId = currentKidToPlace.value.accountId();

    if (  ! parentNode.accountId || ! parentNode.offset || ! kidAccountId)
      throw new Error('Problems with parent node or kid selection!');

    let payload = {
      accountId: kidAccountId,
      sponsorAccountId: parentNode.accountId,
      legNumber: dst.legNumber,
    } as Record<string, unknown>;

    if (props.placementPayloadType === 'v2') {
      const aId = myAccountId?.value;

      if (! aId) throw new Error('myAccountId is null');

      payload = {
        accountId: aId,
        childAccountId: kidAccountId,
        binaryParentPositionId: parentNode.positionId,
        number: dst.legNumber,
      };
    }


    await useCPE3Api().documentCreate({
      documentCreateReq: {
        documentType: props.placementDocument,
        parentOffset: parentNode.offset,
        payload,
      },
    },       {
      headers: {
        'authorization': `Bearer ${auth.getAccessToken()}`,
      },
    });

    placementPopupOpen.value = false;

    if (treeMethods) {
      if (currentRootItem.value && dst.parentNodeId === nodeIdFromItem(currentRootItem.value)) {
        treeMethods.resetTree();
        treeMethods.fetchAndRender(parentNode.accountId, parentNode.offset, 1);
      } else {
        treeMethods.setCurrentAccountByNodeId(dst.parentNodeId);
      }
    }


  } catch (e: any) {
    console.error('placementConfirmed error', e);
    useToast().error(lt('error_placing', [e?.message]));
    placementPopupState.value = 'error';
  }
}

function calcCardLayout() {

  const cardWidth = 200;

  const hMargin = 30; // empty space between cards
  const vMargin = 30;
  const vPadding = 0; // padding inside the card
  const hPadding = 0;


  const avatarRadius = cardWidth / 2 - hPadding;
  const avatarPadding = 0;

  const textRectHPadding = 10;
  const textRectVPaddingTop = 4;
  const textRectVPaddingBottom = 10;

  const nameFontSize = 20;
  const idFontSize = 15;
  const titleVerticalGap = 8;

  const beforePropsGap = 12;
  const propFontSize = 14;
  const propVerticalGap = 4;

  const propsTotalHeight = (
    props.properties && props.properties.length > 0 ?
      beforePropsGap +
      (propFontSize + propVerticalGap) * props.properties.length
      : 0 );

  const textRectOffset = -20;

  const textRectHeight =
    textRectVPaddingTop +
    nameFontSize +
    titleVerticalGap +
    idFontSize +
    propsTotalHeight +
    textRectVPaddingBottom;

  const cardHeight = vPadding +
    (avatarPadding + avatarRadius) * 2
    + textRectHeight
    + textRectOffset;

  const textRectStartY = vPadding +
    (avatarPadding + avatarRadius) * 2
    + textRectOffset;

  return {
    cardWidth,
    cardHeight,
    vMargin,
    hMargin,
    vPadding,
    hPadding,
    separation: 1.4,
    initialZoomScale: -1,

    avatarRadius,
    avatarPadding,

    idFontSize,
    nameFontSize,
    titleVerticalGap,

    textRectHPadding,
    textRectVPaddingTop,
    textRectVPaddingBottom,
    textRectOffset,
    textRectHeight,

    beforePropsGap,
    propFontSize,
    propVerticalGap,

    textRectStartY,

    buttonRadius: 20,
    buttonPadding: 10,
    buttonOffsetX: 5,
    buttonOffsetY: -15,


  };

}


function cardCreate(params: RenderFunctionProps) {

  const { createAvatar, createText, createButtons } = params.core;

  params.nodes
    .select("rect")
    .attr("display", 'none');

  createAvatar(
    params.cardContents,
    d => Item(d.data.item).avatarUrl(),
    'avatar',
    0, 0, cl.avatarRadius, cl.avatarPadding,
  );

  params.cardContents.select('.avatar')
    .on("click", (event, d) =>  openInfoPopup(d.data.item) );

  const { gAvatar: greenPlusAvatarGrp } = createAvatar(
    params.emptyCardContents,
    () => greenPlus,
    'plusButton',
    0, 0, cl.avatarRadius, cl.avatarPadding,
  );

  greenPlusAvatarGrp
    .style('opacity', 0.5);

  params.emptyCardContents.select('.plusButton')
    .on("click", (event, d) => openPlacementPopup(d.data.id, d.parent ? params.core.findItem(d.parent?.data.id) : undefined) );

  // const accountNumber = (d: DNode) => {
  //   const id = pv(d, replaceProperty('r.id'));
  //   const offset = Number(pv(d, 'vmdl.offset'));
  //   if (offset > 1) {
  //     return `${id} / ${offset}`;
  //   }
  //   return id;
  // };

  const textRectWidth = cl.cardWidth - cl.hPadding*2;

  params.cardContents
    .append("rect")
    .attr("class", "info-rect")
    .attr("width", textRectWidth)
    .attr("height", cl.textRectHeight)
    .attr("x", 0)
    .attr("y", cl.textRectStartY)
    .attr("stroke", "#555")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5)
    .attr("rx", 5)
    .attr("fill", "rgba(255,255,255,0.8)")
    .on("click", (event, d) => openInfoPopup(d.data.item) );

  const titleText = createText(
    params.cardContents,
    textRectWidth / 2,
    cl.textRectStartY + cl.textRectVPaddingTop,
    'middle',
    textRectWidth - cl.textRectHPadding*2,
  )

    .add({
      class: 'card-title',
      text: (d: DNode) => shortenCutEnd(pv(d, props.titleField), props.titleFieldMax),
      fontSize: cl.nameFontSize,
      style: () => `font-weight: bold;`,
    })
    .space(cl.titleVerticalGap)
    .add({
      class: 'card-title',
      text: (d: DNode) =>  shortenCutEnd(pv(d, props.subtitleField), props.subtitleFieldMax),
      fontSize: cl.idFontSize,
      style: () => `font-weight: bold;`,
    });


  if (props.properties && props.properties.length > 0) {

    const propsYStart = cl.textRectStartY + cl.textRectVPaddingTop + titleText.height() + cl.beforePropsGap;

    const propNames = createText(params.cardContents, cl.textRectHPadding, propsYStart);

    const pTypes = params.propertyTypes.value || {};

    for(const prop of props.properties) {
      propNames.add({
        class: 'card-props',
        text: pTypes[prop]?.title || prop,
        fontSize: cl.propFontSize,
      });
      propNames.space(cl.propVerticalGap);
    }

    const availWidth = cl.cardWidth - cl.hPadding - cl.textRectHPadding;
    const propValues = createText(
      params.cardContents,
      availWidth,
      propsYStart,
      "end",
      availWidth,
    );

    for(const prop of props.properties) {
      const propTitleLength = pTypes[prop]?.title?.length || 0;
      propValues.add({
        class: 'card-props',
        text: d => pv(d, prop) || '-',
        fontSize: cl.propFontSize,
      }, availWidth - propTitleLength * cl.propFontSize * 0.7);
      propValues.space(cl.propVerticalGap);
    }

  }

  params.cardContents.selectAll<SVGTextElement, DNode>('.card-title')
    .on("click", (event, d) => openInfoPopup(d.data.item) );

  // open one level, open 3 levels buttons
  const buttons = createButtons(
    params.nodes,
    cl.hMargin + cl.cardWidth - cl.buttonOffsetX,
    cl.vMargin + cl.cardHeight - cl.buttonOffsetY,
    {
      anchorEnd: true,
      horizontal: true,
    },
  );

  buttons
    .circle(cl.buttonRadius, {
      id: 'open-1',
      hidden: (d: DNode) => !d.data.childrenCount || Item(d.data.item).level() < 0,
      icon: (d: DNode) => {
        if (d.data.loading)
          return { path: params.core.icons.loading, style: "animation: rotate 0.5s linear infinite;"  };
        if (d.data.open)
          return { path: params.core.icons.arrowDown, rotate: 0 };
        return { path: params.core.icons.arrowDown, rotate: -90 };
      },
      onClick: (event: unknown, d: DNode) => params.methods.toggleKids(d.data.id, props.shortFetch),
    })
    .circle(cl.buttonRadius, {
      id: 'open-3',
      hidden: (d: DNode) => !d.data.childrenCount || d.data.wasOpen,
      icon: (d: DNode) => {
        if (d.data.loading)
          return { path: params.core.icons.loading, style: "animation: rotate 0.5s linear infinite;"  };
        if (d.data.open)
          return { path: params.core.icons.arrowDown3, rotate: 0 };
        return { path: params.core.icons.arrowDown3, rotate: -90 };
      },
      onClick: (event: unknown, d: DNode) => params.methods.toggleKids(d.data.id, props.longFetch),
    });

  // target button
  const buttonsTarget = createButtons(
    params.nodes,
    cl.hMargin + cl.buttonOffsetX,
    cl.vMargin + cl.cardHeight - cl.buttonOffsetY,
    { anchorEnd: false, horizontal: true },
  );

  buttonsTarget
    .circle(cl.buttonRadius, {
      id: 'target',
      hidden: (d: DNode) => d.data.isEmpty || itemsEqual(d.data.item, currentRootItem.value),
      icon: (d: DNode) => ({ path: params.core.icons.target }),
      onClick: (event: unknown, d: DNode) => params.methods.setCurrentAccountByNodeId(d.data.id, d.data.item) ,
    });

}

function cardUpdate(params: RenderFunctionProps) {

  params.cardContents
    .select("rect")
    .attr("fill", (d: DNode) => !d.data.isEmpty ? d.data.item.rowColor?.background || 'rgba(255,255,255,0.8)' : 'none');

  params.cardContents
    .selectAll(".card-title")

    .attr("fill", (d: any) => d.data.item.rowColor?.title || '#000');

  params.cardContents
    .selectAll(".card-props")

    .attr("fill", (d: any) => d.data.item.rowColor?.text || '#000');

}


function onTreeFetched(result: TreeFetchResult) {
  currentRootItem.value = result.root.item;
}

function onInitialized(methods: TreeRenderComponentMethods) {
  treeMethods = methods;

  const extAccountId = useNavigation().param('accountId');
  const extOffset = useNavigation().param('offset') || 1;

  if (! extAccountId) return;

  const nodeId = createNodeId(Number(extAccountId), Number(extOffset));

  // console.log('external set account=', nodeId);
  methods.setCurrentAccountByNodeId(nodeId);
}

function onNavigationButtonClicked(button: NavigationButton) {
  if (button.isReset) {
    treeMethods?.setCurrentAccountByNodeId(undefined);
    return;
  }
  if (! button.item) return;
  treeMethods?.setCurrentAccountByNodeId(nodeIdFromItem(button.item), button.item);
}

function placementConfirmText() {

  const kid = currentKidToPlace.value;
  const parent = currentPlacementParentItem.value;
  const dst = parseEmptyNodeId(currentPlacementDestNodeId.value);

  if (! dst || ! kid || ! parent ) throw new Error('Problems with empty node id!');

  // eslint-disable-next-line no-nested-ternary
  let legText = dst.legNumber === 1
    ? lt('left_leg')
    : dst.legNumber === 2 ? lt('right_leg') : dst.legNumber;

  if (props.forcePlacementMode) {
    legText = '';
  }

  return { kid: kid.namePlusId(), parent: parent.namePlusId(), leg: legText };
}

</script>


<template>
  <TreeRender
    :properties="propsWithCustom"
    :cardLayout="cl"
    :cardCreate="cardCreate"
    :cardUpdate="cardUpdate"

    :treeId="treeId"
    :headerTitles="headerTitles"
    :colors="colors"
    :inputProperties="inputProperties"
    :backgroundColor="backgroundColor"
    :initialFetch="initialFetch"
    :uplineLimit="uplineLimit"

    :useTreeOptions="{ showInactive: showInactive, maxNodesPerRequest: maxNodesPerRequest, placementMode: forcePlacementMode }"

    @tree-fetched="onTreeFetched"
    @initialized="onInitialized($event);"
  >
    <template #above="{ fullscreen, item, loading }">
      <slot
        name="above"
        :fullscreen="fullscreen"
        :item="item"
        :loading="loading"
        :data-loading="loading ? 'true' : undefined"
        :xs="xs"
        :myId="myAccountId"
        :canEdit="canEditPropertiesOf(item?.accountId())"
      />
    </template>

    <template #belowAccountSearch>
      <div class="w-100 text-center">
        <v-btn
          v-for="button in navigationButtons"
          :key="button.title"
          variant="text"
          size="x-small"
          color="rgba(128,128,128,0.9)"
          @click="onNavigationButtonClicked(button)"
        >
          {{ button.title }}
        </v-btn>
      </div>
    </template>
    <template #default="{ containerRef }">
      <MemberInfoPopup
        v-if="infoPopupOpenItem"
        v-model="infoPopupOpen"
        :treeId="props.treeId"
        :properties="popupProperties || []"
        :data="infoPopupOpenItem"
        :propertyTitles="headerTitles"
        :buttonMode="false"
        :attachTo="containerRef"
        :titleField="titleField"
        :subtitleField="subtitleField"
      >
        <div v-if="editableProperties?.length" class="mt-4" />
        <EditProperty
          v-for="prop in editableProperties"
          :key="prop"
          :alias="prop"
          :accountId="infoPopupOpenItemItem?.accountId()"
          :positionId="infoPopupOpenItemItem?.positionId()"
          :treeId="props.treeId"
          :inputProperties="{ ... inputProperties || {}, clearable: false }"
          :readonly="!canEditPropertiesOf(infoPopupOpenItemItem?.accountId())"
        />
      </MemberInfoPopup>

      <v-dialog
        v-model="placementPopupOpen"
        width="auto"
        :attach="containerRef"
      >
        <v-alert
          v-if="disablePlacement"
          type="info"
        >
          {{ disabledPlacementText || lt('placement_disabled') }}
        </v-alert>

        <v-card v-else v-show="placementPopupState === 'open'">
          <v-card-text>
            <FrontlineTable
              :treeId="frontlineTreeId"
              :accountId="myAccountId"
              :properties="frontlineProperties"
              :headerTitles="props.headerTitles"
              autoSwitchMode
              :filter="frontlineFilter"
              disableSearch
              :popupInfoOnField="'-1'"
            >
              <template #cardAvatar="avatarProps">
                <div>
                  <MemberAvatar
                    :size="30"
                    :src="greenPlus"
                    :title="avatarProps.title"
                    @click="selectKidToPlace(avatarProps.data)"
                  />
                </div>
              </template>
              <template #s.id="{ item, value }">
                <MemberAvatar
                  v-if="item"
                  :src="greenPlus"
                  :size="30"
                  class="mr-1"
                  title="Add"
                  @click="selectKidToPlace(item)"
                />
                {{ value }}
              </template>
            </FrontlineTable>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="primary" @click="placementPopupOpen = false">{{ $t('dialogs.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="placementPopupState === 'confirmation'">
          <v-card-title>{{ lt('place_confirm_header') }}</v-card-title>
          <v-card-text>
            {{ lt('place') }}
            <b>{{ placementConfirmText().kid }}</b>
            {{ lt('under') }}
            <b>{{ placementConfirmText().parent }}</b>
            {{ placementConfirmText().leg }}
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="primary" @click="placementPopupState = 'open'">{{ $t('dialogs.back') }}</v-btn>
            <v-btn color="primary" @click="placementConfirmed">{{ $t('dialogs.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>

        <v-alert
          v-else-if="placementPopupState === 'error'"
          type="error"
          closable
          :elevation="2"
          @click:close="placementPopupOpen = false"
        >
          {{ lt('error_placing', ['']) }}
        </v-alert>
      </v-dialog>
    </template>
  </TreeRender>
</template>

<style lang="scss" scoped>

.navButtons {
  background-color: rgba(50, 50, 50, 0.1);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

:deep(.plusButton *),
:deep(.avatar),
:deep(.info-rect),
:deep(.info-rect *),
:deep(.button-open-1 *),
:deep(.button-open-3 *),
:deep(.button-target *) {
  cursor: pointer;
}

:deep(.avatar),
:deep(.button-target),
:deep(.plusButton) {
  transition: transform 0.33s ease-in-out;
}

:deep(.button-target:active) {
  transform: scale(0.5);
}

:deep(.avatar:hover),
:deep(.plusButton:hover) {
  transform: scale(0.9);
}

:deep(.avatar:not(:hover)),
:deep(.plusButton:not(:hover)) {
  transform: none;
}
</style>
