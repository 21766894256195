<template>
  <v-progress-linear
    v-model="resPercent"
    :height="size"
    :color="barColor"
    :bgColor="bgColor"
    :striped="striped"
    :rounded="rounded"
  >
    <v-progress-circular
      v-if="loading"
      :data-loading="true"
      indeterminate
      color="primary"
      :size="size"
    />
    <slot v-else-if="!noLabel">
      <span :class="textClass">{{ label || `${percent}%` }}</span>
    </slot>
  </v-progress-linear>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  percent?: number;
  label?: string;
  loading?: boolean;

  noLabel?: boolean;

  size?: number;
  color?: string;
  bgColor?: string;
  colorDone?: string;
  textClass?: string;
  striped?: boolean;
  rounded?: boolean;
}>(), {
  percent: 0,
  label: undefined,
  size: 30,
  noLabel: false,
  color: 'amber',
  bgColor: undefined,
  colorDone: 'green',
  textClass: 'text-strong',
  title: undefined,
  loading: false,
  striped: false,
  rounded: false,
});


const resPercent = computed({
  get: () => props.percent,
  set: () => null,
});

 
// const label = computed(() => {
//   const res: Array<string> = [];
//   if (props.title) res.push(props.title);
//   if (props.showAbsolute) res.push(`${props.value} ${lt('out_of')} ${props.max}`);
//   if (props.showPercent && props.max) res.push(`${percent.value}%`);
//   return res.join(' ');
// });

const barColor = computed(() => {
  if ( props.loading ) return 'rgba(0,0,0,0.3)';
  if ( props.percent >= 100 ) return props.colorDone;
  return props.color;
});

</script>
