
export type InputMdiColorData = {
  prefix: 'bg' | 'text',
  maxLighten: number;
  maxDarken: number;
  maxAccent: number;
  colors: string[];
}

export const inputMdiColors = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'grey',
  'blue-grey',
];

export const inputMdiColorDefaults: InputMdiColorData = {
  prefix: 'bg',
  maxLighten: 5,
  maxDarken: 4,
  maxAccent: 4,
  colors: inputMdiColors,
};
