/* eslint-disable camelcase */
export default {
  operation: "Operation",
  date: "Date",
  amount: "Amount",
  operation_history_for: "Operation history for {n1}",
  loading: 'loading...',

  op_adjustment: "Adjustment",
  op_commission_payment: "Commission Payment",
  op_payout: "Withdrawal",
  op_account_to_account_transfer: "Account-to-Account Transfer",
  op_administrative_transfer: "Administrative Transfer",
  op_purchase_order: "Purchase Order",
  op_undefined_operation: "Undefined Operation",
};
