import { gravComponentSchema } from '../deep-form-grav';
import { PayoutRequestProps, defaults } from '@/components/ui/PayoutRequest';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<PayoutRequestProps>({
  title: 'Payout Request',
  link: 'https://www.notion.so/mlmsoft/PayoutRequest-883edaac284d4a329a14aae1dbf2d886',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('walletId').as(l.clone('walletId'))
  .title('Default wallet').mandatory()
  .col(4);

s.add('confirmBy').input('dictionary').data([
  { title: 'Email', value: 'email' },
  { title: 'Phone', value: 'phone' },
])
  .props({ clearable: true })
  .title('Requires confirmation by').mandatory(false).col(4);


s.add('edit').input('dictionary').data(['all', 'none', 'editable', 'except_editable'])
  .title('Edit mode').mandatory(false).col(4);

//

const propInput = s.spawn<string>('alias').input('dictionary').dataAsync(src.propertiesProfileNoPrefix)
  .props({ hideDetails: true }).init(() => '').mandatory();

s.add('paymentProperties').input('dlist').data(propInput.item())
  .title('Payment properties to fill').mandatory(false)
  .convert((v) => v?.length ? v : undefined)
  .col(6);


s.add('editableProperties').input('dlist').data(propInput.item())
  .title('Editable properties').mandatory(false)
  .col(6);

const paymentSystemInput = s.spawn<number>('paymentSystem').input('dictionary').dataAsync(src.paymentSystems)
  .init(() => 0).props({ hideDetails: true });

s.add('paymentList').input('dlist').data(paymentSystemInput.item())
  .title('Allowed Payment systems').mandatory(false)
  .col(12).item();

s.add('textStyleType').as( bl.clone('number') )
  .title('Style type of wallet\'s name')
  .col(3);

s.add('showTextPopup').input('text')
  .title('Popup message after invoice creating')
  .col(4);

s.add('showDescriptionPopup').input('text')
  .title('Popup description after invoice creating')
  .col(5);

function text() {
  return `
<b>Payout Request</b>
Payout request form doesn’t initiate any actual payment. Its purpose is just to collect data from your users that your financial department then uses to actually perform a payment. So you may create different payout options with different sets of fields.
`;
}
