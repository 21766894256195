import { gravComponentSchema } from '../deep-form-grav';
import { MetricWidgetProps, defaults } from '../../../../components/ui/MetricWidget';

type WithSlots = MetricWidgetProps & {
  '#default': string;
};

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<WithSlots>({
  title: 'MetricWidget',
  link: 'https://www.notion.so/mlmsoft/MetricWidget-f25f5e3f5965462e97b2c3e96f61d67e',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('propertyAlias').as(l.clone('propertyMarketingNoPrefix'))
  .title('Marketing property')
  .col(4);

s.add('backgroundClass').input('mdiColor').title('Background class').col(3);

s.add('iconClass').input('icon').title('Icon').props({ clearable: true }).col(3);

s.add('fontSize').as(bl.clone('number')).title('Font size').col(2);


s.add('treeId').as(l.clone('selectTree'))
  .title('Use data from tree')
  .col(3);

s.add('numberPeriodsBack').as(bl.clone('number')).props({ clearable: true })
  .title('Number of Periods Back')
    .col(3);

s.add('plainType').input('boolean').title('Plain type')
  .desc('No postfixes or formatting')
  .col(3);

s.add('totalRound').input('boolean').title('Total round')
  .desc('Perform round-up opetaion on total value')
  .col(3);


s.add('title').input('text').title('Title override').col(8);

s.add('postfix').input('text').title('Postfix').col(4);


s.add('hintText').input('text')
.title('Hint')
.desc('Hint to show on hover')
.flag('isText')
.col(4);

s.add('hintIconText').input('text')
.title('Hint Icon')
.desc('Hint Icon to show on hover')
.flag('isText')
.col(4);

s.add('linkTemplate').input('text')
.title('Link url')
.desc('Something like /{{site_language}}/network/view-network/')
.col(4);

s.add('valueOnEmpty').input('text')
.title('Value on empty')
.desc('Value to show when there is no data')
.props({ clearable: true })
.col(4);

s.add('#default').input('vtextarea').props({ clearable: true })
  .flag('isSlot').flag('isText').flag('slotParams', 'props')
  .title('Value as html slot').col(12);

function text() {
  return `
<b>UI Component: Single Metric Widget</b>
This component can show a specific compensation plan metric or a virtual wallet balance.There could be a number of similar components shown on the dashboard. Please specify compensation plan properties (metrics) to be shown this way, as well as color and icon for each metric. We use Material Design icon library so you can pick any of them here: https://pictogrammers.com/library/mdi/
`;
}
