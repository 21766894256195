<template>
  <Filter
    v-if="dfData"
    class="flex-1-1"
    :fields="filterInputFields"
    :initValue="currentFilter"
    @set-filter="onSetFilter"
  />
</template>

<script setup lang="ts">
import { InputProps, InputEmits } from '@/deep-form/models/dform-input-core';
import { computed, ref } from 'vue';

import { UIFilterItem } from '@/models/filter-interface';
import { FilterItem } from '@ui-api3-sdk/api/api3';

import { useProperties } from '@/composables/useProperties';
import { isBackendProperty } from '@/utils/properties-mapping-service';

import Filter from '@/components/ui/base/Filter.vue';

const props = defineProps<InputProps<FilterItem[], string[]>>();
const emit = defineEmits<InputEmits<FilterItem[]>>();

const currentFilter = ref<FilterItem[]>(props.modelValue);

function onSetFilter(filter: FilterItem[]) {
  currentFilter.value = filter;
  emit('update:modelValue', currentFilter.value);
}

const filterInputFields = computed(() => {
  if (!props.dfData) return [];

  try {
    const usedProperties = useProperties( props.dfData || [] );

    return props.dfData.map((property) => {
      const pType = usedProperties.propertyTypes.value?.[property];
      return {
        value: property,
        text: pType?.title || property,
        fieldType: pType?.fieldType || 'string',
      } as UIFilterItem;
    }).filter((f) => isBackendProperty(f.value));
   
  }   catch(e: any) {
    console.log(e);
  }

  return [];
});


</script>
