import { ref , watch } from 'vue';

import { useAccountStore } from '@/pinia/useAccountStore';
import { AwaitableRefs } from './composables-interface';
import { AccountVM } from '@ui-api3-sdk/api/api3';
import { Item, ItemType, TableGridItem } from '@/models/table-grid-interface';
import { createTableGridItem, replaceProperties } from '@/utils/properties-mapping-service';

interface useAccountPropertiesItemRefs {
  srcItem: TableGridItem | null;
  item: ItemType | null;
}

export const useAccountPropertiesItem = (properties?: string[])
  : AwaitableRefs<useAccountPropertiesItemRefs> => {

  const srcItem = ref<TableGridItem | null>(null);
  const item = ref<ItemType | null>(null);
  const isLoading = ref(true);
  const isError = ref(false);

  const accountStore = useAccountStore();
  const currentAccount = accountStore.getAccountValue();

  function refetchAccount() {
    isLoading.value = true;
    accountStore.getAccountAsync().finally( () => {
      isLoading.value = false;
    });
  }

  function refreshPropertyValue(currentAccount: AccountVM ) {
    const needProperties = properties || [ ... currentAccount.profile.map( (p) => `p.${p.alias}` ), 's.id', 'r.id' ];
    srcItem.value = createTableGridItem(replaceProperties(needProperties), { s: currentAccount });
    item.value = Item(srcItem.value);
  }

  watch( () => accountStore.getAccountValue(), (currentAccount) => {
    if ( currentAccount ) {
      refreshPropertyValue(currentAccount);
    }
  });

  if ( ! currentAccount ) {
    refetchAccount();
  } else {
    isLoading.value = false;
    refreshPropertyValue(currentAccount);
  }

  return { item, srcItem, isLoading, isError };
};
