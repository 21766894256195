import { gravComponentSchema } from '../deep-form-grav';
import { MetricChartItem, MetricChartLineProps, defaults } from '@/components/ui/MetricChartLine';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<MetricChartLineProps>({
  title: 'MetricChartLine',
  link: 'https://www.notion.so/mlmsoft/MetricChartLine-399c0be7d3e8419384c84aa4dcac7a98',
  text: text(),
},
  defaults,
);

export default toExport;
/*
  legendPosition?: 'top' | 'bottom' | 'left' | 'right' | 'chartArea' | 'none';
  forceHeight?: string;
*/

s.add('title').input('text').title('Title override').col(4);
s.add('limit').as(bl.clone('number')).title('Limit periods').col(3);
s.add('showCurrentPeriod').input('boolean').title('Show current period').col(3);
s.add('hideHeader').input('boolean').title('Hide header').col(2);

s.add('backgroundClass').input('mdiColor').title('Background class').col(3);
s.add('ticksColor').input('color').title('Ticks color').col(3);
s.add('legendPosition').input('dictionary').data(['top', 'bottom', 'left', 'right', 'chartArea', 'none']).title('Legend position').col(3);
s.add('forceHeight').input('text').title('Force height')
  .desc('You can use pixels or other css units. Example: 300px, 80vh, calc(80vh - 150px)').col(3);

s.add('gridWidth').as(bl.clone('number')).title('Grid width').col(2);
s.add('gridColor').input('color').title('Grid color').col(4);

s.add('axisWidth').as(bl.clone('number')).title('Axis width').col(2);
s.add('axisColor').input('color').title('Axis color').col(4);

s.add('hintText').input('text').title('Hint text').col(12);

const entryProps = {
  dfShowTitle: true,
  class: 'mr-md-4',
  clearable: true,
  //hideDetails: true,
  density: 'compact' as const,
  // variant: 'outlined' as const,
};

s.add('charts').input('dlist').data(
  l.spawn<MetricChartItem>().input('dform').data([
    l.spawn<string>('metric').as(l.clone('propertyMarketing')).title('Metric').col(6).props(entryProps).item(),
    l.spawn<string>('title').as(l.clone('translatedText')).title('Title override').col(6).props(entryProps).item(),
    l.spawn<string>('color').input('color').title('Color').col(3).props(entryProps).item(),
    l.spawn<number>('lineWidth').as(bl.clone('number')).title('Line width').col(3).props(entryProps).item(),
    l.spawn<number>('pointRadius').as(bl.clone('number')).title('Point radius').col(3).props(entryProps).item(),
    l.spawn<boolean>('fill').input('boolean').title('Fill').col(3).props(entryProps).item(),
  ])
  .props({ style: 'border: 1px solid #ccc; padding: 20px; margin: 10px; border-radius: 5px;' })
  .init(() => ({ metric: '', title: '', color: '', lineWidth: 2, pointRadius: 3, fill: false }))
  .item(),

).title('Charts').col(12);


function text() {
  return `
<b>UI Component: Metric History Chart</b>
This component can show a chart of historic values of a specified compensation plan property (metric). There could be a number of similar components shown on the dashboard. You may specify what properties you want to be shown, and chart styles (color, line thickness, filled or not) if you don’t want to spice things up.
  `;
}
