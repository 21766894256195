import { reactive, ref, unref } from "vue";
import { TableGridItem, TableGridPaginationState } from "@/models/table-grid-interface";
import { apiErrorToString } from '@ui-api3-sdk/api/api3';
import { ReactiveOrStaticDictionary } from "./useProperties";

interface UseTableGridProperties {
  properties: string[],
  requestProperties?: string[],
  headerTitles?: ReactiveOrStaticDictionary,
  fetch: (properties: string[], pagination: TableGridPaginationState) => Promise<{ totalItems: number, tableItems: TableGridItem[] }>,
  maximumLimit?: number,
  unknownErrorMessage?: string,
}

const TABLE_GRID_DEFAULT_LIMIT = 500;

export const useTableGrid = (params: UseTableGridProperties) => {

  const currentPagination = ref<TableGridPaginationState>({
    page: 0,
    limit: 25,
  });

  const errorMessage = ref('');
  const loading = ref(false);
  const items = ref<TableGridItem[]>([]);
  const totalItems = ref(0);

  function setItems(newItems: TableGridItem[]) {
    items.value = newItems;
    tableProps.items = newItems;
  }

  function resultRequestProps() {
    return params.requestProperties ?? params.properties;
  }

  async function doFetch() {
    if (!params.fetch) return;
    try {
      const result = await params.fetch(resultRequestProps(), currentPagination.value);
      totalItems.value = result.totalItems;
      items.value = result.tableItems;
    } catch (e) {
      setLoading(false);
      errorMessage.value = apiErrorToString(e, params.unknownErrorMessage ?? 'Unknown error');
      console.error(e);
    }

  }


  function onDataRequest(req: TableGridPaginationState) {

    const old = currentPagination.value;
    currentPagination.value = req;

    if (old.page === req.page && totalItems.value) {
      if (totalItems.value <= Math.min(old.limit, req.limit)) {
        if (!req.orderBy) return;
        const [by, dir] = Object.entries(req.orderBy)[0];
        items.value = [...items.value.sort((a, b) =>
          sortTableGridItem(a, b, ['t.level', by], dir))];
        return;
      }
    }

    doFetch();
  }

  const tableProps = reactive({
    properties: params.properties,
    headerTitles: params.headerTitles,
    loading,
    items,
    totalItems,
    maximumLimit: params.maximumLimit ?? TABLE_GRID_DEFAULT_LIMIT,
    errorMessage,
    onDataRequest,
    onUpdateError: (value: string) => errorMessage.value = value,
  });

  async function csvExport() {

    const pagination = currentPagination.value;

    pagination.limit = params.maximumLimit ?? TABLE_GRID_DEFAULT_LIMIT;
    pagination.page = 0; //todo: really, not 0 ?

    const { tableItems } = await params.fetch(resultRequestProps(), pagination);

    const csvContent = [
      params.properties.map(p => unref(params.headerTitles)?.[p] ?? p).join(";"),
      ...tableItems.map(
        item => params.properties.map(
          p => item[p]?.presentable,
        )
          .join(";"),
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'export.csv';
    a.click();

    URL.revokeObjectURL(url);
  }

  function setLoading(value: boolean) {
    loading.value = value;
  }

  const tableMethods = {
    setLoading,
    getPagination: () => currentPagination.value,
    csvExport,
    doFetch,
    setItems,
  };

  return { tableProps, tableMethods } as const;

};

function sortTableGridItem(a: TableGridItem, b: TableGridItem, by: string[], dir: 'ASC' | 'DESC'): -1 | 0 | 1 {
  for (const p of by) {
    const ca = a[p]?.raw;
    const cb = b[p]?.raw;

    if (ca === undefined && cb === undefined) continue;

    const res = customSort(ca, cb, dir);
    if (res !== 0) return res;
  }
  return 0;
}

// this is used when result fits in one page and user changes sort order
export function customSort(
  a: string | number | boolean | object | null | undefined,
  b: string | number | boolean | object | null | undefined,
  dir: 'ASC' | 'DESC',
): -1 | 0 | 1 {
  a = a == null ? '' : a;
  b = b == null ? '' : b;

  if (typeof a === 'string' && typeof b === 'string') {
    a = a.toLowerCase();
    b = b.toLowerCase();
  }

  if (dir === 'ASC') {
    if (a < b) return -1;
    if (a > b) return 1;
  } else if (dir === 'DESC') {
    if (a > b) return -1;
    if (a < b) return 1;
  }

  return 0;
}
