 

import {  watch, ref, computed } from "vue";
import i18n from "@/i18n";
import { propertyTranslation } from "@/utils/properties-translation";
import { getLocaleRef } from "@/utils/utils";

const loadedModules: string[] = [];

function loadModule(name: string, language?: string) {

  const lang = language || getLocaleRef().value;
  const fileName = `${name}/${lang}`;

  if (loadedModules.includes(fileName)) {
    // console.log('i18n already loaded / in load:', fileName);
    return;
  }

  // console.log('i18n loading:', fileName);
  loadedModules.push(fileName);

  return import(`../locales/${name}/${lang}.js`).then(({ default: translation }) => {
    Object.keys(translation).forEach(item => {

      const dstVariableName = name === 'core' ? item : `${name}.${item}`;

      if (!i18n.global.te(dstVariableName)) {
        i18n.global.mergeLocaleMessage(lang, { [dstVariableName]: translation[item] });
        // console.log(lang, { [dstVariableName]: translation[item] });
      }
    });
    console.log('i18n loaded:', fileName);
  }, () => {
    console.log('i18n built-in module missing:', fileName);
    if (lang !== 'en') loadModule(name, 'en');
  });
}

export function useLocalization<ST extends string>(moduleName?: string, dictionary?: ST[]) {

  type VOCABULARY = { [key in ST]: string };

  const locale = getLocaleRef();
  const language = locale.value;
  const isLoading = ref(false);

  if (moduleName) {
    watch(locale, async () => {
      isLoading.value = true;
      await loadModule(moduleName);
      isLoading.value = false;
    }, { immediate: true });
  }

  function setLocale(lang: string) {
    const locale = getLocaleRef();
    locale.value = lang;
  }

  function localAlias<T extends keyof VOCABULARY>(code: T) {
    return `${moduleName}.${code}`;
  }

  function localTranslation<T extends keyof VOCABULARY>(code: T, ...args: any) {
    const la = localAlias(code);
    return i18n.global.t(la, ...args as [any]);
  }

  const localVocabulary = computed<VOCABULARY>(() =>
    dictionary?.reduce((acc, cur) => {
      acc[cur] = localTranslation(cur);
      return acc;
    }, {} as VOCABULARY) ?? {} as VOCABULARY,
  );

  function reactiveList(prefix: string, properties: string[], override: Record<string,string> = {}) {
    return computed<Record<string,string>>(() => {
      const lt = properties.reduce((acc, cur) => ({ ...acc, [cur]: localTranslation(`${prefix}_${cur}` as ST) }), {});
      return { ... lt, ...override };
  });
  }


  return {
    la: localAlias,
    lt: localTranslation,
    lv: localVocabulary,
    reactiveList,

    pt: propertyTranslation,

    te: i18n.global.te,
    tc: i18n.global.tc,
    t: i18n.global.t,
    d: i18n.global.d,

    setLocale,
    locale,
    language,

    isLoading,
  };

}
