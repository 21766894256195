/* eslint-disable camelcase */
export default {
  verification_code: "Код верификации",
  code_was_sent_to: 'Код был отправлен на',
  your_email: 'Ваш адрес электронной почты',
  your_phone: 'Ваш номер телефона',
  your_email_confirmed: 'Ваш адрес электронной почты {0} подтверждён!',
  your_phone_confirmed: 'Ваш номер телефона {0} подтверждён!',
  fill_in_value: "Пожалуйста, заполните поле с правильным значением, чтобы отправить код подтверждения.",
  click: "Нажмите",
  here: "здесь",
  to_send_code_email: "для отправки кода подтверждения на ваш адрес электронной почты",
  to_send_code_phone: "для отправки кода подтверждения на ваш номер телефона",
  optional_confirm_message: "Шаг подтверждения является необязательным, Вы можете пропустить его, нажав кнопку Изменить прямо сейчас.",
  send_code_button: "Отправить код",
  verify_button: "Подтвердить код",
  not_unique: "Значение не уникально. Пожалуйста, выберите другое.",
  code_incorrect: 'Код неверный, пожалуйста, введите правильный код',
  error_checking_uniqueness: "Ошибка проверки уникальности",
  verify_error: 'Ошибка при проверке кода',
  send_code_error: 'Ошибка при отправке кода подтверждения!',
  loading: 'Загрузка...',
};
