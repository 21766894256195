/* eslint-disable camelcase */
export default {
  verification_code: "Verification code",
  code_was_sent_to: 'Code was sent to',
  your_email: 'your email address',
  your_phone: 'your phone number',
  your_email_confirmed: 'Your e-mail {0} is now confirmed!',
  your_phone_confirmed: 'Your phone number {0} is now confirmed!',
  fill_in_value: "Please fill in the field with correct value to send confirmation code.",
  click: "Click",
  here: "here",
  to_send_code_email: "to send confirmation code to your email address",
  to_send_code_phone: "to send confirmation code to your phone number",
  optional_confirm_message: "The confirmation step is optional, you can skip it by pressing Change button right now.",
  send_code_button: "Send code",
  verify_button: "Verify code",
  not_unique: "Value is not unique. Please choose another one.",
  code_incorrect: 'Code missmatched, please submit the correct code',
  error_checking_uniqueness: "Error checking uniqueness",
  verify_error: 'Error verifying code',
  send_code_error: 'Error sending confirmation code!',
  loading: 'Loading...',
};
