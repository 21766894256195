<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <BasicCard
    :title="title || $t('uiAutoship.autoship')"
    :headerClass="headerClass"
  >
    <v-alert
      v-if="error"
      type="error"
      closable
    >
      {{ error }}
    </v-alert>

    <v-card :class="{ 'bg-red': text === lt('no'), 'bg-green': text === lt('yes') }" class="shopify-card" :text="text" />
  </BasicCard>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import { AutoshipProps, defaults } from './Autoship.ts';

import { useAwaitAccountId, useLocalization } from '@/composables';
import { AccountFullProfileVM, apiErrorToString, useAccountProfileApi } from '@ui-api3-sdk/api/api3';

import { getMissingServerTranslations } from '@/utils/properties-translation';

import BasicCard from './base/BasicCard.vue';

const { lt } = useLocalization('uiAutoship');

const props = withDefaults(defineProps<AutoshipProps>(), {
  title: undefined,
  headerClass: () => defaults.headerClass,
  alias: () => defaults.alias,
  value: () => defaults.value,
});

const error = ref('');
const text = ref(lt('no'));

const loading: Ref<boolean> = ref(true);

onMounted(async () => {
  fetch();
});

const fetch = async () => {
  loading.value = true;
  const aId = await useAwaitAccountId();

  await useAccountProfileApi()
    .getProfile({ id: aId }).then(response => {
      const fields = (response.data.payload || [] as AccountFullProfileVM[]);
      const foundShopify = fields.find((f) => f.alias === props.alias);

      text.value = foundShopify?.value?.presentable === props.value ? lt('yes') : lt('no');
    })
    .catch ((e) => {
      error.value = apiErrorToString(e);
    })
    .finally(() => {
      loading.value = false;
      getMissingServerTranslations();
    });

};
</script>

<style scoped>

  .shopify-card {
      display: flex;
      align-items: center;

      width: 60px;
      height: 50px;

      text-align: center;
  }

</style>
