<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-alert v-if="isSent" type="success">
      <slot name="success" :formMethods="formMethods">
        <div class="mb-4">{{ lt('sent_success') }}</div>
        <v-btn color="primary" @click="formMethods.reset()">
          {{ lt('send_another') }}
        </v-btn>
      </slot>
    </v-alert>
    <EasyForm
      v-else
      v-show="!localizationLoading"
      :class="formClass"
      :fields="fields"
      :onSubmitFn="onFormSubmit"
      @valid="errorMessage = ''"
    >
      <template v-for="(_, slot) in $slots" #[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>

      <template #submit="{ onSubmit, isValid, loading }">
        <slot name="submit" v-bind="{ onSubmit, isValid, loading }" />
      </template>
    </EasyForm>
    <v-alert
      v-if="errorMessage"
      type="error"
      clearable
      class="mt-4"
    >
      {{ errorMessage }}
    </v-alert>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { FeedbackProps, defaults } from './Feedback.ts';

import { apiErrorToString, useAccountSendApi } from '@ui-api3-sdk/api/api3';
import { useLocalization } from '@/composables';

import EasyForm from '@/easy-form/components/easy-form.vue';
import { template } from 'lodash';

const { lt, isLoading: localizationLoading } = useLocalization('uiFeedback');

const props = withDefaults(defineProps<FeedbackProps>(), {
  id: undefined,
  formClass: undefined,
  title: () => defaults.title,
  subjectTitle: () => defaults.subjectTitle,
  bodyTitle: () => defaults.bodyTitle,
});

const fields = props.customForm || [
  {
    name: 'subject',
    label: props.subjectTitle,
    input: 'String' as const,
    required: true,
  },
  {
    name: 'body',
    label: props.bodyTitle,
    input: 'Text' as const,
    required: true,
  },
];


const isSent = ref(false);
const errorMessage = ref('');
const loading = ref(false);

const formMethods = {
  reset: onReset,
  submit: onFormSubmit,
};

function onReset() {
  isSent.value = false;
  errorMessage.value = '';
  loading.value = false;
}


async function onFormSubmit(values: Record<string, string>) {
  try {
    errorMessage.value = '';
    loading.value = true;

    const subject = props.subjectTemplate ? template(props.subjectTemplate)(values) : values.subject;
    const body = props.bodyTemplate ? template(props.bodyTemplate)(values) : values.body;

    if (!subject || !body) {
      throw new Error('Subject and body are required. Check your templates.');
    }


    await useAccountSendApi().sendSupportMail({
      sendSupportMailReq: {
        subject,
        body,
      },
    });

    loading.value = false;
    isSent.value = true;

  } catch (err) {
    console.error('Error sending support mail:', err);
    errorMessage.value = apiErrorToString(err);
  }
}
</script>
