<template>
  <OverlayProgress :loading="loading" :error="error">
    <WidgetCard
      :backgroundClass="backgroundClass"
      :headerClass="headerClass"
      :title="title"
      :hintIconText="hintIconText"
      :height="120"
    >
      <div class="relative">
        <v-icon size="45" class="background-icon">
          {{ iconClass }}
        </v-icon>
        <div class="foreground">
          <div class="d-flex flex-row justify-space-between align-center">
            <div />
            <div class="ml-auto tile_content" :style="`font-size: ${fontSize}px`">
              <slot>
                <span>{{ resultBigValue }}</span>
                <small v-if="resultSmallValue" class="small">{{ resultSmallValue }}</small>
              </slot>
              <span
                v-if="resultPostfix"
                class="text-caption top"
              >{{ resultPostfix }}</span>
            </div>
          </div>
        </div>
      </div>
    </WidgetCard>
  </OverlayProgress>
</template>

<script>
import OverlayProgress from './OverlayProgress.vue';
import WidgetCard from '../WidgetCard.vue';

export default {
  name: 'WidgetTemplate',
  components: { OverlayProgress, WidgetCard },
  props: {
    wMM: {
      type: Function,
      default(x) {
        return x;
      },
    },

    backgroundClass: {
      type: String,
      default: 'bg-primary',
    },

    headerClass: {
      type: String,
      default: undefined,
    },

    fontSize: {
      type: Number,
      default: 30,
    },

    displayValue: {
      type: [String, Number],
      default: '',
    },

    postfix: {
      type: String,
      default: '',
    },

    plainType: {
      type: Boolean,
      default: false,
    },

    totalRound: {
      type: Boolean,
      default: false,
      required: false,
    },

    iconClass: {
      type: String,
      default: '',
    },

    title: {
      required: false,
      type: String,
      default: '',
    },

    hintIconText: {
      required: false,
      type: String,
      default: '',
    },

     
    loading: {
      type: Boolean,
      default: true,
    },

    error: {
      type: String,
      default: '',
    },
  },

  computed: {

    resultPostfix() {
      return this.postfix || this.valueParts?.[0] || '';
    },

    resultBigValue() {
      if (this.plainType) return this.displayValue;
      if (this.totalRound) return this.wMM(Math.round(this.displayValue * 100).toString()).slice(0, -3);
      if (typeof this.displayValue === 'number') {
        return this.wMM(Math.round(this.displayValue * 100).toString()).slice(0, -2);
      }
      if (this.valueParts) return this.valueParts[1];
      return this.displayValue;
    },

    resultSmallValue() {
      if (this.plainType) return '';
      if (this.totalRound) return '';
      if (typeof this.displayValue === 'number') {
        return this.wMM(Math.round(this.displayValue * 100).toString()).slice(-2);
      }
      return '';
    },

    valueParts() {
      if (typeof this.displayValue === 'number' || !this.displayValue)
        return null;
      const dv = this.displayValue.split(' ');
      if (dv.length < 2)
        return null;
      const title = dv.pop();
      if (isNaN(+title) && !isNaN(+dv.join(''))) {
        return [title, dv.join(' ')];
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>

.top {
  vertical-align: top;
}

.small {
  display: inline;
  font-size: 80%;
  text-align: right;
  opacity: 1;
}

.tile_content {
  overflow: hidden;
  margin-left: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.relative {
  position: relative;
}

.background-icon {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0.2;
}

.foreground {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

</style>
