
import { useCountryApi, usePaymentSystemApi, useTreeApi, useWalletOperationTypeApi } from '@ui-api3-sdk/api/api3';
import { InputDictionaryItem } from '@/deep-form/components/InputDictionary';
import { getPropertiesList } from './helpers';
import { useWalletOperations } from '../../../../composables/useWalletOperations';
import { untilFalse } from '@/utils/reactive-helpers';

export async function properties() {
  return await getPropertiesList();
}

export async function propertiesMarketing() {
  return await getPropertiesList(undefined, 'm.');
}

export async function propertiesProfile() {
  return getPropertiesList(undefined, 'p.');
}

export async function propertiesMarketingNoPrefix() {
  return await getPropertiesList(undefined, 'm.', true);
}

export async function propertiesProfileNoPrefix() {
  return getPropertiesList(undefined, 'p.', true);
}

export async function propertiesProfileNoPrefixForRegistration() {
  return [... await getPropertiesList(undefined, 'p.', true),
    { title: 'Password', value: 'password' },
    { title: 'Password Confirm', value: 'password_confirm' },
    { title: 'Sponsor ID', value: 'sponsor_id' }];
}

export async function propertiesArray() {
  const props = await getPropertiesList();
  return props.map((p) => p.value as string);
}

export async function trees() {
  const res: InputDictionaryItem[] = [];

  const result = await useTreeApi().getTrees();
  result.data.payload.forEach((t) => res.push({ title: t.title, value: t.id }));

  return res;

}

export async function wallets() {
  const { wallets, loading } = useWalletOperations();
  await untilFalse(loading);
  return wallets.value.map((w) => ({ title: w.title, value: w.id }));
}

export async function countries() {
  const res = await useCountryApi()
    .getCountries({ limit: 500, page: 0 });
  const countries = res.data.payload.list as Array<{ id: string, title: string }>;
  return countries.map((c) => ({ title: c.title || c.id, value: c.id }));
}

export async function walletOperations() {
  const res = await useWalletOperationTypeApi()
    .getWalletOperationTypes({ limit: 500, page: 0 });
  const operations = res.data.payload.list;
  return operations.map((o) => ({ title: o.title || String(o.id), value: o.id }));
}

export async function paymentSystems() {
  const res = await usePaymentSystemApi().getPaymentSystemList({ limit: 100, page: 0 });
  return res.data.payload.map((p) => ({ title: p.title, value: p.id }));
}
