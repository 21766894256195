
import { ref, computed } from "vue";
import { usePlatformSettingApi , SettingVM } from '@ui-api3-sdk/api/api3';
import { InputByTypeProperties } from "@/models/form-builder-interface";

export enum LoginType {
  EMAIL_AS_LOGIN = 1,
  ID_AS_LOGIN = 2,
  CUSTOM_LOGIN = 3,
  PHONE_AS_LOGIN = 4,
}

export enum PasswordCreationType {
  AUTO = 0,
  BY_USER = 1,
}

export type EnrollSettings = {
  passwordCreationType: PasswordCreationType,
  loginType: LoginType,
  passwordMinLength: number | undefined,
  passwordHasSpecSymbols: boolean,
  passwordHasCamelcase: boolean,
  enrollWithoutSponsor: boolean,
  defaultSponsor?: number,
};

// self_enrollment_type
// access_role

function settingsManagerFactory(settings: SettingVM[]) {

  function get(name: string) {
    const f = settings.find((s) => s.name === name);
    if (!f) throw new Error(`Setting ${name} not found`);
    return f;
  }

  function getNumber(name: string) {
    const setting = get(name);
    const value = setting.values[0].value;
    if (/^\d+$/.test(value)) {
      return Number(setting.values[0].value);
    }
    return undefined;
  }

  function getBoolean(name: string) {
    return getNumber(name) === 1;
  }

  return { getNumber, getBoolean };
}

export const useEnrollSettings = (inputPropertiesOverride?: InputByTypeProperties) => {

  const settings = ref<EnrollSettings>({
    passwordCreationType: PasswordCreationType.BY_USER,
    loginType: LoginType.CUSTOM_LOGIN,
    passwordMinLength: undefined,
    passwordHasSpecSymbols: false,
    passwordHasCamelcase: false,
    enrollWithoutSponsor: false,
  });

  const isLoading = ref(true);
  const isError = ref(false);

  const inputProperties = computed(() => ({
    passwordMinLength: settings.value.passwordMinLength,
    passwordHasSpecSymbols: settings.value.passwordHasSpecSymbols,
    passwordHasCamelcase: settings.value.passwordHasCamelcase,
    ...inputPropertiesOverride,
  }));

  // if needed often reroute through pinia

  isLoading.value = true;

  // TODO: ENABLE WHEN API IS READY

  usePlatformSettingApi()
    .getEnrollSettings()
    .then((res) => {
      // console.log('enrollSettings:', res);
      const sm = settingsManagerFactory(res.data.payload);
      settings.value.passwordCreationType = sm.getNumber('password_creation_type') as PasswordCreationType;
      settings.value.loginType = sm.getNumber('login_type') as LoginType;
      settings.value.passwordMinLength = sm.getNumber('password_min_length');
      settings.value.passwordHasSpecSymbols = sm.getBoolean('password_has_spec_symbols');
      settings.value.passwordHasCamelcase = sm.getBoolean('password_has_camelcase');
      settings.value.defaultSponsor = sm.getNumber('default_sponsor');
      settings.value.enrollWithoutSponsor = sm.getBoolean('enroll_without_sponsor');
      console.log('fetched enroll settings:', settings.value);
    })
    .catch((err) => {
      console.error(err);
      isError.value = true;
    })
    .finally(() => {
      isLoading.value = false;
    });

  isLoading.value = false;
  return { settings, inputProperties, isLoading, isError };

};
