<template>
  <v-select
    v-model="value"
    :items="items"
    clearable
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps<{
  modelValue?: boolean;
}>();

const emit = defineEmits<{
  ( event: 'update:modelValue', value: boolean | undefined ): void;
}>();


const items = [
  // { title: t('common.undefined'), value: '' },
  { title: t('common.true'), value: true },
  { title: t('common.false'), value: false },
];


const value = computed<boolean>({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});


</script>
