import { gravComponentSchema } from '../deep-form-grav';
import { DownlineTableProps, defaults } from '@/components/ui/DownlineTable';

 
const { s, l, c, v, src, h, toExport } = gravComponentSchema<DownlineTableProps>({
  title: 'DownLine',
  link: 'https://www.notion.so/mlmsoft/DownlineTable-a5c5b139b4c449beb8b33448d2d51029',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('properties').as(l.clone('mandatoryProperties')).col(6);
s.add('popupProperties').as(l.clone('popupProperties')).col(6);

s.add('treeId').as(l.clone('selectTree')).col(4);
s.add('popupInfoOnField').as(l.clone('popupInfoOnField')).col(4);
s.add('popupLinkUrl').as(l.clone('popupLinkUrl')).col(4).props({ clearable: true });


s.add('selectLegsFromTree').as(l.clone('selectTree'))
  .props({ clearable: true })
  .convert(c.undefineEmpty)
  .title('Enable select legs from tree')
  .col(3);

s.add('selectPeriods').input('boolean').title('Allow select periods').col(3);
s.add('hideSelf').input('boolean').title('Hide self').col(3);
s.add('autoSwitchMode').as(l.clone('autoSwitchMode')).col(3);

s.add('headerTitles').as(l.clone('headerTitles')).col(12);

s.add('colors').as(l.clone('fullColorRules')).col(12);

function text() {
  return `
<b>Trees / Pages</b>
If there’s more than one tree in your compensation plan structure, you may use all the tree-related components and pages for any of those trees. For example, you can use this Downline component to show your Placement tree, and you can use the Genealogy component to show your genealogy tree.

<b>Page Title and Description</b>
Please provide your custom title for the page. You may also provide a custom description which appears a line below the title. A menu icon can also be modified, please specify if you want to use a different icon.

<b>Data Fields</b>
Please specify what profile fields and/or compensation plan properties (metrics) you want to be shown.

<b>Detailed Popup Data Fields</b>
A click on a certain account opens a popup window which allows it to show more data fields. Please provide a list of fields to be shown (both: profile fields and metrics).

<b>Downline Level Limit</b>
You may want to limit how many levels deep to show in the downline grid. For example, if you only pay out commission for 5 levels down you may decide to limit it to display only 5 commissionable levels.
`;
}
