
export function wearMaskMoney2(num, thousandDelimeter = ' ') {

  const formatted = Number(num)
    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    .replace(/,/g, thousandDelimeter);

  return formatted;
}

export function wearMaskMoney(str, thousandDelimeter = ' ') {
  function separateByThousandths(str, delimiter) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, `${delimiter}`);
  }

  function decimalize(numstr) {
    if (numstr.length > 1) {
      const dp = numstr.substring(numstr.length - 2, numstr.length);
      let denom = numstr.substring(0, numstr.length - 2);

      if (denom.substring(0, 1) === '0') {
        denom = denom.substring(1, denom.length);
      }

      denom = denom || '0';

      return `${denom}.${dp}`;
    }

    if (numstr.length === 1) return `0.0${numstr}`;

    return `0.00`;
  }

  let num = decimalize(str);

  num = separateByThousandths(num, thousandDelimeter);

  return num;
}

// function removeMaskMoney(numstr) {
//   const num = parseInt(numstr.replace(/\D+/g, ''));

//   return Number.isInteger(num) ? num.toString() : numstr.replace(/\D+/g, '');
// }
