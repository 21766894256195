<script setup lang="ts">
import { computed } from 'vue';
import { InputProps, InputEmits } from '@/deep-form/models/dform-input-core';
import { InputDictionaryItem } from '@/deep-form/components/InputDictionary';
import { mdiIconsList } from './InputMdiIconsList.js';

type InternalItem = Exclude<InputDictionaryItem, string>;

const props = defineProps<InputProps<string, null>>();
const emit = defineEmits<InputEmits<string>>();

const items = computed<Array<InternalItem>>(() =>
  mdiIconsList.map((item) => ({
    title: item,
    value: `mdi-${item}`,
  })),
);

/*
{ item: ListItem; index: number; props: Record<string, unknown> }
Define a custom item appearance. The root element of this slot must be a v-list-item with v-bind="props" applied. props includes everything required for the default select list behaviour - including title, value, click handlers, virtual scrolling, and anything else that has been added with item-props.
*/
</script>

<template>
  <v-autocomplete
    v-bind="props"

    :items="items"
    itemTitle="title"
    itemValue="value"
    :prependInnerIcon="props.modelValue"

    @update:model-value="emit('update:modelValue', $event);"
  >
    <template #item="{ item: mdiIcon, props: { title, ...otherProps } }">
      <v-list-item v-bind="otherProps">
        <v-icon>{{ mdiIcon.value }}</v-icon>
        {{ title }}
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
