import { ConfirmableFieldAlias } from "@/models/field-confirmation-interface";
import { InputByTypeProperties } from "@/models/form-builder-interface";

export interface UserLoginProps {
  id?: string;
  inputProperties?: InputByTypeProperties;
  passwordConfirmBy?: ConfirmableFieldAlias;
  onLogin?: (accountId?: number) => void;
}

export const defaults =
{
  passwordConfirmBy: 'email' as const,
};
