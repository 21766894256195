<template>
  <span v-if="CONFIRMABLE_FIELDS_ALIASES.includes(alias as ConfirmableFieldAlias)">
    <v-icon
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"
      :color="confirmed ? 'success' : 'warning'"
      :title="confirmed ? t('common.confirmed') : t('common.not_confirmed')"
      :start="start"
    >
      {{ confirmed ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle' }}
    </v-icon>
  </span>
</template>

<script setup lang="ts">

import { CONFIRMABLE_FIELDS_ALIASES, ConfirmableFieldAlias } from '@/models/field-confirmation-interface';
import { useConfirmableField, useLocalization } from '@/composables';
import { ref , watch } from 'vue';
import { useEventBus } from '@/pinia/useEventBus';

const { t } = useLocalization();

const props = defineProps<{
  alias: string,
  start?: boolean,
}>();

const confirmed = ref(false);
const loading = ref(true);

async function fetchStatus() {
  const alias = props.alias as ConfirmableFieldAlias;

  if (!CONFIRMABLE_FIELDS_ALIASES.includes(alias))
    return;

  loading.value = true;
  confirmed.value = await useConfirmableField(alias).isConfirmed();
  loading.value = false;
}

watch( () => props.alias, fetchStatus, { immediate: true });
watch( () => useEventBus().confirmFieldStatusUpdated, () => {
  const { field } = useEventBus().confirmFieldPayload;
  if (field === props.alias) {
    fetchStatus();
  }
});

</script>
