import { InputByTypeProperties } from "./form-builder-interface";
import { type DynamicStepComponentProperties } from "./dynamic-stepper-interface";

export type ConfirmableFieldAlias = 'email' | 'phone';
export const CONFIRMABLE_FIELDS_ALIASES: ConfirmableFieldAlias[] = ['email', 'phone'];
export interface OtpHeader {
  'x-otp-check': string;
}

export interface ConfirmationResult {
  field: ConfirmableFieldAlias;
  value: string;
  otpHeader: OtpHeader;
}

export type FieldConfirmationProps = {
  alias: ConfirmableFieldAlias,
  value?: string,
  updateProfile?: boolean,
  fieldError?: boolean,
  loading?: boolean,
  inputProperties?: InputByTypeProperties,
  optionalConfirm?: boolean,
  noSendImmediately?: boolean,
} & DynamicStepComponentProperties;
