import { WalletCountries } from '@/components/ui/WalletBalance';

export type WalletHistoryProps = {
  id?: string;
  wallet?: number;
  operationTypes?: number[];
  walletCountries?: WalletCountries;
  start?: string;
  end?: string;
  title?: string;
  headerClass?: string;
};

export const defaults = {
  id: undefined,
  operationTypes: [],
  walletCountries: [],
  start: null,
  end: null,
  wallet: null,
  title: '',
  headerClass: 'bg-primary',
};
