import i18n from "@/i18n";

import { parsePropertyName } from "./properties-mapping-service";
import { usePropertiesStore } from "@/pinia/usePropertiesStore";
import { aliasToPretty } from "./utils";

const missingServerTranslation = new Set<string>();

export function getMissingServerTranslations() {

  let mRequested = false;
  let pRequested = false;

  Array.from(missingServerTranslation)
    .forEach(alias => {
      // console.log('was missing:', alias);
      const [type, name] = parsePropertyName(alias);
      if (type !== 'm' && type !== 'p') return;

      const serverDefinition = usePropertiesStore().findPropertyDefinition(type, name);
      if (serverDefinition) return;

      // console.log('still missing:', alias);

      if (type === 'm' && !mRequested) {
        usePropertiesStore().getPpAsync();
        mRequested = true;
      }

      if (type === 'p' && !pRequested) {
        usePropertiesStore().getPfAsync();
        pRequested = true;
      }

    });

    missingServerTranslation.clear();

}

export function propertyTranslation(alias: string, translationOverride?: Record<string, string> | string) {

  let result = '';
  let fallback = '';

  // console.log('propertyTranslation', alias, 'translationOverride=', translationOverride);

  if (typeof translationOverride === 'string')
    fallback = translationOverride;

  else if (translationOverride?.[alias]) {
    // console.log('  *** pt: found in translationOverride alias=', alias, ' res=', translationOverride[alias]);
    return translationOverride[alias];
  }

  const { type, name, dictionary } = getLocalTranslationDictionary(alias);
  if (dictionary) {
    result = i18n.global.t(`${dictionary}.${name}`);
    // console.log('  *** pt: exists in local dictionary', dictionary, result);
  }

  if (type === 'm' || type === 'p') {
    const serverDefinition = usePropertiesStore().findPropertyDefinition(type, name);
    if (serverDefinition) {
      // console.log('  *** pt: found in m/p server definitions type=', type, 'alias=', alias,' res=', serverDefinition.title);
      // return `*${serverDefinition.title}`;
      return serverDefinition.title;
    }
    missingServerTranslation.add(alias);
  }

  if (! type) {
    const profilePropertyDefinition = usePropertiesStore().findPropertyDefinition('p', alias);
    if (profilePropertyDefinition) {
      // console.log('  *** pt: found in local store as (p) ', profilePropertyDefinition.title);
      // return `**${profilePropertyDefinition.title}`;
      return profilePropertyDefinition.title;
    }

    const planPropertyDefinition = usePropertiesStore().findPropertyDefinition('m', alias);
    if (planPropertyDefinition) {
      // console.log('  *** pt: found in local store as (m) ', planPropertyDefinition.title);
      // return `**${planPropertyDefinition.title}`;
      return planPropertyDefinition.title;
    }
  }

  // console.log('  *** pt: fallback result=', result, 'fallback=', fallback, 'aliasToPretty=', aliasToPretty(name));

  return result || fallback || aliasToPretty(name);
}

// probably better not to use pa() after all, cause we need server translations in the end
export function propertyAlias(alias: string, defaultValue?: string) {
  const { dictionary, name } = getLocalTranslationDictionary(alias);
  if (dictionary) {
    return `${dictionary}.${name}`;
  }
  return defaultValue ? defaultValue : aliasToPretty(name);
}

function getLocalTranslationDictionary(alias: string) {
  const [type, name] = parsePropertyName(alias);

  // console.log('  *** local:', alias, 'type=', type, 'name=', name);

  let dictionary = `properties_${type ?? 'custom'}`;

  const entryExistsInDictionary = () => i18n.global.te(`${dictionary}.${name}`);

  if ( entryExistsInDictionary() )
    return { type, name, dictionary };

  dictionary = `properties_p`;
  if ( entryExistsInDictionary() )
    return { type, name, dictionary };

  dictionary = `properties_m`;
  if ( entryExistsInDictionary() )
    return { type, name, dictionary };

  return { type, name, dictionary: undefined};
}
