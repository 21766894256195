<template>
  <div v-if="timeout > 0" data-component="retry-timeout">
    {{ retryInSeconds }}
  </div>
  <div v-else>
    <a class="cursorPointer" @click="onClick">{{ $t(textRetryNow) }}</a>
  </div>
</template>

<script setup lang="ts">
import { useLocalization } from '@/composables/useLocalization';
import { onUnmounted , onMounted , watch, toRefs, ref, computed } from 'vue';

// TODO: use session storage to survive unmounts and re-creations
// or use pinia! also can integrate api responses with timeLeft in that case

const props = withDefaults(defineProps<{
  trigger?: object | string | number,
  textRetryIn?: string,
  textRetryNow?: string,
  timeout?: number,
  startOnMounted?: boolean,
  onClick: () => void,
}>(), {
  trigger: undefined,
  textRetryIn: 'retry.retry_in',
  textRetryNow: 'retry.retry_now',
  timeout: 60,
  startOnMounted: false,
});

const { t } = useLocalization();

const { trigger } = toRefs(props);
const startedAt = ref(0);
let timerId: number | null = null;

const timeout = ref(props.timeout);

const retryInSeconds = computed(() => t(props.textRetryIn, [timeout.value]));

function startTimer() {
  stopTimer();

  const now = new Date();
  startedAt.value = now.getTime();

  timerId = setInterval(() => {
    const now = new Date();
    const elapsed = now.getTime() - startedAt.value;
    timeout.value = Math.max(props.timeout - Math.floor(elapsed / 1000), 0);
    if (timeout.value <= 0) {
      stopTimer();
    }
  }, 1000);
}

function stopTimer() {
  if (timerId) {
    clearInterval(timerId);
    timerId = null;
  }
}

if (props.trigger) {
  watch( trigger, () => {
    startTimer();
  }, { immediate: true });
}

onMounted(() => {
  if (props.startOnMounted) {
    startTimer();
  }
});

onUnmounted(() => {
  stopTimer();
});

</script>

<style lang="scss" scoped>
.cursorPointer:hover {
  cursor: pointer;
}
</style>
