<template>
  <v-dialog
    v-model="dialogOpen"
    :attach="attachTo"
    :fullscreen="true"
    :scrollable="true"
  >
    <v-card>
      <v-card-title>
        <div class="d-flex">
          <v-tabs v-model="tab">
            <v-tab value="page">Edit page</v-tab>
            <v-tab value="layout">Update Layout</v-tab>
            <v-tab value="pages">Pages</v-tab>
            <v-tab value="backup">Backups</v-tab>
            <v-tab value="migration">Migration</v-tab>
            <v-tab value="logs">Logs</v-tab>
          </v-tabs>
          <v-icon class="ml-auto" @click="dialogOpen = false">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="page">
            <GravFileEdit />
          </v-window-item>

          <v-window-item value="layout">
            <GravManagerLayout />
          </v-window-item>

          <v-window-item value="pages">
            <GravManagerPages
              @edit-file="onEditFile"
            />
          </v-window-item>

          <v-window-item value="backup">
            <GravManagerBackups
              @edit-file="onEditFile"
            />
          </v-window-item>

          <v-window-item value="migration">
            <GravManagerMigration />
          </v-window-item>

          <v-window-item value="logs">
            <GravShowLogs />
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="dialogOpen = false">{{ $t('dialogs.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useGravManager } from '../comosables/useGravManager';

import GravManagerBackups from './GravManagerBackups.vue';
import GravManagerPages from './GravManagerPages.vue';
import GravShowLogs from './GravShowLogs.vue';
import GravManagerMigration from './GravManagerMigration.vue';
import GravManagerLayout from './GravManagerLayout.vue';
import GravFileEdit from './GravFileEdit.vue';

 
const props = withDefaults(defineProps<{
   
  modelValue: boolean,
  attachTo?: HTMLElement;
}>(), {
  attachTo: undefined,
});

const emit = defineEmits<{
  (event: 'update:modelValue', result: boolean): void,
}>();

const dialogOpen = ref<boolean>(props.modelValue);
const tab = ref('page');

function onEditFile(fileName: string) {
  useGravManager().setEditFile(fileName);
  tab.value = 'page';
}

watch( () => dialogOpen.value, (newVal) => {
  if (newVal === false) {
    emit('update:modelValue', false);
  }
});

watch(() => props.modelValue, (newVal) => {
  if (newVal === true) {
    dialogOpen.value = true;
    const currentPageFile = useGravManager().currentPageDTO?.filePath;
    if (currentPageFile) {
      useGravManager().setEditFile(currentPageFile);
    }
  }
});

</script>
