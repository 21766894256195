import { gravComponentSchema } from '../deep-form-grav';
import { FieldCondition, UserProfileProps, defaults } from '../../../../components/ui/UserProfile';

const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<UserProfileProps>({
  title: 'User Profile',
  link: 'https://www.notion.so/mlmsoft/UserProfile-740661045ad144df9c4f8c5982e5336d',
  text: text(),
},
  defaults,
);

export default toExport;

const propInput = l.clone('profilePropertyForRegistration').props({ hideDetails: true });

s.add('profileFields').input('dlist').data(propInput.item())
  .title('Profile fields shown').mandatory().validators([v.minItems(1)])
  .col(6).props({ class: 'mr-4' }).item();

s.add('editableFields').input('dlist').data(propInput.item())
  .title('Editable Fields')
  .col(6).item();

s.add('passwordConfirmBy').input('dictionary').data([
  { title: 'Email', value: 'email' },
  { title: 'Phone', value: 'phone' },
])
  .title('Password confirm by').mandatory().col(4);

s.add('otpConfirm').as(l.clone('otpConfirm')).col(8);

export function escapeSingleAndDoubleQuotes(str: string) {
  const res = str.replace(/'/g, "\\'").replace(/"/g, '\\"');
  // console.log('escape_quotes', str, '->', res);
  return res;
}

s.add('conditions').input('dlist').data(
  s.spawn()
      .input('dform').data([
          s.spawn<string>('field').as(l.clone('profilePropertyForRegistration').mandatory())
            .col(3).title('Property field').props({ class: 'mr-md-4', dfShowTitle: true, hideDetails: false })
            .item(),
          s.spawn<string>('condition').input('vtext').mandatory()
            .col(6).title('JS Condition (Ex: value > 10)').props({ class: 'mr-md-4', dfShowTitle: true, hideDetails: false })
            .item(),
          s.spawn<string>('text').input('vtext')
            .col(3).title('Error text').props({ hideDetails: false, dfShowTitle: true })
            .item(),
        ])
        .init(() => ({ field: '', condition: '', text: '' }))
        .item(),
  )
  .title('Custom field validation conditions')
  .col(12)

  .convert( (data: FieldCondition[] | undefined) => {
    const safe = data?.map( (item: FieldCondition) => ({
      field: item.field,
      condition: escapeSingleAndDoubleQuotes(item.condition),
      text: item.text ? escapeSingleAndDoubleQuotes(item.text) : undefined,
    }));
    // console.log('conditions', data, '->', safe);
    return safe;
  });


function text() {
  return `
<b>Page Title and Description</b>
Please provide your custom title for the page. You may also provide a custom description which appears a line below the title. A menu icon can also be modified, please specify if you want to use a different icon.

<b>Allow to Change Contacts</b>
Users can change their email and/or phone number. To allow the change it must be confirmed with an OTP (one-time password), same way as it’s done during registration. Because email is a unique account identifier it might be used in different integrations, for example: integration with your online store, or with your CRM system. In that case you may not want to allow users to change their emails, as it may affect some of those integrations.

<b>Profile Fields</b>
Each account has a number of pre-defined profile fields which are always present. You can also add any custom profile fields. Some of the fields you may want to show your sales reps in the online office, and allow to edit them. Please provide a list of profile fields. Please mark those fields that you allow to be edited, and those you want to be hidden (only admin can see).
`;
}
