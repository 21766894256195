<template>
  <div
    :style="{ position: 'relative', display: 'inline-block' }"
  >
    <!-- :style="{ position: 'absolute', top: 0, left: 0 }" -->
    <v-avatar
      :size="size"
    >
      <!-- TODO: make the background circle visible before img downloaded as in UserAvatar -->
      <img
        :width="size"
        :src="src || emptyAvatar"
        :alt="title"
      >
    </v-avatar>
    <div
      v-for="badge in badges"
      :key="badge.id"
      :style="badgeStyle(badge)"
    >
      <slot :name="badge.id" :badge="badge">
        <img
          v-if="badge.imgUrl"
          :width="badge.imgSize"
          :src="badge.imgUrl"
        >
        <span v-if="badge.text">{{ runTemplate(badge.text, item) }}</span>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">

import emptyAvatar from '@/assets/images/avatar_undefined.png';
import { TableGridItem } from '@/models/table-grid-interface';
import { Badge } from '@/utils/conditional-colors';
import { runTemplate } from '@/utils/property-template-service';

withDefaults(defineProps<{
  src: string | undefined;
  size?: number;
  title?: string;
   
  badges?: Badge[];
  item?: TableGridItem;
}>(), {
  size: 40,
  src: undefined,
  title: '',
  badges: () => [],
  item: undefined,
});

 
function badgeStyle(badge: Badge) {

  let style: Record<string, number | string | undefined> = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: `${badge.offsetY || 0}px`,
    left: `${badge.offsetX || 0}px`,
    right: undefined,
    bottom: undefined,
    transform: undefined,
    borderRadius: badge.borderRadius || '50%',
    backgroundColor: badge.backgroundColor,
    color: badge.color,
    fontSize: badge.fontSize ? `${badge.fontSize}px` : undefined,
    padding: badge.padding ? `${badge.padding}px` : '2px 5px',
  };

  if (badge.position.includes('bottom')) {
    style.top = undefined;
    style.bottom = `${badge.offsetY || 0}px`;
  }

  if (badge.position.includes('right')) {
    style.left = undefined;
    style.right = `${badge.offsetX || 0}px`;
  }

  if (badge.position.includes('middle')) {
    style.left = '50%';
    style.right = undefined;
    style.transform = 'translateX(-50%)';
  }

   
  style = Object.fromEntries(Object.entries(style).filter(([_, v]) => v !== undefined));

  // console.log('badge', badge, 'style', style);

  return style;
}


</script>
