<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          :loading="useGravManager().loading"
          :data-loading="useGravManager().loading ? 'true' : undefined"

          @click="runMigration"
        >
          RUN MIGRATION
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Use this button to run the migration from UI1 to UI2.
        </p>
      </v-card-text>
      <v-card-text>
        <GravShowLogs :logs="logs" :result="result" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useGravManager } from '../comosables/useGravManager';

import GravShowLogs from './GravShowLogs.vue';

const grav = useGravManager();
const logs = ref<string[]>([]);
const result = ref<boolean | undefined>(undefined);

async function runMigration() {
  result.value = undefined;
  const res = await grav.upcliMigration();
  if (res) {
    logs.value = res;
    result.value = grav.result || false;
  }
}


</script>
