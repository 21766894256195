
<template>
  <v-dialog
    :modelValue="modelValue"
    data-component="upload-image"
    transition="dialog-bottom-transition"
    maxWidth="600"
    @update:model-value="$emit('update:modelValue', false)"
  >
    <div
      class="bg-primary d-flex justify-center align-center vue-image-crop-upload"
    >
      <div
        class="vicp-wrap"
        :class="[dark ? 'theme--dark' : 'theme--light']"
      >
        <div
          class="vicp-close"
          @click="off"
        >
          <i class="vicp-icon4" />
        </div>

        <div
          v-show="step === 1"
          class="vicp-step1"
        >
          <div
            class="vicp-drop-area"

            @dragleave="preventDefault"
            @dragover="preventDefault"
            @dragenter="preventDefault"
            @click="handleClick"
            @drop="handleChange"
          >
            <v-icon
              v-show="loading !== 1"
              size="x-large"
            >
              mdi-cloud-upload
            </v-icon>
            <span
              v-show="loading !== 1"
              class="vicp-hint"
            >{{ lv.hint }}</span>
            <span
              v-show="!isSupported"
              class="vicp-no-supported-hint"
            >{{
              lv.browser_not_supported
            }}</span>
            <input
              v-show="false"
              v-if="step === 1"
              ref="fileinput"
              type="file"
              @change="handleChange"
            >
          </div>
          <div
            v-show="hasError"
            class="vicp-error"
          >
            <i class="vicp-icon2" /> {{ errorMsg }}
          </div>
          <div class="vicp-operate">
            <v-btn
              variant="plain"
              @click="off"
            >
              {{ $t('dialogs.cancel') }}
            </v-btn>
          </div>
        </div>

        <div
          v-if="step === 2"
          class="vicp-step2"
        >
          <div class="vicp-crop">
            <div class="vicp-crop-left">
              <div class="vicp-img-container">
                <img
                  :src="sourceImgUrl"
                  :style="sourceImgStyle"
                  class="vicp-img"
                  draggable="false"
                  @drag="preventDefault"
                  @dragstart="preventDefault"
                  @dragend="preventDefault"
                  @dragleave="preventDefault"
                  @dragover="preventDefault"
                  @dragenter="preventDefault"
                  @drop="preventDefault"
                  @touchstart="imgStartMove"
                  @touchmove="imgMove"
                  @touchend="createImg"
                  @touchcancel="createImg"
                  @mousedown="imgStartMove"
                  @mousemove="imgMove"
                  @mouseup="createImg"
                  @mouseout="createImg"
                >
                <div
                  class="vicp-img-shade vicp-img-shade-1"
                  :style="sourceImgShadeStyle"
                />
                <div
                  class="vicp-img-shade vicp-img-shade-2"
                  :style="sourceImgShadeStyle"
                />
              </div>

              <div class="vicp-range">
                <input
                  type="range"
                  :value="scale.range"
                  step="1"
                  min="0"
                  max="100"
                  @input="zoomChange"
                >
                <i
                  class="vicp-icon5"
                  @mousedown="startZoomSub"
                  @mouseout="endZoomSub"
                  @mouseup="endZoomSub"
                />
                <i
                  class="vicp-icon6"
                  @mousedown="startZoomAdd"
                  @mouseout="endZoomAdd"
                  @mouseup="endZoomAdd"
                />
              </div>

              <div
                v-if="!noRotate"
                class="vicp-rotate"
              >
                <i @click="rotateImg">↻</i>
              </div>
            </div>
            <div class="vicp-crop-right">
              <div class="vicp-preview">
                <div
                  v-if="!noSquare"
                  class="vicp-preview-item"
                >
                  <img
                    :src="createImgUrl"
                    :style="previewStyle"
                  >
                  <span>{{ lv.preview }}</span>
                </div>
                <div
                  v-if="!noCircle"
                  class="vicp-preview-item vicp-preview-item-circle"
                >
                  <img
                    :src="createImgUrl"
                    :style="previewStyle"
                  >
                  <span>{{ lv.preview }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="vicp-operate">
            <v-btn
              variant="plain"
              @click="setStep(1)"
            >
              {{ $t('dialogs.back') }}
            </v-btn>
            <v-btn
              class="bg-primary"
              variant="plain"
              @click="prepareUpload"
            >
              {{ $t('dialogs.save') }}
            </v-btn>
          </div>
        </div>

        <div
          v-if="step === 3"
          class="vicp-step3"
        >
          <div class="vicp-upload">
            <span
              v-show="loading === 1"
              class="vicp-loading"
            >{{ lv.loading }}</span>
            <div class="w-100 d-flex justify-content-center">
              <span
                v-show="loading === 1"
                class="loading"
              />
            </div>

            <div
              v-show="hasError"
              class="vicp-error"
            >
              <i class="vicp-icon2" /> {{ errorMsg }}
            </div>
            <div
              v-show="loading === 2"
              class="vicp-success"
            >
              <i class="vicp-icon3" /> {{ lv.upload_success }}
            </div>
          </div>
          <div class="vicp-operate">
            <v-btn
              variant="plain"
              @click="setStep(2)"
            >
              {{ $t('dialogs.back') }}
            </v-btn>
            <v-btn
              variant="plain"
              @click="off"
            >
              {{ $t('dialogs.close') }}
            </v-btn>
          </div>
        </div>
        <canvas
          v-show="false"
          ref="canvas"
          :width="width"
          :height="height"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
// eslint-disable @typescript-eslint/no-this-alias
import mimes from './upload_image_utils/mimes.js';
import data2blob from './upload_image_utils/data2blob.js';
import { useAccountProfileApi } from '@ui-api3-sdk/api/api3';
import { useAwaitAccountId, useLocalization } from '@/composables';

export default {
  name: 'UploadImage',
  props: {

    accountId: {
      type: [String, Number],
      default: undefined,
    },

    field: {
      type: String,
      default: 'avatar',
    },

    // ki: {
    //   type: Number,
    //   default: 0,
    // },

    modelValue: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default: null,
    },

    width: {
      type: Number,
      default: 400,
    },

    height: {
      type: Number,
      default: 400,
    },

    noRotate: {
      type: Boolean,
      default: true,
    },

    noCircle: {
      type: Boolean,
      default: false,
    },

    noSquare: {
      type: Boolean,
      default: false,
    },

    maxSize: {
      type: Number,
      default: 10240,
    },

    imgFormat: {
      type: String,
      default: 'png',
    },

    imgBgc: {
      type: String,
      default: '#fff',
    },

    dark: {
      type: Boolean,
      default: false,
    },
  },

  emits: {'cropUploadSuccess': null, 'cropUploadFail': null, 'input': null, 'srcFileSet': null, 'cropSuccess': null, 'update:modelValue': null, 'update:image': null },

  data: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const { imgFormat, width, height } = that;
    let isSupported = true;
    const allowImgFormat = ['jpg', 'png'];
    const tempImgFormat = allowImgFormat.indexOf(imgFormat) === -1 ? 'jpg' : imgFormat;
    const mime = mimes[tempImgFormat];

    if (typeof FormData !== 'function') {
      isSupported = false;
    }

    return {
      lv: {},
      lt: (s) => s,
      mime,
      isSupported,
      isSupportTouch: Object.prototype.hasOwnProperty.call(document, 'ontouchstart'),
      step: 1,
      loading: 0,
      progress: 0,
      hasError: false,
      errorMsg: '',
      ratio: width / height,
      sourceImg: null,
      sourceImgUrl: '',
      createImgUrl: '',
      sourceImgMouseDown: {
        on: false,
        mX: 0,
        mY: 0,
        x: 0,
        y: 0,
      },

      previewContainer: {
        width: 100,
        height: 100,
      },

      sourceImgContainer: {
        // sic
        width: 240,
        height: 184,
      },

      scale: {
        zoomAddOn: false,
        zoomSubOn: false,
        range: 1,

        x: 0,
        y: 0,
        width: 0,
        height: 0,
        maxWidth: 0,
        maxHeight: 0,
        minWidth: 0,
        minHeight: 0,
        naturalWidth: 0,
        naturalHeight: 0,
      },
    };
  },

  computed: {

    sourceImgStyle() {
      const { scale, sourceImgMasking } = this;
      const top = `${scale.y + sourceImgMasking.y}px`;
      const left = `${scale.x + sourceImgMasking.x}px`;

      return {
        top,
        left,
        width: `${scale.width}px`,
        height: `${scale.height}px`,
      };
    },

    sourceImgMasking() {
      const { width, height, ratio, sourceImgContainer } = this;
      const sic = sourceImgContainer;
      const sicRatio = sic.width / sic.height;
      let x = 0;
      let y = 0;
      let w = sic.width;
      let h = sic.height;
      let scale = 1;

      if (ratio < sicRatio) {
        scale = sic.height / height;
        w = sic.height * ratio;
        x = (sic.width - w) / 2;
      }

      if (ratio > sicRatio) {
        scale = sic.width / width;
        h = sic.width / ratio;
        y = (sic.height - h) / 2;
      }

      return {
        scale,
        x,
        y,
        width: w,
        height: h,
      };
    },

    sourceImgShadeStyle() {
      const { sourceImgMasking, sourceImgContainer } = this;
      const sic = sourceImgContainer;
      const sim = sourceImgMasking;
      const w = sim.width === sic.width ? sim.width : (sic.width - sim.width) / 2;
      const h = sim.height === sic.height ? sim.height : (sic.height - sim.height) / 2;

      return {
        width: `${w}px`,
        height: `${h}px`,
      };
    },

    previewStyle() {
      const {
        // width,
        // height,
        ratio,
        previewContainer,
      } = this;
      const pc = previewContainer;
      let w = pc.width;
      let h = pc.height;
      const pcRatio = w / h;

      if (ratio < pcRatio) {
        w = pc.height * ratio;
      }

      if (ratio > pcRatio) {
        h = pc.width / ratio;
      }

      return {
        width: `${w}px`,
        height: `${h}px`,
      };
    },
  },

  watch: {
    modelValue(newValue) {
      if (newValue && this.loading !== 1) {
        this.reset();
      }
    },
  },

  mounted() {
    const { lv, lt } = useLocalization('uploadImage', [
      'hint',
      'loading',
      'browser_not_supported',
      'upload_success',
      'upload_fail',
      'preview',
      'error_only_img',
      'error_out_of_size',
      'error_lowest_px',
    ]);

    this.lv = lv;
    this.lt = lt;

  },

  methods: {
    async uploadAvatar({accountId, value, fileName}) {

      const aId = await useAwaitAccountId(accountId);

      return useAccountProfileApi()
        .setProfileField({
          id: aId,
          alias: 'avatar_id',
          setProfileValueReq: {
            data: { fileName },
            value,
          },
        });
    },

    off() {
      setTimeout(() => {
        this.$emit('update:modelValue', false);
        if (this.step === 3 && this.loading === 2) {
          this.setStep(1);
        }
      }, 200);
    },

    setStep(no) {
      setTimeout(() => {
        this.step = no;
      }, 200);
    },

    preventDefault(e) {
      e.preventDefault();
      return false;
    },

    handleClick(e) {
      if (this.loading !== 1) {
        if (e.target !== this.$refs.fileinput) {
          e.preventDefault();
          if (document.activeElement !== this.$refs) {
            this.$refs.fileinput.click();
          }
        }
      }
    },

    handleChange(e) {
      e.preventDefault();
      if (this.loading !== 1) {
        const files = e.target.files || e.dataTransfer.files;

        this.reset();
        if (this.checkFile(files[0])) {
          this.setSourceImg(files[0]);
        }
      }
    },

    checkFile(file) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const { maxSize } = that;

      if (file.type.indexOf('image') === -1) {
        that.hasError = true;
        that.errorMsg = that.lt('error_only_img');
        return false;
      }

      if (file.size / 1024 > maxSize) {
        that.hasError = true;
        that.errorMsg = that.lt('error_out_of_size', [maxSize]);

        return false;
      }

      return true;
    },

    reset() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      that.loading = 0;
      that.hasError = false;
      that.errorMsg = '';
      that.progress = 0;
    },

    setSourceImg(file) {
      this.$emit('srcFileSet', file.name, file.type, file.size);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const fr = new FileReader();

      fr.onload = function () {
        that.sourceImgUrl = fr.result;
        that.startCrop();
      };

      fr.readAsDataURL(file);
    },

    startCrop() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const { width, height, ratio, scale, sourceImgUrl, sourceImgMasking } = that;
      const sim = sourceImgMasking;
      const img = new Image();

      img.src = sourceImgUrl;
      img.onload = function () {
        const nWidth = img.naturalWidth;
        const nHeight = img.naturalHeight;
        const nRatio = nWidth / nHeight;
        let w = sim.width;
        let h = sim.height;
        let x = 0;
        let y = 0;

        if (nWidth < width || nHeight < height) {
          that.hasError = true;
          that.errorMsg = that.lt('error_lowest_px', [width, height]);
          return false;
        }

        if (ratio > nRatio) {
          h = w / nRatio;
          y = (sim.height - h) / 2;
        }

        if (ratio < nRatio) {
          w = h * nRatio;
          x = (sim.width - w) / 2;
        }

        scale.range = 0;
        scale.x = x;
        scale.y = y;
        scale.width = w;
        scale.height = h;
        scale.minWidth = w;
        scale.minHeight = h;
        scale.maxWidth = nWidth * sim.scale;
        scale.maxHeight = nHeight * sim.scale;
        scale.naturalWidth = nWidth;
        scale.naturalHeight = nHeight;
        that.sourceImg = img;
        that.createImg();
        that.setStep(2);
      };
    },

    imgStartMove(e) {
      e.preventDefault();
      if (this.isSupportTouch && !e.targetTouches) {
        return false;
      }

      const et = e.targetTouches ? e.targetTouches[0] : e;
      const { sourceImgMouseDown, scale } = this;
      const simd = sourceImgMouseDown;

      simd.mX = et.screenX;
      simd.mY = et.screenY;
      simd.x = scale.x;
      simd.y = scale.y;
      simd.on = true;
    },

    imgMove(e) {
      e.preventDefault();
      if (this.isSupportTouch && !e.targetTouches) {
        return false;
      }

      const et = e.targetTouches ? e.targetTouches[0] : e;
      const {
        sourceImgMouseDown: { on, mX, mY, x, y },
        scale,
        sourceImgMasking,
      } = this;
      const sim = sourceImgMasking;
      const nX = et.screenX;
      const nY = et.screenY;
      const dX = nX - mX;
      const dY = nY - mY;
      let rX = x + dX;
      let rY = y + dY;

      if (!on) return;

      if (rX > 0) {
        rX = 0;
      }

      if (rY > 0) {
        rY = 0;
      }

      if (rX < sim.width - scale.width) {
        rX = sim.width - scale.width;
      }

      if (rY < sim.height - scale.height) {
        rY = sim.height - scale.height;
      }

      scale.x = rX;
      scale.y = rY;
    },

    rotateImg() {
      const {
        sourceImg,
        scale: { naturalWidth, naturalHeight },
      } = this;
      const width = naturalHeight || 0;
      const height = naturalWidth || 0;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;
      ctx.clearRect(0, 0, width, height);

      ctx.fillStyle = 'rgba(0,0,0,0)';
      ctx.fillRect(0, 0, width, height);

      ctx.translate(width, 0);
      ctx.rotate((Math.PI * 90) / 180);

      ctx.drawImage(sourceImg, 0, 0, naturalWidth, naturalHeight);

      this.sourceImgUrl = canvas.toDataURL(mimes.png);
      this.startCrop();
    },

    startZoomAdd() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const { scale } = that;

      scale.zoomAddOn = true;

      function zoom() {
        if (scale.zoomAddOn) {
          const range = scale.range >= 100 ? 100 : ++scale.range;

          that.zoomImg(range);
          setTimeout(function () {
            zoom();
          }, 60);
        }
      }

      zoom();
    },

    endZoomAdd() {
      this.scale.zoomAddOn = false;
    },

    startZoomSub() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const { scale } = that;

      scale.zoomSubOn = true;

      function zoom() {
        if (scale.zoomSubOn) {
          const range = scale.range <= 0 ? 0 : --scale.range;

          that.zoomImg(range);
          setTimeout(function () {
            zoom();
          }, 60);
        }
      }

      zoom();
    },

    endZoomSub() {
      const { scale } = this;

      scale.zoomSubOn = false;
    },

    zoomChange(e) {
      this.zoomImg(e.target.value);
    },

    zoomImg(newRange) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const {
        sourceImgMasking,
        // sourceImgMouseDown,
        scale,
      } = this;
      const {
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
        width,
        height,
        x,
        y,
        // range
      } = scale;
      const sim = sourceImgMasking;
      const sWidth = sim.width;
      const sHeight = sim.height;
      const nWidth = minWidth + ((maxWidth - minWidth) * newRange) / 100;
      const nHeight = minHeight + ((maxHeight - minHeight) * newRange) / 100;
      let nX = sWidth / 2 - (nWidth / width) * (sWidth / 2 - x);
      let nY = sHeight / 2 - (nHeight / height) * (sHeight / 2 - y);

      if (nX > 0) {
        nX = 0;
      }

      if (nY > 0) {
        nY = 0;
      }

      if (nX < sWidth - nWidth) {
        nX = sWidth - nWidth;
      }

      if (nY < sHeight - nHeight) {
        nY = sHeight - nHeight;
      }

      scale.x = nX;
      scale.y = nY;
      scale.width = nWidth;
      scale.height = nHeight;
      scale.range = newRange;
      setTimeout(function () {
        if (scale.range === newRange) {
          that.createImg();
        }
      }, 300);
    },

    createImg(e) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const {
        imgFormat,
        imgBgc,
        mime,
        sourceImg,
        scale: { x, y, width, height },
        sourceImgMasking: { scale },
      } = that;
      const canvas = that.$refs.canvas;
      const ctx = canvas.getContext('2d');

      if (e) {
        that.sourceImgMouseDown.on = false;
      }

      canvas.width = that.width;
      canvas.height = that.height;
      ctx.clearRect(0, 0, that.width, that.height);

      if (imgFormat === 'png') {
        ctx.fillStyle = 'rgba(255,255,255,1)';
      } else {
        ctx.fillStyle = imgBgc;
      }

      ctx.fillRect(0, 0, that.width, that.height);

      ctx.drawImage(sourceImg, x / scale, y / scale, width / scale, height / scale);
      that.createImgUrl = canvas.toDataURL(mime);
    },

    prepareUpload() {
      const { createImgUrl, field, ki } = this;
      this.$emit('cropSuccess', createImgUrl, field, ki);
      this.upload();
    },

    upload() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      const {
        imgFormat,
        mime,
        params,
        field,
        ki,
        createImgUrl,
      } = this;

      const fmData = new FormData();

      fmData.append(field, data2blob(createImgUrl, mime), `${field}.${imgFormat}`);
      if (typeof params === 'object' && params) {
        Object.keys(params).forEach(k => {
          fmData.append(k, params[k]);
        });
      }

      const uploadProgress = function (event) {
        if (event.lengthComputable) {
          that.progress = (100 * Math.round(event.loaded)) / event.total;
        }
      };

      that.reset();
      that.loading = 1;
      that.setStep(3);

      uploadProgress({ loaded: 30, total: 50 });
      const dataMLMAvatar = createImgUrl.split(',')[1];

      this.uploadAvatar({
        accountId: this.accountId,
        value: dataMLMAvatar,
        fileName: `upload.${imgFormat}`,
      })
        .then(resData => {
          if (that.modelValue) {
            that.loading = 2;
            that.$emit('cropUploadSuccess', resData, field, ki);
          }

          // update field in store to display new image
          const responseRawValue = resData?.data?.payload?.value?.raw;

          if (responseRawValue?.imageUrl) {
            // useAccountStore()
            //   .updateRawProfilePropertyValue('avatar_id', responseRawValue);
            this.$emit('update:image', responseRawValue);
          }

        })
        .catch(sts => {
          if (that.modelValue) {
            that.loading = 3;
            that.hasError = true;
            that.errorMsg = that.lt('upload_fail');
            that.$emit('cropUploadFail', sts, field, ki);
          }
        });
    },
  },
};
</script>


<style>

.vue-image-crop-upload {
  z-index: 300;
}

.vue-image-crop-upload .vicp-wrap {
  display: block;

  box-sizing: border-box;
  width: 100%;
  min-width: 420px;
  max-width: 600px;
  min-height: 330px;
  padding: 25px;

  color: rgba(var(--v-theme-on-background), 1);

  background-color: rgba(var(--v-theme-background), 1);
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);

  animation: vicp .12s ease-in;
}

.theme--dark {
  color: rgba(255, 255, 255, .87);
  background-color: #1E1E1E;
}

.vue-image-crop-upload .vicp-wrap .vicp-close {
  position: absolute;
  top: -30px;
  right: -30px;
}

.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4 {
  cursor: pointer;

  position: relative;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);

  display: block;

  width: 30px;
  height: 30px;

  -webkit-transition: -webkit-transform .18s;
  transition: -webkit-transform .18s;
  transition: transform .18s;
  transition: transform .18s, -webkit-transform .18s;
}

.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::after,
.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::before {
  content: '';

  position: absolute;
  top: 12px;
  left: 4px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  width: 20px;
  height: 3px;

  background-color: #FFFFFF;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);
}

.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area {
  position: relative;

  overflow: hidden;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 170px;
  padding: 35px;

  text-align: center;

  background-color: rgba(0, 0, 0, .03);
  border: 1px dashed rgba(0, 0, 0, .08);
}

.vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-icon1 {
  overflow: hidden;
  display: block;

  width: 42px;
  height: 42px;
  margin: 0 auto 6px;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step1
.vicp-drop-area
.vicp-icon1
.vicp-icon1-arrow {
  display: block;

  width: 0;
  height: 0;
  margin: 0 auto;

  border-right: 14.7px solid transparent;
  border-bottom: 14.7px solid rgba(0, 0, 0, .3);
  border-left: 14.7px solid transparent;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step1
.vicp-drop-area
.vicp-icon1
.vicp-icon1-body {
  display: block;

  width: 12.6px;
  height: 14.7px;
  margin: 0 auto;

  background-color: rgba(0, 0, 0, .3);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step1
.vicp-drop-area
.vicp-icon1
.vicp-icon1-bottom {
  display: block;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 12.6px;

  border: 6px solid rgba(0, 0, 0, .3);
  border-top: none;
}

.vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-hint {
  display: block;
  padding: 15px;
  font-size: 14px;

  /* color: #666; */
  line-height: 30px;
}

.vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-no-supported-hint {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 60px;
  padding: 30px;

  font-size: 14px;
  line-height: 30px;
  color: #666666;
  text-align: center;

  background-color: #EEEEEE;
}

.vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area:hover {
  cursor: pointer;

  /* border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05); */
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop {
  overflow: hidden;
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left {
  float: left;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-img-container {
  position: relative;

  overflow: hidden;
  display: block;

  max-width: 240px;
  height: 180px;

  background-color: #E5E5E0;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-img-container
.vicp-img {
  cursor: move;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: absolute;

  display: block;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-img-container
.vicp-img-shade {
  position: absolute;
  background-color: rgba(241, 242, 243, .8);
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .18);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .18);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-img-container
.vicp-img-shade.vicp-img-shade-1 {
  top: 0;
  left: 0;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-img-container
.vicp-img-shade.vicp-img-shade-2 {
  right: 0;
  bottom: 0;
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate {
  position: relative;
  width: 240px;
  height: 18px;
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate i {
  overflow: hidden;
  display: block;

  width: 18px;
  height: 18px;

  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #FFFFFF;
  text-align: center;

  background-color: rgba(0, 0, 0, .08);
  border-radius: 100%;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-rotate
i:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .14);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-rotate
i:first-child {
  float: left;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-rotate
i:last-child {
  float: right;
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range {
  position: relative;
  width: 240px;
  height: 18px;
  margin: 30px 0 10px 0;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon5,
.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon6 {
  position: absolute;
  top: 0;

  width: 18px;
  height: 18px;

  background-color: rgba(0, 0, 0, .08);
  border-radius: 100%;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon5 {
  left: 0;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon5::before {
  content: '';

  position: absolute;
  top: 8px;
  left: 3px;

  display: block;

  width: 12px;
  height: 2px;

  background-color: #FFFFFF;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon6 {
  right: 0;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon6::before {
  content: '';

  position: absolute;
  top: 8px;
  left: 3px;

  display: block;

  width: 12px;
  height: 2px;

  background-color: #FFFFFF;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon6::after {
  content: '';

  position: absolute;
  top: 3px;
  left: 8px;

  display: block;

  width: 2px;
  height: 12px;

  background-color: #FFFFFF;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range'] {
  cursor: pointer;

  display: block;

  width: 180px;
  height: 8px;
  margin: 0 auto;
  padding-top: 5px;

  /* vertical-align: top; */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:focus {
  outline: none;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  margin-top: -3px;

  -webkit-appearance: none;
  appearance: none;
  background-color: #61C091;
  border: none;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .18);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .18);

  -webkit-transition: .2s;
  transition: .2s;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-moz-range-thumb {
  width: 12px;
  height: 12px;

  -moz-appearance: none;
  appearance: none;
  background-color: #61C091;
  border: none;
  border-radius: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .18);

  -webkit-transition: .2s;
  transition: .2s;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-ms-thumb {
  width: 12px;
  height: 12px;

  appearance: none;
  background-color: #61C091;
  border: none;
  border-radius: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .18);

  -webkit-transition: .2s;
  transition: .2s;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:active::-moz-range-thumb {
  width: 14px;
  height: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:active::-ms-thumb {
  width: 14px;
  height: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:active::-webkit-slider-thumb {
  width: 14px;
  height: 14px;
  margin-top: -4px;

  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .23);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-webkit-slider-runnable-track {
  cursor: pointer;

  width: 100%;
  height: 6px;

  background-color: rgba(68, 170, 119, .3);
  border: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-moz-range-track {
  cursor: pointer;

  width: 100%;
  height: 6px;

  background-color: rgba(68, 170, 119, .3);
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-ms-track {
  cursor: pointer;

  width: 100%;
  height: 6px;

  color: transparent;

  background: transparent;
  border: none;
  border-color: transparent;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-ms-fill-lower {
  background-color: rgba(68, 170, 119, .3);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']::-ms-fill-upper {
  background-color: rgba(68, 170, 119, .15);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:focus::-webkit-slider-runnable-track {
  background-color: rgba(68, 170, 119, .5);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:focus::-moz-range-track {
  background-color: rgba(68, 170, 119, .5);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:focus::-ms-fill-lower {
  background-color: rgba(68, 170, 119, .45);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
input[type='range']:focus::-ms-fill-upper {
  background-color: rgba(68, 170, 119, .25);
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right {
  float: right;
}

.vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview {
  overflow: hidden;
  width: 120px;
  height: 150px;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-right
.vicp-preview
.vicp-preview-item {
  position: relative;

  float: left;

  width: 100px;
  height: 100px;
  margin-right: 16px;
  padding: 5px;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-right
.vicp-preview
.vicp-preview-item
span {
  position: absolute;
  bottom: -30px;

  display: block;

  width: 100%;

  font-size: 14px;
  color: #BBBBBB;
  text-align: center;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-right
.vicp-preview
.vicp-preview-item
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
  display: block;

  margin: auto;
  padding: 3px;

  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, .15);
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-right
.vicp-preview
.vicp-preview-item.vicp-preview-item-circle {
  margin-right: 0;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-right
.vicp-preview
.vicp-preview-item.vicp-preview-item-circle
img {
  border-radius: 100%;
}

.vue-image-crop-upload .vicp-wrap .vicp-step3 .vicp-upload {
  position: relative;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 170px;
  padding: 35px;

  text-align: center;

  background-color: rgba(0, 0, 0, .03);
  border: 1px dashed #DDDDDD;
}

.vue-image-crop-upload .vicp-wrap .vicp-step3 .vicp-upload .vicp-loading {
  display: block;

  padding: 15px;

  font-size: 16px;
  line-height: 30px;
  color: #999999;
}

.vue-image-crop-upload .vicp-wrap .vicp-step3 .vicp-upload .vicp-progress-wrap {
  margin-top: 12px;
  background-color: rgba(0, 0, 0, .08);
  border-radius: 3px;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step3
.vicp-upload
.vicp-progress-wrap
.vicp-progress {
  position: relative;

  display: block;

  height: 5px;

  background-color: #44AA77;
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(68, 170, 119, .3);
  box-shadow: 0 2px 6px 0 rgba(68, 170, 119, .3);

  -webkit-transition: width .15s linear;
  transition: width .15s linear;
  -webkit-animation: vicp_progress .5s linear infinite;
  animation: vicp_progress .5s linear infinite;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step3
.vicp-upload
.vicp-progress-wrap
.vicp-progress::after {
  content: '';

  position: absolute;
  top: -3px;
  right: -3px;

  display: block;

  width: 9px;
  height: 9px;

  background-color: #44AA77;
  border: 1px solid rgba(245, 246, 247, .7);
  border-radius: 100%;
  -webkit-box-shadow: 0 1px 4px 0 rgba(68, 170, 119, .7);
  box-shadow: 0 1px 4px 0 rgba(68, 170, 119, .7);
}

.vue-image-crop-upload .vicp-wrap .vicp-error,
.vue-image-crop-upload .vicp-wrap .vicp-success {
  display: block;

  height: 24px;

  font-size: 14px;
  line-height: 24px;
  color: #DD1100;
  text-align: center;

  /* vertical-align: top; */
}

.vue-image-crop-upload .vicp-wrap .vicp-success {
  color: #44AA77;
}

.vue-image-crop-upload .vicp-wrap .vicp-step3 .vicp-upload .vicp-error,
.vue-image-crop-upload .vicp-wrap .vicp-step3 .vicp-upload .vicp-success {
  height: 100px;
  line-height: 100px;
}

.vue-image-crop-upload .vicp-wrap .vicp-operate {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.vue-image-crop-upload .vicp-wrap .vicp-operate a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: relative;

  float: left;
  overflow: hidden;
  display: block;

  width: 100px;
  height: 36px;
  margin-left: 10px;

  font-size: 14px;
  line-height: 36px;
  color: #44AA77;
  text-align: center;

  border-radius: 2px;
}

.vue-image-crop-upload .vicp-wrap .vicp-operate a:hover {
  background-color: rgba(0, 0, 0, .03);
}

.vue-image-crop-upload .vicp-wrap .vicp-icon3 {
  position: relative;
  top: 4px;

  display: inline-block;

  width: 20px;
  height: 20px;
}

.vue-image-crop-upload .vicp-wrap .vicp-icon3::after {
  content: '';

  position: absolute;
  top: 3px;
  left: 6px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  width: 6px;
  height: 10px;

  border-color: #44AA77;
  border-style: solid;
  border-width: 0 2px 2px 0;
}

.vue-image-crop-upload .vicp-wrap .vicp-icon2 {
  position: relative;
  top: 4px;

  display: inline-block;

  width: 20px;
  height: 20px;
}

.vue-image-crop-upload .vicp-wrap .vicp-icon2::after,
.vue-image-crop-upload .vicp-wrap .vicp-icon2::before {
  content: '';

  position: absolute;
  top: 9px;
  left: 4px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  width: 13px;
  height: 2px;

  background-color: #DD1100;
}

.vue-image-crop-upload .vicp-wrap .vicp-icon2::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.e-ripple {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: absolute;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  opacity: 1;
  background-color: rgba(0, 0, 0, .15);
  background-clip: padding-box;
  border-radius: 100%;
}

.e-ripple.z-active {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);

  opacity: 0;

  -webkit-transition: opacity 1.2s ease-out, -webkit-transform .6s ease-out;
  transition: opacity 1.2s ease-out, -webkit-transform .6s ease-out;
  transition: opacity 1.2s ease-out, transform .6s ease-out;
  transition: opacity 1.2s ease-out, transform .6s ease-out,
    -webkit-transform .6s ease-out;
}

@keyframes donut-spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  float: left;
  display: block;

  width: 24px;
  height: 24px;
  margin-right: 8px;

  /* vertical-align: middle; */

  border: 4px solid rgba(0, 0, 0, .2);
  border-left-color: #FFFFFF;
  border-radius: 50%;

  animation: donut-spin 1.2s linear infinite;
}

.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon5:hover,
.vue-image-crop-upload
.vicp-wrap
.vicp-step2
.vicp-crop
.vicp-crop-left
.vicp-range
.vicp-icon6:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .14);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
}
</style>
