<template>
  <div class="d-flex flex-row align-center">
    <div class="flex-grow-1">
      <FormBuilder
        v-bind="formProps"
        :buttons="[]"
        :inputProperties="inputProperties"
        :hideSubmitButton="true"
        @form-valid="formValid = $event"
      />
    </div>
    <div
      class="flex-grow-0 ml-4"
    >
      <v-btn
        color="primary"
        type="submit"
        :disabled="!formValid"
        :loading="formProps.loading"
        @click.prevent="onSubmit"
      >
        {{ $t('dialogs.update') }}
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { apiErrorToString, useAccountProfileApi } from '@ui-api3-sdk/api/api3';
import { useLocalization, useAwaitAccountId, useFormBuilder, useToast } from '@/composables';
import { useAccountStore } from '@/pinia/useAccountStore';

import { FormField, FormResult, InputByTypeProperties, assertFieldType } from '@/models/form-builder-interface';

import { usePropertiesStore } from '@/pinia/usePropertiesStore';

import FormBuilder from '@/components/ui/base/FormBuilder.vue';


const emit = defineEmits(['updated']);

const { lt } = useLocalization('userProfileMain', [
  'title',
  'unknown_error',
  'profile_updated',
]);

const props = withDefaults(defineProps<{
  alias: string;
  inputProperties?: InputByTypeProperties;
  validateFn?: (value: any) => boolean | string;
}>(), {
  inputProperties: undefined,
  validateFn: undefined,
});

const formValid = ref(false);
const accountStore = useAccountStore();

const { props: formProps, methods: {
  setFields,
  setLoading,
  setValuesFromProfile,
  parseApiValidation,
  addError,
  validate,
} } = useFormBuilder([]);


async function fetchData() {
  setLoading(true);
  await usePropertiesStore().getPfAsync();

  const res: FormField[] = [];

  function genFormField(alias: string) {
    const propType = usePropertiesStore().getPropertyType(alias, undefined, 'p');

    const res: FormField = {
      title: propType.title,
      alias: propType.alias,
      isMandatory: propType.isMandatory,
      fieldType: assertFieldType(propType.fieldType),
      customValidateFn: props.validateFn,
    };

    return res;
  }

  try {

    res.push(genFormField(props.alias));

    if (props.alias === 'region_id') {
      res.push({ ... genFormField('country_id'), ... { hidden: true } });
    }

    setFields(res);

    const currentAccount = await accountStore.getAccountAsync();
    const profile = currentAccount?.profile;
    if (! profile ) throw new Error('Could not get profile fields');
    setValuesFromProfile(profile);
    validate();

  } catch (err) {
    addError(apiErrorToString(err, lt('unknown_error')));
  } finally {
    setLoading(false);
  }
}
 
const onSubmit = async () => {

  const res: FormResult = {};

  formProps.modelValue.forEach( (field) => {
    res[field.alias] = field.value;
  });

  setLoading(true);
  const aId = await useAwaitAccountId();

  useAccountProfileApi()
    .setProfile({
      id: aId,
      setProfileReq: {
        profileFields: res,
      },
    })
    .then(() => {
      useToast().ok(lt('profile_updated'));
      emit('updated');
    })
    .catch(err => {
      if (parseApiValidation(err)) return;
      addError('Error updating profile');
    })
    .finally(() => {
      setLoading(false);
    });

};

onMounted( () => {
  fetchData();
});

</script>
