import { WalletVM , useWalletApi , apiErrorToString } from "@ui-api3-sdk/api/api3";
import { ref } from "vue";
import { useAwaitAccountId } from "./useAwaitAccountId";

const wallets = ref<WalletVM[]>([]);
const loading = ref(false);
const error = ref('');

async function fetchWallets() {
  loading.value = true;
  const accountId = await useAwaitAccountId();

  useWalletApi().getWalletsList({ id: accountId })
    .then((res) => {
      wallets.value = res.data.payload;
    })
    .catch((err) => {
      error.value = apiErrorToString(err);
    })
    .finally(() => {
      loading.value = false;
    });
}

function walletById(id: number): WalletVM | undefined {
  return wallets.value.find((wallet) => wallet.id === id);
}

export function useWalletOperations() {

  if (! loading.value ) fetchWallets();

  return {
    wallets,
    loading,
    error,
    walletById,
    fetchWallets,
  };
}
