import { ref, watch, toRef, Ref } from 'vue';

import { WalletVM , useWalletApi } from '@ui-api3-sdk/api/api3';

import { AwaitableRefs } from './composables-interface';

export const useWallet = (
  accountId: Ref<number | undefined> | number | undefined,
  walletId: Ref<number | undefined> | number | undefined,
): AwaitableRefs<{
  currentWallet: WalletVM | null;
  currentBalance: number;
}> => {
  const currentWallet = ref<WalletVM | null>(null);
  const currentBalance = ref(0);
  const isLoading = ref(true);
  const isError = ref(false);

  const api = useWalletApi();

  watch(
    [toRef(accountId), toRef(walletId)],
    ([accountId, walletId]) => {
      // console.log('useWallet watch', accountId, walletId);

      if (!accountId || !walletId) {
        isLoading.value = false;
        currentWallet.value = null;
        currentBalance.value = 0;

        return;
      }

      isLoading.value = true;

      api.getWalletsList({ id: accountId }).then(res => {
        currentWallet.value = res?.data?.payload.find(({ id }) => id === walletId) || null;
        if (currentWallet.value) {
          currentBalance.value = +currentWallet.value.balance;
        } else {
          throw new Error('Wallet not found');
        }

        isLoading.value = false;
      });
    },
    { immediate: true },
  );

  return { currentWallet, currentBalance, isLoading, isError };
};
