export type WalletCountries = Array<[string, number]>;

export type WalletBalanceProps = {
  id?: string;
  walletId: number;
  accountId?: number;
  backgroundClass?: string;
  headerClass?: string;
  iconClass?: string;
  title?: string;
  walletCountries?: WalletCountries;
};

export const defaults = {
  backgroundClass: 'bg-primary',
  iconClass: 'mdi-cash',
};
