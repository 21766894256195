import { gravComponentSchema } from '../deep-form-grav';
import { PropertiesBoxProps, defaults } from '@/components/ui/PropertiesBox';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<PropertiesBoxProps>({
  title: 'EditProperties',
  link: '',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('wrap').input('boolean').col(3).title('Widget').desc('Wrap inside of widget window with header and background');

s.add('title').input('vtext')
  .title('Title').col(3);

s.add('backgroundClass').input('mdiColor')
  .title('Form background color').col(3);

s.add('headerClass').input('mdiColor')
  .title('Header color').col(3);

s.add('properties').as(l.clone('mandatoryProperties')).col(12);

s.add('editableProperties').as(l.clone('properties')).col(12)
  .title('Editable properties');

s.add('treeId').as(l.clone('selectTree')).col(3);

s.add('rounded').as( bl.clone('number') ).col(3).title('Rounded corners');

s.add('height').as( bl.clone('number') ).col(3).title('Widget height');

//   mode?: 'list' | 'grid';

s.add('mode').input('dictionary').col(3).title('Mode').data(['list', 'grid'])
  .desc('Display mode');

s.add('headerTitles').as(l.clone('headerTitles')).col(12);


function text() {
  return `
<b>Inline View / Edit Property(ies)</b>
This component allows you to place inline edit of any property inside of the page.
`;
}
