<template>
  <div class="d-flex">
    <v-btn
      v-if="prevState !== 'hidden'"
      :disabled="prevState === 'disabled'"
      variant="text"
      @click="prev"
    >
      {{ $t(isFirstStep ? ( firstBackButtonTitle ?? 'dialogs.back' ) : 'dialogs.prev') }}
    </v-btn>
    <v-spacer />
    <v-btn
      v-if="nextState !== 'hidden'"
      :disabled="nextState === 'disabled'"
      variant="elevated"
      color="primary"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"

      @click="next"
    >
      {{ $t(isFinalStep ? ( finalSubmitTitle ?? 'dialogs.submit' ) : 'dialogs.next') }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  step: number;
  maxStep: number;
  disabledNextAtSteps?: number[];
  enableFirstBackButton?: boolean;
  firstBackButtonTitle?: string;
  enableFinalSubmit?: boolean;
  finalSubmitTitle?: string;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void,
  (event: 'next'): void,
  (event: 'prev'): void,
}>();

type ButtonState = 'hidden' | 'enabled' | 'disabled';

const isFinalStep = computed(() => props.step === props.maxStep);
const isFirstStep = computed(() => props.step === 1);

const nextState  = computed<ButtonState>(() => {
  if (isFinalStep.value) {
    if (! props.enableFinalSubmit)
      return 'hidden';
  }
  if (props.disabledNextAtSteps) {
    if (props.disabledNextAtSteps.includes(props.step)) return 'disabled';
  }
  return 'enabled';
});

const prevState  = computed<ButtonState>(() => {
  if (isFirstStep.value) {
    if (! props.enableFirstBackButton)
      return 'hidden';
  }
  return 'enabled';
});


function next() {
  if (props.step < props.maxStep) {
    emit('next');
    return;
  }
  if (isFinalStep.value && props.enableFinalSubmit) {
    emit('next');
  }
}

function prev() {
  if (props.step > 1) {
    emit('prev');
  }
  if (isFirstStep.value && props.enableFirstBackButton) {
    emit('prev');
  }
}

</script>
