import { usePropertiesStore } from "@/pinia/usePropertiesStore";
import { type DynamicStepComponentProperties } from "./dynamic-stepper-interface";

export const LANGUAGE_DICTIONARY = ['ru', 'en', 'es', 'fr', 'de'];

export const FIELD_TYPES = [
  'string',
  'date',
  'numeric',
  'int',
  'float',
  'dictionary',
  'currency',
  'points',
  'txt',
  'html',
  'img',
  'bool',
] as const; // all other should be treated as string

export type FieldType = typeof FIELD_TYPES[number];

 
type PossibleValues = any;

export interface FormField {
  alias: string;
  title?: string;
  value?: PossibleValues;
  fieldType?: FieldType;
  isMandatory?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  customValidateFn?: (value: PossibleValues) => boolean | string;
}

export type FormResult = Record<FormField['alias'], PossibleValues>;
export type FieldErrors = Record<FormField['alias'], Array<string>>;

export interface InputByTypeProperties {
  variant?: 'outlined' | 'plain' | 'underlined' | 'filled' | 'solo' | 'solo-inverted' | 'solo-filled';
  density?: 'default' | 'comfortable' | 'compact';
  rounded?: boolean | number;
  flat?: boolean;
  clearable?: boolean;
  hideDetails?: boolean;

  noAutocomplete?: boolean;

  preferredCountries?: string[];
  onlyCountries?: string[];

  passwordMinLength?: number;
  passwordHasSpecSymbols?: boolean,
  passwordHasCamelcase?: boolean,
}
export interface ActionButton {
  title: string;
  variant?: "flat" | "text" | "elevated" | "tonal" | "outlined" | "plain";
  color?: string;
  onClick: () => void;
}

export type FormBuilderProps = {
  modelValue: FormField[];
  fieldErrors?: FieldErrors;
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  errorMessages?: string[];
  buttons?: ActionButton[];
  hideSubmitButton?: boolean;
  submitTitle?: string;
  hideFields?: boolean;
  inputProperties?: InputByTypeProperties;
  validateTrigger?: boolean;
  resetTrigger?: boolean;
  submitTrigger?: boolean;
} & DynamicStepComponentProperties;


const isFieldType = (type: string): type is FieldType => (FIELD_TYPES as unknown as string[]).includes(type);

export const assertFieldType = (type: string): FieldType  => {
  if (!isFieldType(type)) throw new Error(`Unknown field type ${type}`);
  return type;
};

export function genFormFieldFromAlias(alias: string, translationOverride?: Record<string, string>): FormField {

  let fieldType = 'string' as FieldType;

  const propType = usePropertiesStore().getPropertyType(alias, translationOverride);
  if (propType)
    fieldType = isFieldType(propType.fieldType) ? propType.fieldType : 'string' as FieldType;

  return {
    alias,
    title: propType.title,
    fieldType,
    isMandatory: true,
  };

}
