<template>
  <div v-if="settingsEnabled" id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      theme="dark"
      flat
      link
      minWidth="100"
      style="
        position: fixed;
        z-index: 450;
        top: 250px;
        right: -35px;

        border-radius: 8px;
      "
    >
      <v-icon size="24">
        mdi-table-cog
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :closeOnContentClick="false"
      activator="#settings"
      location="bottom"
      contentClass="v-settings"
      origin="top right"
      transition="scale-transition"
      style="z-index: 400"
    >
      <v-card class="text-center mb-0" width="300" height="500">
        <v-card-text style="overflow-y: auto; height: 100%">
          <strong class="mb-3 d-inline-block">Theme constructor</strong>

          <div v-for="(item, key) in themeColors" :key="'theme_color_' + key">
            <v-row align="center" noGutters>
              <v-col cols="auto">
                {{ key }}
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-menu :closeOnContentClick="false" location="bottom" origin="top right" transition="scale-transition">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      class="mx-2"
                      icon="mdi-eyedropper-variant"
                      theme="dark"
                      size="small"
                      :color="item"
                    />
                  </template>

                  <div>
                    <v-color-picker v-model="themeColors[key]" @update:model-value="onColorsUpdate" />
                  </div>
                </v-menu>
              </v-col>
            </v-row>

            <div class="my-3" />
          </div>


          <strong class="mb-3 d-inline-block">IMAGES</strong>

          <v-item-group v-model="barImage" class="d-flex justify-space-between mb-3">
            <v-item v-for="imageItem in images" :key="imageItem" :value="imageItem">
              <template #default="{ isSelected, toggle }">
                <v-sheet
                  :class="isSelected && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img :src="imageItem" height="100" width="50" />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 bg-secondary" />

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="dark" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>
          </v-row>

          <v-divider class="my-4 bg-secondary" />

          <strong class="mb-3 d-inline-block">Header</strong>
          <!--here-->

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Header Dark
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="headerDark" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>
          </v-row>

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Custom color
            </v-col>

            <v-spacer />

            <v-col v-show="themeHeader.enable" cols="auto">
              <v-menu :closeOnContentClick="false" location="bottom" origin="top right" transition="scale-transition">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    class="mx-2"
                    theme="dark"
                    icon="mdi-eyedropper-variant"
                    size="small"
                    :color="themeHeader.color"
                  />
                </template>

                <div>
                  <v-color-picker v-model="themeHeader.color" />
                </div>
              </v-menu>
            </v-col>

            <v-col cols="auto">
              <v-switch v-model="themeHeader.enable" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>

            <v-col v-show="themeHeader.enable" class="mt-2" cols="12">
              <v-text-field v-model="themeHeader.color" label="Background" variant="solo" />
            </v-col>
          </v-row>

          <v-divider class="my-4 bg-secondary" />

          <strong class="mb-3 d-inline-block">Sidebar</strong>

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Sidebar Dark
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="barDark" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>
          </v-row>

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Custom color
            </v-col>

            <v-spacer />

            <v-col v-show="themeSidebar.enable" cols="auto">
              <v-menu :closeOnContentClick="false" location="bottom" origin="top right" transition="scale-transition">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    class="mx-2"
                    theme="dark"
                    icon="mdi-eyedropper-variant"
                    size="small"
                    :color="themeSidebar.color"
                  />
                </template>

                <div>
                  <v-color-picker v-model="themeSidebar.color" />
                </div>
              </v-menu>
            </v-col>

            <v-col cols="auto">
              <v-switch v-model="themeSidebar.enable" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>

            <v-col v-show="themeSidebar.enable" class="mt-2" cols="12">
              <v-text-field v-model="themeSidebar.color" label="Background" variant="solo" />
            </v-col>
          </v-row>

          <v-divider class="my-4 bg-secondary" />

          <strong class="mb-3 d-inline-block">Footer</strong>

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Footer Dark
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="footerDark" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>
          </v-row>

          <v-row align="center" noGutters>
            <v-col cols="auto">
              Custom color
            </v-col>

            <v-spacer />

            <v-col v-show="themeFooter.enable" cols="auto">
              <v-menu :closeOnContentClick="false" location="bottom" origin="top right" transition="scale-transition">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    class="mx-2"
                    theme="dark"
                    icon="mdi-eyedropper-variant"
                    size="small"
                    :color="themeFooter.color"
                  >
                    <v-icon theme="dark">
                      mdi-eyedropper-variant
                    </v-icon>
                  </v-btn>
                </template>

                <div>
                  <v-color-picker v-model="themeFooter.color" />
                </div>
              </v-menu>
            </v-col>

            <v-col cols="auto">
              <v-switch v-model="themeFooter.enable" class="ma-0 pa-0" color="secondary" hideDetails />
            </v-col>

            <v-col v-show="themeFooter.enable" class="mt-2" cols="12">
              <v-text-field v-model="themeFooter.color" label="Background" variant="solo" />
            </v-col>
          </v-row>

          <v-divider class="my-4 bg-secondary" />
          <div class="d-flex justify-space-between">
            <v-btn
              class="bg-warning mr-2"
              :loading="isSaving"
              :data-loading="isSaving ? 'true' : undefined"
              @click="saveToGrav"
            >
              APPLY
              <v-icon theme="dark" class="ml-2">
                mdi-content-save
              </v-icon>
            </v-btn>

            <v-btn class="bg-primary" @click="exportToJson">
              JSON
              <v-icon theme="dark" class="ml-2">
                mdi-file-export-outline
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import axios from 'axios';

import { useThemeStore } from '@/pinia/useThemeStore';
import { useAccountStore } from '@/pinia/useAccountStore';
import { mapWritableState, mapActions, mapState } from 'pinia';
import { auth } from '@ui-api3-sdk/api';
import { useToast } from '@/composables';

import sidebar1 from '@/assets/images/sidebar-1.jpg';
import sidebar2 from '@/assets/images/sidebar-2.jpg';
import sidebar3 from '@/assets/images/sidebar-3.jpg';
import sidebar4 from '@/assets/images/sidebar-4.jpg';


function validHexColor(color, fallback) {
  if (!color) return fallback;
  if (/^#[0-9A-Fa-f]{6}$/i.test(color)) return color;
  return fallback;
}


export default {
  name: 'DashboardSettings',

  data: () => ({

    settingsEnabled: false,

    isSaving: false,

    themeColors: {
      primary: '',
      secondary: '',
      accent: '',
      error: '',
      info: '',
      success: '',
      warning: '',
    },

    themeHeader: {
      enable: useThemeStore().isHeaderColorDefined,
      color: validHexColor(useThemeStore().headerColor, '#1976D2'),
    },

    themeFooter: {
      enable: useThemeStore().isFooterColorDefined,
      color: validHexColor(useThemeStore().footerColor, '#1976D2'),
    },

    themeSidebar: {
      enable: useThemeStore().isBarColorDefined,
      color: validHexColor(useThemeStore().barColor, '#1976D2'),
    },

    images: [sidebar1, sidebar2, sidebar3, sidebar4],

    menu: false,

  }),

  computed: {
    ...mapState(useAccountStore, ['currentAccount']),
    ...mapWritableState(useThemeStore, ['barImage', 'dark', 'barDark', 'headerDark', 'footerDark']),
  },

  watch: {

    currentAccount: {
      async handler(newVal) {

        if (newVal) {
          const res = await useAccountStore().getProfilePropertyValue('online_office_admin');
          const isAdmin = res?.raw;

          if (isAdmin) {
            if (typeof isAdmin === 'string') {
              this.settingsEnabled = isAdmin === 'true';
            } else if (typeof isAdmin === 'boolean') {
              this.settingsEnabled = isAdmin;
            } else if (typeof isAdmin === 'number') {
              this.settingsEnabled = isAdmin > 0;
            }
          }
        }
      },

      immediate: true,
    },

    themeSidebar: {
      handler(newVal) {
        if (newVal.enable) {
          this.setBarColor(newVal.color);
        } else {
          this.setBarColor(undefined);
        }
      },

      deep: true,
    },

    themeHeader: {
      handler(newVal) {
        if (newVal.enable) {
          this.setHeaderColor(newVal.color);
        } else {
          this.setHeaderColor(undefined);
        }
      },

      deep: true,
    },

    themeFooter: {
      handler(newVal) {
        if (newVal.enable) {
          this.setFooterColor(newVal.color);
        } else {
          this.setFooterColor(undefined);
        }
      },

      deep: true,
    },

  },

  mounted() {
    this.themeColors = {
      primary: this.$vuetify.theme.current.colors.primary,
      secondary: this.$vuetify.theme.current.colors.secondary,
      accent: this.$vuetify.theme.current.colors.accent,
      error: this.$vuetify.theme.current.colors.error,
      info: this.$vuetify.theme.current.colors.info,
      success: this.$vuetify.theme.current.colors.success,
      warning: this.$vuetify.theme.current.colors.warning,
    };
  },

  methods: {

    onColorsUpdate() {

      const themes = this.$vuetify.theme.themes;

      this.$vuetify.theme.themes.dark.colors = { ...themes.dark.colors, ...this.themeColors };
      this.$vuetify.theme.themes.light.colors = { ...themes.light.colors, ...this.themeColors };

    },

    ...mapActions(useThemeStore,
                  [
                    'setBarImage',
                    'setBarColor',
                    'setBarDark',
                    'setHeaderColor',
                    'setHeaderDark',
                    'setFooterColor',
                    'setFooterDark',
                    'setDark',
                  ]),

    async saveToGrav() {

      const data = {
        token: auth.accessToken,
        themeSettings: useThemeStore().saveTheme(this.$vuetify),
      };

      let hostname = window?.location?.hostname;
      const proto = window?.location?.protocol;

      if (hostname === 'localhost') {
        hostname = `localhost:${window?.location?.port}`;
      }

      const url = `${proto}//${hostname}/api/theme-update`;

      this.isSaving = true;

      try {
        const res = await axios.post(url, data, {});

        if (res.status === 200) {
          useToast().ok(this.$t('theme_settings.save_ok'));
        } else {
          useToast().error(this.$t('theme_settings.save_error'));
        }
      } catch (e) {
        console.error(e);
        useToast().error(this.$t('theme_settings.save_error'));
      }

      this.isSaving = false;

    },

    exportToJson() {

      const data = JSON.stringify(useThemeStore().saveTheme(this.$vuetify));
      const filename = 'theme.json';

      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`,
      );
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
