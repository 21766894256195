<template>
  <v-card class="mt-2">
    <v-card-title
      v-if="title"
      :class="headerClass"
      style="height: 40px;"
      class="d-flex align-center justify-space-between rounded-t py-1 px-2 px-sm-3 px-md-3 px-lg-3 px-xl-3"
    >
      <div class="font-weight-regular text-body-1 flex-1-1">
        {{ $t(title) }}
      </div>

      <div>
        <slot name="buttons" />
      </div>
    </v-card-title>

    <div class="py-3 px-1 px-sm-3 px-md-3 px-lg-3 px-xl-3">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'BasicCard',
  props: {
    headerClass: {
      type: String,
      default: 'bg-primary text-on-primary',
    },

    title: {
      type: String,
      default: '',
    },
  },
};
</script>
