<template>
  <OverlayProgress :loading="loading" :error="error">
    <BasicCard
      :title="la('title')"
    >
      <v-form
        ref="formRef"
        v-model="formValid"
      >
        <v-row>
          <v-col cols="12" sm="4">
            <span class="text-caption"> {{ lv.label_debit_amount }} </span>
            <v-text-field
              v-model.number="amountToSend"
              v-mask:currency
              :prefix="srcWallet?.currency_id"
              prependInnerIcon="mdi-arrow-left-thick"
              required

              :density="inputProperties?.density"
              :variant="inputProperties?.variant"
              :rounded="inputProperties?.rounded"
              :flat="inputProperties?.flat"

              :loading="!usingAmountToSend && previewLoading"
              :rules="[amountSrcWalletRules]"
              @update:model-value="userChangedAmountToSend"
            />
          </v-col>

          <v-col cols="12" sm="8">
            <span class="text-caption"> {{ lv.label_transfer_from }} </span>
            <WalletSelect
              v-model="srcWalletId"
              :title="lv.label_transfer_from"
              :inputProperties="inputProperties"
              :rules="[(v: any) => !!v || lt('error_wallet_required')]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <span class="text-caption"> {{ lv.label_amount_to_receive }} </span>
            <v-text-field
              v-model.number="amountToReceive"
              v-mask:currency
              :prefix="dstWallet?.currency_id"
              prependInnerIcon="mdi-arrow-right-thick"
              required

              :density="inputProperties?.density"
              :variant="inputProperties?.variant"
              :rounded="inputProperties?.rounded"
              :flat="inputProperties?.flat"
              :disabled="!conversionRate"

              :loading="usingAmountToSend && previewLoading"
              :rules="[amountDstWalletRules]"
              @update:model-value="userChangedAmountToReceive"
            />
          </v-col>

          <v-col cols="12" sm="8">
            <span class="text-caption"> {{ lv.label_transfer_to }} </span>
            <WalletSelect
              v-model="dstWalletId"
              :title="lv.label_transfer_to"
              :inputProperties="inputProperties"
              :rules="[(v: any) => !!v || lt('error_wallet_required')]"
              :excepdIds="srcWalletId ? [srcWalletId] : undefined"
            />
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col cols="12" sm="3">
            <span class="text-caption"> {{ lv.exchange_rate }} </span>
            <div class="mt-2 ml-2">
              <span v-if="srcWallet && dstWallet && conversionRate">
                1 {{ srcWallet.currency_id }} = {{ conversionRate.toFixed(2) }} {{ dstWallet.currency_id }}
              </span>
              <span v-else>-</span>
            </div>
          </v-col>

          <v-col cols="12" sm="3">
            <span class="text-caption"> {{ lv.label_transfer_amount }}</span>
            <div class="mt-2 ml-2">
              <span v-if="resultAmountToSend && srcWallet">
                {{ resultAmountToSend.toFixed(2) }} {{ srcWallet.currency_id }}
              </span>
              <span v-else>-</span>
            </div>
          </v-col>

          <v-col cols="12" sm="3">
            <span class="text-caption"> {{ lv.commission }} ({{ displayComissionPercent.toFixed(2) }}%)</span>
            <div class="mt-2 ml-2">
              <span v-if="resultAmountToSend && transferComission && srcWallet">
                {{ transferComission.toFixed(2) }} {{ srcWallet.currency_id }}
              </span>
              <span v-else>-</span>
            </div>
          </v-col>

          <v-col cols="12" sm="3">
            <span class="text-caption"> {{ lv.result_sum }} </span>
            <div class="mt-2 ml-2">
              <span v-if="resultAmountIn && !errorMessage">
                <b>{{ resultAmountOut.toFixed(2) }}</b> {{ srcWallet?.currency_id }} ⇢ <b>{{ resultAmountIn.toFixed(2) }}</b> {{ dstWallet?.currency_id }}
              </span>
              <span v-else>-</span>
            </div>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-alert
            v-if="errorMessage"
            class="mb-4 mt-2"
            type="error"
          >
            {{ errorMessage }}
          </v-alert>
        </v-expand-transition>

        <div class="d-flex flex-row justify-center">
          <v-btn
            class="ma-1"
            color="primary"
            type="submit"
            :disabled="submitDisabled"
            :loading="transferInProgress || previewLoading"
            @click.prevent="submit()"
          >
            {{ lv.button_transfer }}
          </v-btn>
          <v-btn
            class="ma-1"
            color="secondary"
            @click="resetForm()"
          >
            {{ lv.button_reset }}
          </v-btn>
        </div>
      </v-form>
    </BasicCard>
  </OverlayProgress>
</template>

<script setup lang="ts">
import { ref, computed, nextTick , watch } from 'vue';
import debounce from 'lodash.debounce';

 
import { useAwaitAccountId, useLocalization, useToast } from '@/composables';
 
import { apiErrorToString, useWalletApi } from '@ui-api3-sdk/api/api3';
import { useWalletOperations } from '@/composables/useWalletOperations';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { useEventBus } from '@/pinia/useEventBus';

import BasicCard from './base/BasicCard.vue';
import OverlayProgress from '@/components/ui/base/OverlayProgress.vue';

import WalletSelect from './base/WalletSelect.vue';

const { lt, lv, la } = useLocalization('uiWalletToWallet', [
  'title',
  'label_src_balance',
  'label_dst_balance',
  'result_sum',
  'commission',
  'exchange_rate',
  'label_transfer_to',
  'label_transfer_from',
  'label_transfer_amount',
  'label_debit_amount',
  'label_amount_to_receive',
  'start_typing_to_search',
  'button_transfer',
  'button_reset',
  'error_balance_must_be_positive',
  'error_amount_required',
  'error_not_number',
  'error_not_enough_balance',
  'error_wallet_required',
  'transfer_success',
]);

defineProps<{
   
  id?: string;
  inputProperties?: InputByTypeProperties;
}>();

const { loading, error, walletById, fetchWallets } = useWalletOperations();

const previewLoading = ref(false);

const formRef = ref<HTMLFormElement | null>(null);
const formValid = ref(true);
const errorMessage = ref('');

const amountToSend = ref(0);
const amountToReceive = ref(0);

const usingAmountToSend = ref(true);
const resultAmountToSend = ref(0);

const transferInProgress = ref(false);
const submitDisabled = computed(() => !formValid.value || !!errorMessage.value);

const srcWalletId = ref<number | undefined>(undefined);
const dstWalletId = ref<number | undefined>(undefined);

const srcWallet = computed(() => srcWalletId.value ? walletById(srcWalletId.value) : undefined);
const dstWallet = computed(() => dstWalletId.value ? walletById(dstWalletId.value) : undefined);

const conversionRate = ref(0);
const transferComission = ref(0);
const resultAmountIn = ref(0);
const resultAmountOut = ref(0);

const comissionPercent = ref(0);
const displayComissionPercent = ref(0);

const srcWalletBalance = computed({
  get: () => Number(srcWallet.value?.balance || 0),
  set: () => {},
});

watch ([amountToSend, amountToReceive, srcWallet, dstWallet], () => {
  errorMessage.value = '';
});

watch ([amountToSend, amountToReceive, comissionPercent, conversionRate], debounce(() => {
  if (usingAmountToSend.value) {
    const res = Number( (amountToSend.value - amountToSend.value * (comissionPercent.value / 100)).toFixed(2) );
    // console.log('resultAmountToSend (src)', res);
    return resultAmountToSend.value = res;
  }
  if (!conversionRate.value) return resultAmountToSend.value = 0;

  const sum = amountToReceive.value * (1 / conversionRate.value);
  const res = Number( sum.toFixed(2) );
  // console.log('resultAmountToSend (dst)', res);
  return resultAmountToSend.value = res;
}, 500, { leading: false, trailing: true }));


watch( [resultAmountToSend, srcWallet, dstWallet ], debounce(async () => {

  if (!srcWallet.value || !dstWallet.value || !resultAmountToSend.value) {
    resultAmountIn.value = 0;
    resultAmountOut.value = 0;
    return;
  }

  if (previewLoading.value) {
    return;
  }

  previewLoading.value = true;

  try {

    const r = await useWalletApi().previewTransfer({
      walletId: srcWallet.value.id,
      id: await useAwaitAccountId(),
      internalTransferReq: {
        amount: resultAmountToSend.value,
        targetWalletTypeId: dstWallet.value.id,
      },
    });


    // const r = {
    //   data: {
    //     payload: {
    //       rate: 1.2,
    //       fee: 0.1,
    //       amountIn: 100,
    //       amountOut: 120,
    //       feeRate: 0.1,
    //     },
    //   },
    // };


    conversionRate.value = r.data.payload.rate > 0 ? 1 / r.data.payload.rate : 0;
    transferComission.value = r.data.payload.fee;
    resultAmountIn.value = r.data.payload.amountIn;
    resultAmountOut.value = r.data.payload.amountOut;
    displayComissionPercent.value = r.data.payload.feeRate ?? 0;

    if (usingAmountToSend.value) amountToReceive.value = resultAmountIn.value;
    else {
      amountToSend.value = resultAmountOut.value;
    }

    comissionPercent.value = resultAmountOut.value ? (transferComission.value / resultAmountOut.value) * 100 : 0;

    errorMessage.value = '';
    previewLoading.value = false;

   
  }   catch (e: any) {

    previewLoading.value = false;

    if (displayComissionPercent.value && resultAmountToSend.value)
      transferComission.value = resultAmountToSend.value * displayComissionPercent.value / 100;

    if (e?.response?.data.error.code === 11003) {
      console.log('Not enough funds', e);
      errorMessage.value = lt('error_not_enough_balance');
    } else
      errorMessage.value = apiErrorToString(e);
  }

  nextTick(() => {
    formRef.value?.validate();
  });

}, 500, { leading: false, trailing: true }));


const amountSrcWalletRules = (v: number) => {
  if (!v) return lt('error_amount_required');

  const f = parseFloat(v.toFixed(2));

  if (isNaN(f) || !isFinite(f)) return lt('error_not_number');
  if (f < 0.01) return lt('error_amount_required');
  if (! srcWalletBalance.value ) return lt('error_not_enough_balance');
  if (f > srcWalletBalance.value) return lt('error_not_enough_balance');
  if (! previewLoading.value && srcWalletBalance.value < resultAmountOut.value) return lt('error_not_enough_balance');

  return true;
};

const amountDstWalletRules = (v: number) => {
  if (!v) return lt('error_amount_required');
  const f = parseFloat(v.toFixed(2));
  if (isNaN(f) || !isFinite(f)) return lt('error_not_number');
  if (f < 0.01) return lt('error_amount_required');

  if (! srcWalletBalance.value ) return lt('error_not_enough_balance');

  const sum = Math.max(resultAmountToSend.value + transferComission.value, resultAmountOut.value);
  if (sum > srcWalletBalance.value) return lt('error_not_enough_balance');

  return true;
};

const submit = async () => {
  if (!formValid.value) return;
  if (! srcWalletId.value) return;
  if (! dstWalletId.value) return;

  transferInProgress.value = true;

  const accountId = await useAwaitAccountId();

  useWalletApi().createInternalTransfer({
    walletId: srcWalletId.value,
    id: accountId,
    internalTransferReq: {
      amount: resultAmountToSend.value,
      targetWalletTypeId: dstWalletId.value,
    },
  })
    .then(() => {
      useToast().ok(lt('transfer_success'));
      resetForm();
    })
    .catch((e) => {
      errorMessage.value = apiErrorToString(e);
    })
    .finally(() => {
      transferInProgress.value = false;
      fetchWallets();
      useEventBus().update('wallet_history');
    });
};

const resetForm = () => {
  amountToSend.value = 0;
  amountToReceive.value = 0;
  errorMessage.value = '';
  nextTick(() => {
    formRef.value?.resetValidation();
  });
};

function userChangedAmountToReceive() {
  // console.log('userChangedAmountToReceive');
  usingAmountToSend.value = false;
}

function userChangedAmountToSend() {
  // console.log('userChangedAmountToSend');
  usingAmountToSend.value = true;
}

</script>
