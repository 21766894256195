import { reactive, computed, watch } from "vue";
import { useDeepForm, DFFormValue } from "./composables/useDeepForm";
import { DFBasicInputs } from './lib/input-components';
import { getLibrary } from './lib/library';
import { useDeepSchemaBuilder } from "./composables/useDeepSchemaBuilder";
import * as v from './lib/validators';
import * as c from './lib/converters';

import DeepForm from "./components/DeepForm.vue";
export { DeepForm };

import { DFRootFormProps } from "./models/dform-input-core";

 
function isValidInputStructure(input: any): boolean {
  if (!input) {
    return true;
  }
  if (typeof input !== 'object') {
    return false;
  }

  return Object.entries(input).every(([key, value]) => (
    typeof key === 'string' &&
    typeof value === 'object' && value !== null &&
    'component' in value &&
    typeof value.component !== 'undefined'
  ));
}

export function useDeef<T extends DFFormValue, I>(
  initialValue: T extends object ? T : never,
  customInputs?: I,
  rootFormProps?: DFRootFormProps,
) {

  if (!isValidInputStructure(DFBasicInputs)) {
    throw new Error('Invalid DFBasicInputs structure. Please provide a valid DFInputSchema.');
  }

  if (!isValidInputStructure(customInputs)) {
    throw new Error('Invalid customInputs structure. Please provide a valid DFInputSchema.');
  }

  const inputs = { ...DFBasicInputs, ...customInputs } as typeof DFBasicInputs & typeof customInputs;
  const schema = useDeepSchemaBuilder<T, typeof inputs>(inputs);

  const value = reactive(initialValue);

  let usedForm: ReturnType<typeof useDeepForm<T>> | undefined = undefined;

  watch(() => usedForm?.formValue, () => {
    if (usedForm) {
      // console.log('usedForm.props.modelValue', usedForm.props.modelValue);
      Object.assign(value, usedForm.formValue);
    }
  }, { deep: true });

  const valid = computed(() => usedForm?.valid);

  const form = () => {
    if (usedForm) return usedForm;
    usedForm = useDeepForm<T>(initialValue || {} as T, schema.items(), rootFormProps);
    return usedForm;
  };

  return {
    s: schema,
    v,
    c,
    lib: getLibrary(),
    form,
    value,
    valid,
  };
}
