<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <BasicCard
    :title="title || $t('uiMetrics.metrics')"
    :headerClass="headerClass"
  >
    <template #buttons>
      <PeriodSelectScroll
        v-if="allowPeriodSelect"
        v-model="selectedPeriod"
        :forCurrentAccount="true"
        class="flex-1-1"
        @loaded="onPeriodsLoaded($event)"
      />
    </template>

    <v-alert
      v-if="error"
      type="error"
      closable
    >
      {{ error }}
    </v-alert>

    <table v-else class="w-100">
      <tbody>
        <template v-if="positionType === 'vertical'">
          <tr
            v-for="item in list"
            :key="'pp_' + item.title"
          >
            <td
              :class="colPropertyClass"
            >
              {{ pt(item.alias, item.title) }}
            </td>
            <td
              :class="colValueClass"
            >
              <v-progress-circular
                v-if="loading"
                :data-loading="true"
                class="px-2"
                indeterminate
                color="primary"
                size="20"
              />
              <span v-else>{{ item.value ?? '-' }}</span>
            </td>
          </tr>
        </template>
        <template v-else-if="positionType === 'horizontal'">
          <tr>
            <template
              v-for="item in list"
              :key="'pp1_' + item.title"
            >
              <td
                :class="colPropertyClass"
              >
                {{ pt(item.alias, item.title) }}
              </td>
            </template>
          </tr>
          <tr>
            <template
              v-for="item in list"
              :key="'pp2_' + item.title"
            >
              <td
                :class="colValueClass"
              >
                <v-progress-circular
                  v-if="loading"
                  :data-loading="true"
                  indeterminate
                  color="primary"
                  size="20"
                />
                <span v-else>{{ item.value ?? '-' }}</span>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </BasicCard>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, type Ref } from 'vue';
import { MetricsProps, defaults } from './Metrics.ts';

import { useAwaitAccountId, useLocalization } from '@/composables';
import { getMissingServerTranslations } from '@/utils/properties-translation';
import { PLanPropertyValueVM, apiErrorToString, useAccountPositionApi } from '@ui-api3-sdk/api/api3';
import { PeriodSelectItem } from './base/PeriodSelect';

import BasicCard from './base/BasicCard.vue';
import PeriodSelectScroll from './base/PeriodSelectScroll.vue';

const { pt } = useLocalization();

const props = withDefaults(defineProps<MetricsProps>(), {
  id: undefined,
  title: undefined,
  offset: undefined,
  numberPeriodsBack: undefined,
  allowPeriodSelect: undefined,
  treeId: undefined,
  headerClass: () => defaults.headerClass,
  accountProperties: () => defaults.accountProperties,
  positionType: () => defaults.positionType,
  colPropertyClass: () => defaults.colPropertyClass,
  colValueClass: () => defaults.colValueClass,
});

// data
interface List {
  title?: string,
  alias: string,
  value?: string | number | null,
  id: number,
}

const error = ref('');
const list: Ref<List[]> = ref(props.accountProperties.map((alias, index) => ({ alias, value: null, id: index })));
const loading: Ref<boolean> = ref(true);
const selectedPeriod = ref<PeriodSelectItem | undefined>(undefined);

const planProperties = computed<string[]>(() => props.accountProperties.map(alias => alias.replace('m.', '')));

onMounted(async () => {
  if (! props.allowPeriodSelect) {
    await fetch(props.numberPeriodsBack);
  }
});

function onPeriodsLoaded(periods: PeriodSelectItem[]) {

  if (props.numberPeriodsBack !== undefined) {
    const foundPeriod = periods.find(period => period.index === props.numberPeriodsBack);
    if (foundPeriod) {
      selectedPeriod.value = foundPeriod;
    }
  }

}

watch( selectedPeriod, (newValue) => {
  if (newValue) {
    fetch(undefined, newValue.id);
  }
});


const fetch = async (periodBack?: number, periodId?: number) => {
  loading.value = true;
  const aId = await useAwaitAccountId();

  await useAccountPositionApi()
    .getPosition({
      id: aId,
      treeId: props.treeId,
      properties: planProperties.value,
      offset: props.offset,
      numberPeriodsBack: periodBack,
      periodId,
    })
    .then(response => {

      // if (!response.data.payload?.length) throw new Error('response is empty');
      const properties = (response.data.payload || [] as PLanPropertyValueVM[])
        .reduce((acc, position) => ([ ...acc, ...position.properties ]), [] as PLanPropertyValueVM[]);

      const list_: List[] = [];

      planProperties.value.forEach(alias => {
        const property = properties.find(property => property.alias === alias);

        list_.push({
          title: property?.title || pt(`m.${alias}`),
          alias: `m.${alias}`,
          value: property?.value?.presentable,
          id: property?.id || -1,
        });
      });

      list.value = list_;
    })
    .catch ((e) => {
      error.value = apiErrorToString(e);
    })
    .finally(() => {
      loading.value = false;
      getMissingServerTranslations();
    });

};
</script>
