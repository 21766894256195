<!-- TODO: move to typescript, use InputByType -->
<template>
  <div>
    <v-dialog
      v-if="selectedItem"
      v-model="dialog"
      maxWidth="400px"
    >
      <v-card data-component="filter-dialog">
        <v-card-title>{{ selectedItem.text }}</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column mb-4">
            <v-radio-group
              v-if="operations.length < 5"
              @update:model-value="operation = operations[$event]"
            >
              <v-radio
                v-for="(op, index) in operations"
                :key="op.value"
                :label="op.title"
                :value="index"
              />
            </v-radio-group>
            <v-select
              v-else
              v-model="operation"
              :items="operations"
              itemTitle="title"
              itemValue="value"
              :label="$t('common.operation')"
              returnObject
            />
            <template v-if="operation">
              <div
                v-for="(n, index) in operation.count === 'one' ? 1 : 2"
                :key="'operation_' + n"
              >
                <InputByType
                  v-model="dataValue[index]"
                  :alias="selectedItem.value"
                  :fieldType="selectedItem.fieldType"
                />
              </div>
            </template>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary-darken-1"
            variant="text"
            @click="dialog = false"
          >
            {{ $t('dialogs.cancel') }}
          </v-btn>
          <v-btn
            color="primary-darken-1"
            variant="text"
            :disabled="disabledOk"
            @click="addItem"
          >
            {{ $t('dialogs.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-select
      data-component="filter-select"
      :modelValue="chips"
      :items="fields"
      chips
      clearable
      :label="$t('common.filter')"

      singleLine
      prependInnerIcon="mdi-filter"
      :density="inputProperties?.density"
      :variant="inputProperties?.variant"
      :rounded="inputProperties?.rounded"
      :flat="inputProperties?.flat"

      hideSelected
      multiple
      itemTitle="text"
      itemValue="value"
      :loading="loading"
      :data-loading="loading ? 'true' : undefined"

      :disabled="disabled"

      @update:model-value="onChangeCombobox"
    >
      <template #chip="{ item, props }">
        <v-chip
          v-bind="props"
          :modelValue="selected"
          closable
          class="bg-primary"
          theme="dark"
          @click:close="remove(item)"
        >
          <strong>{{ chipTitle(item) }}</strong>
        </v-chip>
      </template>
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
          @click.stop="dialogClick(item)"
        >
          {{ item.text }}
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import { getFilterOperations } from '@/utils/filter-service';
import InputByType from './InputByType.vue';

export default {
  name: 'MyFilter',
  components: { InputByType },
  props: {
    fields: {
      type: Array,
      default() {
        return [];
      },
    },

    initValue: {
      type: Array,
      default() {
        return [];
      },
    },

    inputProperties: {
      type: Object,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: { 'setFilter': null },
  data() {
    return {
      operationIndex: null,

      operation: null,
      selectedItem: null,
      selected: true,
      dialog: false,
      chips: [],
      dataValue: [null, null],
      valueOut: [],
    };
  },

  computed: {
    operations() {
      if (!this.selectedItem)
        return [];
      if (['p.country_id', 'p.region_id', 'p.language_id'].includes(this.selectedItem.value))
        return getFilterOperations('dictionary');
      return getFilterOperations(this.selectedItem.fieldType);
    },

    disabledOk() {
      if (!this.operation)
        return true;
      if (this.dataValue[0] === null ||
        this.dataValue[0] === undefined ||
        this.dataValue[0] === '')
        return true;
      if (this.operation.count === 'two' &&
        (this.dataValue[1] === null ||
          this.dataValue[1] === undefined ||
          this.dataValue[1] === ''))
        return true;
      return false;
    },
  },

  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.selectedItem = null;
        this.dataValue = [null, null];
        this.operation = null;
        this.operationIndex = null;
      }
    },

    chips(newVal) {
      if (this.valueOut.length === newVal.length)
        return;
      this.valueOut = this.valueOut.filter(item => newVal.find(itemVal => itemVal.value === item.field));
      this.$emit('setFilter', this.valueOut);
    },
  },

  mounted() {
    if (! this.initValue || ! Array.isArray(this.initValue) || this.initValue.length === 0) return;
    this.initValue.forEach( (v) => {
      this.valueOut.push(v);
      this.chips.push(this.fields.find((f) => f.value === v.field));
    });
  },

  methods: {


    dialogClick(item) {
      this.selectedItem = this.fields.find(f => f.value === item.value);
      if (!this.selectedItem) {
        console.log(this.fields);
        throw new Error(`No field for ${item.value}`);
      }
      this.dialog = true;
    },

    onChangeCombobox(items) {
      this.chips = this.chips.filter(f => items.includes(f.value));
    },

    getOperationByChip(chip) {
      if (! chip ) return null;
      // console.log('find in ', this.valueOut, 'chip:', chip);
      return this.valueOut.find(item => item.field === chip.value);
    },

    chipTitle(chip) {
      const operation = this.getOperationByChip(chip);
      if (! operation) return '';

      const shorten = {
        'between': '><',
        'n-between': '>!<',
        'iLike': '~',
        'n-iLike': '!~',
      };

      const opName = shorten[operation.operation] || operation.operation;

      return `${chip.title} ${opName} ${operation.value}`;
    },

    addItem() {
      this.valueOut.push({
        field: this.selectedItem.value,
        value: this.operation.count === 'one' ? this.dataValue[0] : [...this.dataValue],
        operation: this.operation.value,
      });
      this.$emit('setFilter', this.valueOut);
      this.chips.push(this.selectedItem);
      this.dialog = false;
    },

    remove(item) {
      this.chips = this.chips.filter(chip => chip.value !== item.value);
    },
  },
};
</script>

<style lang="scss">
.dashboardBase-filter.v-autocomplete
{
  max-width: unset;
}
</style>
