/* eslint-disable camelcase */
export default {
  step_change: "Изменить",
  step_confirm: "Подтвердить",
  change_button: "Изменить",
  your_new_email_confirmed: "Ваш новый адрес электронной почты {0} успешно подтвержден!",
  your_new_phone_confirmed: "Ваш новый номер телефона {0} успешно подтвержден!",

  your_email_confirmation_skipped: "Подтверждение вашего адреса электронной почты {0} было пропущено.",
  your_phone_confirmation_skipped: "Подтверждение вашего номера телефона {0} было пропущено.",

  unknown_error: "Произошла неизвестная ошибка при редактировании контакта",
};
