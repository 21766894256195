<template>
  <div
    v-if="languages && languages.length > 1"
    id="language-select"
    class="ml-2 mr-2"
    :class="xs ? 'pb-2' : undefined"
    style="min-width: 1.5em"
  >
    <v-select
      v-model="currentLanguage"
      :items="languages"
      :singleLine="true"
      density="compact"
      :variant="xs ? 'plain' : 'outlined'"
      :flat="true"
      :hideDetails="true"
      @update:model-value="onUpdate($event)"
    />
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';

import { LANGUAGE_DICTIONARY } from '@/models/form-builder-interface';
import { useLocalization } from '@/composables/useLocalization';
import { useNavigation } from '@/composables';

import { useDisplay } from 'vuetify';
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore';

const { xs } = useDisplay();

const props = defineProps<{
  languages?: string[],
  devMode?: boolean,
}>();

const { locale, setLocale } = useLocalization();
const runtimeSettings = useRuntimeSettingsStore();

const currentLanguage = ref(locale.value);

const languages = props.languages || runtimeSettings.getLanguages || LANGUAGE_DICTIONARY;

function onUpdate(newLang: string) {
  if (props.devMode) {
    setLocale(newLang);
    return;
  }

  useNavigation().changeLanguage(newLang);
}

watch(locale, (newVal) => {
  // console.log('LanguageSelect (watch) new_locale:', newVal);
  if (props.devMode) {
    window.sessionStorage.setItem('locale', newVal);
    currentLanguage.value = newVal;
  }
});


</script>

<style lang="scss">

@media screen and (max-width: 600px) {

  #language-select i {
    display: none !important;
  }
}

</style>
