<template>
  <div>
    <v-chip size="small" :color="color" :variant="variant">{{ value }}</v-chip>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  value: number | string,
  negativeIsGood?: boolean,
  redColor?: string,
  greenColor?: string,
  zeroColor?: string,
  variant?: "text" | "flat" | "outlined" | "plain" | "elevated" | "tonal",
}>(), {
  negativeIsGood: false,
  redColor: 'red',
  greenColor: 'green',
  zeroColor: 'green',
  variant: 'flat',
});

function toNumber(value: number | string) {
  if (typeof value === 'string')
    return Number(value?.replace(/[^\d]+/g,''));
  return value;
}

const color = computed(() => {
  const v = toNumber(props.value);

  if (isNaN(v))
    return undefined;

  if (v === 0) return props.zeroColor;
  const isGood = props.negativeIsGood ? v < 0 : v > 0;
  return isGood ? props.greenColor : props.redColor;
});

</script>
