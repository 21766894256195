<template>
  <!-- <v-select
    v-model="currentLanguage"
    :items="languages"
    @update:model-value="onUpdate($event)"
  /> -->
  <v-menu>
    <template #activator="{ props }">
      <v-btn v-bind="props" variant="text">
        <!-- <template #prepend>
          <v-icon>mdi-cart</v-icon>
        </template> -->
        {{ currentLanguage }}
        <v-icon end>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(lang) in languages" :key="lang">
        <v-list-item-title
          :active="lang === currentLanguage"
          @click="onUpdate(lang)"
        >
          {{ lang }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';

import { LANGUAGE_DICTIONARY } from '@/models/form-builder-interface';
import { useLocalization } from '@/composables/useLocalization';
import { useNavigation } from '@/composables';
import { auth } from '@ui-api3-sdk/api';

import { useDisplay } from 'vuetify';
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore';

 
const { xs } = useDisplay();

const props = defineProps<{
  languages?: string[],
  devMode?: boolean,
   
  variant?: string,
}>();

const { locale, setLocale } = useLocalization();
const runtimeSettings = useRuntimeSettingsStore();

const currentLanguage = ref(locale.value);

const languages = props.languages || runtimeSettings.getLanguages || LANGUAGE_DICTIONARY;

function onUpdate(newLang: string) {
  if (props.devMode) {
    setLocale(newLang);
    return;
  }

  useNavigation().changeLanguage(newLang);
}

watch(locale, (newVal) => {
  if (props.devMode) {
    window.sessionStorage.setItem('locale', newVal);
    currentLanguage.value = newVal;
  }
});


</script>
