<template>
  <VAppBar
    id="app-bar"
    :style="{ background: headerColor }"
    :theme="headerTheme"
    flat
    height="78"
    class="pa-0"
  >
    <div :class="{ 'AppBar-leftBlock-mini': !burgerHide }" class="AppBar-leftBlock d-flex align-center flex-shrink-0">
      <div
        :class="{ 'logo-navHeader-big': burgerHide, 'logo-navHeader-small': !burgerHide }"
        :style="logoStyle"
        :title="logoTitle"
        @click="onLogoClick"
      />
      <v-btn class="mr-1" icon="mdi-menu-right" size="small" @click="burgerClick">
        <v-icon v-if="!burgerHide" class="mr-0">
          mdi-menu-right
        </v-icon>
        <v-icon v-else>
          mdi-menu-left
        </v-icon>
      </v-btn>
    </div>

    <div class="d-flex flex-row align-center justify-space-between w-100 pl-2 pr-2">
      <slot name="title">
        {{ title }}
      </slot>
      <slot name="default" />
    </div>
  </VAppBar>
</template>

<script>
import { useThemeStore } from '@/pinia/useThemeStore';
import { mapState, mapActions } from 'pinia';
import { useNavigation } from '@/composables';
import { VAppBar } from 'vuetify/lib/components/index.mjs';

export default {
  name: 'DashboardAppBar',

  components: {
    VAppBar,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    logoTitle: {
      type: String,
      default: '',
    },
  },

  computed: {

    ...mapState(useThemeStore, ['drawer', 'headerColor', 'headerTheme', 'headerLogoSmall', 'headerLogoBig']),

    burgerHide() {
      return this.drawer;
    },

    logoStyle() {

      if (!this.headerLogoSmall && !this.headerLogoBig) {
        return {
          background: 'unset',
        };
      }

      if (this.burgerHide) {
        return {
          backgroundImage: `url(${this.headerLogoBig})`,
        };
      }

      return {
        backgroundImage: `url(${this.headerLogoSmall})`,
      };
    },
  },

  watch: {
    '$vuetify.display.name'(newVal) {
      this.onDisplayUpdate(newVal);
    },
  },

  mounted() {
    this.onDisplayUpdate(this.$vuetify.display.name);
  },

  methods: {
    ...mapActions(useThemeStore, ['setDrawer']),

    onLogoClick() {
      const mobile = this.$vuetify.display?.name === 'xs';

      if (mobile) {
        this.burgerClick();
        return;
      }

      this.redirectToHome();

    },

    redirectToHome() {
      useNavigation().go('/');
    },

    onDisplayUpdate(newVal) {

      // const storedMode = useThemeStore().drawerModes[newVal];
      // if (storedMode !== undefined) {
      //   this.setDrawer(storedMode);
      //   return;
      // }

      switch (newVal) {
        case 'xs':
          this.setDrawer(false);
          break;
        case 'sm':
          this.setDrawer(false);
          break;
        case 'md':
          this.setDrawer(true);
          break;
        case 'lg':
          this.setDrawer(true);
          break;
        case 'xl':
          this.setDrawer(true);
          break;
        case 'xxl':
          this.setDrawer(true);
          break;
      }


    },

    burgerClick() {
      this.setDrawer(!this.drawer);
      useThemeStore().storeDrawerMode(this.$vuetify.display.name, this.drawer);
      return this.drawer;
    },
  },
};
</script>

<style lang="scss">
#app-bar {

  .v-toolbar__content {
    padding: 0;
  }

  .AppBar-leftBlock {
    position: relative;

    width: 260px;
    height: 100%;
    padding: 14px;

    background-color: rgba(255, 255, 255, .1);

    transition: width .2s;

    button {
      position: absolute;
      top: 18px;
      right: 0;
    }

    &-mini {
      width: 100px;
    }


    .logo-navHeader-big {
      cursor: pointer;

      width: 200px;
      height: 50px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .logo-navHeader-small {
      cursor: pointer;

      width: 35px;
      height: 34px;
      margin: 8px 4px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}
</style>
