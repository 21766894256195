import { WalletBalanceProps, defaults } from '@/components/ui/WalletBalance';
import { gravComponentSchema } from '../deep-form-grav';

 
const { s, l, c, v, src, h, toExport } = gravComponentSchema<WalletBalanceProps>({
  title: 'Wallet Balance',
  link: 'https://www.notion.so/mlmsoft/WalletBalance-1e83d61ea45e44aa942846cfb72c0da9',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('walletId').as(l.clone('walletId'))
  .title('Default wallet').mandatory()
  .col(12);

s.add('backgroundClass').input('mdiColor')
  .title('Form background color').col(3);

s.add('iconClass').input('icon').title('Icon').props({ clearable: true }).col(4);

s.add('title').input('text').title('Title').props({ clearable: true }).col(5);

s.add('walletCountries').as(l.clone('walletCountries'))
  .title('Wallet depending on country')
  .desc('Depending on country setting in users profile make this widget to use corresponding wallet');


function text() {
  return `
<b>UI Component: Wallet Balance</b>
This component shows the wallet name and current balance. Wallet name is set for the whole project, and if you rename it it will change its name everywhere. What you can customize here is the color of a widget and icon.
There is a way to display different wallet id depending on users country setting in profile.
`;
}
