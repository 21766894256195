<template>
  <div
    class="avatar"
    :style="{ width: `${size}px`, height: `${size}px`, borderRadius }"
    data-component="user-avatar"
  >
    <UploadImage
      v-if="aId && allowChange"
      v-model="uploadImageDialog"
      :accountId="aId"
      :noSquare="true"
      @update:image="onImageUpdate($event)"
    />

    <div v-if="loading" />
    <div v-else class="avatar-image-div" :style="{ borderRadius }">
      <img :src="avatarUrl" :style="{ width: `${size}px`, height: `${size}px`, borderRadius }">
      <div v-if="allowChange" class="avatar-image-div-overlay">
        <v-btn
          icon
          color="primary"
          :size="size < 100 ? 'x-small' : 'default'"
          @click="uploadImageDialog = true"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import { useAccountId, useAccountPropertiesItem } from '@/composables';

import UploadImage from '@/components/ui/base/UploadImage.vue';
import emptyAvatar from '@/assets/images/avatar_undefined.png';
import { useAccountStore } from '@/pinia/useAccountStore';


const props = defineProps({
  allowChange: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 200,
  },
  round: {
    type: Boolean,
    default: false,
  },
});

const { aId } = useAccountId();
// const { prop, isLoading, setRaw, setPresentable } = useAccountPropertyCache('avatar_id');
const avatar = useAccountPropertiesItem(['p.avatar_id']);

const uploadImageDialog = ref(false);

 
const loading = computed(() => avatar.isLoading.value || false);
const avatarUrl = computed(() => avatar.item.value?.avatarUrl()|| emptyAvatar);
const borderRadius = computed(() => (props.round ? '100%' : '5%'));

type ImageData = {
  imageUrl: string;
  thumbUrl: string;
  fileStorageId: number;
};

 
function onImageUpdate(data: ImageData) {
  console.log('onImageUpdate', data);
  const accountStore = useAccountStore();

  accountStore.updateRawProfilePropertyValue('avatar_id', data);
  //accountStore.updatePresentableProfilePropertyValue('avatar_id', data.thumbUrl);

  //updateAccountProperty('avatar_id', data.fileStorageId);
}

</script>

<style lang="scss" scoped>
  .avatar {

  /* stylelint-disable-next-line */
  background-color: lightgray;
  border-radius: 5%;

  &-image-div {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 100%;

    border-radius: 5%;

    /* stylelint-disable-next-line */
    background-color: lightgray;

    img {
      // width: 100%;
      // height: 100%;
      object-fit: cover;
    }

    &-overlay {
      position: absolute;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 33.33%;

      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);

      transition: opacity 0.3s ease;
    }

    &:hover .avatar-image-div-overlay {
      opacity: 1;
    }
  }
}

</style>
