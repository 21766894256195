<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<!-- eslint-disable vue/no-unused-properties -->
<script setup lang="ts">
import { useAccountPropertiesItem, useAwaitValue } from '@/composables';
import { GravManagerCogComponent, getGravManagerCogComponent } from '@/utils/grav-manager-instance';
import { computed, onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify';

const props = withDefaults(defineProps<{
  componentName?: string;
  properties?: Record<string, unknown>;
  attachTo?: HTMLElement;
  xOffset?: number;
  yOffset?: number;
  forcePage?: string;
}>(), {
  properties: undefined,
  attachTo: undefined,
  componentName: undefined,
  forcePage: undefined,
  xOffset: 0,
  yOffset: 0,
});

const { smAndUp } = useDisplay();

const isAdmin = ref<boolean>(false);


const showCog = computed(() => isAdmin.value && smAndUp.value && ( (props.componentName && componentId.value) || (!props.componentName && !componentId.value) ));
const componentId = computed(() => props.properties?.id);
const cogInitialized = ref<boolean>(false);

onMounted(async () => {
  const item = await useAwaitValue(useAccountPropertiesItem(), 'srcItem');
  if (! item) return;
  await checkIfAdmin('p.online_office_admin');
  cogInitialized.value = true;

  async function checkIfAdmin(field: string) {
    if (! item ) return false;

    const p = item[field]?.presentable;
    const r = item[field]?.raw;
    if (p === undefined || r === undefined)
      return undefined;

    if (p === "true" || Number(r) > 0) {
      console.log('GravManagerWrapper: Welcome Admin!');
      await getGravManagerCogComponent();
      isAdmin.value = true;
      return true;
    }
    return false;
  }

});

</script>

<template>
  <div v-if="cogInitialized" :data-component="componentName">
    <div v-if="!showCog">
      <slot />
    </div>
    <template v-else>
      <component
        :is="GravManagerCogComponent"
        v-if="!$slots.default"
        :attachTo="attachTo"
        mode="menu"
      />
      <div v-else>
        <div class="victim">
          <slot />
          <div class="overlay" :style="`top: ${yOffset}px; right: ${xOffset}px`">
            <component
              :is="GravManagerCogComponent"
              :attachTo="attachTo"
              :componentId="componentId"
              :componentName="componentName"
              :componentProperties="properties"
              :forcePage="forcePage"
              mode="component"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>

.victim {
  position: relative;
  z-index: 200;
}

.overlay {
  position: absolute;
  z-index: 250;
  top: 0;
  right: 0;

  /* transform: translate(15px, -15px); */
}

</style>
