<template>
  <div>
    <v-list-item
      :active="item.active"
      :href="!item.rt && href && href !== '#' ? href : undefined"
      :rel="!item.rt && href && href !== '#' ? 'noopener' : undefined"
      :target="!item.rt && href && href !== '#' ? '_self' : undefined"
      :to="item.to"
      :title="undefined"
      class="mt-0"
      density="compact"
      @click="actionItem(item)"
    >
      <div class="d-flex flex-row align-center">
        <div v-if="item.icon" :class="{ 'pl-3': child }">
          <v-icon :color="itemColor(item)" :icon="item.icon" />
        </div>

        <div v-if="item.title || item.subtitle" :class="{ 'pl-2': child }">
          <v-list-item-title class="font-weight-medium">
            {{ item.title }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </div>
      </div>
    </v-list-item>
  </div>
</template>

<script>
import { auth } from '@ui-api3-sdk/api';
import { useThemeStore } from '@/pinia/useThemeStore';

export default {
  name: 'DashboardMenuItem',

  //mixins: [Themeable],
  props: {
    item: {
      type: Object,
      default: () => ({
        active: false,
        href: undefined,
        icon: undefined,
        textClass: '',
        subtitle: undefined,
        title: undefined,
        to: undefined,
        rt: undefined,
        color: undefined,
        action: '',
      }),
    },

    child: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    barDark() {
      return useThemeStore().barDark;
    },

    href() {
      return this.item.href || (!this.item.to ? '#' : undefined);
    },
  },

  methods: {
    actionItem(item) {
      // console.log('action:', item);
      if (item.action === 'logout') {
        auth.logout();
      }
    },

    itemColor(item) {
      if (item.color) return `${item.color} lighten-2`;

      return this.barDark ? 'white' : 'primary lighten-2';
    },
  },
};
</script>
