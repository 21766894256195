<template>
  <div v-bind="props" :style="debugMode ? 'border: 2px solid rgb(91, 152, 12);' : undefined">
    <!-- This input here only to pass error state to parent v-form -->
    <v-input
      v-show="false"
      :error="errorMessages && errorMessages.length > 0"
    />
    <div v-if="debugMode" class="d-flex text-caption">
      <div class="text-no-wrap">
        <span :class="resultValidState ? 'bg-green' : 'bg-red'">res</span> |
        <span :class="myFormValid ? 'bg-green' : 'bg-red'">me</span>
      </div>
      <div>
        {{ props.modelValue }}
      </div>
    </div>

    <v-form
      ref="formRef"
      v-model="myFormValid"
      :style="`display: flex; flex-wrap: wrap; margin: 0; ${dfFormStyle}`"
      :class="dfFormClass"
    >
      <div
        v-for="(input, index) in inputsRenderList || []"
        :key="`${input.schema.alias}-${index}-${dfPath}`"
        :class="inputClass(input.componentProps)"
        :style="`${input.componentProps.dfContainerStyle}${debugMode ? ' border: 1px dashed rgb(235, 79, 79);' : ''}`"
      >
        <div v-if="debugMode" class="text-caption">{{ input.schema.inputAlias }}: {{ generatePathAlias(input.schema.alias) }}</div>
        <div class="d-flex align-center">
          <div>
            <slot :name="`prepend-${input.schema.alias}`" :input="input" />
          </div>
          <div class="flex-grow-1">
            <span v-if="showTitle(input)" class="text-caption">
              <v-tooltip
                v-if="input.schema.description"
                :openDelay="500"
                location="bottom"
                :offsetY="true"
              >
                <template #activator="{ props }">
                  <v-icon
                    v-if="input.schema.description"
                    v-bind="props"
                    size="16"
                    class="mr-2"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>

                {{ input.schema.description }}
              </v-tooltip>

              <slot name="title">
                {{ $t(input.schema.title) }}
                <span v-if="input.schema.isMandatory" class="text-error font-weight-bold">*</span>
              </slot>
            </span>
            <!-- || asyncValidationStates[input.schema.alias] -->
            <Component
              :is="input.component"
              v-show="!input.componentProps.dfHidden"
              v-bind="input.componentProps"
              :dfPath="generatePathAlias(input.schema.alias)"
              :loading="input.componentProps.loading || asyncValidationStates[input.schema.alias]"
              :data-loading="loading ? 'true' : undefined"

              :rules="translatedRules(input)"
              @update:model-value="input.componentProps.dfOnUpdate?.($event)"
              @form-valid-state="onKidsFormValidState($event)"
            />
          </div>
          <div>
            <slot :name="`append-${input.schema.alias}`" :input="input" />
          </div>
        </div>
      </div>
    </v-form>

    <v-expand-transition>
      <v-alert
        v-if="errorMessages && errorMessages.length > 0"
        class="mb-4 mt-2"
        type="error"
        closable
      >
        {{ errorMessages.join(', ') }}
      </v-alert>
    </v-expand-transition>
    <slot name="after" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed , onUnmounted , onMounted } from 'vue';
import i18n from "@/i18n";

import { DFModelValue, DFormProps, InputProps, InputEmits } from '../models/dform-input-core';
import { shortenCutEnd, stringifyAny } from '@/utils/utils';

const debugMode = computed(() => props.dfDebug && props.dfDebug > 0);

const props = defineProps<InputProps<DFModelValue, unknown> & DFormProps<DFModelValue>>();
const emit = defineEmits<InputEmits<DFModelValue>>();

const formRef = ref<HTMLFormElement | null>(null);
const myFormValid = ref(true);
const childFormsState = ref<{alias: string, state: boolean}[]>([]);

const errorMessages = ref<Array<string>>([]);
const asyncValidationStates = ref<Record<string, boolean>>({});

const resultValidState = computed(() => {
  if (childFormsState.value.length === 0) return myFormValid.value;
  return myFormValid.value && childFormsState.value.every((item) => item.state);
});

function showTitle(input: typeof props.inputsRenderList[0]) {
  if (! input.schema.title) return false;
  if ( input.componentProps.dfShowTitle === false) return false;
  if ( props.frontPage === true ) return true;
  if ( input.componentProps.dfShowTitle === true ) return true;
  return false;
}

function inputClass(componentProps: InputProps<DFModelValue, unknown>) {
  const cols = componentProps.dfCol;
  const classes = [componentProps.dfContainerClass];

  if (! cols )
    classes.push( props.frontPage ? 'v-col-12' : 'w-100');
  else
    classes.push(props.frontPage ? `v-col-md-${cols}` : `col-${cols}`);

  return classes.join(' ');
}


function generatePathAlias(alias: string) {
  return props.dfPath ? `${props.dfPath}-${alias}` : alias;
}

// here we apply i18n to rules and also convert validators behavior to vuetify rules
 
function translatedRules(input: typeof props.inputsRenderList[0]) {
  const rules = input.schema.validators?.map((vFn) => async (val: unknown) => {
    //console.log(`validate: alias="${schema.alias}", value="${val}"`);
    asyncValidationStates.value[input.schema.alias] = true;
    const res = await vFn(val);
    asyncValidationStates.value[input.schema.alias] = false;
    if (! res) return true;
    return i18n.global.t(res);
  });
  //console.log('translatedRules for ',schema.alias,' = ' , rules);
  return rules;
}

function onKidsFormValidState(event: {alias: string, state: boolean | null}) {
  const idx = childFormsState.value.findIndex((item) => item.alias === event.alias);

  if (event.state === null) {
    if (idx !== -1) {
      childFormsState.value.splice(idx, 1);
    }
    return;
  }

  if (idx === -1) childFormsState.value.push({ alias: event.alias, state: event.state });
  else childFormsState.value[idx].state = event.state;

  if (childFormsState.value.length === 0) return;

  const allKidsValid = childFormsState.value.every((item) => item.state);
  props.methods.forceInvalid(! allKidsValid);

}

function emitValid(valid: boolean | null) {
  if (props.dfPath === undefined) return;
  // console.log('form', props.dfPath, ' emitsValid', valid);
  emit('formValidState', { alias: props.dfPath!, state: valid });
}

onMounted(() => emitValid(resultValidState.value));
onUnmounted(() => emitValid(null));
watch(resultValidState, (valid) => emitValid(valid), { immediate: true });

watch(formRef, (form) => {
  if (form)
    props.methods?.init(form, props);
});


watch( () => props.modelValue, async () => {

  if (debugMode.value) {
    const formName = props.formDebugName || 'form';
    const formPath = props.dfPath || 'root';
    console.log(`${formName} watch-emit (${formPath}) = `, shortenCutEnd(stringifyAny(props.modelValue), 50));
  }

  if (props.rules) {
    errorMessages.value = [];
    for (const vFn of props.rules) {
      const res = await vFn(props.modelValue);
      if (res === false) errorMessages.value.push('Unknown error');
      if (typeof res === 'string') errorMessages.value.push(res);
    }
  }

  emit('update:modelValue', props.modelValue);

}, { immediate: true, deep: true});

</script>

<style lang="scss">

.col-1 { flex: 1 0 calc(100% / 12); }

.col-2 { flex: 1 0 calc(100% / 6); }

.col-3 { flex: 1 0 calc(100% / 4); }

.col-4 { flex: 1 0 calc(100% / 3); }

.col-5 { flex: 1 0 calc(100% / (12 / 5)); }

.col-6 { flex: 1 0 calc(100% / 2); }

.col-7 { flex: 1 0 calc(100% / (12 / 7)); }

.col-8 { flex: 1 0 calc(100% / (12 / 8)); }

.col-9 { flex: 1 0 calc(100% / (12 / 9)); }

.col-10 { flex: 1 0 calc(100% / (12 / 10)); }

.col-11 { flex: 1 0 calc(100% / (12 / 11)); }

.col-12 { flex: 1 0 100%; }

@media screen and (max-width: 600px) {

 .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
   flex: 1 0 100%;
 }

}

</style>
