<template>
  <FormBuilder
    v-bind="confirmFormProps"
    :buttons="[]"
    :hideSubmitButton="isStepperMode || !sentConfirmationRequestId || isCurrentValueConfirmed"
    :submitTitle="$t('fieldConfirmation.verify_button')"
    :disabled="!sentConfirmationRequestId && !canCodeBeSent()"
    :inputProperties="inputProperties"
    :hideFields="isCurrentValueConfirmed || !sentConfirmationRequestId"
    :loading="isLoading"
    :data-loading="isLoading ? 'true' : undefined"
    :stepRequest="stepRequest"
    :step="step"
    @step-response="onFormStepResponse"
    @step-next-disabled="onFormNextDisable"
    @submit="onSubmit($event)"
  >
    <v-alert
      v-show="isLoading"
      type="info"
      class="mb-4"
    >
      <div>{{ $t('fieldConfirmation.loading') }}</div>
    </v-alert>
    <div v-show="!isLoading">
      <v-alert
        v-if="isCurrentValueConfirmed"
        type="success"
        class="mb-4"
      >
        <div>{{ $t(`fieldConfirmation.your_${alias}_confirmed`, [value]) }}</div>
        <RetryTimeoutLink
          :trigger="sentConfirmationRequestId"
          :onClick="onRetrySendCodeClick"
          :timeout="60"
        />
      </v-alert>
      <v-alert
        v-else-if="sentConfirmationRequestId"
        type="info"
        class="mb-4"
      >
        <div> {{ $t('fieldConfirmation.code_was_sent_to') }} {{ yourAddress }}</div>
        <div><b>{{ value }}</b></div>

        <RetryTimeoutLink
          class="mt-2"
          :trigger="sentConfirmationRequestId"
          :onClick="onRetrySendCodeClick"
          :timeout="60"
        />
      </v-alert>
      <v-alert
        v-else-if="!canCodeBeSent()"
        type="warning"
        class="mb-4"
      >
        {{ $t('fieldConfirmation.fill_in_value') }}
      </v-alert>
      <v-alert
        v-else
        type="info"
        class="mb-4"
      >
        <div>
          {{ $t('fieldConfirmation.click') }}
          <span style="cursor: pointer;" @click="onRetrySendCodeClick">
            <b>{{ $t('fieldConfirmation.here') }}</b>
          </span>
          {{ $t(`fieldConfirmation.to_send_code_${alias}`) }}
          <div class="mt-2"><b>{{ value }}</b></div>
        </div>
        <div v-if="optionalConfirm" class="mt-2">
          {{ $t('fieldConfirmation.optional_confirm_message') }}
        </div>
        <div class="mt-4">
          <v-btn
            color="primary"
            @click="onRetrySendCodeClick"
          >
            {{ $t('fieldConfirmation.send_code_button') }}
          </v-btn>
        </div>
      </v-alert>
    </div>
  </FormBuilder>
</template>

<script setup lang="ts">
import { ref, computed, watch , onMounted } from 'vue';

import { useAccountProfileApi, useOTPApi } from '@ui-api3-sdk/api/api3';

import { useAwaitAccountId, useFormBuilder } from '@/composables';
import { FormResult } from '@/models/form-builder-interface';

import { useConfirmableField } from '@/composables/useConfirmableField';
import { ConfirmationResult, OtpHeader, FieldConfirmationProps } from '@/models/field-confirmation-interface';

import { DynamicStepComponentEvents, StepTriggerResponseEvent } from '@/models/dynamic-stepper-interface';
import { useLocalization } from '@/composables/useLocalization';

import FormBuilder from '@/components/ui/base/FormBuilder.vue';
import RetryTimeoutLink from '@/components/ui/base/RetryTimeoutLink.vue';

const emit = defineEmits<{
  (event: 'confirmed', result: ConfirmationResult): void,
  (event: 'loading', result: boolean): void,
} & DynamicStepComponentEvents<ConfirmationResult>>();

const props = defineProps<FieldConfirmationProps>();

const { props: confirmFormProps, methods: confirmForm } = useFormBuilder([
  {
    title: 'fieldConfirmation.verification_code',
    alias: 'code',
    isMandatory: !props.optionalConfirm,
  },
]);

const { t, isLoading: i18nLoading } = useLocalization('fieldConfirmation');

const sentConfirmationRequestId = ref('');
const lastSentValue = ref<string | undefined>(undefined);
const code = ref('');
const confirmedValue = ref<string | undefined>(undefined);
const localLoading = ref(false);

const isLoading = computed(() => localLoading.value || props.loading || i18nLoading.value);
const isStepperMode = computed(() => !!props.step);
const isCurrentValueConfirmed = computed(() => !!confirmedValue.value && confirmedValue.value === props.value );

const yourAddress = computed(() => t(`fieldConfirmation.your_${props.alias}`));

function setLoading(value: boolean) {
  localLoading.value = value;
  emit('loading', value);
}

async function updateFieldWithConfirmation() {

  if (! props.value
    || ! sentConfirmationRequestId.value
    || ! code.value ) {
    throw new Error('Cannot update field with confirmation, some values are missing');
  }

  const aId = await useAwaitAccountId();

  return useAccountProfileApi().setProfileField(
    {
      id: aId,
      alias: props.alias,
      setProfileValueReq: {
        value: props.value,
        forceConfirm: true,
      },
    },
    {
      headers: { ...genOtpHeader() },
    });
}

async function updateFieldWithoutConfirmation() {
  if (! props.value) return;
  const aId = await useAwaitAccountId();
  await useAccountProfileApi()
    .setProfile({
      id: aId,
      setProfileReq: {
        profileFields: { [props.alias]: props.value },
      },
    });

  return true;
}

function genOtpHeader(): OtpHeader | undefined {
  if (! sentConfirmationRequestId.value || ! code.value) return undefined;
  return {
    'x-otp-check': `${props.alias}_confirm:${sentConfirmationRequestId.value}:${code.value}`,
  };
}

function genConfirmationResult(): ConfirmationResult | undefined {
  if (! props.value ) return undefined;
  const otpHeader = genOtpHeader();
  if (! otpHeader) return undefined;
  return {
    field: props.alias,
    value: props.value,
    otpHeader,
  };
}

function sendConfirmedEvent() {
  const res = genConfirmationResult();
  if (! res) return;
  emit('confirmed', res);
}

const submitConfirm = async (formRes: FormResult): Promise<boolean> => {

  // console.log ('onSubmitConfirm formRes=', formRes,
  //              'isCurrentValueConfirmed=', isCurrentValueConfirmed.value,
  //              'sentConfirmationRequestId=', sentConfirmationRequestId.value,
  //              'code=', code.value,
  //              'confirmedValue=', confirmedValue.value,
  //              'alias=', props.alias );


  code.value = formRes['code'];

  setLoading(true);

  try  {

    if ( props.optionalConfirm && ! code.value ) {
      if (props.updateProfile) {
        await updateFieldWithoutConfirmation();
      }
      confirmForm.reset();
      // confirmedValue.value = props.value;
      // sendConfirmedEvent();
      setLoading(false);
      return true;
    }

    const checkResult = await useOTPApi()
      .otpCheck({
        otpCheckReq: {
          "otp_type": `${props.alias}_confirm`,
          requestId: sentConfirmationRequestId.value,
          code: code.value,
        },
      });

    if (checkResult.data.payload.verified === true) {

      if (props.updateProfile) {
        await updateFieldWithConfirmation();
      }

      setLoading(false);
      confirmForm.reset();

      confirmedValue.value = props.value;
      sendConfirmedEvent();

      return true;
    }

    setLoading(false);
    confirmForm.addFieldError('code', t('fieldConfirmation.code_incorrect'));

   
  } catch(err: any) {
    setLoading(false);
    if (confirmForm.parseApiValidation(err)) return false;
    confirmForm.addError(t('fieldConfirmation.verify_error'));
  }

  return false;

};


function canCodeBeSent() {
  const { value, alias, fieldError } = props;
  if (! value || ! alias || fieldError) return false;
  return true;
}

async function sendCode() {

  if (! canCodeBeSent()) return;
  const [ value, alias ] = [ props.value!, props.alias! ];

  setLoading(true);
  try {
    sentConfirmationRequestId.value =
      await useConfirmableField(alias)
        .sendCodeGetRequestId(value);
    lastSentValue.value = value;
    confirmedValue.value = undefined;
    setLoading(false);
  } catch (err) {
    setLoading(false);
    lastSentValue.value = undefined;
    confirmedValue.value = undefined;
    if (confirmForm.parseApiValidation(err)) return;
    confirmForm.addError('fieldConfirmation.send_code_error');
  }
}

function onRetrySendCodeClick() {
  if (! canCodeBeSent()) return;
  confirmForm.reset();
  sendCode();
}

// we intercept responses to stepper and affect on go-next
async function onFormStepResponse(formBuilderEvent: StepTriggerResponseEvent<FormResult>) {

  const step = props.step;
  if (! step) return;

  if (isCurrentValueConfirmed.value && formBuilderEvent.event === 'go-next') {
    emit('stepResponse', { step, event: 'go-next', allow: true, payload: genConfirmationResult() });
    return;
  }

  if (!formBuilderEvent.allow || formBuilderEvent.event !== 'go-next') {
    emit('stepResponse', { step, event: formBuilderEvent.event, allow: formBuilderEvent.allow });
    return;
  }

  if (! formBuilderEvent.payload) throw new Error('no payload');

  const resConfirm = await submitConfirm(formBuilderEvent.payload);
  if (! resConfirm) {
    emit('stepResponse', { step, event: 'go-next', allow: false });
    return;
  }

  emit('stepResponse', { step, event: 'go-next', allow: true, payload: genConfirmationResult() });

}

function onSubmit(formRes: FormResult) {
  if (! isStepperMode.value) submitConfirm(formRes);
}

function onFormNextDisable(formStep: number, disable: boolean) {
  const step = props.step;
  if (! step) return;

  emit('stepNextDisabled', step, disable);
}

function sendInitialCode() {
  if (props.noSendImmediately) return;
  if (isCurrentValueConfirmed.value) return;
  if (lastSentValue.value && lastSentValue.value === props.value) return;

  if (! props.optionalConfirm)
    onRetrySendCodeClick();
}

watch( () => props.stepRequest, async () => {

  const event = props.stepRequest?.event;

  if (! event) return;
  if (! props.step) return;

  if (event === 'on-enter') {
    sendInitialCode();
  }

}, { immediate: true });


onMounted(() => {
  if (! isStepperMode.value)
    sendInitialCode();
});

</script>
