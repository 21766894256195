import { PlanPropertyHistoryVM } from "@ui-api3-sdk/api/api3";
import { Item, PresentableValueFn, RawValueFn, TableGridItem, TableGridItemProperty, slotParams } from "@/models/table-grid-interface";
import * as api from '@ui-api3-sdk/api/api3';

type HistoryPresentableFn = (prop: string, positionOffset?: number) => Array<string>;
type HistoryRawFn = (prop: string, positionOffset?: number) => Array<TableGridItemProperty['raw']>;

export type Callback<T> = (
  p?: PresentableValueFn,
  r?: RawValueFn,
  item?: TableGridItem,
  hp?: HistoryPresentableFn,
  hr?: HistoryRawFn,
  history?: PlanPropertyHistoryVM[],
  downline?: TableGridItem[],
) => T;

export type CallbackV2<T> = (
  params: {
    p?: PresentableValueFn,
    r?: RawValueFn,
    item?: TableGridItem,
    hp?: HistoryPresentableFn,
    hr?: HistoryRawFn,
    history?: PlanPropertyHistoryVM[],
    downline?: TableGridItem[],
  }
) => T;

type CallbackVersion = 'v1' | 'v2';

 
export function getGeneralCallback<P, R>(callback: any): ((props: P) => R) | undefined {
  if (!callback) return undefined;
  if (typeof callback === 'function') return callback;
  if (typeof callback !== 'string') throw new Error('Callback must be string or function');

   
  const maybeCallback = window[callback as any];
  if (!maybeCallback || (typeof maybeCallback !== 'function')) return undefined;

  return maybeCallback as (props: P) => R;
}

 
export function getAuxCallback<T>(v: CallbackVersion, callback: any) {
  if (!callback) return undefined;
  if (typeof callback === 'function') return callback;
  if (typeof callback !== 'string') throw new Error('Aux: callback must be string or function');

   
  const maybeCallback = window[callback as any];
  if (!maybeCallback || (typeof maybeCallback !== 'function')) return undefined;

  if (v === 'v1')
    return maybeCallback as Callback<T>;

  return maybeCallback as CallbackV2<T>;
}

 
export async function runCallback<T>(v: CallbackVersion, callback: any, item: TableGridItem, history?: PlanPropertyHistoryVM[], downline?: TableGridItem[]): Promise<T | undefined> {
  const callbackFn = getAuxCallback(v, callback);
  if (!callbackFn) return undefined;

  const { p, r } = slotParams(item);

  const positionId = Item(item).positionId();

  const h = (prop: string) => history?.map((h) => {

    const property = h.properties.find( (p) => p.alias === prop);
    if (!property) return undefined;

    const positions = property.values;
    if (!positions) return undefined;

    const position = positions.find((pos) => positionId ? pos.positionId === positionId : true);
    if (!position) return undefined;

    return position.value;

  });

  const hp = (prop: string) => h(prop)?.map(v => v?.presentable || '') || [];
  const hr = (prop: string) => h(prop)?.map(v => v?.raw || undefined) || [];

  const cbResult = v === 'v1' ?
    await callbackFn(p, r, item, hp, hr, history, downline) :
    await callbackFn({ p, r, item, hp, hr, history, downline, api });

  if (cbResult) return cbResult as Promise<T>;
  return undefined;
}

 
export async function runCallbackAndMerge(v: CallbackVersion, callback: any, item: TableGridItem, history?: PlanPropertyHistoryVM[], downline?: TableGridItem[]): Promise<TableGridItem> {

   
  const cbResult = await runCallback<Record<string, any>>(v, callback, item, history, downline);

  if (cbResult && typeof cbResult === 'object') {

    const cbResultItem = Object.entries(cbResult).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: {
        raw: value,
        presentable: String(value),
      },
    }), {} as TableGridItem);

    return { ...item, ...cbResultItem };
  }

  return item;
}
