import { FormField } from "@/easy-form/lib/form.interface";
import { INPUTS_MAP } from "@/easy-form/lib/inputs.interface";

export interface FeedbackProps {
  id?: string;
  title?: string;
  subjectTitle?: string;
  bodyTitle?: string;

  customForm?: FormField<keyof typeof INPUTS_MAP>[];
  subjectTemplate?: string;
  bodyTemplate?: string;
  formClass?: string;
}

export const defaults = {
  title: 'uiFeedback.title',
  subjectTitle: 'uiFeedback.subject',
  bodyTitle: 'uiFeedback.body',
};
