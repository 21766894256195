<template>
  <div>
    <v-list-item density="compact">
      <v-list-item-title class="text-caption text-uppercase font-weight-bold">
        {{ item.title }}
      </v-list-item-title>
    </v-list-item>
    <div style="margin-top: -0.7em;">
      <template v-for="(child, i) in children" :key="i">
        <div style="margin-top: -0.2em;">
          <DashboardMenuItem :item="child" child text />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import DashboardMenuItem from './DashboardMenuItem.vue';

export default {
  name: 'DashboardMenuItemGroup',
  components: {
    DashboardMenuItem,
  },

  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
  },

  data() {
    return {};
  },

  computed: {
    children() {
      return this.item.children.map(item => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`,
      }));
    },
  },

  methods: {
    genGroup(children) {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join('|');
    },
  },
};
</script>
<!--
<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style> -->
