 

import { useAccountPositionApi } from '@ui-api3-sdk/api/api3';
import { absolutelyAllPropertyNames, parseVMListToTableGridItems } from "./properties-mapping-service";


export async function fetchPositions(accountId: number, treeId?: number, properties: string[] = []) {

  const positionProps = properties.join().includes('.') ?
    properties
      .filter((prop) => prop.startsWith('m.'))
      .map((prop) => prop.replace('m.', ''))
    : properties;

  const rp = {
    treeId,
    id: accountId,
    offset: -1,
    properties: positionProps,
  };

  const positionResult = await useAccountPositionApi().getPosition(rp);

  if (!positionResult)
    throw new Error(`Could not get positions for account ${accountId}`);

  const positions = positionResult.data.payload;

  const resProperties = properties.length ? properties : absolutelyAllPropertyNames(positions);

  const tableItems = parseVMListToTableGridItems(resProperties, positions);

  return tableItems;
}
