<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">

import { InputProps, InputEmits } from '../models/dform-input-core';
import { InputDRecordArrayItem, InputDRecordData, InputDRecordValue } from './InputDRecrod';
import { useDeepForm } from '../composables/useDeepForm';

import DeepForm from './DeepForm.vue';
import { ref, watch, computed } from 'vue';
import { useDeef } from '../useDeef';

const props = defineProps<InputProps<InputDRecordValue, InputDRecordData>>();
const emit = defineEmits<InputEmits<InputDRecordValue>>();

type FormSchema = { items: InputDRecordArrayItem[]; }

const usedForm = ref<ReturnType<typeof useDeepForm<FormSchema>>>();
const internalFormValue = computed(() => usedForm.value?.props.modelValue);

watch( internalFormValue, (newValue) => {
  if (! newValue) return;
  emitConvertedToRecordValue(newValue);
}, { deep: true });

watch([() => props.dfData, () => props.modelValue], () => {
  if (! props.dfData) return;
  if (usedForm.value) return;
  // console.log('drecord re-create form:', props.dfPath);

  const { s, form } = useDeef({
    items: convertRecordToArray(props.modelValue),
  }, {}, props.dfRootFormProps);

  s.add('items').input('dlist').data(props.dfData);

  usedForm.value = form();
}, { immediate: true },
);

function convertRecordToArray(record?: InputDRecordValue): InputDRecordArrayItem[] {
  const result: InputDRecordArrayItem[] = [];
  if (! record) return result;
  Object.keys(record).forEach((key) => {
    result.push({ key, value: record[key] });
  });
  return result;
}

function convertArrayToRecord(array: InputDRecordArrayItem[]): InputDRecordValue {
  const result: InputDRecordValue = {};
  array.forEach((item) => {
    result[item.key] = item.value;
  });
  return result;
}

 
function onValidState(event: any) {
  emit('formValidState', event);
}


function emitConvertedToRecordValue($event: FormSchema) {
  // console.log('d-record emit converted:', $event.items);
  emit('update:modelValue', convertArrayToRecord($event.items));
}

</script>

<template>
  <DeepForm
    v-if="usedForm?.props"
    v-bind="{ ... props, ... usedForm.props }"
    formDebugName="drecord"
    @update:model-value="emitConvertedToRecordValue($event)"
    @form-valid-state="onValidState($event)"
  />
</template>
