<!-- eslint-disable vue/no-unused-vars -->
<template>
  <TableGrid
    v-bind="tableProps"
    autoSwitchMode
  >
    <template #top>
      <PeriodSelect
        v-if="showPeriods !== 'always-all'"
        v-model="selectedPeriod"
        class="flex-1-1"
        :inputProperties="inputProperties"
        @loaded="onPeriodsLoaded"
      />
    </template>

    <template #expansion="{ item }">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="v-row align-center">
            <div class="v-col-4 text-truncate">{{ item.srcItem['vmtl.created'].presentable }}</div>
            <TransactionLogValueChange :item="item.srcItem" mode="diff" class="v-col-4" />
            <div class="v-col-4 text-truncate">{{ item.srcItem['vmtl.property'].presentable }}</div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pt-4">
          <MemberInfoTable
            :data="item.srcItem"
            :items="items"
            :itemTitles="itemTitles || {}"
          >
            <template #volumeChange>
              <TransactionLogValueChange :item="item.srcItem" mode="diff" />
            </template>
            <template #volumeDiff>
              <TransactionLogValueChange :item="item.srcItem" mode="change" />
            </template>
          </MemberInfoTable>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </template>

    <template #volumediff="{ item }">
      <TransactionLogValueChange v-if="item" :item="item" mode="diff" />
    </template>

    <template #volumechange="{ item }">
      <TransactionLogValueChange v-if="item" :item="item" mode="change" />
    </template>
  </TableGrid>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

import { TableGridItem, TableGridPaginationState } from "@/models/table-grid-interface";

import { useAwaitAccountId, useLocalization } from '@/composables';
import { useTableGrid } from '@/composables/useTableGrid';
import { createTableGridItem } from '@/utils/properties-mapping-service';

import { TransactionLogVM , useAccountTransactionLogApi } from '@ui-api3-sdk/api/api3';

import { PeriodSelectItem } from './base/PeriodSelect.ts';
import PeriodSelect from './base/PeriodSelect.vue';
import TableGrid from './base/TableGrid.vue';

import MemberInfoTable from './base/MemberInfoTable.vue';
import TransactionLogValueChange from './base/TransactionLogValueChange.vue';

import { TransactionLogProps, defaults } from './TransactionLog.ts';

const { la } = useLocalization('uiTransactionLog');


const props = withDefaults(defineProps<TransactionLogProps>(),{
  items: () => defaults.items,
  showPeriods: () => defaults.showPeriods,
  id: undefined,
  accountId: undefined,
  filterDocument:  undefined,
  filterProperties: undefined,
  inputProperties: undefined,
  itemTitles: undefined,
});

const selectedPeriod = ref<PeriodSelectItem | undefined>(undefined);

const { tableProps, tableMethods } = useTableGrid({
  properties: props.items,
  headerTitles: props.itemTitles,
  fetch,
  maximumLimit: 500,
  unknownErrorMessage: la('unknown_error'),
});

watch( [selectedPeriod], () => {
  tableMethods.doFetch();
});

function onPeriodsLoaded(periods: PeriodSelectItem[]) {
  if (periods.length > 0 && ! (props.showPeriods === 'always-all')) {
    selectedPeriod.value = periods[0];
  }
}

async function fetch(properties: string[], pagination: TableGridPaginationState) {

  if (! selectedPeriod.value && (props.showPeriods === 'only-selected' ) ) return { tableItems: [], totalItems: 0 };

  tableMethods.setLoading(true);

  const forAccountId = await useAwaitAccountId(props.accountId);

  const result = await useAccountTransactionLogApi().getAccountTransactionLog({
    id: forAccountId,
    documentType: props.filterDocument as any,
    planProperty: props.filterProperties as any,
    periodId: selectedPeriod.value?.id,
    limit: pagination.limit,
    page: pagination.page - 1,
  });

  const tableItems = result.data.payload.list.map( (e) => mapListEntry(e) );
  const totalItems = result.data.payload.total;

  tableMethods.setLoading(false);
  return { tableItems, totalItems };
}


function mapListEntry(transaction: TransactionLogVM): TableGridItem {

  // if (! transaction.document) {
  //   throw new Error('TransactionLog: transaction.document is undefined');
  // }

  const docAccount = transaction.document ? transaction.document.account : undefined;
  const posAccount = transaction.position?.account;

  const docItem = docAccount
    ? createTableGridItem([], {
      s: docAccount,
      p: docAccount.profile,
    })
    : {} as TableGridItem;

  const renamedDocItem = Object.entries(docItem).reduce((acc, [key, value]) => {
    const newKey = `doc:${key}`;
    return { ...acc, [newKey]: value } as TableGridItem;
  }, {} as TableGridItem);

  const posItem = createTableGridItem([], {
    s: posAccount,
    p: posAccount.profile,
    vmdl: transaction.position,
    vmtl: transaction,
  });

  if (transaction?.propertyModel && posItem['vmtl.property']) {
    posItem['vmtl.property'] = {
      raw: transaction.propertyModel.title,
      presentable: transaction.propertyModel.title,
    };
  }

  const resultItem: TableGridItem = {
    ... posItem,
    ... renamedDocItem,
  };

  // console.log('resultItem', resultItem);

  return resultItem;
}

</script>
