
export type MetricsHistoryProps = {
  id?: string;
  accountId?: number;
  accountProperties?: string[];
  treeId?: number;
  showCurrentPeriod?: boolean;
};

export const defaults = {
  treeId: 0,
};
