 
 
import { DeepFormSchemaItem, DFValidatorFn, DFWatchEntry } from '../models/dform-schema';
import { DFInputSchema, DFModelValue, DFormMethods, InputVisualProps } from '../models/dform-input-core';

import * as v from '../lib/validators';
import { DFFormValue } from '@/deep-form/composables/useDeepForm';

type ItemDefaults = Partial<Omit<DeepFormSchemaItem<unknown, unknown>, 'inputAlias' | 'data' | 'convert'>>;

const defaultSchemaItem: ItemDefaults = {
  customFlags: {},
  validators: [],
};

type NarrowedInputs<I extends DFInputSchema, V> = {
  [IK in keyof I]: V extends I[IK]['value'] ? IK : never;
}[keyof I];

export class DFSchemaBuilderItem<T, K extends keyof T, V, I extends DFInputSchema, D, F> {
  private schemaItem: DeepFormSchemaItem<V, I>;
  private dfInputs: I;
  private parent: DFSchemaBuilder<T, I>;

  constructor(
    alias: string,
    dfInputs: I,
    parent: DFSchemaBuilder<T, I>,
    defaults: ItemDefaults = defaultSchemaItem,
  ) {

    const parentDefaults = parent.default()?.item() as unknown as DeepFormSchemaItem<V, I> || {};

    this.schemaItem = {
      ...defaults,
      ...parentDefaults,
      initialValue: undefined,
      customFlags: {} as F,
    } as unknown as DeepFormSchemaItem<V, I, T>;

    this.schemaItem.inputProps = { ...this.schemaItem.inputProps, ...parent.getEnforcedProps() };

    this.parent = parent;
    this.dfInputs = dfInputs;
    this.schemaItem.validators = [...this.schemaItem.validators || []];
    this.schemaItem.alias = alias;
  }

  public add<K extends keyof T>(key: K) {
    return this.parent.add(key);
  }

  and() {
    return this.parent;
  }

  item() {
    return this.schemaItem;
  }

  items() {
    return this.parent.items();
  }

  getAlias() {
    return this.schemaItem.alias;
  }

  desc(description: string) {
    this.schemaItem.description = description;
    return this;
  }

   
  init(initialValueFunction: (context?: T, rootContext?: DFFormValue | any) => V) {
    this.schemaItem.initialValue = initialValueFunction;
    return this;
  }

  title(title: string) {
    this.schemaItem.title = title;
    return this;
  }

  edit(allowEdit?: boolean) {
    this.schemaItem.allowEdit = allowEdit === undefined ? true : allowEdit;
    return this;
  }

  mandatory(isMandatory?: boolean) {
    this.schemaItem.isMandatory = isMandatory === undefined ? true : isMandatory;

    if (this.schemaItem.isMandatory) {
      this.addValidator(v.required);
    } else {
      this.removeValidator(v.required);
    }

    return this;
  }

  private addValidator(validator: DFValidatorFn<V | undefined>) {
    const exists = this.schemaItem.validators?.find((v) => v === validator);
    if (!exists) this.schemaItem.validators?.push(validator);
  }

  private removeValidator(validator: DFValidatorFn<V | undefined>) {
    this.schemaItem.validators = this.schemaItem.validators?.filter((v) => v !== validator);
  }

  validators(validators: Array<DFValidatorFn<V | undefined>>) {
    validators.forEach((validator) => this.addValidator(validator));
    return this;
  }

   
  convert(convertFn: (value: V, context?: T, rootContext?: DFFormValue | any) => T[K] | undefined) {
    this.schemaItem.convert = convertFn;
    // console.log('added convert to:', this.schemaItem.alias, 'convertFn', convertFn);
    return this as unknown as DFSchemaBuilderItem<T, K, T[K], I, D, F>;
  }

  watch(watchKeys: Array<keyof T>, triggerTarget: DFWatchEntry<T>['triggerTarget']) {
    if (!this.schemaItem.watchers) this.schemaItem.watchers = [];
    this.schemaItem.watchers.push({
      triggerTarget,
      watchKeys,
    });
    return this;
  }

   
  control(formController: (form: DFormMethods<T>, context?: T, rootContext?: DFFormValue | any) => void) {
    this.schemaItem.formController = formController;
    return this;
  }

   
  pre<NV>(preFn: (value: V) => NV) {
    this.schemaItem.pre = preFn;
    return this as unknown as DFSchemaBuilderItem<T, K, NV, I, D, F>;
  }

  props(props: InputVisualProps) {
    this.schemaItem.inputProps = {
      ...this.schemaItem.inputProps,
      ...props,
      ... this.parent.getEnforcedProps(),
    };
    return this;
  }

  col(col: number) {
    this.props({ dfCol: col });
    return this;
  }

  input<IK extends NarrowedInputs<I, V>>(inputAlias: IK) {
    this.schemaItem.inputAlias = inputAlias;
    this.schemaItem.input = this.dfInputs[inputAlias];
    return this as unknown as DFSchemaBuilderItem<T, K, V, I, I[IK]['data'], F>;
  }

  public clone() {
    const newItem = { ...this } as typeof this;
    Object.setPrototypeOf(newItem, DFSchemaBuilderItem.prototype);
    newItem.schemaItem = { ...this.schemaItem };
    newItem.schemaItem.validators = [...this.schemaItem.validators || []];
    newItem.schemaItem.customFlags = { ...this.schemaItem.customFlags || {} } as F;
    newItem.schemaItem.inputProps = { ...this.schemaItem.inputProps || {} };
    return newItem;
  }

   
  as<D = unknown>(clone: DFSchemaBuilderItem<D, keyof D, any, any, any, any>) {
    const myOldSchemaItem = this.schemaItem;

    this.schemaItem = { ...myOldSchemaItem, ...clone.item() as unknown as DeepFormSchemaItem<V, I> };
    this.schemaItem.alias = myOldSchemaItem.alias;

    this.schemaItem.inputProps = {
      ...myOldSchemaItem.inputProps,
      ...this.schemaItem.inputProps,
      ...this.parent.getEnforcedProps(),
    };

    this.schemaItem.validators = [...myOldSchemaItem.validators || [], ...this.schemaItem.validators || []];
    this.schemaItem.customFlags = { ...myOldSchemaItem.customFlags || {}, ...this.schemaItem.customFlags || {} } as F;

    return this as unknown as DFSchemaBuilderItem<T, K, V, I, D, F>;
  }

   
  asMany<D = unknown>(clones: DFSchemaBuilderItem<any, any, any, any, any, any>[]) {
    return clones.reduce((acc, clone) => acc.as<D>(clone), this);
  }

   
  data(data: D | ((context?: T, rootContext?: DFFormValue | any) => D)) {
    this.schemaItem.data = data;
    return this;
  }

  dataAsync(handler: (value?: V, context?: T) => Promise<D>) {
    this.schemaItem.dataAsync = handler;
    return this;
  }


  flag(key: keyof F, value?: F[keyof F]) {
    this.schemaItem.customFlags[key] = value === undefined ? true : value;
    return this;
  }

}

 
class DFSchemaBuilder<T, I extends DFInputSchema, F = any> {
  private schemaItems: DFSchemaBuilderItem<T, keyof T, T[keyof T], I, unknown, F>[];

  private defaults: DFSchemaBuilderItem<T, keyof T, T[keyof T], I, unknown, F>;
  private enforcedProps: InputVisualProps = {};
  private dfInputs: I;

  constructor(dfInputs: I, forceProps: InputVisualProps = {}) {
    this.schemaItems = [];
    this.dfInputs = dfInputs;
    this.enforcedProps = forceProps;
    this.defaults = new DFSchemaBuilderItem<T, keyof T, T[keyof T], I, unknown, F>('', this.dfInputs, this, defaultSchemaItem);
  }

  public getEnforcedProps() {
    return this.enforcedProps;
  }

  public schema<S>() {
    return useDeepSchemaBuilder<S, I>(this.dfInputs, this.enforcedProps);
  }

  public default() {
    return this.defaults;
  }

  public add<K extends keyof T>(key: K) {
    const item
      = new DFSchemaBuilderItem<T, K, T[K], I, unknown, F>(key as string, this.dfInputs, this);
    this.schemaItems.push(item as unknown as DFSchemaBuilderItem<T, keyof T, T[keyof T], I, unknown, F>);

    return this.get(key)!;
  }

  public spawn<V extends DFModelValue = DFModelValue>(key = '') {

    type NT = { [K in keyof T]: V };

    const item: DFSchemaBuilderItem<NT, keyof NT, V, I, unknown, F> =
       
      new DFSchemaBuilderItem<NT, keyof NT, V, I, unknown, F>(key as string, this.dfInputs, this as any);
    item.title('');
    return item as unknown as DFSchemaBuilderItem<NT, keyof NT, V, I, unknown, F>;
  }

  public clone<K extends keyof T>(key: K) {
    const existingItem = this.get(key);
    if (!existingItem) throw new Error(`DFSchemaBuilder->clone: item with key ${String(key)} not found`);
    return existingItem.clone();
  }

  public get<K extends keyof T>(key: K): DFSchemaBuilderItem<T, K, T[K], I, unknown, F> | undefined {
    return this.schemaItems.find((item) => item.getAlias() === key) as unknown as DFSchemaBuilderItem<T, K, T[K], I, unknown, F>;
  }

  public items() {
    const result: DeepFormSchemaItem<T[keyof T], I>[] = [];
    for (const item of this.schemaItems) {

      const schemaItem = item.item();
      // console.log('DFSchemaBuilder->items:', schemaItem);
      schemaItem.input = this.dfInputs[schemaItem.inputAlias as keyof I];
      if (!schemaItem.input) continue;
      result.push(schemaItem);
    }
    return result;
  }

}

 
export function useDeepSchemaBuilder<T, I extends DFInputSchema, F = any>(dfInputs: I, forceProps: InputVisualProps = {}) {
  return new DFSchemaBuilder<T, I, F>(dfInputs, forceProps);
}
