/* eslint-disable camelcase */
export default {
  dialogs: {
    cancel: "Отмена",
    ok: "ОК",
    next: "Далее",
    prev: "Назад",
    submit: "Отправить",
    clear: "Очистить",
    back: "Назад",
    save: "Сохранить",
    close: "Закрыть",
    apply: "Применить",
    confirm: "Подтвердить",
    logout: "Выйти",
    update: "Обновить",
  },
  theme_settings: {
    save_error: "Ошибка сохранения настроек",
    save_ok: "Настройки сохранены",
  },
  common: {
    filter: "Фильтр",
    period: "Период",
    account: "Аккаунт",
    frontline: "Фронтлайн",
    plan_props: "Маркетинговые показатели",
    profile_props: "Данные профиля",
    operation: "Операция",
    documents: "Документы",
    search: "Поиск",
    confirmed: "Подтверждено",
    not_confirmed: "Не подтверждено",
  },
  filter: {
    equals: "Равно (=)",
    not_equals: "Не равно (!=)",
    less: "Меньше (<)",
    less_or_equals: "Меньше или равно (<=)",
    greater: "Больше (>)",
    greater_or_equals: "Больше или равно (>=)",
    between: "Между",
    not_between: "Не между",
    contains: "Содержит",
    not_contains: "Не содержит",
  },
  retry: {
    retry_in: 'Повторить через {0} секунд',
    retry_now: 'Повторить сейчас',
  },

  inputs: {
    date_select: "Выберите дату",
    date_enter: "Ввести дату",
    err: {
      invalid_value: "Неверное значение",
      file_size_limit_mb: "Размер файла не должен превышать {0} MB",
      field_required: "Это поле обязательно",
      email_invalid: "Неверный адрес электронной почты",
      number_invalid: "Не является числом",
      password_min_length: "Пароль должен содержать не менее {0} символов",
      passwords_not_match: "Пароли не совпадают",
      password_need_spec_chars: "Пароль должен содержать специальные символы",
      password_need_capital: 'Пароль должен содержать заглавные буквы',
      date_in_future: "Дата не может быть в будущем",
    },
  },
  "properties_custom": {
    sponsor_id: "Идентификатор спонсора",
    password: "Пароль",
    password_confirm: "Подтверждение пароля",
    volumeChange: "Изменение",
    volumeDiff: "Разница",
  },
  "properties_p": {
    title: "Название",
    invite_code: "Код приглашения",
    created_at: "Дата создания",
    account_status_id: "Статус аккаунта",
    no_sign_up: "Без регистрации",
    privacy: "Конфиденциальность",
    language_id: "Язык",
    country_id: "Страна",
    bank_account: "Банковский счет",
    bank_name: "Название банка",
    skype: "Skype ID",
    phone: "Телефон",
    card_id: "Карточка",
    gender: "Пол",
    login: "Логин",
    accountId: "ID аккаунта",
    email: "E-mail",
    lastname: "Фамилия",
    firstname: "Имя",
    region_id: "Регион",
    birth_date: "Дата рождения",
    avatar_id: "Аватар",
    country_and_region: "Страна/Регион",
    account_id: "ID аккаунта",
    parent_name: "Имя родителя",
    sponsor_info: "Информация о спонсоре",
  },
  "properties_m": {
  },
  "properties_s": {
    id: "ID",
    externalId: "ID",
  },
  "properties_t": {
    fullName: "Полное имя",
    namePlusId: "Имя/ID",
    accountPlusOffset: "Аккаунт/Позиция",
  },
  "properties_r": {
    id: "ID",
  },
  "properties_vmdl": {
    parent_id: "ID родителя",
    level: "Относ. Уровень",
    genealogyParentAccountId: "ID генеолог. родителя",
    number: "Номер в фронтлайн",
    childrenCount: "Детей",
    offset: "Оффсет",
  },
  "properties_vmtl": {
    property: "Свойство",
    docType: "Тип документа",
    created: "Создан",
    newValue: "Новое значение",
    oldValue: "Старое значение",
    context: "Контекст",
  },
  "properties_position": {
    tree_level: "Уровень",
  },
  "properties_parent-profile": {
    parent_wrap: "{0} (спонсор)",
  },
  errors: {
    1000: "Доступ к этому аккаунту запрещен!",
    2001: "Неверный логин или пароль!",
    12000: "Позиция не найдена. Вероятно вы не в дереве.",
  },
};
