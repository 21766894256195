import { Item, TableGridItem } from "./table-grid-interface";
export interface TreePosition {
  accountId: number;
  offset?: number;
  positionId?: number;
}

export interface TreeNode {
  id: string;
  item: TableGridItem;
  open: boolean;
  loading: boolean;
  childrenCount: number;
  number: number;
  isEmpty: boolean;
  wasOpen: boolean;
  children: TreeNode[];
}

export type TreeFetchResult =  {
  tableItems: TableGridItem[];
  totalItems: number;
  root: TreeNode;
  uplineRoot: TreeNode;
};

export function validTP(position: TreePosition): boolean {
  if (position.accountId) return true;
  if (position.positionId) return true;
  return false;
}

export function validNodeId(nodeId: string) {
  return validTP(parseNodeId(nodeId));
}

export function parseNodeId(nodeId: string): TreePosition {
  const [sId, sOffset, sPosId] = nodeId.split('-');

  const result: TreePosition = {
    accountId: Number(sId || 0),
    offset: Number(sOffset || 1),
    positionId: Number(sPosId) > 0 ? Number(sPosId) : undefined,
  };

  return result;
}

export function createNodeIdFromTP(position: TreePosition) {
  return createNodeId(position.accountId, position.offset, position.positionId);
}

export function createNodeId(accountId: number, offset = 1, positionId = 0) {
  if (isNaN(accountId)) throw new Error('accountId is NaN');

  if (!positionId) positionId = 0;
  if (!offset) offset = 1;
  return `${accountId}-${offset}-${positionId}`;
}

export function tpFromItem(item: TableGridItem): TreePosition | undefined {

  const accountId = Number(item['s.id']?.raw);
  const offset = (item['vmdl.offset']?.raw ? Number(item['vmdl.offset']?.raw) : 1);
  const positionId = Number(item['vmdl.id']?.raw || 0);

  const tp = {
    accountId,
    offset,
    positionId,
  };

  if (!validTP(tp)) return undefined;
  return tp;
}

export function nodeIdFromItem(item: TableGridItem) {
  const tp = tpFromItem(item);
  if (!tp) return undefined;
  return createNodeIdFromTP(tp);
}


// Compares two node ids allowing positionId to be 0 (yet uknown) on either
// that is because sometimes we don't get root entry from downline, where position is known
export function sameNode(nodeId1: string, nodeId2: string) {
  const nid1 = parseNodeId(nodeId1);
  const nid2 = parseNodeId(nodeId2);
  return sameNodeTP(nid1, nid2);
}

export function sameNodeTP(nid1: TreePosition, nid2: TreePosition) {
  if (nid1.positionId && nid1.positionId === nid2.positionId) return true;
  return (nid1.accountId === nid2.accountId)
    && (nid1.offset === nid2.offset);
}


export function createNodeFromItem(item: TableGridItem): TreeNode {
  const id = nodeIdFromItem(item);
  if (!id) throw new Error(`Could not create node from item: ${JSON.stringify(item)}`);

  const itemQuery = Item(item);
  const childrenCount = itemQuery.kids();
  const node = {
    item,
    id,
    childrenCount,
    open: false,
    wasOpen: false,
    isEmpty: false,
    number: itemQuery.number(),
    children: [] as TreeNode[],
    loading: false,
  };
  return node;
}
