/* eslint-disable camelcase */
export default {
  request_payout: 'Запросить выплату из',
  payout_from: 'Выплата из',
  amount: 'Сумма',
  no_creds: 'Нет настроек учетных данных для этой платежной системы',
  cancel: 'Отмена',
  submit: 'Отправить запрос',
  update_successful: 'Обновление прошло успешно',
  payment_data: 'Персональные данные для оплаты',
  create_successful: 'Создание прошло успешно',
  enter: 'Ввести',
  error_payout: 'Ошибка выплаты',
  wallet_not_found: 'Кошелёк не найден',
};
