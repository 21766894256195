<!-- eslint-disable vue/no-unused-properties -->

<script setup lang="ts">
import { ref , reactive , computed , toRefs } from 'vue';

import { apiErrorToString, useAccountProfileApi, useAccountPropertiesApi } from '@ui-api3-sdk/api/api3';
import { useLocalization, useAwaitAccountId, useToast } from '@/composables';

import { usePropertiesStore } from '@/pinia/usePropertiesStore';

import { FormField, InputByTypeProperties } from '@/models/form-builder-interface';

import { parsePropertyName } from '@/utils/properties-mapping-service';
import { Item } from '@/models/table-grid-interface';

import InputByType from './InputByType.vue';
import debounce from 'lodash.debounce';
import { useCache } from '@/composables/useCache';

const emit = defineEmits(['updated']);

const { lt, pt } = useLocalization('userProfileMain', [
  'title',
  'unknown_error',
  'profile_updated',
]);

const props = withDefaults(defineProps<{
  alias: string;
  accountId?: number;
  positionId?: number;
  offset?: number;
  treeId?: number;
  includeTree?: number;
  readonly?: boolean;
  inputProperties?: InputByTypeProperties;
  autoCommit?: boolean;
  noTitle?: boolean;
  value?: string | number | boolean;
  forceLoading?: boolean;
}>(), {
  treeId: undefined,
  inputProperties: undefined,
  positionId: undefined,
  accountId: undefined,
  readonly: false,
  autoCommit: false,
  noTitle: false,
  value: undefined,
  offset: undefined,
  includeTree: undefined,
  forceLoading: undefined,
});

const usedCache = useCache(reactive({
  ... toRefs(props),
  properties: [props.alias],
}), onFetched);

const { loading, formFields, data, error } = usedCache;

const positionOffset = ref<number | undefined>(props.offset);
const updating = ref(false);
const errorMessages = ref<string[]>([]);
const formField = ref<FormField>();

const resultLoading = computed(() => props.forceLoading || loading.value || updating.value);

function onFetched() {

  if (error.value) {
    errorMessages.value = [ error.value ];
    return;
  }

  if (! data.value || ! formFields.value?.length)
    throw new Error('invalid data');

  const item = Item(data.value);
  positionOffset.value = props.offset || undefined;

  formField.value = formFields.value[0];
  formField.value.value = props.value || item.r(props.alias);
}

function onValueUpdate() {
  errorMessages.value = [];

  if (props.autoCommit) {
    debounce( onSubmit, 500, { leading: false, trailing: true } )();
  }

}

const onSubmit = async () => {

  loading.value = true;

  const aId = await useAwaitAccountId(props.accountId);

  const [prefix, alias] = parsePropertyName(props.alias);
  if (! prefix || ! alias)
    throw new Error('invalid alias');

  const propDefinition = usePropertiesStore().findPropertyDefinition(prefix, alias);
  if (! propDefinition)
    throw new Error('property definition not found');

  const updatePromise = prefix === 'm'
    ? useAccountPropertiesApi().setProperty({
      id: aId,
      offset: positionOffset.value,
      propertyId: propDefinition.id,
      editPropertyReq: {
        value: formField.value?.value,
      },
    })
    : useAccountProfileApi()
      .setProfile({
        id: aId,
        setProfileReq: {
          profileFields: { [alias]: formField.value?.value },
        },
      });


  updatePromise
    .then(() => {
      useToast().ok(lt('profile_updated'));
      usedCache.updateProperty(props.alias, formField.value?.value);
      emit('updated');
    })
    .catch(err => {
      errorMessages.value = [ apiErrorToString(err, lt('unknown_error')) ];
    })
    .finally(() => {
      loading.value = false;
    });

};


</script>

<template>
  <div class="d-flex flex-row align-center">
    <div class="flex-grow-1">
      <InputByType
        v-if="!formField"
        :alias="alias"
        :errorMessages="errorMessages"
        :title="noTitle ? undefined : pt(alias)"
        :inputProperties="inputProperties"
        :disabled="readonly"
        :loading="resultLoading"
        :data-loading="resultLoading ? 'true' : undefined"
        v-bind="inputProperties"
        @update:model-value="onValueUpdate"
      />
      <InputByType
        v-else
        v-model="formField.value"
        :alias="alias"
        :fieldType="formField.fieldType"
        :isMandatory="formField.isMandatory"
        :title="noTitle ? undefined : formField.title"
        :errorMessages="errorMessages"
        :inputProperties="inputProperties"
        :disabled="readonly"
        :loading="resultLoading"
        :data-loading="resultLoading ? 'true' : undefined"
        v-bind="inputProperties"
        @update:model-value="onValueUpdate"
      />
    </div>
    <div
      v-if="!readonly && !autoCommit && formField"
      class="flex-grow-0 ml-4"
    >
      <v-btn
        color="primary"
        type="submit"
        :loading="loading"
        :data-loading="loading ? 'true' : undefined"
        :disabled="( formField.isMandatory && !formField.value )"
        @click.prevent="onSubmit"
      >
        {{ $t('dialogs.update') }}
      </v-btn>
    </div>
  </div>
</template>
