import { gravComponentSchema } from '../deep-form-grav';
import { FeedbackProps, defaults } from '../../../../components/ui/Feedback';
import i18n from "@/i18n";

type WithSlots = FeedbackProps & {
  '#success': string;
};

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<WithSlots>({
  title: 'FeedBack',
  link: 'https://www.notion.so/mlmsoft/Feedback-3204d9f0d2f94ec7bbe3e280f2403fdb',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('title').input('text').title('Title')
  .pre((v) => i18n.global.t(v || ''))
  .col(4);

s.add('subjectTitle').input('text').title('Subject title')
  .pre((v) => i18n.global.t(v || ''))
  .col(4);

s.add('bodyTitle').input('text').title('Body title')
  .pre((v) => i18n.global.t(v || ''))
  .col(4);

s.add('#success').input('vtextarea').props({ clearable: true })
  .flag('isSlot').flag('isText').flag('slotParams', "formMethods")
  .title('Message after form has been sent').col(12);

function text() {
  return `
<b>Feedback Form</b>
This form is used to allow Online Office users to send a request to your company. The request will be sent to your support email address, and will be populated with the user's name, email, and account ID, as well as message text from the form. Most of the modern helpdesk software platforms (such as ZenDesk, FreshDesk, Kayako) support email integration, when incoming email leads to creation of a ticket in helpdesk software that then can be worked upon by your support team members. So we recommend using your helpdesk platform system email for this form.
`;
}
