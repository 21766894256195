/* eslint-disable camelcase */
export default {
  back_button: "Back",
  change_password_button: "Change Password",
  password_updated: "Your password has been successfully updated!",
  field_new_password: "New Password",
  field_confirm_password: "Confirm Password",
  step_change_password: "Change Password",
  step_contact: "Contact Address",
  step_contact_confirm: "Confirmation",
  unknown_error: "An unknown error occurred while changing the password",
};
