
import { TableColumnActionsSetupProps } from '@/composables/useTableColumnActions';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { ColorRules } from '@/utils/conditional-colors';
import { DownlineServiceParams } from '@/utils/fetch-downline-service';

export type DownlineTableProps = {
  id?: string;
  properties: string[],
  headerTitles?: Record<string, string>;
  autoSwitchMode?: boolean;
  treeId?: number;
  inputProperties?: InputByTypeProperties;
  hideSelf?: boolean;
  selectPeriods?: boolean;
  selectLegsFromTree?: number;
  selectLegsLimit?: number;
  selectLegsLabel?: string;
  selectLegsPositionTitles?: Record<number, string>;
  colors?: ColorRules;
  showInactive?: DownlineServiceParams['showInactive'];
  paginations?: number[];
} & TableColumnActionsSetupProps;

export const defaults = {
  autoSwitchMode: true,
  treeId: 0,
  hideSelf: false,
  selectLegsLabel: 'uiDownlineTable.select_leg',
  selectLegsLimit: 2,
  selectLegsPositionTitles: {
    1: 'uiDownlineTable.left_leg',
    2: 'uiDownlineTable.right_leg',
  },
  colors: {},
  popupInfoOnField: '0',
  popupLinkUrl: '/{{site_language}}/network/view-network/?nodeId={{s.id.raw}}',
  paginations: [5, 25, 50, 100],
};
