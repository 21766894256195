<template>
  <div>
    <div class="d-flex align-center">
      <div class="flex-grow-1">
        <GravFileOperations
          :selectedFiles="selectedFiles"
        >
          <template #prepend>
            <v-btn
              class="flex-grow-1"
              color="primary"
              :loading="useGravManager().loading"
              :data-loading="useGravManager().loading ? 'true' : undefined"

              @click="fetchNodes"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
        </GravFileOperations>
      </div>
    </div>

    <div>
      <GravBrowseFiles
        v-model="selectedFiles"
        :nodes="nodes"
        @edit-file="emit('editFile', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref , watch } from 'vue';
import { GravFileInfoNode } from '@/grav/grav-api-models';
import { useGravManager } from '../comosables/useGravManager';

import GravBrowseFiles from './GravBrowseFiles.vue';
import GravFileOperations from './GravFileOperations.vue';

const grav = useGravManager();
const nodes = ref<GravFileInfoNode[]>([]);
const selectedFiles = ref<string[] | null>([]);

watch( () => grav.pageFiles, () => {
  nodes.value = grav.pageFiles;
  nodes.value.forEach((node) => {
    node.isShown = true;
  });
});

const emit = defineEmits<{
  (event: 'editFile', value: string): void;
}>();

onMounted(async () => {
  await fetchNodes();
});

async function fetchNodes() {
  await grav.upcliShowPages();
}

</script>
