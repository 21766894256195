<template>
  <WidgetCard
    :disableWrapper="singleLine"
    :title="title"
    :backgroundClass="backgroundClass"
    :headerClass="headerClass"
    v-bind="$attrs"
  >
    <template #[slotName]>
      <span v-if="visibleBadges.length">
        <span v-for="(badge, index) in visibleBadges" :key="index">
          <img
            :src="badge"
            :width="props.badgeWidth"
            :height="props.badgeHeight"
            class="mr-2"
          >
        </span>
      </span>
      <span v-else>
        <slot name="noBadges">
          {{ $t(resultNoBadgesText) }}
        </slot>
      </span>
    </template>
  </WidgetCard>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import WidgetCard from './WidgetCard.vue';

import { mapAccountVMToV2 } from '@/models/account-v2-convert';
import { TableGridItem } from '@/models/table-grid-interface';
import { useAccountStore } from '@/pinia/useAccountStore';
import { runCallback } from '@/utils/callback-service';
import { getMatchedRules } from '@/utils/conditional-colors';
import { fetchMyPosition } from '@/utils/fetch-my-position-service';
import { createTableGridItem } from '@/utils/properties-mapping-service';
import { runTemplate } from '@/utils/property-template-service';
import { BadgeBoxProps, defaults } from './BadgeBox.ts';
import { useLocalization } from '@/composables/useLocalization.ts';

const props = withDefaults(defineProps<BadgeBoxProps>(), {
  noBadgesText: undefined,
  fromArrayField: undefined,
  callbackBadgeUrlTemplate: undefined,
  badgeWidth: () => defaults.badgeWidth,
  badgeHeight: () => defaults.badgeHeight,
  badgeUrlTemplate: () => defaults.badgeUrlTemplate,
  center: () => defaults.center,
});

const visibleBadges = ref<Array<string>>([]);

const loading = ref(false);
const item = ref<TableGridItem | undefined>();

const slotName = computed (() => props.center && !props.singleLine ? 'center' : 'default');

const urlTemplate = computed<string>(() => props.callbackBadgeUrlTemplate || props.badgeUrlTemplate || '{{badgeId}}');

const resultNoBadgesText = computed(() => {
  // <!-- {{ noBadgesText === null || noBadgesText === undefined ? $t('$vuetify.noDataText') || 'No data' : noBadgesText }} -->

  const notSpecified = props.noBadgesText === null || props.noBadgesText === undefined;
  const defaultText = '$vuetify.noDataText';
  const inlineMode = props.singleLine === true;
  
  if (inlineMode) {
    if (notSpecified || props.noBadgesText === '') return '';
    return props.noBadgesText;
  }

  return props.noBadgesText || defaultText;

});


onMounted(async () => {
  loading.value = true;

  let resItem: TableGridItem = {};

  if (props.onlyAccount) {
    const account = await useAccountStore().getAccountAsync();
    if (! account ) throw new Error('Account not fetched');

    resItem = createTableGridItem([], { s: mapAccountVMToV2(account) });

  } else {
    const result = await fetchMyPosition(props.treeId || 0, []);

    if (!result) {
      loading.value = false;
      console.error('BadgeBox: No position in tree');
      return;
    }

    resItem = result.item;
  }

  item.value = resItem;
  loading.value = false;

});

watch(() => item.value, async () => {
        if (! item.value) return;
        const conditionalBadges = props.badges?.filter(
          (badge) => getMatchedRules(badge.rules, item.value!, {}).length > 0)
          .map((badge) => badge.badgeUrl)
          || [];

        const badgesFromCallback = (await runCallback<Array<string>>('v2', props.callback, item.value) || [])
          .map((badge) => runTemplate(urlTemplate.value, item.value, { badgeId: badge }));

        const badgesFromArrayField = (props.fromArrayField
          ? getArrayOfStrings(props.fromArrayField, item.value) || []
          : []).map((badge) => runTemplate(urlTemplate.value, item.value, { badgeId: badge }));


        visibleBadges.value = [ ... badgesFromCallback, ... conditionalBadges, ... badgesFromArrayField ];
      },
      { immediate: true},
);

function getArrayOfStrings(fieldAlias: string, item: TableGridItem): Array<string> {
  const raw = item[fieldAlias].raw;

  if (Array.isArray(raw)) {
    return raw.map((x) => x.toString());
  }

  if (typeof raw === 'string') {
    return raw.split(/\s*,\s*/);
  }

  return [];
}


</script>
