

<template>
  <GravManagerWrapper :id="id" :componentName="componentName" :properties="props">
    <component :is="Feedback" v-bind="{ style: $attrs.style, ...changableProps }">
      <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import { FeedbackProps } from './Feedback.ts';
import Feedback from './Feedback.vue';
import GravManagerWrapper from './GravManagerWrapper.vue';

import { useChangableProps } from '@/composables/useChangableProps';

const componentName = 'ui-feedback';
const $slots = defineSlots<Record<string, unknown>>();

const props = defineProps<FeedbackProps>();
const changableProps = useChangableProps(componentName, props);

</script>
