
import { TableGridPaginationState } from "@/models/table-grid-interface";
import { parseVMListToTableGridItems } from "./properties-mapping-service";

import { useAccountApi } from '@ui-api3-sdk/api/api3';
import { usePropertiesStore } from '../pinia/usePropertiesStore';

export const UPLINE_MAX_LIMIT = 500;

type UplineParams = {
  treeId: number,

  accountId: number,
  offset?: number,
  positionId?: number,

  properties: string[],

  pagination?: TableGridPaginationState,
};


export async function fetchUpline(up: UplineParams) {

  const requestPlanProperties = up.properties.filter((p) => p.startsWith('m.')).map((p) => p.split('.')[1]);

  const rp = {
    id: up.accountId,
    limit: up.pagination?.limit || UPLINE_MAX_LIMIT,
    page: up.pagination?.page ? up.pagination?.page - 1 : 0,
    treeId: up.treeId,
    properties: requestPlanProperties,
    offset: up.offset,
    positionId: up.positionId,
  };

  const res = await useAccountApi().getUpline(rp);

  usePropertiesStore().updatePlanProperties(res.data.map.planProperties);
  usePropertiesStore().updateProfileFields(res.data.map.profileFields);

  const totalItems = res.data.payload.total;
  const uplinePositionList = res.data.payload.list;

  const tableItems = parseVMListToTableGridItems(
    [],
    uplinePositionList,
  );

  return { totalItems, tableItems };

}
