<script setup lang="ts">
import { computed } from 'vue';
import { inputMdiColorDefaults } from './mdi-color.interface';

type InputDictionaryItemValue = string | number | boolean | null | undefined;

type InputDictionaryItem = {
  title: string;
  value: InputDictionaryItemValue;
};

type InternalItem = Exclude<InputDictionaryItem, string>;

const props = defineProps<{ modelValue: string }>();

const emit = defineEmits<{
  (event: 'update:modelValue', result: string): void
}>();

const noAccent = ['brown', 'grey', 'blue-grey'];

const items = computed<Array<InternalItem>>(() => {

  const data = inputMdiColorDefaults;
  const res: InternalItem[] = [];

  ['primary', 'secondary', 'error', 'warning', 'info', 'success'].forEach((color) => {
    // add(`${data.prefix}-${color}`);
    addp(color, data.prefix);
  });

  data.colors.forEach((color) => {

    // add(`${data.prefix}-${color}`);
    addp(color, data.prefix);

    for (let i = 1; i <= data.maxLighten; i++) {
      // add(`${data.prefix}-${color}-lighten-${i}`);
      addp(`${color}-lighten-${i}`, data.prefix);
    }

    for (let i = 1; i <= data.maxDarken; i++) {
      // add(`${data.prefix}-${color}-darken-${i}`);
      addp(`${color}-darken-${i}`, data.prefix);
    }

    if (! noAccent.includes(color)) {
      for (let i = 1; i <= data.maxAccent; i++) {
        // add(`${data.prefix}-${color}-accent-${i}`);
        addp(`${color}-accent-${i}`, data.prefix);
      }
    }

  });

  // if (data.prefix === 'bg') {
  //   add('elevation-0');
  //   add('elevation-1');
  //   add('elevation-2');
  //   add('elevation-3');
  //   add('elevation-4');
  //   add('elevation-5');
  //   add('elevation-6');
  // }


  function addp(color: string, prefix?: string) {
    if (prefix)
      add(`${prefix}-${color}`);
    else
      add(color);
  }

  function add(color: string) {
    res.push({ title: color, value: color });
  }

  return res;

});


</script>

<template>
  <v-autocomplete
    v-bind="props"

    :items="items"
    itemTitle="title"
    itemValue="value"
    :prependInnerIcon="`bg-${modelValue}`"

    @update:model-value="emit('update:modelValue', $event);"
  >
    <template #item="{ item, props: { title, ...otherProps } }">
      <v-list-item
        v-bind="otherProps"
        :class="`bg-${item.value}`"
      >
        {{ title }}
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
./mdi-color.interface
