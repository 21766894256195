<template>
  <div>
    <slot />
    <v-form
      v-show="!hideFields"
      ref="formRef"
      v-model="formValid"
      v-bind="$attrs"
      @submit.prevent="submit()"
    >
      <InputByType
        v-for="field in formFields"
        :key="field.alias"
        v-model="field.value"
        v-bind="inputProperties"
        :isMandatory="field.isMandatory"
        :alias="field.alias"
        :fieldType="field.fieldType"
        :title="field.title"
        :disabled="field.disabled || disabled || loading"
        :errorMessages="fieldErrors?.[field.alias]"
        :countryId="formFields.find(f => f.alias === 'country_id')?.value"
        :password="formFields.find(f => f.alias === 'password')?.value"
        :hidden="field.hidden"
        :customValidateFn="field.customValidateFn"
        @update:model-value="emit('fieldUpdated', field.alias)"
      />
    </v-form>
    <slot name="belowForm" v-bind="{ submitDisabled, loading, submit, resetForm }" />
    <v-expand-transition>
      <v-alert
        v-if="errorMessages && errorMessages.length > 0"
        class="mb-4 mt-2"
        type="error"
        closable
      >
        {{ errorMessages.join(', ') }}
      </v-alert>
    </v-expand-transition>

    <slot name="belowError" v-bind="{ submitDisabled, loading, submit, resetForm }" />

    <slot name="actions">
      <v-btn
        v-if="!hideSubmitButton"
        class="mt-1 mr-1"
        variant="elevated"
        color="primary"
        type="submit"
        :loading="loading"
        :data-loading="loading ? 'true' : undefined"

        :disabled="submitDisabled"
        @click.prevent="submit()"
      >
        {{ $t(submitTitle ?? 'dialogs.submit') }}
      </v-btn>
      <v-btn
        v-for="button in actionButtons"
        :key="button.title"
        class="mt-1 mr-1"
        :color="button.color ?? 'secondary'"
        :variant="button.variant ?? 'elevated'"
        :disabled="loading"
        @click="button.onClick()"
      >
        {{ $t(button.title) }}
      </v-btn>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, computed, watch , onMounted } from 'vue';
import { ActionButton, FormBuilderProps, FormField, FormResult  } from '@/models/form-builder-interface';

 
import InputByType from './InputByType.vue';
import { DynamicStepComponentEvents } from '@/models/dynamic-stepper-interface';

const props = defineProps<FormBuilderProps>();

const actionButtons = computed<ActionButton[]>(() => props.buttons ?? [
  {
    title: 'dialogs.clear',
    variant: "text",
    onClick: () => resetForm(),
  },
]);

const emit = defineEmits<{
  ( event: 'submit', result: FormResult): void;
  ( event: 'wasReset', ): void;
  ( event: 'fieldUpdated', alias: FormField['alias']): void;
  ( event: 'formLoading', loading: boolean): void;
  ( event: 'formValid', valid: boolean): void;
} & DynamicStepComponentEvents<FormResult>>();

const formFields = computed(() => props.modelValue);

const formRef = ref<HTMLFormElement | null>(null);
const formValid = ref(true);

function resetForm() {
  formFields.value.forEach(field => {
    if (field.fieldType === 'date') field.value = null;
    else
      field.value = undefined;
  });

  nextTick(() => {
    formRef.value?.resetValidation();
    // formRef.value?.clear();
    // formRef.value?.reset();
    emit('wasReset');
  });
}

const submitDisabled = computed(() =>
  ! formValid.value
  || props.loading
  || props.disabled
  || !!props.errorMessages?.length,
);

function genResult() {
  const res = formFields.value.reduce<FormResult>(
    (acc, cur) => ({ ...acc, ...{ [cur.alias]: cur.value } }),
    {},
  );
  return res;
}

const submit = () => {
  if (! formValid.value) {
    console.log('Form is not valid, no submit');
    return;
  }
  emit('submit', genResult());
};

function emitValidState() {
  emit('formValid', formValid.value);
  if (props.step) {
    emit('stepNextDisabled', props.step, !formValid.value);
  }
}

watch( () => props.submitTrigger, () => {
  submit();
});

watch( () => props.resetTrigger, () => {
  resetForm();
});

watch( () => props.validateTrigger, () => {
  formRef.value?.validate();
});

watch( formValid, async () => {
  if (formValid.value === true || formValid.value === false) {
    emitValidState();
  }
});

onMounted( () => {
  emitValidState();
});

watch( () => props.stepRequest, () => {

  const step = props.step;
  const event = props.stepRequest?.event;
  if (! event || ! step) return;

  if (event === 'go-next') {
    emit('stepResponse', { step, event, allow: formValid.value, payload: genResult() });
    return;
  }

  emit('stepResponse', { step, event, allow: true });

}, { immediate: true });


</script>
