<template>
  <div>
    <v-card>
      <v-card-title
        v-if="!noCurrentPage"
      >
        Page: {{ grav.selectedPageName }} ({{ grav.selectedPageLanguage }})
      </v-card-title>
      <v-card-title
        v-else
      >
        No current page
      </v-card-title>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="3">
            <v-btn
              color="primary"
              :disabled="actionsDisabled"
              @click="runLayoutUpdate"
            >
              Layout update
            </v-btn>
          </v-col>
          <v-col>
            <p>
              This function takes current language of this page and updates all other languages with the same layout.
              It tries to keep component properties of the original pages.
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="3">
            <v-btn
              color="primary"
              :disabled="actionsDisabled"
              @click="runComponentsUpdate"
            >
              Components update
            </v-btn>
          </v-col>
          <v-col>
            <p>
              This function takes all components on the current language page and their properties
              and updates all other languages with those properties avoiding updating text properties
              (preserving translations).
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="3">
            <v-btn
              color="primary"
              :disabled="actionsDisabled"
              @click="runResave"
            >
              Resave current page
            </v-btn>
          </v-col>
          <v-col>
            <p>
              This function takes current language of this page and resaves it.
              It involves parsing the page and saving it again.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <GravShowLogs :logs="logs" :result="result" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useGravManager } from '../comosables/useGravManager';

import GravShowLogs from './GravShowLogs.vue';

const grav = useGravManager();
const logs = ref<string[]>([]);
const result = ref<boolean | undefined>(undefined);

const noCurrentPage = computed(() => !grav.selectedPageName || !grav.selectedPageLanguage);
const actionsDisabled = computed(() => grav.loading || noCurrentPage.value);

async function runLayoutUpdate() {
  result.value = undefined;
  const res = await grav.upcliLayoutUpdate();
  if (res) {
    logs.value = res;
    result.value = grav.result || false;
  }
}

async function runComponentsUpdate() {
  result.value = undefined;
  const res = await grav.upcliComponentsUpdate();
  if (res) {
    logs.value = res;
    result.value = grav.result || false;
  }
}

async function runResave() {
  result.value = undefined;
  const res = await grav.upcliResave();
  if (res) {
    logs.value = res;
    result.value = grav.result || false;
  }
}


</script>
