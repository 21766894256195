import { ColorRules } from "@/utils/conditional-colors";

export interface LeaderBoardProps {
  id?: string;
  boardId: number;
  items?: string[],
  itemTitles?: Record<string, string>,
  avatarSize?: number,
  showPositionChange?: boolean,
  colors?: ColorRules;
}

export const defaults = {
  items: [
    'position',
    'accountAvatar',
    'accountTitle',
  ],
  avatarSize: 60,
  showPositionChange: true,
};
