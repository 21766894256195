<template>
  <div>
    <v-expansion-panels v-model="panelStates">
      <GravEditComponentProperty
        v-for="prop in component.properties"
        :key="prop.name"

        :componentId="component.id"
        :currentLanguage="grav.selectedPageLanguage"
        :properties="componentPropertyByLanguages(component.id, prop.name)"
        @deleted="panelStates = null"
      />
    </v-expansion-panels>
    <div class="d-flex align-center">
      <v-text-field
        v-model="newPropertyName"
        style="max-width: 180px;"
        label="New property name"
        variant="plain"
      />
      <v-btn
        :disabled="!nameIsValid"
        :loading="grav.loading"
        :data-loading="grav.loading ? 'true' : undefined"

        @click="addProperty"
      >
        Add property
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useGravManager } from '../comosables/useGravManager';
import { GravComponentDTO, GravComponentPropertyDTO } from '../../grav-api-models';

import GravEditComponentProperty from './GravEditComponentProperty.vue';
import { getLocaleRef } from '@/utils/utils';

 
const props = withDefaults(defineProps<{
  component: GravComponentDTO;
}>(), {
});

const grav = useGravManager();

const newPropertyName = ref<string>('');
const panelStates = ref<number | null>(null);
const nameIsValid = computed(() => /^[#@]?[a-z]{1}[-a-z0-9_.]*[a-z0-9]{1}$/.test(newPropertyName.value));

async function addProperty() {
  const lang = getLocaleRef().value;
  await grav.upcliUpdateProperty(
    props.component.id,
    newPropertyName.value,
    { [lang]: '' },
  );
  newPropertyName.value = '';
  panelStates.value = null;
}

function componentPropertyByLanguages(componentId: string, propName: string) {

  const res: Record<string, GravComponentPropertyDTO> = {};

  grav.allPagesDTO?.forEach((pageDto) => {
    const otherComponent = pageDto.page.find((c) => c.id === componentId);
    if (otherComponent) {
      const otherProp = otherComponent.properties.find((p) => p.name === propName);
      if (otherProp) res[pageDto.language] = otherProp;
      else res[pageDto.language] = { name: propName, value: '', isText: true, isDynamic: false };
    }
  });

  return res;
}

</script>
