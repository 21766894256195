<template>
  <v-carousel
    v-if="srcs"
    ref="carouselRef"
    :height="height"
    :hideDelimiters="hideDelimiters"
    hideDelimiterBackground
    :showArrows="arrows"
    cycle
    :interval="interval"
  >
    <template v-for="src in srcs">
      <v-carousel-item
        v-if="src.length === 1 || src[1] === 'image'"
        :key="src[0]"
        :src="src[0]"
        :cover="!noCover"
      />

      <v-window-item v-else-if="src[1] === 'video'" :key="src[0] + '_' + src[1]" class="v-carousel-item">
        <iframe
          :style="{
            'width': videoWidth,
            'height': videoHeight
          }"
          :src="setUrl(src[0])"
          title="Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </v-window-item>
    </template>
  </v-carousel>
  <div v-else>
    {{ lt('empty') }}
  </div>
</template>


<script setup lang="ts">
import { useLocalization } from '@/composables';

import { computed, onMounted, ref } from 'vue';
import { CarouselWidgetProps, defaults } from './CarouselWidget.ts';

const { lt } = useLocalization('uiCarouselWidget');

const props = withDefaults(defineProps<CarouselWidgetProps>(), {
  srcs: undefined,
  id: undefined,
  autoplay: () => defaults.autoplay,
  mute: () => defaults.mute,
  hideDelimiters: () => defaults.hideDelimiters,
  delimitersStyle: () => defaults.delimitersStyle,
  interval: () => defaults.interval,
  showArrows: () => defaults.showArrows,
  videoWidth: () => defaults.videoWidth,
  videoHeight: () => defaults.videoHeight,
  height: () => defaults.height,
});

const carouselRef = ref<HTMLFormElement | null>(null);

const arrows = computed(() => props.showArrows ? '': 'hover');

const setUrl = (url: string) => {
  const isParam = /\?/g.test(url);
  return `${url}${(isParam ? '&' : '?')}autoplay=${props.autoplay}&mute=${props.mute}`;
};

onMounted(() => {
  if (props.delimitersStyle === 1) {
    carouselRef.value?.$el.classList.add('v-carousel--custom');
  }
});

</script>

<style>
.v-carousel--custom .v-carousel__controls {
    position: relative;
    bottom: 47px;

    min-width: 100px;
    max-width: max-content;
    margin-right: auto;
    margin-left: auto;
}
</style>
