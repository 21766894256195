<template>
  <v-text-field
    v-bind="props"
    :label="props.dfTitleInside ? props.dfTitle : props.label"
    @update:model-value="emit('update:modelValue', $event);"
  />
</template>

<script setup lang="ts">
import { InputProps, InputEmits } from '../models/dform-input-core';

const props = defineProps<InputProps<string, unknown>>();
const emit = defineEmits<InputEmits<string>>();

</script>
