
import { ColorRules } from '@/utils/conditional-colors';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { DownlineServiceParams } from '@/utils/fetch-downline-service';

export type VisualTreeProps = {
  id?: string,
  properties: string[],
  popupProperties?: string[],
  treeId?: number;
  accountId?: number;
  headerTitles?: Record<string, string>;
  colors?: ColorRules;
  inputProperties?: InputByTypeProperties;
  backgroundColor?: string;
  initialFetch?: number,
  shortFetch?: number,
  longFetch?: number,
  uplineLimit?: number,

  titleField?: string,
  subtitleField?: string,
  titleFieldMax?: number,
  subtitleFieldMax?: number,

  cardLayout?: {
    cardWidth?: number,
    cardMargin?: number,
    avatarRadius?: number,
    titleFontSize?: number,
    subtitleFontSize?: number,
    propsFontSize?: number,
    buttonRadius?: number,
    cardPadding?: number,
    cardHorizontalGap?: number,
    cardVerticalGap?: number,
    propHorizontalGap?: number,
    propVerticalGap?: number,
    buttonPadding?: number,
  },
  showInactive?: DownlineServiceParams['showInactive'];
  maxNodesPerRequest?: number;
}

export const defaults = {
  treeId: 0,
  backgroundColor: 'rgb(196, 201, 250, 0.5)',
  initialFetch: 2,
  shortFetch: 1,
  longFetch: 3,
  uplineLimit: 500,
  titleField: 't.fullName',
  subtitleField: 't.accountPlusOffset',
  titleFieldMax: 25,
  subtitleFieldMax: 30,
  maxNodesPerRequest: 250,
  cardLayout: {
    cardWidth: 300,
    cardMargin: 40,
    avatarRadius: 30,
    titleFontSize: 20,
    subtitleFontSize: 15,
    propsFontSize: 14,
    buttonRadius: 20,
    cardPadding: 10,
    cardHorizontalGap: 14,
    cardVerticalGap: 20,
    propHorizontalGap: 32,
    propVerticalGap: 4,
    buttonPadding: 10,
  },
};
