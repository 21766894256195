
<script setup lang="ts">
import { watch, ref } from 'vue';
import { InputProps, InputEmits } from '../models/dform-input-core';
import { InputDFormValue, InputDFormData } from './InputDForm';

import { useDeepForm } from '@/deep-form/composables/useDeepForm';
import DeepForm from '@/deep-form/components/DeepForm.vue';

const props = defineProps<InputProps<InputDFormValue, InputDFormData>>();
const emit = defineEmits<InputEmits<InputDFormValue>>();
const usedForm = ref<ReturnType<typeof useDeepForm<InputDFormValue>>>();
const skipEmit = ref(false);
/*
  props.modelValue - изменение извне
  usedForm.value.value - изменение от внутренних компонентов
*/

watch( [() => props.dfData, () => props.modelValue], () => {
  if (!props.dfData) return undefined;
  if (usedForm.value) return;
  // console.log('dform re-create form:', props.dfPath, 'value:', props.modelValue);
  usedForm.value = useDeepForm<InputDFormValue>(props.modelValue || {}, props.dfData, props.dfRootFormProps);
}, { immediate: true, deep: false });

function emitValue(value: InputDFormValue) {
  if (skipEmit.value) {
    skipEmit.value = false;
    return;
  }
  // console.log('dform-emit:', value);
  emit('update:modelValue', value);
}

</script>

<template>
  <DeepForm
    v-if="usedForm?.props"
    v-bind="{ ... props, ... usedForm.props }"
    formDebugName="dform"
    @form-valid-state="emit('formValidState', $event);"
    @update:model-value="emitValue"
  />
</template>
