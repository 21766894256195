<template>
  <v-select
    v-model="selectedPeriod"
    data-component="period-select"

    :items="items"
    :loading="loading"
    :data-loading="loading ? 'true' : undefined"

    :label="$t('common.period')"
    itemTitle="label"
    itemValue="id"
    prependInnerIcon="mdi-calendar-month"
    :clearable="true"

    :singleLine="true"
    :density="inputProperties?.density"
    :variant="inputProperties?.variant"
    :rounded="inputProperties?.rounded"
    :flat="inputProperties?.flat"

    :returnObject="true"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { useLocalization } from '@/composables';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { PeriodVM, usePeriodApi } from '@ui-api3-sdk/api/api3';
import { PeriodSelectItem } from './PeriodSelect';


const props = defineProps<{
  modelValue?: PeriodSelectItem,
  inputProperties?: InputByTypeProperties,
}>();

const { d } = useLocalization();

const emit = defineEmits<{
  (event: 'update:modelValue', result: PeriodSelectItem): void,
  (event: 'loaded', result: PeriodSelectItem[]): void,
}>();

const loading = ref(false);
const items = ref<Array<PeriodSelectItem>>([]);
const totalItems = ref(0);
const selectedPeriod = ref<PeriodSelectItem | undefined>(undefined);

watch( () => props.modelValue, (newValue) => {
  selectedPeriod.value = newValue;
});

function parsePeriods(list: Array<PeriodVM>) {
  return list.map( (p, index) => ({
    ...p,
    label: periodName(p),
    index,
  }));
  function periodName(p: PeriodVM){
    const start = d(new Date(p.start_date), 'medium');
    const end = p.finish_date ? d(new Date(p.finish_date), 'medium') : '...';
    return `${start} - ${end}`;
  }
}

onMounted( async () => {

  loading.value = true;
  usePeriodApi().getPeriods({
    page: 0,
    limit: 100,
  })
    .then((res) => {
      items.value = parsePeriods(res.data.payload.list);
      totalItems.value = res.data.payload.total;

      if (selectedPeriod.value === undefined) {
        selectedPeriod.value = items.value[0];
      }

      emit('loaded', items.value);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });

});

</script>
