/* eslint-disable camelcase */
export default {
  error_placing: 'Error placing kid: {0}',
  placement_disabled: 'Manual placement disabled',
  place_confirm_header: 'Are you sure?',
  place: 'Place',
  under: 'under',
  left_leg: '- left leg?',
  right_leg: '- right leg?',

  lowest_left: 'Lowest left',
  lowest_right: 'Lowest right',
  lowest_pos: 'Lowest {0}',
  back_to_me: 'back to me',
};
