<template>
  <div>
    <div v-if="mode === 'component' && componentId && componentName" class="component-config-button">
      <v-icon
        :id="`drag-icon-${componentName}-${componentId}`"
        size="small"
        variant="text"
        :draggable="true"
        @click="openComponentById(componentName, componentId)"
        @dragstart="$event.dataTransfer.setData('text/plain', `${componentName}:${componentId}`);"
        @dragover.prevent="onDragOver($event)"
        @dragleave="onDragLeave($event)"
        @drop="moveItem($event.dataTransfer.getData('text/plain'), `${componentName}:${componentId}`, $event);"
        @contextmenu.prevent="onContextMenuClick"
      >
        mdi-cog
      </v-icon>
      <v-menu
        v-model="contextMenuOpen"
        :activator="`#drag-icon-${componentName}-${componentId}`"
        :openOnClick="false"
        location="center"
      >
        <v-list>
          <v-list-item
            v-for="(item, index) in contextMenuItems"
            :key="index"
            :value="index"
            :active="componentClass.includes(item.class)"
            @click="onContextItemClick(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else>
      <GravDialogEditComponent />
      <GravManagerWindow
        v-model="mainWindowOpen"
      />
      <v-menu
        v-model="mainMenuOpen"
        transition="scale-transition"
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" icon @click="fetchState()">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-progress-circular
            v-if="loading"
            :data-loading="true"
            class="ma-4"
            indeterminate
            color="primary"
          />
          <div v-else>
            <v-list>
              <v-list-item @click="mainWindowOpen = true">
                <v-icon>mdi-file-code-outline</v-icon>
                {{ currentPageLabel() }}
              </v-list-item>
            </v-list>
            <v-divider />
            <GravPageComponentsList :componentId="componentId" :attachTo="attachTo" />
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, nextTick } from 'vue';
import { useGravManager } from '../comosables/useGravManager';
import { useToast } from '@/composables';

import GravManagerWindow from './GravManagerWindow.vue';
import GravPageComponentsList from './GravPageComponentsList.vue';
// import GravDialogEditAnyComponent from './GravDialogEditAnyComponent.vue';
import GravDialogEditComponent from './GravDialogEditComponent.vue';

const props = withDefaults(defineProps<{
  mode: 'menu' | 'component';
  componentName?: string;
  componentId?: string;
   
  attachTo?: HTMLElement;
  forcePage?: string;
}>(), {
  mode: 'menu',
  componentId: undefined,
  componentName: undefined,
  attachTo: undefined,
  forcePage: undefined,
});

const grav = useGravManager();
const { loading } = toRefs(grav);

const mainMenuOpen = ref(false);
const mainWindowOpen = ref(false);
const contextMenuOpen = ref(false);
const componentClass = ref('');

type ContextMenuItem = { title: string; class: string };
const contextMenuItems: Array<ContextMenuItem> = [];

for (let i=1; i<=12; i++) {
  contextMenuItems.push({ title: `${i}`, class: `v-col-md-${i}` });
}

function onContextItemClick(item: ContextMenuItem) {
  if (! props.componentId ) return;
  const srcEl = document.getElementById(props.componentId);
  if (! srcEl) throw new Error('component not found');

  const classesArray = srcEl.className.split(/\s+/).filter(c => !c.startsWith('v-col'));
  classesArray.push(item.class);
  const newClassName = classesArray.join(' ');

  srcEl.className = newClassName;
  grav.upcliUpdateProperty(
    `${props.componentName}:${props.componentId}`,
    'class',
    { '*': newClassName },
  );

}

function onContextMenuClick() {
  if (! props.componentId ) return '';
  const srcEl = document.getElementById(props.componentId);
  if (! srcEl) throw new Error('component not found');
  componentClass.value = srcEl.className;

  contextMenuOpen.value = true;
}

async function fetchState() {

  if (props.forcePage)
    grav.setOverrideCurrentPage(props.forcePage);
  else grav.resetOverrideCurrentPage();

  await grav.upcliShow();
}

async function openComponentById(componentName: string, componentIdProp: string) {
  const component = () => grav.components.find(c => c.id === `${componentName}:${componentIdProp}`);
  if (!component()) await fetchState();

  const fullId = component()?.id;

  if (fullId) {
    grav.selectComponentById(fullId);
    return;
  }

  useToast().error(`Component not found: ${componentName} ${componentIdProp}`);
}

function currentPageLabel() {
  if (!grav.selectedPageName || !grav.selectedPageLanguage) return 'No current page';
  return `${grav.selectedPageName}.${grav.selectedPageLanguage}`;
}

 
function onDragOver(event: any) {
  const t = event.target;
  t.style.border='1px solid black';
}

 
function onDragLeave(event: any) {
  const t = event.target;
  t.style.border='none';
}

 
function moveItem(from: string, to: string, event: any) {

  const t = event.target;
  t.style.border='none';

  nextTick( () => {
    const idFrom = from.split(':').pop();
    const idTo = to.split(':').pop();

    if (! idFrom || !idTo) return;
    if (idFrom === idTo) return;

    const srcEl = document.getElementById(idFrom);
    const dstEl = document.getElementById(idTo);

    if (! srcEl || ! dstEl) return;

    const srcParent = srcEl.parentNode;
    const dstParent = dstEl.parentNode;

    if (!srcParent || !dstParent) return;

    const srcIndex = Array.from(srcParent.children).indexOf(srcEl);
    const dstIndex = Array.from(dstParent.children).indexOf(dstEl);

    if (srcIndex !== -1 && dstIndex !== -1) {
      //const clonedSrcEl = srcEl.cloneNode(true);

      //srcParent.insertBefore(clonedSrcEl, dstParent.children[dstIndex]);
      dstParent.insertBefore(srcEl, dstParent.children[dstIndex]);
      srcParent.insertBefore(dstEl, srcParent.children[srcIndex]);

      const tmp = dstEl.className;
      dstEl.className = srcEl.className;
      srcEl.className = tmp;

      useGravManager().upcliSwitchPlaces(from, to);

    }

  });
}

</script>

<style scoped>
.component-config-button {
  cursor: pointer;
  padding: 4px;

  /* color: var(--v-theme-background); */

  opacity: 0.3;

  /* background-color: rgba(var(--v-theme-on-background), 0.12); */
  border-radius: 50%;
}

</style>
