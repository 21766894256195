
import { TableColumnActionsSetupProps } from '@/composables/useTableColumnActions';
import { InputByTypeProperties } from '@/models/form-builder-interface';
import { DownlineServiceParams } from '@/utils/fetch-downline-service';

export type GenealogyTableProps = {
  id?: string;
  properties: string[],
  accountId?: number;
  headerTitles?: Record<string, string>;
  autoSwitchMode?: boolean;
  treeId?: number;
  buttonOnField?: string;
  inputProperties?: InputByTypeProperties;
  showInactive?: DownlineServiceParams['showInactive'];
  maxNodesPerRequest?: number;
} & TableColumnActionsSetupProps;

export const defaults = {
  autoSwitchMode: true,
  maxNodesPerRequest: 250,
  treeId: 0,
  buttonOnField: '0',
  popupInfoOnField: '0',
  popupLinkUrl: '/{{site_language}}/network/view-network/?nodeId={{s.id.raw}}',
};
