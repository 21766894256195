<script setup lang="ts">
import { InputEmits, InputProps } from '../models/dform-input-core';

const props = defineProps<InputProps<boolean, unknown>>();
const emit = defineEmits<InputEmits<boolean | null>>();

</script>

<template>
  <v-checkbox
    v-bind="props"
    :label="props.dfTitle"
    @update:model-value="emit('update:modelValue', $event);"
  />
</template>
