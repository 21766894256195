export interface MetricWidgetProps {
  id?: string;
  offset?: number;
  accountId?: number;
  title?: string;
  forceValue?: string;

  hintText?: string;
  hintIconText?: string;
  linkTemplate?: string;

  propertyAlias?: string;
  backgroundClass?: string;
  headerClass?: string;
  fontSize?: number;

  treeId?: number
  postfix?: string;
  plainType?: boolean;
  totalRound?: boolean;
  iconClass?: string;
  numberPeriodsBack?: number;
  valueOnEmpty?: string;
}

export const defaults = {
  propertyAlias: '',
  backgroundClass: 'bg-primary',
  fontSize: 30,
  iconClass: '',
  offset: 1,
  postfix: '',
  plainType: false,
  totalRound: false,
  treeId: 0,
  numberPeriodsBack: 0,
};
