<template>
  <v-tooltip
    :text="resultHint"
    :disabled="!resultHint"
    location="top"
  >
    <template #activator="{ props }">
      <MaybeLink :href="resultLink">
        <WidgetTemplate
          v-bind="{ ... props, ... $attrs }"
          :backgroundClass="backgroundClass"
          :headerClass="headerClass"
          :fontSize="fontSize"
          :postfix="postfix"
          :plainType="plainType"
          :totalRound="totalRound"
          :iconClass="iconClass"
          :wMM="wMM"
          :displayValue="resultValue"
          :loading="loading"
          :data-loading="loading ? 'true' : undefined"
          :title="resultTitle"
          :hintIconText="resultHintIconText"
          :error="error"
        >
          <slot name="default" v-bind="{ accountId: resultAccountId, value, rawValue, wMM: wMM2 }" />
        </WidgetTemplate>
      </MaybeLink>
    </template>
  </v-tooltip>
</template>

<script>
import { useAwaitAccountId, useLocalization } from '@/composables';
import { apiErrorToString, useAccountPositionApi } from '@ui-api3-sdk/api/api3';
import { defaults } from './MetricWidget';

import { wearMaskMoney, wearMaskMoney2 } from '@/utils/money';
import { getMissingServerTranslations } from '@/utils/properties-translation';
import { runTemplate } from '@/utils/property-template-service';

import WidgetTemplate from '@/components/ui/base/WidgetTemplate';
import MaybeLink from './base/MaybeLink.vue';

const { pt } = useLocalization();

export default {
  name: 'MetricWidget',
  components: {
    WidgetTemplate,
    MaybeLink,
  },

  props: {

    id: {
      type: String,
      default: undefined,
    },

    forceValue: {
      type: String,
      default: undefined,
    },

    title: {
      type: String,
      default: undefined,
    },

    hintText: {
      type: String,
      default: undefined,
    },

    hintIconText: {
      type: String,
      default: '',
    },

    linkTemplate: {
      type: String,
      default: undefined,
    },

    propertyAlias: {
      type: String,
      default: '',
    },

    accountId: {
      type: Number,
      default: null,
    },

    backgroundClass: {
      type: String,
      default: 'bg-primary',
    },

    headerClass: {
      type: String,
      default: undefined,
    },

    fontSize: {
      type: Number,
      default: defaults.fontSize,
    },

    treeId: {
      type: Number,
      default: defaults.treeId,
    },

    postfix: {
      type: String,
      default: defaults.postfix,
    },

    plainType: {
      type: Boolean,
      default: defaults.plainType,
    },

    totalRound: {
      type: Boolean,
      default: defaults.totalRound,
    },

    iconClass: {
      type: String,
      default: defaults.iconClass,
    },

    offset: {
      type: Number,
      default: defaults.offset,
    },

    numberPeriodsBack: {
      type: Number,
      default: defaults.numberPeriodsBack,
    },

    valueOnEmpty: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      wMM: wearMaskMoney,
      wMM2: wearMaskMoney2,
      value: '',
      rawValue: '',
      titleFrom: '',
      loading: true,
      error: '',
      resultAccountId: undefined,
    };
  },

  computed: {

    resultHint() {
      return this.hintText;
    },

    resultHintIconText() {
      return this.hintIconText;
    },


    resultLink() {
      if (!this.linkTemplate) {
        return '';
      }

      return runTemplate(this.linkTemplate);

    },

    resultValue() {
      if (this.forceValue) {
        return this.forceValue;
      }

      return this.value;
    },

    resultTitle() {

      if (this.title) {
        return this.title;
      }

      if (this.titleFrom) {
        return this.titleFrom;
      }

      return pt(`m.${this.propertyAlias}`);

    },
  },

  created() {
    if (! this.forceValue && this.propertyAlias)
      return this.fetch();
    this.loading = false;
  },

  methods: {
    async fetch() {
      // this.loading = true;

      const aId = await useAwaitAccountId(this.accountId);
      this.resultAccountId = aId;

      useAccountPositionApi()
        .getPosition({
          id: aId,
          treeId: this.treeId,
          properties: [this.propertyAlias],
          offset: this.offset,
          numberPeriodsBack: this.numberPeriodsBack,
        })
        .then(res => {
          const positions = res.data.payload;

          if (!positions.length || !positions[0]?.properties[0]?.value?.presentable) {

            if (this.valueOnEmpty !== undefined && this.valueOnEmpty !== '')  {
              this.value = this.valueOnEmpty;
              return;
            }

            throw new Error('No presentable value');

          }

          this.value = positions[0].properties[0].value.presentable;
          this.rawValue = positions[0].properties[0].value.raw;

          this.titleFrom = positions[0].properties[0].title;
        })
        .catch(e => {

          if (e?.response?.data?.error?.code === 12000) {

            if (this.valueOnEmpty !== undefined && this.valueOnEmpty !== '') {
              this.value = this.valueOnEmpty;
              return;
            }

            return;
          }

          this.error = apiErrorToString(e);
        })
        .finally(() => {
          this.loading = false;
          getMissingServerTranslations();
        });
    },
  },
};
</script>
