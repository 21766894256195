import { gravComponentSchema, createTranslationsInput } from '../deep-form-grav';
import { RegisterMemberProps, defaults } from '../../../../components/ui/RegisterMember';

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<RegisterMemberProps>({
  title: 'Register Member',
  link: 'https://www.notion.so/mlmsoft/RegisterMember-07f882213e014f55a3eb97918742612c',
  text: text(),
},
  defaults,
);

export default toExport;

const propInput = l.clone('profilePropertyForRegistration').props({ hideDetails: true, density: 'compact' });

s.add('profileFields').input('dlist').data(propInput.item())
  .title('Profile Fields').mandatory().validators([v.minItems(1)])
  .col(6).item();

s.add('optionalFields').input('dlist').data(propInput.item())
  .title('Optional Fields').col(6).item();

s.add('fieldTitles').as(
  createTranslationsInput('fieldTitles', l.clone('profilePropertyForRegistration')),
).title('Field Titles').col(12);


function text() {
  return `
<b>Is Member Registration Allowed?</b>
The usual way for new members to be enrolled to your program is by using a referral link and registering on your website and/or Online Office sign-up form. You may also want to allow your existing sales rep to register new members by themselves. Please be advised that these newly registered members must confirm their email with OTP (one-time password) anyway, so they must present at the moment of registration. Except for the email confirmation there’s no means used to control those registrations against fake account creation. That’s why in most cases we don’t recommend using this option.
<b>Password Generation</b>
There’s two options on how a user password is set: it can be either generated automatically, or it may be set by a user. In both scenarios it’s emailed to a user afterwards.
<b>Page Title and Description</b>
Please provide your custom title for the page. You may also provide a custom description which appears a line below the title. A menu icon can also be modified, please specify if you want to use a different icon.
<b>Profile Fields</b>
Please provide a list of profile fields for a registration form, and mark required fields with ‘*’.
Please note: fields sponsor, name, and email are must have on a registration form.
  `;
}
