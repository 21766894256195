<template>
  <div>
    <v-alert
      v-if="error"
      type="error"
      class="mb-4"
    >
      {{ error }}
    </v-alert>
    <BasicCard
      v-else
      :title="windowTitle"
      :headerClass="headerClass"
    >
      <slot
        v-if="currentDataItem"
        name="default"
        v-bind="slotParams(currentDataItem)"

        :yourCurrentRank="lt('current_rank')"
        :yourNextRank="lt('next_rank')"

        :earnedRankTitle="earnedRankTitle"
        :nextGoalRankTitle="nextGoalRankTitle"
        :currentRankTitle="currentRankTitle"

        :earnedRank="earnedRank"
        :nextGoalRank="nextGoalRank"
        :currentRank="currentRank"

        :reachedMaxRank="reachedMaxRank"
        :notReachedFirstRank="notReachedFirstRank"

        :metricData="metricData"
      />

      <div v-for="metric of metricsList" :key="metric">
        <!--
          export interface MetricBarStyle {
  type?: 'grid' | 'percent';

  color?: string;
  bgColor?: string;
  colorDone?: string;

  striped?: boolean;
  labelIsPercent?: boolean;

  rounded?: boolean;
  size?: number;
  titleClass?: string;
  labelClass?: string;

}
        -->
        <slot v-if="metricData[metric]?.max" name="metric" :data="metricData[metric]">
          <ProgressMetric
            class="mb-2"
            :metric="metric"
            :type="metricData[metric]?.type!"
            :title="metricData[metric]?.title"
            :value="metricData[metric]?.value"
            :max="metricData[metric]?.max"
            :color="metricData[metric]?.color"
            :bgColor="metricData[metric]?.bgColor"
            :colorDone="metricData[metric]?.colorDone"
            :striped="metricData[metric]?.striped"
            :labelIsPercent="metricData[metric]?.labelIsPercent"
            :rounded="metricData[metric]?.rounded"
            :size="metricData[metric]?.size"
            :titleClass="metricData[metric]?.titleClass"
            :labelClass="metricData[metric]?.labelClass"
            :loading="loading"
            :data-loading="loading ? 'true' : undefined"
            :noCheckbox="false"
          />
        </slot>
      </div>

      <slot
        v-if="currentDataItem"
        name="after"
        v-bind="slotParams(currentDataItem)"

        :yourCurrentRank="lt('current_rank')"
        :yourNextRank="lt('next_rank')"

        :earnedRankTitle="earnedRankTitle"
        :nextGoalRankTitle="nextGoalRankTitle"
        :currentRankTitle="currentRankTitle"

        :earnedRank="earnedRank"
        :nextGoalRank="nextGoalRank"
        :currentRank="currentRank"

        :reachedMaxRank="reachedMaxRank"
        :notReachedFirstRank="notReachedFirstRank"

        :metricData="metricData"
      />
    </BasicCard>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import { apiErrorToString } from '@ui-api3-sdk/api/api3';
import { useLocalization, useProperties } from '@/composables';
import { TableGridItem, slotParams } from '@/models/table-grid-interface';
import { fetchFullProperties } from '@/utils/fetch-full-properties-service';
import { runTemplate } from '@/utils/property-template-service';

import { MetricBarDefinition, RankDefinition, RankProgressProps, defaults } from './RankProgress.ts';

import { filterUndefinedOrEmpty } from '@/utils/utils';
import BasicCard from './base/BasicCard.vue';
import ProgressMetric from './ProgressMetric.vue';

type Rank = RankDefinition & {
  index: number;
};

type MetricData = {
  value: number;
  max: number;
} & MetricBarDefinition;

const props = withDefaults(defineProps<RankProgressProps>(), {
  id: undefined,
  headerClass: undefined,
  accountId: undefined,
  types: undefined,
  dataItem: undefined,
  items: () => defaults.items,
  ranks: () => defaults.ranks,
  title: () => defaults.title,
  treeId: () => defaults.treeId,
  metricTitles: () => defaults.metricTitles,
  rankField: () => defaults.rankField,
  noSkipLevel: () => defaults.noSkipLevel,
  styleDefaults: () => defaults.styleDefaults,
});

const { lt } = useLocalization('uiRankProgress');

const metricsList = getMetricsList();

const usedProperties = useProperties(
  metricsList,
  [],
  props.metricTitles,
);

const { propertyTranslations, allFetchableProperties, setMandatoryProperties } = usedProperties;

const error = ref('');
const loading = ref(false);

const fetchedItem = ref<TableGridItem | undefined>(undefined);
const currentDataItem = computed(() => props.dataItem || fetchedItem.value);
const ranks = computed<Rank[]>(() => props.ranks.map((rank, index) => ({ ...rank, index })));

const earnedRank = ref<Rank | undefined>(undefined);
const nextGoalRank = ref<Rank | undefined>(undefined);
const currentRank = computed(() => ranks.value.find((rank) => rank.title === currentDataItem.value?.[props.rankField]?.presentable));

const reachedMaxRank = ref(false);
const notReachedFirstRank = ref(false);

const metricData = ref<Record<string, MetricData>>({});
const reversedRanks = computed<Rank[]>(() => [...ranks.value].reverse());

const currentRankTitle = computed(() => String(currentDataItem.value?.[props.rankField]?.presentable || ''));
const earnedRankTitle = computed(() => notReachedFirstRank.value ? lt('no_rank') : earnedRank.value?.title || '');
const nextGoalRankTitle = computed(() => reachedMaxRank.value ? lt('max_rank') : nextGoalRank.value?.title || '');


const windowTitle = computed(() => {
  if (! currentDataItem.value) return lt('rank_progress');
  return runTemplate(props.title, currentDataItem.value, {
    yourCurrentRank: lt('current_rank'),
    yourNextRank: lt('next_rank'),
    earnedRankTitle: earnedRankTitle.value,
    nextGoalRankTitle: nextGoalRankTitle.value,
    currentRankTitle: currentRankTitle.value,
  });
});


watch( [currentDataItem, nextGoalRank, earnedRank, propertyTranslations], () => {
  const data: Record<string, MetricData> = {};

  metricsList.forEach((metric) => {
    const item = getMetricData(metric);
    if (item) data[metric] = item;
  });

  metricData.value = data;

}, { immediate: true, deep: true });


watch( [currentDataItem, currentRank], () => {
  if (! currentDataItem.value) return;

  let maxIndex = ranks.value.length - 1;

  if (props.noSkipLevel)
    maxIndex = Math.min(maxIndex, currentRank.value?.index === undefined ? 0 : currentRank.value.index + 1);

  // eslint-disable-next-line no-nested-ternary
  const minIndex = props.noDropRank ?
    currentRank.value?.index === undefined ? 0 : currentRank.value.index
    : 0;

  console.log('maxIndex', maxIndex, 'currentRank.value', currentRank.value, 'minIndex', minIndex);

  earnedRank.value = reversedRanks.value.
    filter((rank) => rank.index >= minIndex).
    find((rank) => {
      const levels = Object.entries(rank.levels);
      const v = currentDataItem.value!;
      const passed = levels.every(([key, value]) => v[key] && Number(v[key].raw) >= value);
      if (passed && rank.index <= maxIndex) {
        return true;
      }
    });

  if (! earnedRank.value && props.noDropRank) {
    earnedRank.value = currentRank.value;
  }

  if ( ! earnedRank.value ) {
    notReachedFirstRank.value = true;
    nextGoalRank.value = ranks.value[0];
    return;
  }

  if (earnedRank.value.index >= maxIndex) {
    reachedMaxRank.value = true;
    return;
  }

  if ( currentRank.value ) {

    if (earnedRank.value.index < currentRank.value.index) {
      earnedRank.value = currentRank.value;
    }

  }

  nextGoalRank.value = ranks.value[earnedRank.value.index + 1];

}, { immediate: true });


onMounted(async () => {

  if (props.dataItem) return;

  loading.value = true;

  try {
    setMandatoryProperties([props.rankField]);
    fetchedItem.value = await fetchFullProperties(allFetchableProperties(), props.treeId);
  } catch (e) {
    error.value = apiErrorToString(e);
    console.error('RankProgress: ', e);
  }

  loading.value = false;

});


function getMetricsList() {
  if (typeof props.metrics[0] === 'string') {
    return props.metrics as string[];
  }
  return (props.metrics as unknown as MetricBarDefinition[]).map((metric) => metric.metric);
}

function getMetricsDefinition(metric: string): MetricBarDefinition {

  if (typeof props.metrics[0] === 'string') {
    return { ... props.styleDefaults, metric };
  }
  const foundDefinition = (props.metrics as unknown as MetricBarDefinition[]).find((m) => m.metric === metric);
  if (! foundDefinition) throw new Error(`Metric ${metric} not found in metrics definition`);

  return { ... props.styleDefaults, ... filterUndefinedOrEmpty(foundDefinition) };
}

function getMetricData(metric: string): MetricData | null {
  if (!currentDataItem.value) return null;
  return {
    ... getMetricsDefinition(metric),
    metric,
    value: Number(currentDataItem.value[metric]?.raw),
    max: nextGoalRank.value ? nextGoalRank.value.levels[metric] : earnedRank.value?.levels[metric] || -1,
    title: propertyTranslations.value[metric],
  };
}

</script>
