import { MenuItem } from '@/models/menu';
import { TableGridItem } from '@/models/table-grid-interface';
import { fetchFullProperties } from '@/utils/fetch-full-properties-service';
import { defineStore } from 'pinia';

const TIMEOUT = 1000 * 60; // 1 minute

function removeLanguagePrefix(path: string) {
  return path.replace(/(^\/[a-z]{2}(?:-[a-zA-Z]{2,4}){0,1})($|\/)/, '$2');
}

function filterMenu(items: MenuItem[], hidden: string[]) {
  return items.filter((item) => {
    if (item.children) {
      item.children = filterMenu(item.children, hidden);
    }
    return !hidden.includes(removeLanguagePrefix(item.href || '-'));
  });
}

// some mebers or children have conditions
function hasConditions(items: MenuItem[]): boolean {
  return items.some((item) => !!item.condition || (item.children && hasConditions(item.children)));
}

type TBGObject = Record<string, Record<string, string | number | undefined | null | boolean | object>>;


function tableGridItemToObject(data: TableGridItem) {
  return Object.keys(data).reduce((acc, key) => {
    // split key by dot
    const [prefix, name] = key.split('.');

    if (!acc[prefix]) {
      acc[prefix] = {};
    }

    acc[prefix][name] = data[key].raw;

    return acc;
  }, {} as TBGObject);
}


function toCamelCase(str: string): string {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

function conditionProcessorFactory(dataItem: TableGridItem) {

  const data = tableGridItemToObject(dataItem);

  const keys = Object.keys(data);
  // eslint-disable-next-line prefer-template
  const code = keys.map((key) => `const ${toCamelCase(key)} = ${JSON.stringify(data[key])};`).join("\n\r") + '\n\r return ';

  return function checkCondition(condition: string) {

    const codeCondition = `${code}${condition};`;

    try {

      const fn = new Function(codeCondition);
      const res = fn();

      console.log('CM: Evaluated condition', condition, ' = ', res);

      return res;
    } catch (e) {
      console.error('Failed to evaluate condition', condition, e);
      return false;
    }
  };


}


function runConditionsAndGenerateHidden(items: MenuItem[], checkFn: (s: string) => boolean): string[] {

  return items.reduce((acc, item) => {
    if (item.condition) {
      if (checkFn(item.condition)) {
        return acc;
      }
      return [...acc, removeLanguagePrefix(item.href || '-')];
    }

    if (item.children) {
      return [...acc, ...runConditionsAndGenerateHidden(item.children, checkFn)];
    }

    return acc;
  }, [] as string[]);
}


export function useConditionalMenu() {

  const state = usePersistMenuStore();

  async function filter(items: MenuItem[]) {

    if (!hasConditions(items)) {
      return items;
    }

    if (!state.isExpired) {
      return filterMenu(items, state.hidden);
    }
    // fetch full properties, re-run conditions, store hidden and return filtered

    try {
      const dataItem = await fetchFullProperties();
      const checkFn = conditionProcessorFactory(dataItem);
      const hidden = runConditionsAndGenerateHidden(items, checkFn);
      state.setHidden(hidden);
      return filterMenu(items, hidden);
    } catch (e) {
      console.error('Failed to fetch full properties', e);
    }

  }

  return {
    filter,
  };
}

export const usePersistMenuStore = defineStore('menu', {
  state: () => ({
    timestamp: 0,
    hidden: [] as string[],
  }),
  persist: {
    storage: sessionStorage,
  },
  getters: {
    isExpired(state) {
      return state.timestamp + TIMEOUT < Date.now();
    },
    getHidden(state) {
      if (!this.isExpired) {
        return state.hidden;
      }
    },
  },
  actions: {
    setHidden(hidden: string[]) {
      this.hidden = hidden;
      this.timestamp = Date.now();
    },
  },
});
