<template>
  <div>
    <div
      :style="{
        width: '100%',
        padding: '0px',
        height: `${size}px`,
        borderRadius: rounded ? `${size}px` : undefined,
        overflow: 'hidden',
      }"
    >
      <div
        v-if="loading"
        :style="{
          backgroundColor: bgColor,
          width: '100%',
        }"
      >
        <v-progress-circular
          :data-loading="true"
          indeterminate
          color="primary"
          :size="size"
        />
      </div>
      <div
        v-else
      >
        <div
          v-for="(entry, index) in resStackedValues"
          :key="index"
          :style="{
            display: 'inline-block',
            width: `${entry.percent}%`,
            backgroundColor: entry.bgColor,
          }"
          :class="entry.bgClass"
        >
          <ProgressMetricPercent
            :loading="loading"
            :data-loading="loading ? 'true' : undefined"

            :percent="100"

            :colorDone="entry.bgColor"
            :size="size"
            :striped="entry.striped"
            :rounded="entry.rounded"

            :noLabel="!entry.showLabel"

            :textClass="entry.labelClass"
            :label="entry.label || (entry.value ? String(entry.value) : false) || `${(entry.percent || 0).toFixed(2)}%`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { StackableEntry } from './ProgressMetricStackable';
 
import ProgressMetricPercent from './ProgressMetricPercent.vue';

 
const props = withDefaults(defineProps<{
  max?: number;
  stackedValues: StackableEntry[];

  loading?: boolean;
  size?: number;
  bgColor?: string;
  rounded?: boolean;

}>(), {
  max: undefined,
  size: 30,
  bgColor: undefined,
  loading: false,
  rounded: false,
});

const percentedValues = computed(() => {
  if (props.max === undefined || ! props.max)
    return props.stackedValues;

  const res = props.stackedValues.map( (sv) => {
    const nv = { ... sv };
    if (! props.max || nv.value === undefined)
      throw new Error('Either max or one of the values is undefined');

    nv.percent = nv.value / props.max * 100;
    return nv;
  },
  );

  return res;

});

const sumPercent = computed(() => percentedValues.value.reduce( (acc, entry) => acc + (entry.percent || 0), 0 ));

const remainingPercent = computed( () => Math.max(
  0, 100 - sumPercent.value,
));


const resStackedValues = computed(() => {
  const remaining = remainingPercent.value;
  if (remaining <= 0)
    return percentedValues.value;
  const res = [ ... percentedValues.value, {
    percent: remaining,
    bgColor: props.bgColor,
  } as StackableEntry];
  return res;
});


</script>
