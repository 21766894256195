<template>
  <div>
    <!-- <v-textarea
      v-model="value"
      :label="fileName"
      :rows="5"
    />
    <div>fname = {{ fileName }}</div> -->
    <div
      class="back"
      :style="`width: ${width}px; height: ${height}px;`"
    >
      <div
        v-if="value"
        class="overlay"
      >
        <img
          :src="srcValue"
          alt="image"
          :style="`width: ${width}px; height: ${height}px; object-fit: cover;`"
        >
      </div>
      <div
        v-if="value"
        class="overlay"
        style="align-items: flex-start;justify-content: flex-end;"
      >
        <v-btn
          color="red"
          icon="mdi-close"
          size="small"
          @click="value = undefined; error = undefined;"
        />
      </div>
      <div
        v-else
        class="overlay"
      >
        <v-btn
          color="white"
          variant="text"
          icon="mdi-upload"
          @click="onUpload"
        />
      </div>
    </div>
    <v-alert
      v-if="error"
      :style="`width: ${width}px;`"
      color="error"
      variant="tonal"
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Image64 } from './image64.interface ';
const props = withDefaults(defineProps<{
  modelValue?: Image64;
  width?: number;
  height?: number;
  maxBytes?: number;
}>(), {
  modelValue: undefined,
  width: 200,
  height: 200,
  maxBytes: 2 * 1024 * 1024, // 2MB
});

const { t } = useI18n();

const error = ref<string | undefined>(undefined);

const emit = defineEmits<{
  ( event: 'update:modelValue', value: Image64 | undefined | string ): void;
}>();

const fileName = ref<string | undefined>(props.modelValue?.fileName);

const srcValue = computed(() => value.value ? addPrefix(value.value) : undefined);

const onlyUrlProvided = computed(() => {
  if (! props.modelValue) return false;
  if (! props.modelValue.fileData && props.modelValue.imageUrl?.startsWith('http')) return true;
  return false;
});

const value = computed<string | undefined>({
  get: () => props.modelValue?.fileData,
  set: (val: string | undefined) => {
    if (val === undefined) {
      emit('update:modelValue', undefined);
      return;
    }
    emit('update:modelValue', {
      fileData: val,
      fileName: fileName.value || '',
    });
  },
});


watch ( onlyUrlProvided, async (val) => {
  if (val) {

    const imgExtension = props.modelValue?.imageUrl?.split('.').pop()?.toLocaleLowerCase() || 'png';
    const urlFileName = props.modelValue?.imageUrl?.split('/').pop();

    const response = await fetch(props.modelValue?.imageUrl || '');
    const respBlob = await response.blob();
    const blob = new Blob([respBlob], { type: `image/${imgExtension}` });

    const reader = new FileReader();
    reader.onload = () => {
      const downloadedImg = reader.result as string;
      fileName.value = urlFileName;
      value.value = downloadedImg;
    };
    reader.readAsDataURL(blob);
  }
}, { immediate: true });

// function removePrefix(value: string) {
//   return value.replace(/^data:image\/\w+;base64,/, '');
// }

function addPrefix(value: string) {

  const extension = fileName.value?.split('.').pop()?.toLocaleLowerCase() || 'png';

  if (!value.startsWith('data:image/')) {
    return `data:image/${extension};base64,${value}`;
  }

  return value;
}

function safeFileName(value: string) {
  // limit size to 30 characters but keep extension
  const safe = value.replace(/[^.a-z0-9]/gi, '_').toLowerCase();
  return safe.substring(0, 30) + safe.substring(value.lastIndexOf('.'));
}

function onUpload() {
  error.value = undefined;
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = (e) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;

    // Check file size
    if (file.size > props.maxBytes) {
      const maxSizeMB = (props.maxBytes / 1024 / 1024).toFixed(2);
      error.value = t('inputs.err.file_size_limit_mb', [maxSizeMB]);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result !== 'string') return;
      fileName.value = safeFileName(file.name);
      value.value = result;
      console.log('file', file);
    };
    reader.readAsDataURL(file);
  };
  input.click();
}

</script>

<style scoped>
.back {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  background-color: rgba(0, 0, 0, 0.5);
}

.back:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay {
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #ffffff;
}

</style>
./image64
