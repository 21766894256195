<template>
  <GravManagerWrapper
    :id="id"
    :componentName="componentName"
    :properties="props"
    :xOffset="-5"
    :yOffset="-15"
    forcePage="header"
  >
    <component :is="UserPop" v-bind="{ style: $attrs.style, ...changableProps }">
      <template v-for="slotName in Object.keys($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </component>
  </GravManagerWrapper>
</template>

<script setup lang="ts">
import GravManagerWrapper from './GravManagerWrapper.vue';
import { UserPopProps } from './UserPop.ts';
import UserPop from './UserPop.vue';

import { useChangableProps } from '@/composables/useChangableProps';

const componentName = 'ui-user-pop';
const $slots = defineSlots<Record<string, unknown>>();

const props = defineProps<UserPopProps>();
const changableProps = useChangableProps(componentName, props);

</script>
