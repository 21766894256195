 
export let GravManagerCogComponent: any = undefined;

export async function getGravManagerCogComponent() {

  if (GravManagerCogComponent)
    return GravManagerCogComponent;

  GravManagerCogComponent = (await import('@/grav/front/components/GravManagerCog.vue')).default;
  return GravManagerCogComponent;
}
