/* eslint-disable camelcase */
export default {
  hint: 'Click or drag and drop a file into this window',
  loading: 'Loading...',
  browser_not_supported: 'Your browser is not supported. Please use IE10+ or other modern browsers',
  upload_success: 'Upload successful',
  upload_fail: 'Upload failed',
  preview: 'Preview',
  error_only_img: 'Only images are allowed',
  error_out_of_size: 'Image exceeds the maximum size of {0} kilobytes',
  error_lowest_px: 'Minimum image dimensions are {0} x {1} pixels',
};
