/* eslint-disable camelcase */
export default {
  out_of: 'из',
  current_rank: 'Текущий ранг',
  next_rank: 'Следующий ранг',
  rank_progress: 'Ваш прогресс',
  rank: 'Ранг',
  no_rank: 'Ранг не достигнут',
  max_rank: 'Максимальный ранг',
};
