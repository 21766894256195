<template>
  <div style="max-width: 600px;" class="v-row">
    <VSelect
      v-model="searchBy"
      data-component="search-filter"
      class="v-col-4"
      prependInnerIcon="mdi-magnify"
      :items="searchByItems"
      :singleLine="true"
      density="compact"
      variant="outlined"
      :flat="true"
      :hideDetails="true"
      itemTitle="title"
      itemValue="id"
    />
    <VTextField
      v-model="searchText"
      class="v-col-8"
      style="min-width: 230px"
      :label="$t('common.search')"
      clearable
      singleLine
      :hideDetails="true"
      density="compact"
    />
  </div>
</template>

<script setup lang="ts">
import { FilterItem } from '@ui-api3-sdk/api/api3';
import { useSearchFilter } from '@/composables/useSearchFilter';
import { watch } from 'vue';

const props = withDefaults(defineProps<{
  properties: string[],
  defaultSearchBy?: string,
  initialFilter?: FilterItem[];
}>(), {
  defaultSearchBy: undefined,
  initialFilter: () => [],
});

const emit = defineEmits<{
  (event: 'updated', result: FilterItem[]): void,
}>();

const { searchBy, searchByItems, searchText, resultSearchFilter }
  = useSearchFilter(props.properties, props.initialFilter, props.defaultSearchBy );

watch( resultSearchFilter, () => emit('updated', resultSearchFilter.value) );

</script>
