import { RefLinkProps, defaults } from '@/components/ui/RefLink';
import { gravComponentSchema } from '../deep-form-grav';

type WithSlots = RefLinkProps & {
  '#default': string;
  '#after': string;
};

 
const { s, l, bl, c, v, src, h, toExport } = gravComponentSchema<WithSlots>({
  title: 'RefLink',
  link: 'https://www.notion.so/mlmsoft/RefLink-7a18125839df4a248703d2becef496da',
  text: text(),
},
  defaults,
);

export default toExport;

s.add('headerClass').input('mdiColor').title('Header calss').col(4);
s.add('title').input('text').title('Title').col(8);

s.add('template').input('text').title('Url template').col(12);

s.add('singleLine').input('boolean').title('Inline layout')
  .desc('No window, just a single line')
  .col(4);

s.add('showUrl').input('boolean').title('Show url').col(4);
s.add('showQr').input('boolean').title('Show QR Code').col(4);

s.add('maxVisibleChars').as(bl.clone('number')).title('Max visible chars')
  .desc('If url is more than this number of chars, will be cut in the middle')
  .col(6);

s.add('qrSize').as(bl.clone('number')).title('QR size')
  .desc('Determines the size of the QR code in pixels')
  .col(6);

s.add('#default').input('vtextarea').props({ clearable: true })
  .flag('isSlot').flag('isText').flag('slotParams', "url")
  .title('Text before').col(12);

s.add('#after').input('vtextarea').props({ clearable: true })
  .flag('isSlot').flag('isText').flag('slotParams', "url")
  .title('Text after').col(12);


function text() {
  return `
<b>Referral Links</b>
MLM Soft platform allows you to have more than one referral link. It’s all set up during an integration. For example, one link may lead to your online store, where you sell your product, and another one may lead to your landing page where you present your affiliate program. Or, one link may encapsulate a 10% retail discount in it, so your sales reps may decide if they want to send a link without a discount or with it. This section is closely related to integrations that are included in your customization scope. Please provide additional details on what referral links you want to use here.
Please note: it may require additional integration work, and therefore affect a setup fee.
`;
}
