<template>
  <PlainCard>
    <FormBuilder
      v-bind="formProps"
      :inputProperties="resultInputProperties"
      @submit="onSubmit"
    >
      <template #belowForm>
        <v-alert
          v-if="registeredUserInfo"
          type="success"
          :value="true"
          closable
          @update:model-value="registeredUserInfo = null"
        >
          {{ lt('account_created') }}<br>
          {{ lt('id_of_created_user') }}: {{ registeredUserInfo.accountId }}<br>
        </v-alert>
      </template>
    </FormBuilder>
  </PlainCard>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { RegisterMemberProps, defaults } from './RegisterMember.ts';

import { FormResult, genFormFieldFromAlias } from '@/models/form-builder-interface';

import { useAccountApi, useAccountCreationApi } from '@ui-api3-sdk/api/api3';
import { useAwaitAccountId, useEnrollSettings, useFormBuilder, useLocalization, useToast } from '@/composables';
import { usePropertiesStore } from '@/pinia/usePropertiesStore';
import { resolveListStatic } from '@/utils/properties-mapping-service';
import { generatePassword } from '@/utils/utils';

import FormBuilder from './base/FormBuilder.vue';
import PlainCard from './base/PlainCard.vue';

const { lt } = useLocalization('uiRegisterMember', [
  'email_not_unique',
  'phone_not_unique',
  'account_created',
  'unknown_uniqueness_error',
  'unknown_create_error',
  'id_of_created_user',
]);

const props = withDefaults(defineProps<RegisterMemberProps>(), {
  id: undefined,
  fieldTitles: undefined,
  optionalFields: undefined,
  profileFields: () => defaults.profileFields,
  inputProperties: () => defaults.inputProperties,
});

const { inputProperties: resultInputProperties } = useEnrollSettings(props.inputProperties);
const { props: formProps, methods: form } = useFormBuilder([]);

type RegisteredUserInfo = {
  name: string,
  accountId: string;
}

const CUSTOM_FIELDS = resolveListStatic('custom').map(p => p.replace('custom.', ''));

const registeredUserInfo = ref<RegisteredUserInfo | null>(null);

function isCustomProperty(alias: string): boolean {
  return CUSTOM_FIELDS.includes(alias);
}

onMounted(async () => {
  await usePropertiesStore().getPfAsync();

  const fields = props.profileFields.map(alias => {
    const field = {
      ... genFormFieldFromAlias(`${ isCustomProperty(alias) ? 'custom' : 'p' }.${alias}`),
      isMandatory: ! isFieldOptional(alias),
      alias,
    };
    if (props.fieldTitles?.[alias]) {
      field.title = props.fieldTitles[alias];
    }
    return field;
  });

  form.setFields(fields);
});

const onSubmit = async (res: FormResult) => {

  const { phone, email } = res;

  form.setLoading(true);

  const uniqueCheckResult = await useAccountApi()
    .otpUniquenessCheck({
      uniquenessCheckReq: { phone, email },
    })
    .catch(err => {
      if (form.parseApiValidation(err)) return;
      form.addError(lt('unknown_uniqueness_error'));
    })
    .finally(() => {
      form.setLoading(false);
    });

  if (!uniqueCheckResult?.data?.payload) return;
  const { emailUnique, phoneUnique } = uniqueCheckResult?.data?.payload || {};

  if ( email && emailUnique === false ) form.addFieldError('email', lt('email_not_unique'));
  if ( phone && phoneUnique === false ) form.addFieldError('phone', lt('phone_not_unique'));

  if ( form.hasErrors() ) return;

  // creating

  form.setLoading(true);

  const profile = { ... res };

  Object.keys(profile).forEach( k => {
    if (! profile[k]) delete profile[k];
  } );

  CUSTOM_FIELDS.forEach( cf => delete profile[cf] );

  const aId = await useAwaitAccountId();

  useAccountCreationApi()
    .accountCreateControllerCreateAccount({
      accountCreateReq: {
        login: email,
        password: getPasswordValue(),
        sponsorId: aId,
        profile,
      },
    })
    .then((res) => {


      const info = res.data.payload.info as any;

      registeredUserInfo.value = {
        name:      info.title as string,
        accountId: info.account.id as string,
      };

      useToast().ok(lt('account_created'));
      form.reset();
      form.clearErrors();
    })
    .catch(err => {
      if (form.parseApiValidation(err)) return;
      form.addError(lt('unknown_create_error'));
    })
    .finally(() => {
      form.setLoading(false);
    });

};


function getPasswordValue() {
  if (form.hasField('password')) return form.getField('password').value;

  return generatePassword({
    size: props.inputProperties?.passwordMinLength || 6,
    hasUpper: props.inputProperties.passwordHasCamelcase || false,
    hasSpecial: props.inputProperties.passwordHasSpecSymbols || false,
  });
}

function isFieldOptional(alias: string) {
  return props.optionalFields?.includes(alias);
}

</script>
