/* eslint-disable camelcase */
export default {
  title: "Transfer between wallets",

  label_src_balance: "Balance",
  label_dst_balance: "Balance",
  result_sum: "Outcome",
  commission: "Commission",
  exchange_rate: "Exchange rate",

  label_transfer_to: "Destination wallet",
  label_transfer_from: "Source wallet",
  error_wallet_required: "Wallet is required",

  label_debit_amount: "Debit amount",
  label_transfer_amount: "Transfer amount",
  label_amount_to_receive: "Amount to Receive",

  button_transfer: "Transfer",
  button_reset: "Reset",
  error_balance_must_be_positive: "Balance must be positive",
  error_amount_required: "Amount is required",
  error_not_number: "Amount must be a number",
  error_not_enough_balance: "Insufficient funds on source wallet",
  transfer_success: "Transfer successful",
};
