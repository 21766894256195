
import { useRuntimeSettingsStore } from '@/pinia/useRuntimeSettingsStore';
import { useLocalization } from './useLocalization';
import { auth } from '@ui-api3-sdk/api';

export const useNavigation = () => {

  const runtimeSettings = useRuntimeSettingsStore();

  const urlParams = new URLSearchParams(window.location.search);
  const { locale } = useLocalization();

  function param(name: string) {
    return urlParams.get(name);
  }

  function go(localUrlNoLanguage: string, language?: string) {
    if (localUrlNoLanguage.startsWith('http')) {
      redirect(localUrlNoLanguage);
      return;
    }
    const nUrl = localUrlNoLanguage.replace(/^\//, '');
    const lang = language || locale.value;
    const newLoc = nUrl ? `/${lang}/${nUrl}` : `/${lang}`;

    if (newLoc.endsWith('/')) {
      window.location.href = newLoc.slice(0, -1);
      return;
    }

    window.location.href = newLoc;
  }

  function redirect(url: string) {
    if (url && url?.endsWith('/')) {
      window.location.href = url.slice(0, -1);
      return;
    }
    window.location.href = url;
  }

  function getCurrentPath() {
    const path = window.location.pathname;
    const nPath = path.replace(/(^\/[a-z]{2}(?:-[a-zA-Z]{2,4}){0,1})($|\/)/, '$2');
    // console.log('getCurrentPath', path, '->', nPath);
    return nPath;
  }

  function isLoginPage() {
    return getCurrentPath() === runtimeSettings.getLoginPath;
  }

  function isRegistrationPage() {
    return getCurrentPath() === runtimeSettings.getRegistrationPath;
  }

  function toLogin() {
    go(runtimeSettings.getLoginPath);
  }

  function redirectToLoginIfNeeded() {
    if (auth.accessTokenExists())
      return false;
    return redirectToLoginIfNotAllready();
  }

  function isLoginOrRegistrationPage() {
    return isLoginPage() || isRegistrationPage();
  }

  function redirectToLoginIfNotAllready() {
    if (isLoginOrRegistrationPage())
      return false;

    if (runtimeSettings.getLogoutRedirectUrl) {
      redirect(runtimeSettings.getLogoutRedirectUrl);
      return true;
    }

    toLogin();
    return true;
  }

  function changeLanguage(newLang: string) {
    go(getCurrentPath(), newLang);
  }

  return {
    param,
    go,
    redirect,
    toLogin,
    isLoginPage,
    isRegistrationPage,
    changeLanguage,
    getCurrentPath,
    redirectToLoginIfNeeded,
    redirectToLoginIfNotAllready,
    isLoginOrRegistrationPage,
  };
};
