
import { TableGridItem } from '@/models/table-grid-interface';
import { replaceProperty } from './properties-mapping-service';

export function fullName(item: TableGridItem) {
  const res: string[] = [];

  const overriden  = replaceProperty('r.fullName');

  if (overriden !== 'r.fullName' && overriden !== 't.fullName') {
    const fullName = item[overriden]?.presentable;
    return String(fullName);
  }

  const fn = item['p.firstname']?.presentable;
  const ln = item['p.lastname']?.presentable;

  if (fn) res.push(String(fn));
  if (ln) res.push(String(ln));

  return res.join(' ');
}

export function namePlusId(item: TableGridItem) {
  const pid = presentableId(item);
  return `${fullName(item)} (${pid})`;
}

export function presentableId(item: TableGridItem) {
  const prop = replaceProperty('r.id') || 's.id';
  return String(item[prop]?.presentable || item[prop]?.raw || '');
}

export function idWithOffset(item: TableGridItem) {
  const id = presentableId(item);
  if (!id) return '';

  const offset = Number(item['vmdl.offset']?.raw || 1);
  if (offset > 1) {
    return `${id} / ${offset}`;
  }
  return id;
}
